import React from "react";

import Title from "antd/lib/typography/Title";

export default function TermsAndConditions() {
  return (
    <div className="landing-section termsAndPolicy">
      <Title>TERMS & CONDITIONS </Title>
      <p>Effective from September 10th 2023</p>
      <br />
      By accessing the Services and this Website you (as referred to as « the
      Customer » or « User » or simply « You ») are agreeing to be bound by all
      these Terms and Conditions. The « Services » refers all services provided
      by CTOT.app, including this Website. The « Provider » or simply « We »,
      means CTOT.app, a company registered under the laws of France who is the
      editor for this website and other services.
      <br />
      <br />
      Terms and conditions By accessing the Services and this Website you (as
      referred to as « the Customer » or « User » or simply « You ») are
      agreeing to be bound by all these Terms and Conditions. The « Services »
      refers all services provided by CTOT.app, including this Website. The
      « Provider » or simply « We », means CTOT.app, a company registered under
      the laws of France who is the editor for this website and other services.
      <div>
        <Title level={2}>1. Eligibility & Registration</Title>
        <p>
          1.1 The access to some feature of the Services require the
          registration for an account (« Account »), such registration is
          subject to the approval by the Provider.
          <br />
          1.2 The Provider may, in his sole discretion, refuse to provide or
          continue providing the Services to any person or entity and change
          eligibility criteria at any time, including if the User fails to
          comply with the Terms and Conditions.
          <br />
          1.3 The Provider reserves the right to deactivate, terminate, prevent
          access to, disable services for, and/or delete any Accounts or access
          to the Websites and Services at any time at his sole discretion.
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>2. Warranties</Title>
        <p>
          2.1 The User warrants and represents that he has full power and
          authority and has taken all action necessary, including obtaining all
          necessary approvals or consents, to enable it to enter into and
          perform according to the Terms and Conditions.
          <br />
          2.2 The User accepts to follow all applicable laws and regulations,
          and agree that he responsible for compliance with any applicable local
          laws. <br />
        </p>
      </div>
      <div>
        <Title level={2}>3. Services </Title>
        <p>
          3.1 The Provider permits access to the Services through its iOS,
          Android, and web applications ("Apps"), or the API, on an "as is" and
          on an "as available" basis.
          <br />
          3.2 The User acknowledges that the Services may be subject to
          interruptions or limitations beyond the Provider's control, including
          but not limited to technical issues, force majeure events, or actions
          of third parties. <br />
          3.3 The data supplied must not be utilized for any navigation-related
          purposes.
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>4. Pricing</Title>
        <p>
          4.1 Some features require a paid subscription as described on the
          Website and the Apps, if not otherwise agreed with the Customer with a
          Commercial Agreement.
          <br />
          4.2 Subscriptions can be terminated at any time by either the User or
          the Provider.
          <br />
          4.3 The Provider does not issue refunds for Individual Subscriptions.
          <br />
          4.4 An Individual subscription should not be used by more than one
          person.
          <br />
          4.5 The Business subscription is reserved to registered companies. A
          minimum of 20 users are billed for each billing period for the
          Business subscription.
          <br />
          4.6 The Customer purchasing a Business Subscription, agrees to inform
          its users who have individually purchased subscriptions for the
          Services that they are responsible for canceling their previously
          purchased subscriptions through the appropriate channels.
          <br />
          4.7 For any subscription, a valid payment method should be made
          available to the Provider through its Payment Partner to charge the
          user for the subscription. Failure to provide a valid payment method
          will result in the termination of the subscription.
          <br />
          4.8 The User understands that subscriptions bought through any
          marketplace (such as Apple In App purchase) are also bound to the
          marketplace’s Terms and Conditions. In such cases, the Customer’s
          payments, refund, billing and claims are solely handled by the
          marketplace itself.
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>5. Payment Terms </Title>
        <p>
          5.1 The User shall make payments to the Provider within 10 days of
          receiving an invoice, or 30 days if using bank wire.
          <br />
          5.2 The Provider reserves the right to suspend or terminate access to
          the Services in the event of non-payment or late payment by the User.{" "}
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>6. Rules of conduct</Title>
        <p>
          6.1 Users are prohibited from using the Services for any purpose that
          is unlawful or prohibited by these terms of service. You may not use
          the Services provided to:
          <br />
          (a) upload, post, transmit, or store any material that is illegal,
          unlawful, or infringes upon intellectual property laws, including but
          not limited to copyright, trademark, and other proprietary rights;
          <br />
          (b) employ any automated or manual process, such as robots, spiders,
          or human-in-the-loop methods, to access or collect information from
          the Services;
          <br />
          (c) access or attempt to access the App or the Website through any
          means other than the interfaces provided by the Provider;
          <br />
          (d) interfere with or disrupt access to the Apps or the Website;
          <br />
          (e) engage in any activity that violates the principle of fair use,
          including but not limited to excessive or unreasonable use of the
          Services that adversely affects the experience of other users or
          imposes an undue burden on our servers or systems; 6.2 The User may
          not use the Services to access data outside his direct field of
          activity, including but not limited to flight data not within his own
          employer or airline or company or airport.
          <br />
          6.3 An Account may not be shared between multiple individuals.
          <br />
          6.4 The Provider reserves the right to monitor the usage of the
          Services to ensure compliance with these terms of service and to take
          appropriate action, including but not limited to suspending or
          terminating user accounts that violate these terms.
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>7. Term and Termination </Title>
        <p>
          7.1 The Provider reserves the right to terminate the access to the
          Services with or without prior notice.
          <br />
          7.2 In the event of termination, the User shall remain liable for any
          outstanding fees owed to the Provider for the Services provided up to
          the effective date of termination.
          <br />
          7.3 The Provider will not be liable to the User for any costs or
          damages, including attorneys fees, incurred by the Provider as a
          result of refusing or terminating a User’s subscription or Account.
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>8. Limitation of Liability </Title>
        <p>
          8.1 The Provider shall not be liable for any direct, indirect,
          incidental, special, consequential, or exemplary damages, including
          but not limited to, damages for loss of profits, data, or other
          intangible losses, arising out of or in connection with the use or
          inability to use the Services.8.2 Force Majeure: neither party will be
          liable for inadequate performance to the extent caused by a condition
          (for example, natural disaster, act of war or terrorism, riot, labor
          condition, governmental action, and Internet disturbance) that was
          beyond the party’s reasonable control.
          <br />
          8.3 The materials appearing in the Services and/or the sites linked on
          the Services could include technical, typographical, or photographic
          errors. The Provider does not warrant that any of the materials on the
          Services or linked sites are accurate, complete or current. The
          Provider may make changes to the materials contained in the Services
          at any time without notice.
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>9. Confidentiality </Title>
        <p>
          9.1 Both parties agree to keep confidential any proprietary or
          sensitive information obtained during the use of the Services.9.2 This
          confidentiality obligation shall survive the termination of the User
          Account. <br />
        </p>
      </div>
      <div>
        <Title level={2}>10. Intellectual Property </Title>
        <p>
          10.1 The Provider retains all rights, title, and interest in and to
          the Services, including all intellectual property rights associated
          therewith.
          <br />
          10.2 The User acknowledges that the Services, including any software,
          content, designs, trademarks, or other intellectual property provided
          by the Provider, are the exclusive property of the Provider.
          <br />
          10.3 The User agrees not to modify, reproduce, distribute, create
          derivative works of, or reverse engineer the Services or any part
          thereof without the prior written consent of the Provider. <br />
          10.4 The User may provide feedback, suggestions, or ideas to the
          Provider regarding the Services. The Provider shall have the
          unrestricted right to use, implement, and incorporate such feedback,
          suggestions, or ideas into the Services without any obligation or
          compensation to the User. <br />
        </p>
      </div>
      <div>
        <Title level={2}>11. Governing Law and Jurisdiction </Title>
        <p>
          11.1 This Agreement shall be governed by and construed in accordance
          with the laws of France. 11.2 Any disputes arising out of or in
          connection with this Agreement shall be subject to the exclusive
          jurisdiction of the courts of France. <br />
        </p>
      </div>
      <div>
        <Title level={2}>12. Data Policy </Title>
        <p>
          12.1 The User acknowledges and agrees to comply with the Provider's
          data policy as outlined on the Provider's website. The data policy
          governs the collection, use, storage, and disclosure of user data in
          connection with the Services.
          <br />
          12.2 The User is responsible for ensuring that its use of the Services
          and the collection and processing of user data comply with applicable
          data protection laws and regulations.
          <br />
          12.3 Each Party undertakes not to, as far as reasonable, do anything
          or permit anything to be done which has the effect of placing the
          other Party in breach of Data Protection Legislation.
          <br />
        </p>
      </div>
      <div>
        <Title level={2}>13. Modification</Title>
        <p>
          13.1 The Provider may revise these terms of service for its app at any
          time without notice. By using the Services you are agreeing to be
          bound by the then current version of these terms of service.
          <br />
        </p>
      </div>
    </div>
  );
}
