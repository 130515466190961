import React from "react";
import { Button, Divider, Drawer, Space, Table } from "antd";
import { useQuery } from "@apollo/client";
import { flightGQL } from "../graphql/queries";
import _ from "lodash";
import moment from "moment";
import delayCodes from "../api/delayCodes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RestrictionMap from "./RestrictionMap";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import ErrorMessage from "./ErrorMessage";

const FlightDetails = ({ showModal, setShowModal, uniqId }) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  // Prepare the query
  let { loading, error, data, refetch } = useQuery(flightGQL, {
    variables: { uniqId },
    fetchPolicy: "network-only",
    skip: !uniqId,
  });

  // Retrieve the flight from the query result
  let flight = _.get(data, "flight", {});

  if (flight._errorCode) error = { _errorCode: flight._errorCode };

  const {
    lastUpdate,
    callsign,
    depIcao,
    arrIcao,
    tsat,
    ctot,
    tobt,
    eobt,
    depAirportType,
    mostPenalisingRegulation,
    mostPenalisingRegulationCause,
    depProc,
    flightState,
    suspensionStatus,
    taxiDuration,
    regulations = [],
    registration,
    icaoRoute,
  } = flight;

  const iataDelayCode = _.get(mostPenalisingRegulationCause, "iataDelayCode");

  const depProcId = _.get(flight, "depProc.id");

  const skyVectorLink = `https://skyvector.com/?ll=52.1808,-9.52389&chart=302&fpl=${depIcao} ${icaoRoute} ${arrIcao}`;

  const numberOfRestrictions = (regulations && regulations?.length) || 0;

  const regulationsElements = _.map(regulations, (ele, i) => {
    return (
      <p key={i}>
        Restriction {i + 1}/{numberOfRestrictions}: {ele.type}/{ele.id}
      </p>
    );
  });

  const allFlightData = [
    {
      title: "Registration",
      value: registration,
    },
    {
      title: "FlightRadar24",
      value: registration && (
        <a
          href={`https://www.flightradar24.com/data/aircraft/${registration}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Find the plane{" "}
          <FontAwesomeIcon icon={faExternalLinkAlt} style={{ fontSize: 14 }} />
        </a>
      ),
    },
    {
      title: "SkyVector",
      value: registration && (
        <a href={skyVectorLink} target="_blank" rel="noopener noreferrer">
          View the route{" "}
          <FontAwesomeIcon icon={faExternalLinkAlt} style={{ fontSize: 14 }} />
        </a>
      ),
    },
    {
      title: "EOBT",
      value: moment.utc(eobt).format("HH:mm"),
    },
    {
      title: "TOBT",
      value: tobt ? moment.utc(tobt).format("HH:mm") : "-",
    },
    {
      title: "TSAT",
      value: tsat ? moment.utc(tsat).format("HH:mm"): "-",
    },
    {
      title: "CTOT",
      value: moment.utc(ctot).format("HH:mm"),
      display: !!ctot,
    },
    {
      title: "Airport Type",
      value: depAirportType,
    },
    {
      title: "SID",
      value: depProcId,
    },
    {
      title: "IATA Delay Code",
      value: _.get(mostPenalisingRegulationCause, "iataDelayCode"),
      display: !!ctot,
    },
    {
      title: "IATA Delay Code Full",
      value: (_.find(delayCodes, { code: iataDelayCode }) || {}).details,
      display: !!ctot,
    },
    {
      title: "Ready to Depart",
      value: _.get(flight, "readyStatus.readyToDepart") ? "Yes" : "No",
    },
    {
      title: "Ready for Improvement",
      value: _.get(flight, "readyStatus.readyForImprovement") ? "Yes" : "No",
    },

    {
      title: "Suspension Status",
      value: suspensionStatus,
    },
    {
      title: "Flight State",
      value: flightState,
    },
    {
      title: "Taxi Time",
      value: moment.duration(taxiDuration).asMinutes(),
    },
    {
      title: "Most Penalising Regulation",
      value: mostPenalisingRegulation,
      display: !!ctot,
    },
    {
      title: "Most Penalising Regulation Cause",
      value: _.get(mostPenalisingRegulationCause, "iataDelayCode"),
      display: !!ctot,
    },
    {
      title: "Regulation Locations",
      value: regulationsElements,
      display: !!ctot,
    },
    {
      title: "Departure Airport",
      value: depIcao,
    },
    {
      title: "Route",
      value: icaoRoute,
    },
    {
      title: "Arrival Airport",
      value: arrIcao,
    },
  ];

  const RegulationsColumns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Location",
      render: (text, record) => record?.locationId,
    },
    {
      title: "Type",
      render: (text, record) => record?.type,
    },
  ];

  const allFlightDataColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const Regulations = () => {
    if (regulations?.length > 0)
      return (
        <>
          <h3>Regulations</h3>
          <Table
            dataSource={regulations}
            columns={RegulationsColumns}
            pagination={false}
          />
          <Divider />
          <RestrictionMap
            regulations={regulations}
            departureAirport={depIcao}
            arrivalAirport={arrIcao}
            icaoRoute={icaoRoute}
          />
        </>
      );
    else
      return (
        <>
          <h2>Regulations</h2>

          <p>None</p>
        </>
      );
  };

  return (
    <Drawer
      title={
        <>
          {callsign || ""} | {depIcao} - {arrIcao} | Last update{" "}
          {moment.unix(lastUpdate / 1000).format("HH:mm")}
          <Button
            size="small"
            type="primary"
            onClick={() => refetch()}
            loading={loading}
            style={{ marginLeft: "10px" }}
          >
            Refresh
          </Button>
        </>
      }
      size="large"
      placement="right"
      onClose={handleCancel}
      open={showModal}
      styles={{
        wrapper: {
          width: "80%",
          //minWidth: "800px",}
        },
      }}
      destroyOnClose={true}
      extra={
        <Space>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleCancel}>
            OK
          </Button>
        </Space>
      }
    >
      <ErrorMessage error={error} />
      <Regulations />
      <Divider />
      <h2>Data</h2>
      <Table
        dataSource={allFlightData.filter((ele) => ele.display !== false)}
        columns={allFlightDataColumns}
        pagination={false}
        showHeader={false}
        rowKey={() => _.random(10000)}
      />
    </Drawer>
  );
};

export default FlightDetails;
