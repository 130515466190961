import { ConfigProvider, theme } from "antd";
import LoginForm from "../components/LoginForm";

import { ReactComponent as Logo } from "../assets/isolated-monochrome-black.svg";

export default function LoginPage() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        token: {
          // Seed Token
          colorPrimary: "#423BAB",

          // Alias Token
          colorBgContainer: "#white",
        },

        components: {
          Input: {
            colorBgContainer: "white",
            //colorBorder: "black",
            borderRadius: 3,
            controlOutlineWidth: 1,
            lineWidth: 1,
          },
          Button: {
            //colorBgContainerDisabled: "lightgrey",
          },
        },
      }}
    >
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //backgroundColor: "#F5F5F5"
        }}
      >
        <div className="ctot-dashboard-login-card">
          <div
            className="ctot-dashboard-login-card-header"
            style={{ alignItems: "center" }}
          >
            <Logo height={"5em"} />
            <br />
            <div
              style={{
                fontSize: 20,
                textTransform: "uppercase",
                fontWeight: 700,
                letterSpacing: "0.02rem",
              }}
            >
              CTOT <span style={{ fontWeight: 300 }}>Dashboard</span>
            </div>
          </div>
          <div className="ctot-dashboard-login-card-content">
            <span style={{fontWeight: 500}}>LOGIN WITH YOUR EMAIL</span>
            <br />
            <br />
            <LoginForm />
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}
