export const fir = {
  type: "FeatureCollection",
  name: "firs",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { id: "LJLAFIR", name: "LJUBLJANA FIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.391289128912895, 45.557355735573552],
            [13.504075407540761, 45.635363536353637],
            [13.530103010301033, 45.62886288628863],
            [13.729647964796484, 45.615861586158616],
            [13.83375837583759, 45.576857685768573],
            [13.920517051705176, 45.635363536353637],
            [13.799054905490557, 45.752375237523751],
            [13.703620362036212, 45.784878487848786],
            [13.590834083408346, 45.84988498849885],
            [13.634213421342139, 45.940894089408943],
            [13.668916891689172, 45.992899289928992],
            [13.608185818581866, 46.012401240124007],
            [13.599509950995106, 45.986398639863985],
            [13.504075407540761, 45.992899289928992],
            [13.608185818581866, 46.11641164116412],
            [13.694944494449452, 46.187918791879184],
            [13.608185818581866, 46.194419441944191],
            [13.469371937193728, 46.22042204220422],
            [13.408640864086415, 46.311431143114305],
            [13.530103010301033, 46.415441544154419],
            [13.625537553755379, 46.44144414441444],
            [13.694944494449452, 46.467446744674461],
            [13.703620362036212, 46.49344934493449],
            [13.712296229622964, 46.525952595259525],
            [13.83375837583759, 46.519451945194518],
            [13.911841184118416, 46.519451945194518],
            [13.963896389638968, 46.499949994999497],
            [14.033303330333041, 46.486948694869483],
            [14.085358535853594, 46.480448044804476],
            [14.120062006200627, 46.480448044804476],
            [14.180793079307939, 46.434943494349433],
            [14.293579357935798, 46.428442844284426],
            [14.328282828282831, 46.428442844284426],
            [14.36298629862987, 46.434943494349433],
            [14.389013901390143, 46.44144414441444],
            [14.406365636563663, 46.447944794479447],
            [14.441069106910696, 46.44144414441444],
            [14.449744974497456, 46.421942194219426],
            [14.519151915191522, 46.421942194219426],
            [14.562531253125314, 46.376437643764376],
            [14.588558855885594, 46.434943494349433],
            [14.623262326232627, 46.447944794479447],
            [14.70134513451346, 46.480448044804476],
            [14.753400340034005, 46.499949994999497],
            [14.779427942794285, 46.512951295129511],
            [14.831483148314838, 46.551955195519554],
            [14.857510751075111, 46.590959095909589],
            [14.866186618661871, 46.603960396039604],
            [14.918241824182424, 46.610461046104611],
            [15.039703970397042, 46.649464946494646],
            [15.083083308330835, 46.642964296429639],
            [15.21322132213222, 46.649464946494646],
            [15.247924792479253, 46.642964296429639],
            [15.360711071107119, 46.649464946494646],
            [15.421442144214424, 46.655965596559653],
            [15.464821482148217, 46.616961696169611],
            [15.50820082008201, 46.616961696169611],
            [15.55158015801581, 46.649464946494646],
            [15.55158015801581, 46.668966896689668],
            [15.577607760776083, 46.681968196819682],
            [15.594959495949603, 46.688468846884689],
            [15.629662966296635, 46.681968196819682],
            [15.655690569056908, 46.70797079707971],
            [15.794504450445046, 46.70797079707971],
            [15.811856185618566, 46.70797079707971],
            [15.863911391139119, 46.720972097209724],
            [15.994049404940498, 46.681968196819682],
            [16.03742874287429, 46.655965596559653],
            [16.011401140114017, 46.714471447144717],
            [15.994049404940498, 46.805480548054803],
            [15.985373537353738, 46.831483148314831],
            [16.115511551155123, 46.870487048704874],
            [16.36711171117112, 46.850985098509852],
            [16.419166916691672, 46.681968196819682],
            [16.419166916691672, 46.642964296429639],
            [16.531953195319538, 46.499949994999497],
            [16.36711171117112, 46.571457145714575],
            [16.236973697369741, 46.506450645064504],
            [16.280353035303534, 46.395939593959397],
            [16.271677167716774, 46.376437643764376],
            [16.07213221322133, 46.408940894089412],
            [16.03742874287429, 46.369936993699369],
            [15.976697669766985, 46.311431143114305],
            [15.924642464246432, 46.285428542854284],
            [15.829207920792086, 46.26592659265927],
            [15.777152715271534, 46.226922692269227],
            [15.603635363536355, 46.194419441944191],
            [15.629662966296635, 46.168416841684163],
            [15.664366436643668, 46.070907090709071],
            [15.699069906990701, 45.973397339733971],
            [15.681718171817188, 45.862886288628857],
            [15.655690569056908, 45.843384338433843],
            [15.490849084908497, 45.804380438043808],
            [15.430118011801184, 45.791379137913793],
            [15.352035203520359, 45.771877187718772],
            [15.352035203520359, 45.687368736873687],
            [15.352035203520359, 45.661366136613665],
            [15.352035203520359, 45.635363536353637],
            [15.299979997999806, 45.576857685768573],
            [15.326007600760079, 45.453345334533452],
            [15.20454545454546, 45.466346634663466],
            [15.005000500050009, 45.492349234923495],
            [14.866186618661871, 45.466346634663466],
            [14.805455545554558, 45.492349234923495],
            [14.736048604860493, 45.524852485248523],
            [14.579882988298834, 45.641864186418644],
            [14.545179517951802, 45.62886288628863],
            [14.475772577257729, 45.53135313531353],
            [14.406365636563663, 45.505350535053509],
            [14.389013901390143, 45.492349234923495],
            [14.35431043104311, 45.485848584858488],
            [14.33695869586959, 45.479347934793481],
            [14.302255225522558, 45.492349234923495],
            [14.276227622762278, 45.492349234923495],
            [14.267551755175525, 45.479347934793481],
            [14.258875887588765, 45.505350535053509],
            [14.241524152415245, 45.505350535053509],
            [14.224172417241732, 45.498849884988502],
            [14.206820682068212, 45.472847284728473],
            [14.137413741374139, 45.472847284728473],
            [14.015951595159521, 45.524852485248523],
            [13.998599859986001, 45.518351835183516],
            [13.773027302730277, 45.466346634663466],
            [13.712296229622964, 45.466346634663466],
            [13.616861686168619, 45.466346634663466],
            [13.582158215821586, 45.472847284728473],
            [13.547454745474553, 45.492349234923495],
            [13.504075407540761, 45.511851185118509],
            [13.417316731673175, 45.544354435443545],
            [13.391289128912895, 45.557355735573552],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EISNUIR", name: "SHANNON UIR", fab: "9" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.496074607460741, 53.917191719171917],
            [-5.496074607460741, 53.806680668066804],
            [-5.496074607460741, 53.767676767676768],
            [-5.496074607460741, 53.761176117611761],
            [-5.496074607460741, 53.68966896689669],
            [-5.496074607460741, 53.683168316831683],
            [-5.496074607460741, 53.663666366636662],
            [-5.496074607460741, 53.494649464946491],
            [-5.496074607460741, 53.481648164816477],
            [-5.496074607460741, 53.462146214621463],
            [-5.496074607460741, 53.384138413841384],
            [-5.496074607460741, 53.286628662866285],
            [-5.496074607460741, 53.280128012801278],
            [-5.496074607460741, 53.273627362736271],
            [-5.496074607460741, 53.247624762476249],
            [-5.496074607460741, 53.195619561956192],
            [-5.496074607460741, 53.182618261826178],
            [-5.496074607460741, 53.176117611761171],
            [-5.496074607460741, 53.085108510851086],
            [-5.496074607460741, 53.065606560656065],
            [-5.496074607460741, 53.05260526052605],
            [-5.496074607460741, 52.948594859485944],
            [-5.496074607460741, 52.753575357535752],
            [-5.496074607460741, 52.740574057405738],
            [-5.496074607460741, 52.669066906690666],
            [-5.496074607460741, 52.55855585558556],
            [-5.496074607460741, 52.552055205520553],
            [-5.496074607460741, 52.415541554155411],
            [-5.496074607460741, 52.331033103310332],
            [-5.66959195919592, 52.246524652465247],
            [-5.678267826782673, 52.24002400240024],
            [-5.686943694369432, 52.24002400240024],
            [-5.825757575757571, 52.168516851685169],
            [-5.843109310931091, 52.155515551555155],
            [-5.851785178517851, 52.155515551555155],
            [-6.120737073707367, 52.012501250125013],
            [-6.233523352335233, 51.960496049604956],
            [-6.242199219921986, 51.953995399539956],
            [-6.250875087508746, 51.947494749474949],
            [-6.407040704070404, 51.86948694869487],
            [-6.563206320632062, 51.784978497849785],
            [-6.710696069606961, 51.706970697069707],
            [-6.866861686168612, 51.615961596159615],
            [-7.092434243424343, 51.498949894989501],
            [-7.231248124812481, 51.427442744274423],
            [-7.413441344134412, 51.323432343234323],
            [-7.491524152415238, 51.277927792779273],
            [-7.508875887588758, 51.271427142714273],
            [-7.569606960696063, 51.238923892389238],
            [-7.682393239323929, 51.180418041804181],
            [-8.003400340034002, 50.998399839983996],
            [-8.497924792479246, 50.998399839983996],
            [-12.002975297529751, 50.998399839983996],
            [-12.497499749974995, 50.998399839983996],
            [-13.998424842484248, 50.998399839983996],
            [-14.996149614961492, 50.998399839983996],
            [-14.996149614961492, 51.251925192519252],
            [-14.996149614961492, 51.498949894989501],
            [-14.996149614961492, 51.75247524752475],
            [-14.996149614961492, 51.999499949994998],
            [-14.996149614961492, 52.064506450645062],
            [-14.996149614961492, 52.253025302530254],
            [-14.996149614961492, 52.500050005000496],
            [-14.996149614961492, 52.747074707470745],
            [-14.996149614961492, 53.000600060006001],
            [-14.996149614961492, 53.247624762476249],
            [-14.996149614961492, 53.501150115011498],
            [-14.996149614961492, 53.696169616961697],
            [-14.996149614961492, 53.748174817481747],
            [-14.996149614961492, 54.001700170017003],
            [-13.503900390039, 54.18371837183718],
            [-10.666891689168914, 54.5022502250225],
            [-9.998849884988495, 54.567256725672564],
            [-9.556380638063803, 54.65176517651765],
            [-8.949069906990697, 54.788278827882785],
            [-8.801580158015799, 54.905290529052905],
            [-8.740849084908486, 54.950795079507948],
            [-8.541304130413039, 55.106810681068104],
            [-8.454545454545453, 55.178317831783176],
            [-8.385138513851381, 55.217321732173218],
            [-8.246324632463242, 55.334333433343332],
            [-8.064131413141311, 55.347334733473346],
            [-7.907965796579653, 55.366836683668367],
            [-7.33535853585358, 55.418841884188417],
            [-6.918916891689165, 55.334333433343332],
            [-6.936268626862685, 55.321332133213318],
            [-6.988323832383237, 55.282328232823282],
            [-7.049054905490543, 55.236823682368232],
            [-7.187868786878688, 55.13931393139314],
            [-7.283303330333027, 55.067806780678069],
            [-7.647689768976896, 54.801280128012799],
            [-7.838558855885587, 54.658265826582657],
            [-7.89061406140614, 54.625762576257621],
            [-8.055455545554551, 54.5022502250225],
            [-8.098834883488347, 54.463246324632465],
            [-8.168241824182417, 54.417741774177415],
            [-7.977372737273726, 54.385238523852387],
            [-7.89061406140614, 54.365736573657365],
            [-7.699744974497449, 54.33323332333233],
            [-7.396089608960892, 54.28122812281228],
            [-7.127137713771376, 54.22922292229223],
            [-6.910241024102405, 54.190219021902188],
            [-6.832158215821579, 54.177217721772173],
            [-6.762751275127506, 54.164216421642159],
            [-6.649964996499648, 54.138213821382138],
            [-6.407040704070404, 54.092709270927088],
            [-6.268226822682266, 54.066706670667067],
            [-6.207495749574953, 54.053705370537052],
            [-6.068681868186815, 54.027702770277024],
            [-6.042654265426542, 54.021202120212017],
            [-5.738998899889985, 53.96269626962696],
            [-5.496074607460741, 53.917191719171917],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LFFFUIR", name: "FRANCE UIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.782803280328032, 43.581158115811576],
            [-1.964996499649963, 43.646164616461647],
            [-2.017051705170516, 43.665666566656668],
            [-2.069106910691069, 43.685168516851682],
            [-2.164541454145414, 43.717671767176718],
            [-2.520252025202517, 43.841184118411839],
            [-2.728472847284728, 43.91269126912691],
            [-2.737148714871481, 43.91269126912691],
            [-2.841259125912586, 43.951695169516952],
            [-2.910666066606659, 43.971197119711974],
            [-3.153590359035903, 44.049204920492045],
            [-3.891039103910387, 44.296229622962294],
            [-3.986473647364733, 44.328732873287329],
            [-4.229397939793976, 44.374237423742372],
            [-4.437618761876188, 44.413241324132414],
            [-4.611136113611359, 44.445744574457443],
            [-4.949494949494948, 44.5042504250425],
            [-5.140364036403639, 44.543254325432542],
            [-5.62621262126212, 44.621262126212621],
            [-5.834433443344331, 44.660266026602656],
            [-6.918916891689165, 44.835783578357834],
            [-8.003400340034002, 44.998299829982997],
            [-8.003400340034002, 45.498849884988502],
            [-8.003400340034002, 45.999399939993999],
            [-8.003400340034002, 46.499949994999497],
            [-8.003400340034002, 47.000500050005002],
            [-8.003400340034002, 47.247524752475243],
            [-8.003400340034002, 47.501050105010499],
            [-8.003400340034002, 47.826082608260826],
            [-8.003400340034002, 48.001600160015997],
            [-8.003400340034002, 48.313631363136309],
            [-8.003400340034002, 48.489148914891487],
            [-8.003400340034002, 48.502150215021501],
            [-8.003400340034002, 48.58015801580158],
            [-8.003400340034002, 48.833683368336835],
            [-7.578282828282823, 48.931193119311928],
            [-7.213896389638961, 49.009200920092006],
            [-6.14676467646764, 49.236723672367233],
            [-5.860461046104611, 49.28872887288729],
            [-5.270502050205017, 49.412241224122411],
            [-5.036253625362534, 49.457745774577454],
            [-5.001550155015501, 49.464246424642461],
            [-4.914791479147908, 49.477247724772475],
            [-4.44629462946294, 49.568256825682568],
            [-4.333508350835082, 49.587758775877589],
            [-4.159990999099904, 49.620262026202617],
            [-4.003825382538253, 49.652765276527653],
            [-3.821632163216321, 49.685268526852681],
            [-3.526652665266525, 49.737273727372738],
            [-3.257700770077001, 49.782778277827781],
            [-3.170942094209416, 49.802280228022802],
            [-3.162266226622656, 49.802280228022802],
            [-3.06683168316831, 49.821782178217823],
            [-3.032128212821277, 49.821782178217823],
            [-2.997424742474244, 49.834783478347831],
            [-2.511576157615757, 49.912791279127909],
            [-2.372762276227618, 49.938793879387937],
            [-2.24262426242624, 49.958295829582958],
            [-2.173217321732167, 49.971297129712973],
            [-2.164541454145414, 49.971297129712973],
            [-2.103810381038102, 49.98429842984298],
            [-2.008375837583756, 49.997299729972994],
            [-1.999699969996996, 49.997299729972994],
            [-1.973672367236723, 49.997299729972994],
            [-1.886913691369131, 49.997299729972994],
            [-1.860886088608858, 49.997299729972994],
            [-1.843534353435338, 49.997299729972994],
            [-1.782803280328032, 49.997299729972994],
            [-1.71339633963396, 49.997299729972994],
            [-1.687368736873687, 49.997299729972994],
            [-1.513851385138508, 49.997299729972994],
            [-1.496499649964996, 49.997299729972994],
            [-1.38371337133713, 49.997299729972994],
            [-1.253575357535752, 49.997299729972994],
            [-1.192844284428439, 49.997299729972994],
            [-1.054030403040301, 49.997299729972994],
            [-1.001975197519748, 49.997299729972994],
            [-0.837133713371337, 49.997299729972994],
            [-0.53347834783478, 49.997299729972994],
            [-0.368636863686362, 49.997299729972994],
            [-0.247174717471744, 49.997299729972994],
            [-0.108360836083605, 50.055805580558051],
            [-0.0042504250425, 50.101310131013101],
            [0.099859985998606, 50.133813381338129],
            [0.264701470147017, 50.205320532053207],
            [0.446894689468948, 50.276827682768278],
            [0.637763776377639, 50.354835483548356],
            [0.767901790179025, 50.400340034003399],
            [0.81995699569957, 50.41984198419842],
            [0.889363936393643, 50.445844584458442],
            [0.967446744674469, 50.478347834783477],
            [1.002150215021508, 50.497849784978499],
            [1.062881288128814, 50.517351735173513],
            [1.097584758475854, 50.530353035303527],
            [1.227722772277232, 50.575857585758577],
            [1.366536653665371, 50.634363436343634],
            [1.401240124012403, 50.647364736473648],
            [1.453295329532956, 50.660366036603662],
            [1.470647064706476, 50.666866686668662],
            [1.470647064706476, 50.751375137513747],
            [1.470647064706476, 50.777377737773776],
            [1.470647064706476, 50.855385538553854],
            [1.470647064706476, 50.907390739073904],
            [1.470647064706476, 50.913891389138911],
            [1.470647064706476, 50.920392039203918],
            [1.470647064706476, 50.959395939593961],
            [1.470647064706476, 50.965896589658968],
            [1.470647064706476, 50.998399839983996],
            [1.557405740574062, 51.017901790179017],
            [1.618136813681375, 51.030903090309032],
            [1.670192019201927, 51.043904390439046],
            [1.730923092309233, 51.056905690569053],
            [1.999874987498757, 51.115411541154117],
            [2.242799279927993, 51.102410241024103],
            [2.54645464546455, 51.082908290829081],
            [2.589833983398343, 51.01140114011401],
            [2.650565056505656, 50.816381638163811],
            [2.650565056505656, 50.809880988098811],
            [2.902165216521659, 50.699369936993698],
            [2.910841084108412, 50.692869286928691],
            [3.153765376537656, 50.783878387838783],
            [3.266551655165522, 50.608360836083605],
            [3.283903390339034, 50.595359535953591],
            [3.361986198619867, 50.517351735173513],
            [3.37933793379338, 50.497849784978499],
            [3.483448344834486, 50.517351735173513],
            [3.509475947594765, 50.52385238523852],
            [3.552855285528558, 50.497849784978499],
            [3.604910491049111, 50.484848484848484],
            [3.630938093809384, 50.47184718471847],
            [3.648289828982904, 50.452345234523449],
            [3.665641564156417, 50.41984198419842],
            [3.665641564156417, 50.348334833483349],
            [3.674317431743177, 50.341834183418342],
            [3.787103710371042, 50.341834183418342],
            [3.873862386238628, 50.341834183418342],
            [3.92591759175918, 50.335333533353335],
            [3.969296929692973, 50.328832883288328],
            [4.090759075907592, 50.315831583158314],
            [4.116786678667872, 50.296329632963293],
            [4.177517751775184, 50.224822482248221],
            [4.168841884188424, 50.146814681468143],
            [4.142814281428144, 49.97779777977798],
            [4.151490149014904, 49.97779777977798],
            [4.368386838683875, 49.964796479647966],
            [4.481173117311734, 49.951795179517951],
            [4.533228322832286, 49.964796479647966],
            [4.567931793179319, 49.97779777977798],
            [4.862911291129116, 49.98429842984298],
            [4.854235423542356, 49.860786078607859],
            [4.854235423542356, 49.802280228022802],
            [4.940994099409949, 49.802280228022802],
            [4.984373437343741, 49.802280228022802],
            [5.11451145114512, 49.737273727372738],
            [5.12318731873188, 49.737273727372738],
            [5.166566656665672, 49.717771777177717],
            [5.262001200120018, 49.691769176917688],
            [5.288028802880291, 49.678767876787674],
            [5.314056405640571, 49.646264626462646],
            [5.374787478747876, 49.626762676267624],
            [5.383463346334636, 49.61376137613761],
            [5.418166816681669, 49.600760076007596],
            [5.444194419441949, 49.568256825682568],
            [5.452870287028709, 49.548754875487546],
            [5.435518551855189, 49.516251625162518],
            [5.574332433243328, 49.529252925292525],
            [5.747849784978499, 49.542254225422539],
            [5.756525652565259, 49.542254225422539],
            [5.817256725672571, 49.548754875487546],
            [5.825932593259331, 49.542254225422539],
            [5.834608460846091, 49.542254225422539],
            [5.843284328432844, 49.529252925292525],
            [5.999449944994502, 49.457745774577454],
            [6.051505150515055, 49.464246424642461],
            [6.112236223622368, 49.477247724772475],
            [6.181643164316434, 49.496749674967496],
            [6.207670767076714, 49.503250325032504],
            [6.225022502250226, 49.509750975097511],
            [6.346484648464852, 49.464246424642461],
            [6.389863986398645, 49.464246424642461],
            [6.398539853985405, 49.464246424642461],
            [6.46794679467947, 49.464246424642461],
            [6.546029602960303, 49.457745774577454],
            [6.563381338133816, 49.431743174317432],
            [6.684843484348441, 49.249724972497248],
            [6.728222822282234, 49.223722372237219],
            [7.040554055405543, 49.171717171717169],
            [7.162016201620169, 49.152215221522148],
            [7.3442094209421, 49.11971197119712],
            [7.587133713371344, 49.080708070807077],
            [7.717271727172722, 49.048204820482049],
            [7.830058005800588, 49.041704170417042],
            [8.003575357535759, 49.02220222022202],
            [8.064306430643072, 49.002700270026999],
            [8.116361636163617, 48.989698969896992],
            [8.220472047204723, 48.976697669766978],
            [8.151065106510657, 48.918191819181914],
            [8.020927092709279, 48.775177517751771],
            [7.968871887188726, 48.716671667166715],
            [7.804030403040308, 48.521652165216523],
            [7.795354535453548, 48.508650865086508],
            [7.734623462346242, 48.437143714371437],
            [7.699919991999202, 48.346134613461345],
            [7.65654065406541, 48.216121612161217],
            [7.64786478647865, 48.196619661966196],
            [7.578457845784584, 48.086108610861089],
            [7.613161316131617, 48.001600160015997],
            [7.613161316131617, 47.988598859885983],
            [7.587133713371344, 47.910591059105911],
            [7.561106110611064, 47.839083908390833],
            [7.561106110611064, 47.832583258325826],
            [7.535078507850791, 47.780578057805783],
            [7.535078507850791, 47.689568956895684],
            [7.500375037503751, 47.631063106310634],
            [7.578457845784584, 47.605060506050606],
            [7.474347434743478, 47.494549454945492],
            [7.430968096809686, 47.488048804880485],
            [7.378912891289133, 47.416541654165414],
            [7.205395539553962, 47.423042304230421],
            [7.144664466446649, 47.501050105010499],
            [6.988498849884991, 47.494549454945492],
            [6.997174717471751, 47.468546854685471],
            [6.962471247124718, 47.462046204620464],
            [6.901740174017405, 47.384038403840378],
            [7.066581658165823, 47.345034503450343],
            [7.005850585058511, 47.325532553255329],
            [6.953795379537958, 47.241024102410236],
            [6.81498149814982, 47.13051305130513],
            [6.719546954695474, 47.052505250525051],
            [6.641464146414648, 47.02650265026503],
            [6.520002000200023, 46.974497449744973],
            [6.47662266226623, 46.961496149614959],
            [6.433243324332437, 46.922492249224916],
            [6.45927092709271, 46.850985098509852],
            [6.398539853985405, 46.779477947794774],
            [6.441919191919197, 46.75997599759976],
            [6.407215721572165, 46.753475347534753],
            [6.389863986398645, 46.740474047404739],
            [6.320457045704572, 46.714471447144717],
            [6.294429442944299, 46.701470147014703],
            [6.277077707770779, 46.681968196819682],
            [6.251050105010506, 46.668966896689668],
            [6.164291429142921, 46.610461046104611],
            [6.094884488448848, 46.545454545454547],
            [6.094884488448848, 46.525952595259525],
            [6.068856885688575, 46.415441544154419],
            [6.120912091209128, 46.330933093309326],
            [5.97342234223423, 46.194419441944191],
            [5.97342234223423, 46.148914891489149],
            [6.008125812581262, 46.142414241424142],
            [6.077532753275335, 46.187918791879184],
            [6.216346634663473, 46.207420742074206],
            [6.294429442944299, 46.317931793179312],
            [6.47662266226623, 46.382938293829383],
            [6.528677867786783, 46.356935693569355],
            [6.658815881588161, 46.363436343634362],
            [6.745574557455747, 46.343934393439341],
            [6.780278027802787, 46.343934393439341],
            [6.81498149814982, 46.239923992399241],
            [6.80630563056306, 46.142414241424142],
            [6.988498849884991, 46.005900590058999],
            [7.049229922992303, 45.9018901890189],
            [6.867036703670372, 45.752375237523751],
            [7.040554055405543, 45.479347934793481],
            [7.031878187818783, 45.466346634663466],
            [7.023202320232031, 45.453345334533452],
            [7.083933393339336, 45.401340134013395],
            [6.962471247124718, 45.193319331933196],
            [6.641464146414648, 45.115311531153111],
            [6.780278027802787, 45.011301130113011],
            [6.901740174017405, 44.842284228422841],
            [7.014526452645271, 44.803280328032798],
            [7.040554055405543, 44.660266026602656],
            [7.005850585058511, 44.582258225822585],
            [6.919091909190925, 44.45224522452245],
            [7.005850585058511, 44.237723772377237],
            [7.083933393339336, 44.211721172117208],
            [7.283478347834787, 44.140214021402137],
            [7.621837183718377, 44.146714671467144],
            [7.708595859585962, 44.062206220622059],
            [7.665216521652169, 43.997199719971995],
            [7.587133713371344, 43.899689968996896],
            [7.535078507850791, 43.776177617761775],
            [7.682568256825689, 43.769676967696768],
            [7.717271727172722, 43.737173717371732],
            [7.838733873387341, 43.704670467046704],
            [8.324582458245828, 43.568156815681569],
            [8.541479147914799, 43.503150315031505],
            [8.602210221022105, 43.49014901490149],
            [8.71499649964997, 43.464146414641462],
            [8.801755175517556, 43.438143814381434],
            [8.819106910691076, 43.431643164316426],
            [8.957920792079214, 43.386138613861384],
            [9.174817481748178, 43.334133413341334],
            [9.747424742474252, 43.165116511651163],
            [9.747424742474252, 42.957095709570957],
            [9.747424742474252, 42.820582058205815],
            [9.747424742474252, 42.619061906190616],
            [9.747424742474252, 42.502050205020502],
            [9.747424742474252, 42.41104110411041],
            [9.747424742474252, 42.326532653265325],
            [9.747424742474252, 41.936493649364934],
            [9.747424742474252, 41.793479347934792],
            [9.747424742474252, 41.747974797479742],
            [9.747424742474252, 41.734973497349735],
            [9.747424742474252, 41.663466346634664],
            [9.747424742474252, 41.33193319331933],
            [9.617286728672873, 41.33193319331933],
            [9.495824582458248, 41.33193319331933],
            [9.313631363136317, 41.33193319331933],
            [9.270252025202524, 41.33193319331933],
            [9.105410541054113, 41.33193319331933],
            [9.03600360036004, 41.33193319331933],
            [8.810431043104316, 41.33193319331933],
            [8.688968896889691, 41.33193319331933],
            [8.567506750675072, 41.33193319331933],
            [8.446044604460454, 41.33193319331933],
            [8.333258325832588, 41.33193319331933],
            [8.281203120312036, 41.286428642864287],
            [8.159740974097417, 41.162916291629159],
            [8.003575357535759, 41.000400040003996],
            [8.003575357535759, 40.915891589158917],
            [8.003575357535759, 40.863886388638861],
            [8.003575357535759, 40.746874687468747],
            [8.003575357535759, 40.610361036103612],
            [8.003575357535759, 40.42184218421842],
            [8.003575357535759, 40.350335033503349],
            [8.003575357535759, 39.999299929993001],
            [8.003575357535759, 39.700270027002702],
            [8.003575357535759, 39.212721272127212],
            [8.003575357535759, 39.147714771477148],
            [8.003575357535759, 38.998199819981998],
            [7.630513051305137, 38.998199819981998],
            [7.569781978197824, 38.998199819981998],
            [7.32685768576858, 38.998199819981998],
            [7.031878187818783, 38.998199819981998],
            [6.719546954695474, 38.998199819981998],
            [6.251050105010506, 38.998199819981998],
            [6.086208620862088, 38.998199819981998],
            [5.374787478747876, 38.998199819981998],
            [5.001725172517254, 38.998199819981998],
            [4.680718071807185, 38.998199819981998],
            [4.663366336633665, 39.583258325832581],
            [4.663366336633665, 39.719771977197723],
            [4.663366336633665, 39.953795379537951],
            [4.663366336633665, 40.031803180318036],
            [4.663366336633665, 40.064306430643065],
            [4.663366336633665, 40.181318131813185],
            [4.663366336633665, 40.285328532853285],
            [4.663366336633665, 40.389338933893384],
            [4.663366336633665, 40.499849984998498],
            [4.663366336633665, 40.551855185518548],
            [4.663366336633665, 40.857385738573853],
            [4.663366336633665, 41.05890589058906],
            [4.663366336633665, 41.416441644164415],
            [4.663366336633665, 41.734973497349735],
            [4.663366336633665, 42.001500150015005],
            [4.437793779377941, 42.066506650665062],
            [4.411766176617668, 42.079507950795076],
            [4.333683368336835, 42.099009900990097],
            [4.116786678667872, 42.170517051705168],
            [4.038703870387046, 42.196519651965197],
            [4.004000400040006, 42.209520952095204],
            [3.92591759175918, 42.216021602160211],
            [3.830483048304835, 42.261526152615261],
            [3.500800080008005, 42.333033303330332],
            [3.37933793379338, 42.372037203720367],
            [3.197144714471449, 42.430543054305431],
            [3.179792979297936, 42.430543054305431],
            [3.162441244124416, 42.430543054305431],
            [3.127737773777383, 42.430543054305431],
            [2.980248024802485, 42.482548254825481],
            [2.910841084108412, 42.463046304630467],
            [2.832758275827587, 42.450045004500453],
            [2.693944394439448, 42.417541754175417],
            [2.52910291029103, 42.398039803980396],
            [2.216771677167721, 42.430543054305431],
            [2.164716471647168, 42.385038503850382],
            [2.103985398539855, 42.385038503850382],
            [2.103985398539855, 42.378537853785375],
            [2.00855085508551, 42.36553655365536],
            [1.730923092309233, 42.502050205020502],
            [1.67886788678868, 42.619061906190616],
            [1.644164416441647, 42.619061906190616],
            [1.479322932293236, 42.619061906190616],
            [1.375212521252131, 42.697069706970694],
            [1.227722772277232, 42.723072307230723],
            [1.114936493649367, 42.775077507750773],
            [0.993474347434748, 42.775077507750773],
            [0.86333633363337, 42.814081408140808],
            [0.750550055005505, 42.846584658465844],
            [0.733198319831985, 42.853085308530851],
            [0.559680968096814, 42.690569056905687],
            [0.420867086708675, 42.697069706970694],
            [0.368811881188122, 42.697069706970694],
            [0.151915191519159, 42.72957295729573],
            [-0.064981498149812, 42.697069706970694],
            [-0.203795379537951, 42.762076207620765],
            [-0.247174717471744, 42.788078807880787],
            [-0.299229922992296, 42.840084008400837],
            [-0.464071407140707, 42.814081408140808],
            [-0.654940494049399, 42.872587258725872],
            [-0.724347434743471, 42.8985898589859],
            [-0.84580958095809, 42.931093109310929],
            [-0.975947594759475, 42.970097009700964],
            [-1.106085608560853, 43.009100910091007],
            [-1.331658165816577, 43.04810481048105],
            [-1.453120312031203, 43.061106110611064],
            [-1.470472047204716, 43.288628862886284],
            [-1.487823782378236, 43.282128212821277],
            [-1.513851385138508, 43.295129512951291],
            [-1.565906590659061, 43.256125612561256],
            [-1.617961796179614, 43.288628862886284],
            [-1.72207220722072, 43.314631463146313],
            [-1.739423942394232, 43.353635363536355],
            [-1.782803280328032, 43.386138613861384],
            [-1.782803280328032, 43.581158115811576],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LZBBFIR", name: "BRATISLAVA  FIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.570357035703573, 49.087208720872084],
            [22.448894889488955, 48.950695069506949],
            [22.370812081208129, 48.820682068206821],
            [22.353460346034609, 48.768676867686764],
            [22.258025802580264, 48.651665166516651],
            [22.249349934993504, 48.632163216321629],
            [22.162591259125918, 48.541154115411544],
            [22.153915391539158, 48.469646964696466],
            [22.145239523952398, 48.463146314631459],
            [22.162591259125918, 48.43064306430643],
            [22.01510151015102, 48.385138513851388],
            [21.928342834283434, 48.359135913591359],
            [21.780853085308536, 48.339633963396338],
            [21.66806680668067, 48.398139813981402],
            [21.616011601160125, 48.508650865086508],
            [21.451170117011706, 48.58015801580158],
            [21.355735573557361, 48.560656065606558],
            [21.295004500450048, 48.521652165216523],
            [21.18221822182219, 48.521652165216523],
            [21.104135413541357, 48.489148914891487],
            [20.748424842484255, 48.573657365736572],
            [20.635638563856389, 48.547654765476551],
            [20.514176417641771, 48.515151515151516],
            [20.488148814881491, 48.48264826482648],
            [20.444769476947698, 48.450145014501445],
            [20.418741874187425, 48.398139813981402],
            [20.375362536253633, 48.326632663266324],
            [20.288603860386047, 48.268126812681267],
            [20.054355435543563, 48.177117711771174],
            [19.898189818981905, 48.157615761576153],
            [19.663941394139421, 48.248624862486245],
            [19.594534453445348, 48.229122912291231],
            [19.394989498949897, 48.086108610861089],
            [19.368961896189624, 48.092609260926096],
            [19.316906690669072, 48.086108610861089],
            [19.247499749975006, 48.066606660666068],
            [19.126037603760381, 48.073107310731075],
            [18.831058105810584, 48.047104710471046],
            [18.813706370637071, 48.021102110211018],
            [18.770327032703278, 47.943094309430947],
            [18.796354635463551, 47.826082608260826],
            [18.735623562356238, 47.806580658065805],
            [18.726947694769486, 47.793579357935791],
            [18.709595959595966, 47.780578057805783],
            [18.692244224422446, 47.780578057805783],
            [18.5968096809681, 47.767576757675769],
            [18.466671667166722, 47.761076107610762],
            [18.327857785778583, 47.735073507350734],
            [18.197719771977205, 47.741574157415741],
            [18.128312831283132, 47.754575457545755],
            [18.110961096109619, 47.754575457545755],
            [18.058905890589067, 47.754575457545755],
            [17.76392639263927, 47.748074807480748],
            [17.659815981598165, 47.787078707870791],
            [17.616436643664372, 47.819581958195819],
            [17.425567556755681, 47.917091709170919],
            [17.373512351235128, 47.949594959495947],
            [17.330133013301335, 47.949594959495947],
            [17.304105410541062, 47.982098209820975],
            [17.23469846984699, 48.021102110211018],
            [17.156615661566164, 48.008100810081004],
            [17.078532853285331, 48.027602760276025],
            [17.087208720872091, 48.066606660666068],
            [17.087208720872091, 48.105610561056103],
            [17.009125912591266, 48.125112511251125],
            [16.974422442244233, 48.170617061706167],
            [16.974422442244233, 48.177117711771174],
            [16.965746574657473, 48.190119011901189],
            [16.957070707070713, 48.216121612161217],
            [16.939718971897193, 48.242124212421245],
            [16.92236723672368, 48.268126812681267],
            [16.90501550155016, 48.307130713071302],
            [16.844284428442847, 48.372137213721373],
            [16.835608560856087, 48.385138513851388],
            [16.852960296029607, 48.43064306430643],
            [16.852960296029607, 48.450145014501445],
            [16.939718971897193, 48.612661266126608],
            [17.078532853285331, 48.781678167816779],
            [17.165291529152924, 48.853185318531857],
            [17.173967396739677, 48.859685968596857],
            [17.364836483648368, 48.814181418141814],
            [17.408215821582161, 48.820682068206821],
            [17.538353835383546, 48.820682068206821],
            [17.607760776077612, 48.840184018401843],
            [17.677167716771685, 48.859685968596857],
            [17.711871187118717, 48.866186618661864],
            [17.902740274027408, 48.97019701970197],
            [17.911416141614168, 49.002700270026999],
            [17.946119611961201, 49.02220222022202],
            [17.972147214721474, 49.02220222022202],
            [18.06758175817582, 49.035203520352034],
            [18.0936093609361, 49.067706770677063],
            [18.110961096109619, 49.126212621262127],
            [18.119636963696372, 49.152215221522148],
            [18.119636963696372, 49.178217821782177],
            [18.145664566456652, 49.197719771977198],
            [18.154340434043412, 49.217221722172219],
            [18.154340434043412, 49.230223022302226],
            [18.163016301630165, 49.243224322432241],
            [18.180368036803685, 49.28872887288729],
            [18.284478447844791, 49.301730173017297],
            [18.371237123712376, 49.327732773277326],
            [18.423292329232929, 49.399239923992397],
            [18.449319931993202, 49.39273927392739],
            [18.544754475447547, 49.431743174317432],
            [18.562106210621067, 49.509750975097511],
            [18.700920092009206, 49.496749674967496],
            [18.848409840984104, 49.516251625162518],
            [18.961196119611969, 49.509750975097511],
            [18.978547854785482, 49.39273927392739],
            [19.204120412041206, 49.412241224122411],
            [19.256175617561759, 49.529252925292525],
            [19.47307230723073, 49.61376137613761],
            [19.646589658965901, 49.457745774577454],
            [19.637913791379141, 49.425242524252425],
            [19.794079407940799, 49.373237323732369],
            [19.802755275527559, 49.301730173017297],
            [19.854810481048112, 49.197719771977198],
            [20.00230023002301, 49.223722372237219],
            [20.097734773477356, 49.217221722172219],
            [20.175817581758182, 49.314731473147312],
            [20.253900390039007, 49.347234723472347],
            [20.705045504550462, 49.418741874187418],
            [20.930618061806186, 49.28872887288729],
            [21.008700870087011, 49.334233423342333],
            [21.095459545954604, 49.366736673667369],
            [21.199569956995703, 49.405740574057404],
            [21.225597559755983, 49.418741874187418],
            [21.329707970797088, 49.451245124512447],
            [21.442494249424946, 49.412241224122411],
            [22.570357035703573, 49.087208720872084],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LHCCFIR", name: "BUDAPEST FIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.162591259125918, 48.43064306430643],
            [22.231998199819991, 48.411141114111409],
            [22.318756875687576, 48.339633963396338],
            [22.596384638463853, 48.131613161316132],
            [22.683143314331438, 48.099109910991096],
            [22.830633063306337, 48.118611861186118],
            [22.86533653365337, 47.949594959495947],
            [22.440219021902195, 47.793579357935791],
            [22.197294729472951, 47.637563756375641],
            [22.110536053605365, 47.57255725572557],
            [22.02377737773778, 47.397039703970393],
            [21.858935893589361, 47.247524752475243],
            [21.858935893589361, 47.208520852085208],
            [21.841584158415849, 47.182518251825186],
            [21.806880688068816, 47.156515651565158],
            [21.772177217721776, 47.117511751175115],
            [21.763501350135016, 47.098009800980094],
            [21.69409440944095, 47.059005900590059],
            [21.67674267426743, 47.013501350135016],
            [21.616011601160125, 46.863986398639867],
            [21.581308130813085, 46.857485748574859],
            [21.511901190119019, 46.714471447144717],
            [21.494549454945499, 46.688468846884689],
            [21.416466646664674, 46.636463646364632],
            [21.321032103210328, 46.616961696169611],
            [21.303680368036808, 46.584458445844582],
            [21.268976897689775, 46.519451945194518],
            [21.268976897689775, 46.49344934493449],
            [21.268976897689775, 46.428442844284426],
            [21.251625162516255, 46.421942194219426],
            [21.199569956995703, 46.369936993699369],
            [21.130163016301637, 46.304930493049305],
            [21.104135413541357, 46.26592659265927],
            [21.086783678367844, 46.252925292529255],
            [20.85253525352536, 46.285428542854284],
            [20.705045504550462, 46.161916191619156],
            [20.401390139013905, 46.155415541554156],
            [20.262576257625767, 46.129412941294127],
            [20.097734773477356, 46.161916191619156],
            [19.941569156915698, 46.17491749174917],
            [19.872162216221625, 46.148914891489149],
            [19.768051805180527, 46.135913591359135],
            [19.724672467246734, 46.168416841684163],
            [19.698644864486454, 46.181418141814177],
            [19.603210321032108, 46.17491749174917],
            [19.533803380338043, 46.155415541554156],
            [19.48174817481749, 46.103410341034106],
            [19.351610161016104, 46.044904490449042],
            [19.282203220322039, 45.999399939993999],
            [19.082658265826588, 45.966896689668964],
            [18.926492649264929, 45.934393439343935],
            [18.848409840984104, 45.9018901890189],
            [18.813706370637071, 45.869386938693864],
            [18.414616461646169, 45.745874587458744],
            [18.362561256125616, 45.752375237523751],
            [18.136988698869892, 45.758875887588758],
            [17.868036803680376, 45.771877187718772],
            [17.564381438143819, 45.934393439343935],
            [17.399539953995408, 45.94739473947395],
            [17.295429542954302, 45.992899289928992],
            [17.182643264326437, 46.11641164116412],
            [17.182643264326437, 46.12291229122912],
            [17.147939793979404, 46.168416841684163],
            [16.965746574657473, 46.239923992399241],
            [16.88766376637664, 46.291929192919291],
            [16.636063606360644, 46.473947394739469],
            [16.531953195319538, 46.499949994999497],
            [16.419166916691672, 46.642964296429639],
            [16.419166916691672, 46.681968196819682],
            [16.36711171117112, 46.850985098509852],
            [16.115511551155123, 46.870487048704874],
            [16.228297829782981, 47.000500050005002],
            [16.349759975997607, 47.143514351435144],
            [16.436518651865192, 47.241024102410236],
            [16.471222122212225, 47.286528652865286],
            [16.497249724972505, 47.312531253125314],
            [16.540629062906298, 47.358035803580357],
            [16.601360136013604, 47.429542954295428],
            [16.618711871187124, 47.449044904490449],
            [16.636063606360644, 47.468546854685471],
            [16.705470547054709, 47.546554655465542],
            [16.800905090509055, 47.585558555855584],
            [16.826932693269335, 47.598559855985599],
            [17.009125912591266, 47.676567656765677],
            [17.087208720872091, 47.709070907090705],
            [17.095884588458851, 47.754575457545755],
            [17.095884588458851, 47.767576757675769],
            [17.113236323632371, 47.832583258325826],
            [17.139263926392644, 47.923592359235926],
            [17.139263926392644, 47.93659365936594],
            [17.156615661566164, 47.982098209820975],
            [17.156615661566164, 48.008100810081004],
            [17.23469846984699, 48.021102110211018],
            [17.304105410541062, 47.982098209820975],
            [17.330133013301335, 47.949594959495947],
            [17.373512351235128, 47.949594959495947],
            [17.425567556755681, 47.917091709170919],
            [17.616436643664372, 47.819581958195819],
            [17.659815981598165, 47.787078707870791],
            [17.76392639263927, 47.748074807480748],
            [18.058905890589067, 47.754575457545755],
            [18.110961096109619, 47.754575457545755],
            [18.128312831283132, 47.754575457545755],
            [18.197719771977205, 47.741574157415741],
            [18.327857785778583, 47.735073507350734],
            [18.466671667166722, 47.761076107610762],
            [18.5968096809681, 47.767576757675769],
            [18.692244224422446, 47.780578057805783],
            [18.709595959595966, 47.780578057805783],
            [18.726947694769486, 47.793579357935791],
            [18.735623562356238, 47.806580658065805],
            [18.796354635463551, 47.826082608260826],
            [18.770327032703278, 47.943094309430947],
            [18.813706370637071, 48.021102110211018],
            [18.831058105810584, 48.047104710471046],
            [19.126037603760381, 48.073107310731075],
            [19.247499749975006, 48.066606660666068],
            [19.316906690669072, 48.086108610861089],
            [19.368961896189624, 48.092609260926096],
            [19.394989498949897, 48.086108610861089],
            [19.594534453445348, 48.229122912291231],
            [19.663941394139421, 48.248624862486245],
            [19.898189818981905, 48.157615761576153],
            [20.054355435543563, 48.177117711771174],
            [20.288603860386047, 48.268126812681267],
            [20.375362536253633, 48.326632663266324],
            [20.418741874187425, 48.398139813981402],
            [20.444769476947698, 48.450145014501445],
            [20.488148814881491, 48.48264826482648],
            [20.514176417641771, 48.515151515151516],
            [20.635638563856389, 48.547654765476551],
            [20.748424842484255, 48.573657365736572],
            [21.104135413541357, 48.489148914891487],
            [21.18221822182219, 48.521652165216523],
            [21.295004500450048, 48.521652165216523],
            [21.355735573557361, 48.560656065606558],
            [21.451170117011706, 48.58015801580158],
            [21.616011601160125, 48.508650865086508],
            [21.66806680668067, 48.398139813981402],
            [21.780853085308536, 48.339633963396338],
            [21.928342834283434, 48.359135913591359],
            [22.01510151015102, 48.385138513851388],
            [22.162591259125918, 48.43064306430643],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EGPXFIR", name: "SCOTTISH FIR", fab: "9" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.998849884988495, 61.002900290029004],
            [-8.064131413141311, 61.002900290029004],
            [-7.161841184118408, 61.002900290029004],
            [-6.996999699969997, 61.002900290029004],
            [-6.50247524752475, 61.002900290029004],
            [-6.48512351235123, 61.002900290029004],
            [-6.077357735773575, 61.002900290029004],
            [-5.999274927492749, 61.002900290029004],
            [-5.496074607460741, 61.002900290029004],
            [-5.149039903990399, 61.002900290029004],
            [-4.836708670867083, 61.002900290029004],
            [-4.585108510851079, 61.002900290029004],
            [-4.003825382538253, 61.002900290029004],
            [-1.852210221022098, 61.002900290029004],
            [-0.0042504250425, 61.002900290029004],
            [-0.0042504250425, 60.001800180018002],
            [0.01310131013102, 59.988798879887987],
            [0.672467246724679, 59.637763776377639],
            [1.652840284028407, 59.085208520852085],
            [1.739598959895993, 59.033203320332028],
            [2.156040604060408, 58.792679267926793],
            [2.598509850985103, 58.519651965196516],
            [2.832758275827587, 58.389638963896388],
            [2.997599759976005, 58.298629862986296],
            [3.162441244124416, 58.175117511751175],
            [3.587558755875591, 57.921592159215919],
            [3.604910491049111, 57.908590859085905],
            [4.047379737973799, 57.622562256225621],
            [4.134138413841391, 57.570557055705571],
            [4.498524852485254, 57.336533653365336],
            [5.001725172517254, 56.998499849984995],
            [5.001725172517254, 56.582458245824583],
            [5.001725172517254, 56.536953695369533],
            [5.001725172517254, 56.419941994199419],
            [5.001725172517254, 56.250925092509249],
            [5.001725172517254, 56.055905590559057],
            [5.001725172517254, 55.95189518951895],
            [5.001725172517254, 55.938893889388936],
            [5.001725172517254, 55.795879587958794],
            [5.001725172517254, 55.607360736073609],
            [5.001725172517254, 55.581358135813581],
            [5.001725172517254, 55.490349034903488],
            [5.001725172517254, 55.386338633863382],
            [5.001725172517254, 55.340834083408339],
            [5.001725172517254, 55.308330833083303],
            [5.001725172517254, 55.243324332433239],
            [5.001725172517254, 55.178317831783176],
            [5.001725172517254, 55.126312631263126],
            [5.001725172517254, 55.080808080808076],
            [5.001725172517254, 55.002800280027998],
            [4.038703870387046, 55.002800280027998],
            [3.500800080008005, 55.002800280027998],
            [3.119061906190623, 55.002800280027998],
            [3.049654965496551, 55.002800280027998],
            [2.980248024802485, 55.002800280027998],
            [-0.785078507850784, 55.002800280027998],
            [-0.86316131613161, 55.002800280027998],
            [-0.984623462346228, 55.002800280027998],
            [-1.270927092709265, 55.002800280027998],
            [-1.505175517551748, 55.002800280027998],
            [-1.912941294129411, 55.002800280027998],
            [-2.121162116211615, 55.002800280027998],
            [-2.468196819681964, 55.002800280027998],
            [-2.711121112111208, 55.002800280027998],
            [-3.439893989398939, 55.002800280027998],
            [-3.517976797679765, 55.002800280027998],
            [-4.003825382538253, 55.002800280027998],
            [-4.897439743974395, 55.002800280027998],
            [-5.496074607460741, 55.002800280027998],
            [-5.496074607460741, 54.898789878987898],
            [-5.496074607460741, 53.917191719171917],
            [-5.738998899889985, 53.96269626962696],
            [-6.042654265426542, 54.021202120212017],
            [-6.068681868186815, 54.027702770277024],
            [-6.207495749574953, 54.053705370537052],
            [-6.268226822682266, 54.066706670667067],
            [-6.407040704070404, 54.092709270927088],
            [-6.649964996499648, 54.138213821382138],
            [-6.762751275127506, 54.164216421642159],
            [-6.832158215821579, 54.177217721772173],
            [-6.910241024102405, 54.190219021902188],
            [-7.127137713771376, 54.22922292229223],
            [-7.396089608960892, 54.28122812281228],
            [-7.699744974497449, 54.33323332333233],
            [-7.89061406140614, 54.365736573657365],
            [-7.977372737273726, 54.385238523852387],
            [-8.168241824182417, 54.417741774177415],
            [-8.098834883488347, 54.463246324632465],
            [-8.055455545554551, 54.5022502250225],
            [-7.89061406140614, 54.625762576257621],
            [-7.838558855885587, 54.658265826582657],
            [-7.647689768976896, 54.801280128012799],
            [-7.283303330333027, 55.067806780678069],
            [-7.187868786878688, 55.13931393139314],
            [-7.049054905490543, 55.236823682368232],
            [-6.988323832383237, 55.282328232823282],
            [-6.936268626862685, 55.321332133213318],
            [-6.918916891689165, 55.334333433343332],
            [-7.33535853585358, 55.418841884188417],
            [-7.907965796579653, 55.366836683668367],
            [-8.064131413141311, 55.347334733473346],
            [-8.246324632463242, 55.334333433343332],
            [-8.385138513851381, 55.217321732173218],
            [-8.454545454545453, 55.178317831783176],
            [-8.541304130413039, 55.106810681068104],
            [-8.740849084908486, 54.950795079507948],
            [-8.801580158015799, 54.905290529052905],
            [-8.949069906990697, 54.788278827882785],
            [-9.556380638063803, 54.65176517651765],
            [-9.998849884988495, 54.567256725672564],
            [-9.998849884988495, 54.5997599759976],
            [-9.998849884988495, 54.723272327232721],
            [-9.998849884988495, 54.749274927492749],
            [-9.998849884988495, 55.002800280027998],
            [-9.998849884988495, 55.119811981198119],
            [-9.998849884988495, 55.236823682368232],
            [-9.998849884988495, 55.496849684968495],
            [-9.998849884988495, 55.665866586658666],
            [-9.998849884988495, 55.750375037503751],
            [-9.998849884988495, 55.756875687568758],
            [-9.998849884988495, 55.769876987698765],
            [-9.998849884988495, 55.821882188218822],
            [-9.998849884988495, 55.997399739973993],
            [-9.998849884988495, 56.094909490949092],
            [-9.998849884988495, 56.133913391339135],
            [-9.998849884988495, 56.250925092509249],
            [-9.998849884988495, 56.263926392639263],
            [-9.998849884988495, 56.335433543354334],
            [-9.998849884988495, 56.439443944394441],
            [-9.998849884988495, 56.497949794979498],
            [-9.998849884988495, 56.582458245824583],
            [-9.998849884988495, 56.58895889588959],
            [-9.998849884988495, 56.666966696669668],
            [-9.998849884988495, 56.764476447644761],
            [-9.998849884988495, 56.998499849984995],
            [-9.998849884988495, 57.018001800180016],
            [-9.998849884988495, 57.252025202520251],
            [-9.998849884988495, 57.271527152715272],
            [-9.998849884988495, 57.336533653365336],
            [-9.998849884988495, 57.4015401540154],
            [-9.998849884988495, 57.466546654665464],
            [-9.998849884988495, 57.4990499049905],
            [-9.998849884988495, 57.551055105510549],
            [-9.998849884988495, 57.752575257525748],
            [-9.998849884988495, 57.765576557655763],
            [-9.998849884988495, 57.804580458045805],
            [-9.998849884988495, 57.986598659865983],
            [-9.998849884988495, 57.999599959995997],
            [-9.998849884988495, 58.097109710971097],
            [-9.998849884988495, 58.253125312531253],
            [-9.998849884988495, 58.480648064806481],
            [-9.998849884988495, 58.500150015001495],
            [-9.998849884988495, 58.649664966496651],
            [-9.998849884988495, 58.669166916691665],
            [-9.998849884988495, 58.961696169616957],
            [-9.998849884988495, 58.974697469746971],
            [-9.998849884988495, 58.981198119811978],
            [-9.998849884988495, 59.000700070006999],
            [-9.998849884988495, 59.16971697169717],
            [-9.998849884988495, 59.332233223322334],
            [-9.998849884988495, 59.462246224622461],
            [-9.998849884988495, 59.481748174817483],
            [-9.998849884988495, 59.501250125012497],
            [-9.998849884988495, 59.98229822982298],
            [-9.998849884988495, 60.001800180018002],
            [-9.998849884988495, 60.476347634763478],
            [-9.998849884988495, 60.502350235023499],
            [-9.998849884988495, 60.664866486648663],
            [-9.998849884988495, 61.002900290029004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LTBBFIR", name: "ISTANBUL FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.300980098009802, 41.734973497349735],
            [26.569931993199333, 41.87148714871487],
            [26.613311331133119, 41.981998199819984],
            [26.76947694769477, 41.968996899689969],
            [27.012401240124021, 42.04050405040504],
            [27.133863386338646, 42.086008600860083],
            [27.211946194619472, 42.066506650665062],
            [27.281353135313537, 42.066506650665062],
            [27.420167016701683, 41.988498849884991],
            [27.567656765676574, 41.903990399039898],
            [27.775877587758785, 41.968996899689969],
            [27.853960396039611, 41.994999499949998],
            [28.001450145014502, 41.981998199819984],
            [28.313781378137818, 41.981998199819984],
            [28.48729872987299, 42.014501450145012],
            [28.764926492649266, 42.079507950795076],
            [28.999174917491757, 42.118511851185119],
            [29.146664666466648, 42.170517051705168],
            [29.276802680268034, 42.222522252225218],
            [29.363561356135619, 42.261526152615261],
            [29.623837383738376, 42.36553655365536],
            [29.866761676167627, 42.463046304630467],
            [30.014251425142518, 42.51505150515051],
            [30.265851585158529, 42.612561256125616],
            [30.595534553455352, 42.736073607360737],
            [30.751700170017003, 42.801080108010801],
            [31.003300330033014, 42.801080108010801],
            [31.003300330033014, 41.000400040003996],
            [31.003300330033014, 40.031803180318036],
            [31.003300330033014, 39.498749874987496],
            [30.534803480348046, 39.251725172517254],
            [30.569506950695072, 39.167216721672162],
            [30.647589758975897, 39.043704370437041],
            [30.907865786578668, 38.653665366536657],
            [30.005575557555758, 38.270127012701266],
            [29.988223822382238, 38.133613361336131],
            [29.996899689968998, 38.016601660166017],
            [29.996899689968998, 37.906090609060904],
            [29.996899689968998, 37.581058105810584],
            [29.996899689968998, 37.431543154315435],
            [29.996899689968998, 37.230023002300229],
            [29.996899689968998, 36.989498949894987],
            [29.996899689968998, 36.833483348334838],
            [29.996899689968998, 36.703470347034703],
            [29.996899689968998, 36.319931993199319],
            [29.996899689968998, 36.079407940794077],
            [29.806030603060307, 36.072907290729077],
            [29.710596059605962, 36.085908590859084],
            [29.58045804580459, 36.144414441444141],
            [29.502375237523765, 36.170417041704169],
            [29.32018201820182, 36.183418341834184],
            [29.250775077507754, 36.085908590859084],
            [28.816981698169826, 36.215921592159219],
            [28.548029802980309, 36.384938493849383],
            [28.48729872987299, 36.547454745474546],
            [28.209670967096713, 36.534453445344532],
            [28.053505350535062, 36.482448244824482],
            [27.992774277427756, 36.495449544954496],
            [27.914691469146916, 36.664466446644667],
            [27.862636263626371, 36.696969696969695],
            [27.723822382238225, 36.638463846384639],
            [27.532953295329534, 36.566956695669568],
            [27.359435943594363, 36.61246124612461],
            [27.229297929792992, 36.716471647164717],
            [27.402815281528163, 36.865986598659866],
            [27.177242724272432, 37.048004800480044],
            [27.099159915991606, 37.132513251325136],
            [27.116511651165126, 37.431543154315435],
            [26.986373637363741, 37.626562656265627],
            [26.977697769776981, 37.652565256525648],
            [27.081808180818086, 37.698069806980698],
            [27.047104710471046, 37.84108410841084],
            [27.029752975297541, 37.88658865886589],
            [27.003725372537261, 37.951595159515946],
            [26.873587358735875, 37.958095809580954],
            [26.526552655265533, 37.984098409840982],
            [26.300980098009802, 38.107610761076103],
            [26.248924892489256, 38.166116611661167],
            [26.170842084208431, 38.244124412441245],
            [26.196869686968697, 38.348134813481352],
            [26.300980098009802, 38.484648464846487],
            [26.318331833183322, 38.6016601660166],
            [26.431118111811188, 38.835683568356835],
            [26.630663066306639, 38.881188118811878],
            [26.752125212521264, 38.998199819981998],
            [26.526552655265533, 39.264726472647268],
            [26.535228522852293, 39.284228422842283],
            [26.500525052505253, 39.329732973297325],
            [26.431118111811188, 39.414241424142418],
            [26.370387038703882, 39.433743374337432],
            [26.153490349034911, 39.368736873687368],
            [25.884538453845394, 39.453245324532453],
            [25.615586558655878, 39.901790179017901],
            [25.554855485548558, 40.096809680968093],
            [25.91924192419242, 40.382838283828377],
            [25.884538453845394, 40.499849984998498],
            [25.901890189018914, 40.564856485648562],
            [25.901890189018914, 40.668866886688669],
            [26.248924892489256, 40.883388338833882],
            [26.318331833183322, 40.948394839483946],
            [26.292304230423042, 41.065406540654067],
            [26.318331833183322, 41.247424742474252],
            [26.595959595959599, 41.33193319331933],
            [26.552580258025813, 41.630963096309628],
            [26.300980098009802, 41.734973497349735],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EETTFIR", name: "TALLINN FIR", fab: "7" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.644314431443149, 58.415641564156417],
            [20.774452445244528, 58.63016301630163],
            [20.939293929392946, 58.9031903190319],
            [21.000025002500259, 59.000700070006999],
            [21.754825482548263, 59.189218921892184],
            [21.945694569456954, 59.234723472347234],
            [22.136563656365645, 59.280228022802277],
            [22.179942994299438, 59.286728672867284],
            [22.743874387438751, 59.423242324232419],
            [22.804605460546057, 59.436243624362433],
            [22.926067606760682, 59.462246224622461],
            [22.943419341934202, 59.462246224622461],
            [22.986798679867995, 59.475247524752476],
            [23.151640164016406, 59.514251425142511],
            [23.811006100610065, 59.66376637663766],
            [23.880413041304138, 59.676767676767675],
            [23.993199319931996, 59.702770277027703],
            [24.166716671667174, 59.741774177417739],
            [24.453020302030211, 59.800280028002796],
            [24.661241124112422, 59.845784578457845],
            [24.852110211021113, 59.884788478847881],
            [24.999599959996004, 59.891289128912888],
            [25.034303430343044, 59.891289128912888],
            [25.042979297929804, 59.891289128912888],
            [25.251200120012001, 59.904290429042902],
            [25.329282928292841, 59.910791079107909],
            [25.416041604160426, 59.904290429042902],
            [25.580883088308838, 59.897789778977895],
            [25.615586558655878, 59.897789778977895],
            [25.658965896589663, 59.891289128912888],
            [25.867186718671874, 59.884788478847881],
            [25.97129712971298, 59.865286528652867],
            [27.63706370637064, 59.611761176117611],
            [28.044829482948302, 59.468746874687469],
            [28.096884688468847, 59.455745574557454],
            [28.200995099509953, 59.384238423842383],
            [27.463546354635469, 58.844684468446843],
            [27.420167016701683, 58.701670167016701],
            [27.515601560156028, 58.331133113311331],
            [27.61971197119712, 58.03860386038604],
            [27.819256925692571, 57.902090209020898],
            [27.558980898089814, 57.830583058305827],
            [27.385463546354643, 57.603060306030599],
            [27.220622062206232, 57.596559655965592],
            [26.81285628562857, 57.583558355835585],
            [26.604635463546359, 57.525052505250521],
            [26.387738773877388, 57.577057705770578],
            [25.91924192419242, 57.856585658565855],
            [24.982248224822484, 58.012601260126011],
            [24.461696169616971, 57.928092809280926],
            [24.29685468546856, 57.902090209020898],
            [24.21009600960096, 57.902090209020898],
            [23.915116511651171, 57.902090209020898],
            [23.602785278527861, 57.902090209020898],
            [23.646164616461654, 57.785078507850784],
            [23.585433543354341, 57.668066806680663],
            [23.40324032403241, 57.596559655965592],
            [23.359860986098617, 57.590059005900585],
            [23.177667766776686, 57.583558355835585],
            [22.995474547454748, 57.700570057005699],
            [22.908715871587162, 57.778577857785777],
            [22.787253725372544, 57.876087608760876],
            [22.709170917091718, 57.94109410941094],
            [22.587708770877093, 57.928092809280926],
            [22.518301830183027, 57.908590859085905],
            [22.275377537753783, 57.82408240824082],
            [22.101860186018605, 57.77207720772077],
            [21.919666966696674, 57.752575257525748],
            [21.841584158415849, 57.765576557655763],
            [21.720122012201223, 57.785078507850784],
            [21.659390939093917, 57.843584358435841],
            [21.650715071507157, 57.856585658565855],
            [21.616011601160125, 57.895589558955891],
            [21.546604660466052, 58.019101910191019],
            [21.485873587358739, 58.116611661166118],
            [20.644314431443149, 58.415641564156417],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "ENORFIR", name: null, fab: "7" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.996899689968998, 71.000900090008997],
            [30.907865786578668, 70.669366936693677],
            [31.714721472147218, 70.363836383638358],
            [31.541204120412047, 70.259825982598258],
            [31.23754875487549, 70.071307130713066],
            [31.133438343834385, 69.999799979997988],
            [30.821107110711083, 69.791779177917789],
            [30.829782978297843, 69.531753175317533],
            [30.569506950695072, 69.544754475447547],
            [30.135713571357144, 69.674767476747675],
            [30.083658365836584, 69.655265526552654],
            [30.031603160316038, 69.421242124212426],
            [29.537078707870791, 69.330233023302327],
            [29.493699369937005, 69.310731073107306],
            [29.051230123012303, 69.018201820182014],
            [28.834333433343346, 69.102710271027092],
            [28.834333433343346, 69.128712871287121],
            [28.834333433343346, 69.21972197219722],
            [29.33753375337534, 69.486248624862483],
            [29.242099209920994, 69.577257725772569],
            [29.181368136813688, 69.64226422642264],
            [29.129312931293143, 69.700770077007689],
            [28.886388638863892, 69.739773977397732],
            [28.799629962996306, 69.752775277527746],
            [28.530678067806789, 69.798279827982796],
            [28.331133113311338, 69.889288928892881],
            [28.166291629162927, 69.91529152915291],
            [28.010126012601262, 70.097309730973095],
            [28.001450145014502, 70.103810381038102],
            [27.576332633263334, 70.077807780778073],
            [27.029752975297541, 69.91529152915291],
            [26.78682868286829, 69.954295429542952],
            [26.500525052505253, 69.91529152915291],
            [26.422442244224428, 69.908790879087917],
            [26.231573157315736, 69.791779177917789],
            [26.144814481448151, 69.759275927592753],
            [25.97997299729974, 69.694269426942697],
            [25.91924192419242, 69.668266826682668],
            [25.754400440044009, 69.330233023302327],
            [25.598234823482358, 68.998699869986993],
            [25.459420942094212, 68.914191419141915],
            [25.311931193119321, 68.823182318231829],
            [24.886813681368139, 68.556655665566552],
            [24.600510051005102, 68.634663466346638],
            [24.001875187518763, 68.79067906790678],
            [23.845709570957105, 68.836183618361844],
            [23.602785278527861, 68.751675167516751],
            [23.316481648164824, 68.660666066606666],
            [22.952095209520955, 68.693169316931687],
            [22.370812081208129, 68.73217321732173],
            [21.937018701870194, 69.063706370637064],
            [21.798204820482056, 69.174217421742171],
            [21.329707970797088, 69.317231723172313],
            [21.234273427342742, 69.206720672067206],
            [21.086783678367844, 69.05070507050705],
            [20.548879887988804, 69.057205720572057],
            [20.3406590659066, 68.927192719271915],
            [20.33198319831984, 68.881688168816879],
            [20.32330733073308, 68.79067906790678],
            [20.31463146314632, 68.771177117711773],
            [19.97627262726273, 68.563156315631559],
            [20.262576257625767, 68.491649164916481],
            [19.958920892089218, 68.348634863486353],
            [19.915541554155418, 68.361636163616367],
            [19.169416941694173, 68.485148514851488],
            [19.100010001000108, 68.498149814981502],
            [18.62283728372838, 68.504650465046495],
            [18.414616461646169, 68.582658265826581],
            [18.128312831283132, 68.537153715371545],
            [18.102285228522859, 68.426642664266424],
            [18.102285228522859, 68.41364136413641],
            [18.163016301630165, 68.205620562056197],
            [17.902740274027408, 67.971597159715969],
            [17.633788378837892, 68.049604960496055],
            [17.442919291929201, 68.082108210821076],
            [17.286753675367542, 68.121112111211119],
            [17.104560456045611, 68.030103010301019],
            [17.026477647764779, 68.004100410041005],
            [16.983098309830986, 67.997599759975998],
            [16.748849884988502, 67.91309130913092],
            [16.36711171117112, 67.536053605360536],
            [16.158890889088916, 67.516551655165514],
            [16.106835683568363, 67.458045804580451],
            [16.332408240824087, 67.263026302630266],
            [16.419166916691672, 67.068006800680067],
            [16.297704770477054, 67.00300030003001],
            [16.150215021502156, 66.91199119911991],
            [15.985373537353738, 66.833983398339825],
            [15.872587258725879, 66.781978197819782],
            [15.855235523552359, 66.768976897689768],
            [15.673042304230428, 66.63896389638964],
            [15.412766276627671, 66.502450245024505],
            [15.464821482148217, 66.333433343334335],
            [15.499524952495257, 66.268426842684264],
            [15.395414541454151, 66.255425542554264],
            [15.083083308330835, 66.164416441644164],
            [15.039703970397042, 66.15141514151415],
            [14.597234723472354, 66.138413841384136],
            [14.536503650365042, 66.131913191319128],
            [14.597234723472354, 65.800380038003794],
            [14.579882988298834, 65.748374837483738],
            [14.510476047604769, 65.585858585858574],
            [14.510476047604769, 65.55985598559856],
            [14.501800180018009, 65.51435143514351],
            [14.415041504150423, 65.267326732673268],
            [14.302255225522558, 65.098309830983098],
            [13.955220522052208, 64.844784478447849],
            [13.746999699970004, 64.675767576757664],
            [13.720972097209724, 64.64976497649765],
            [13.712296229622964, 64.643264326432643],
            [13.668916891689172, 64.597759775977593],
            [13.738323832383244, 64.558755875587565],
            [13.937868786878695, 64.500250025002501],
            [14.111386138613867, 64.461246124612458],
            [14.163441344134419, 64.201220122012202],
            [13.929192919291935, 63.999699969996996],
            [13.790379037903797, 64.032203220322032],
            [13.599509950995106, 64.05820582058206],
            [13.373937393739382, 64.077707770777067],
            [13.191744174417444, 64.071207120712074],
            [13.000875087508753, 64.064706470647053],
            [12.98352335233524, 64.05820582058206],
            [12.810006000600062, 63.960696069606961],
            [12.167991799179923, 63.603160316031598],
            [12.246074607460748, 63.466646664666463],
            [12.202695269526956, 63.434143414341435],
            [12.081233123312337, 63.349634963496349],
            [12.055205520552057, 63.330133013301328],
            [12.003150315031505, 63.284628462846285],
            [12.246074607460748, 62.998599859985994],
            [12.12461246124613, 62.940094009400937],
            [12.081233123312337, 62.914091409140909],
            [12.046529652965305, 62.667066706670667],
            [12.167991799179923, 62.498049804980496],
            [12.185343534353443, 62.433043304330432],
            [12.237398739873996, 62.361536153615361],
            [12.263426342634268, 62.322532253225319],
            [12.315481548154821, 62.264026402640262],
            [12.246074607460748, 62.101510151015098],
            [12.220047004700476, 61.997499749974999],
            [12.211371137113716, 61.919491949194921],
            [12.220047004700476, 61.782978297829779],
            [12.220047004700476, 61.678967896789679],
            [12.341509150915094, 61.639963996399636],
            [12.584433443344338, 61.568456845684565],
            [12.671192119211923, 61.496949694969494],
            [12.836033603360342, 61.366936693669366],
            [12.723247324732476, 61.152415241524153],
            [12.237398739873996, 61.002900290029004],
            [12.289453945394541, 60.918391839183919],
            [12.350185018501854, 60.833883388338833],
            [12.393564356435647, 60.736373637363734],
            [12.610461046104618, 60.515351535153513],
            [12.584433443344338, 60.378837883788378],
            [12.497674767476752, 60.307330733073307],
            [12.497674767476752, 60.3008300830083],
            [12.532378237823785, 60.177317731773172],
            [12.497674767476752, 60.099309930993094],
            [12.393564356435647, 60.014801480148016],
            [12.167991799179923, 59.897789778977895],
            [12.09858485848585, 59.891289128912888],
            [12.046529652965305, 59.891289128912888],
            [12.020502050205025, 59.891289128912888],
            [12.003150315031505, 59.897789778977895],
            [11.916391639163919, 59.884788478847881],
            [11.829632963296334, 59.832783278327831],
            [11.925067506750679, 59.793779377937788],
            [11.916391639163919, 59.702770277027703],
            [11.864336433643366, 59.650765076507646],
            [11.777577757775781, 59.644264426442639],
            [11.699494949494955, 59.598759875987597],
            [11.699494949494955, 59.566256625662561],
            [11.725522552255228, 59.520752075207518],
            [11.794929492949301, 59.364736473647362],
            [11.820957095709574, 59.319231923192319],
            [11.768901890189021, 59.12421242124212],
            [11.664791479147922, 58.9031903190319],
            [11.465246524652471, 58.883688368836879],
            [11.352460246024606, 59.111211121112106],
            [11.239673967396747, 59.091709170917092],
            [11.118211821182122, 59.013701370137014],
            [10.970722072207224, 58.955195519551957],
            [10.831908190819085, 58.935693569356935],
            [10.719121912191227, 58.909690969096907],
            [10.606335633563361, 58.8056805680568],
            [10.597659765976601, 58.760176017601758],
            [10.571632163216329, 58.695169516951694],
            [10.528252825282536, 58.58465846584658],
            [10.502225222522256, 58.500150015001495],
            [10.215921592159219, 58.36363636363636],
            [10.146514651465154, 58.337633763376338],
            [10.129162916291634, 58.324632463246324],
            [10.007700770077015, 58.266126612661267],
            [9.868886888688877, 58.201120112011196],
            [9.782128212821284, 58.162116211621161],
            [9.617286728672873, 58.077607760776075],
            [9.591259125912593, 58.064606460646061],
            [9.521852185218528, 58.032103210321033],
            [9.495824582458248, 58.019101910191019],
            [9.452445244524455, 57.999599959995997],
            [9.435093509350942, 57.99309930993099],
            [9.226872687268731, 57.889088908890891],
            [9.192169216921698, 57.869586958695869],
            [9.05335533553356, 57.798079807980798],
            [8.801755175517556, 57.67456745674567],
            [8.793079307930796, 57.668066806680663],
            [8.749699969997003, 57.648564856485649],
            [8.662941294129418, 57.603060306030599],
            [8.576182618261832, 57.557555755575557],
            [8.046954695469552, 57.291029102910286],
            [7.899464946494653, 57.213021302130208],
            [7.604485448544857, 57.057005700570052],
            [7.500375037503751, 56.998499849984995],
            [7.456995699569958, 56.998499849984995],
            [7.3442094209421, 56.998499849984995],
            [7.231423142314235, 56.998499849984995],
            [6.936443644364438, 56.998499849984995],
            [6.83233323332334, 56.998499849984995],
            [6.80630563056306, 56.998499849984995],
            [6.728222822282234, 56.998499849984995],
            [6.693519351935201, 56.998499849984995],
            [6.45927092709271, 56.998499849984995],
            [5.999449944994502, 56.998499849984995],
            [5.96474647464747, 56.998499849984995],
            [5.773877387738779, 56.998499849984995],
            [5.687118711871193, 56.998499849984995],
            [5.583008300830087, 56.998499849984995],
            [5.504925492549262, 56.998499849984995],
            [5.175242524252432, 56.998499849984995],
            [5.001725172517254, 56.998499849984995],
            [4.498524852485254, 57.336533653365336],
            [4.134138413841391, 57.570557055705571],
            [4.047379737973799, 57.622562256225621],
            [3.604910491049111, 57.908590859085905],
            [3.587558755875591, 57.921592159215919],
            [3.162441244124416, 58.175117511751175],
            [2.997599759976005, 58.298629862986296],
            [2.832758275827587, 58.389638963896388],
            [2.598509850985103, 58.519651965196516],
            [2.156040604060408, 58.792679267926793],
            [1.739598959895993, 59.033203320332028],
            [1.652840284028407, 59.085208520852085],
            [0.672467246724679, 59.637763776377639],
            [0.01310131013102, 59.988798879887987],
            [-0.0042504250425, 60.001800180018002],
            [-0.0042504250425, 61.002900290029004],
            [-0.0042504250425, 61.217421742174217],
            [-0.0042504250425, 61.496949694969494],
            [-0.0042504250425, 61.52945294529453],
            [-0.0042504250425, 61.997499749974999],
            [-0.0042504250425, 62.433043304330432],
            [-0.0042504250425, 62.998599859985994],
            [-0.0042504250425, 63.018101810181015],
            [0.030453045304533, 62.998599859985994],
            [0.524977497749781, 62.998599859985994],
            [1.279777977797785, 62.998599859985994],
            [1.999874987498757, 62.998599859985994],
            [3.067006700670071, 62.998599859985994],
            [3.717696769676969, 62.998599859985994],
            [4.004000400040006, 62.998599859985994],
            [4.073407340734079, 63.070107010701065],
            [4.108110811081112, 63.109110911091108],
            [4.203545354535457, 63.206620662066207],
            [4.229572957295737, 63.239123912391236],
            [4.359710971097115, 63.362636263626364],
            [4.550580058005806, 63.557655765576555],
            [4.559255925592566, 63.564156415641563],
            [4.715421542154218, 63.720172017201719],
            [4.802180218021803, 63.798179817981797],
            [5.001725172517254, 63.999699969996996],
            [5.0971597159716, 64.071207120712074],
            [5.296704670467051, 64.266226622662259],
            [5.426842684268429, 64.383238323832387],
            [5.478897889788982, 64.428742874287423],
            [5.565656565656568, 64.513251325132501],
            [5.799904990499051, 64.721272127212728],
            [6.259725972597266, 65.130813081308133],
            [6.268401840184019, 65.130813081308133],
            [6.303105310531059, 65.163316331633155],
            [6.771602160216027, 65.546854685468546],
            [6.841009100910092, 65.618361836183624],
            [6.884388438843885, 65.657365736573652],
            [6.997174717471751, 65.748374837483738],
            [7.439643964396446, 66.040904090409043],
            [7.708595859585962, 66.2099209920992],
            [7.968871887188726, 66.378937893789384],
            [8.107685768576864, 66.463446344634463],
            [8.610886088608865, 66.768976897689768],
            [9.06203120312032, 67.042004200420038],
            [9.183493349334938, 67.107010701070095],
            [9.417741774177422, 67.250025002500252],
            [9.721397139713979, 67.419041904190408],
            [10.302680268026805, 67.744074407440735],
            [10.571632163216329, 67.893589358935884],
            [10.962046204620464, 68.09510951095109],
            [11.178942894289435, 68.199119911991204],
            [11.734198419841988, 68.498149814981502],
            [11.829632963296334, 68.543654365436538],
            [12.810006000600062, 69.018201820182014],
            [13.434668466846688, 69.310731073107306],
            [13.82508250825083, 69.486248624862483],
            [14.041979197919794, 69.58375837583759],
            [14.33695869586959, 69.713771377137704],
            [14.996324632463249, 69.999799979997988],
            [16.219621962196221, 70.201320132013194],
            [16.297704770477054, 70.214321432143208],
            [17.998174817481754, 70.467846784678471],
            [18.544754475447547, 70.558855885588557],
            [19.013251325132522, 70.623862386238613],
            [21.251625162516255, 70.916391639163919],
            [22.943419341934202, 71.117911791179125],
            [23.498674867486756, 71.156915691569154],
            [24.001875187518763, 71.208920892089196],
            [24.635213521352142, 71.299929992999296],
            [24.999599959996004, 71.332433243324331],
            [26.344359435943602, 71.332433243324331],
            [26.864911491149115, 71.332433243324331],
            [27.350760076007603, 71.332433243324331],
            [27.593684368436854, 71.332433243324331],
            [28.001450145014502, 71.332433243324331],
            [29.701920192019202, 71.052905290529054],
            [29.988223822382238, 71.000900090008997],
            [29.996899689968998, 71.000900090008997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EPWWFIR", name: "WARSZAWA FIR", fab: "1" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.35431043104311, 54.91829182918292],
            [14.380338033803383, 54.898789878987898],
            [14.397689768976903, 54.885788578857884],
            [14.423717371737176, 54.87278727872787],
            [14.441069106910696, 54.859785978597856],
            [14.467096709670969, 54.853285328532849],
            [14.484448444844489, 54.840284028402841],
            [14.510476047604769, 54.833783378337834],
            [14.536503650365042, 54.827282728272827],
            [14.562531253125314, 54.82078207820782],
            [14.597234723472354, 54.814281428142813],
            [14.623262326232627, 54.807780778077806],
            [14.649289928992907, 54.801280128012799],
            [14.67531753175318, 54.801280128012799],
            [14.710021002100213, 54.801280128012799],
            [14.736048604860493, 54.794779477947792],
            [14.770752075207525, 54.801280128012799],
            [14.796779677967805, 54.801280128012799],
            [14.822807280728078, 54.801280128012799],
            [14.857510751075111, 54.807780778077806],
            [14.883538353835391, 54.807780778077806],
            [14.909565956595664, 54.814281428142813],
            [14.944269426942697, 54.82078207820782],
            [14.961621162116217, 54.827282728272827],
            [14.987648764876489, 54.833783378337834],
            [15.013676367636769, 54.846784678467849],
            [15.039703970397042, 54.853285328532849],
            [15.057055705570562, 54.866286628662863],
            [15.083083308330835, 54.879287928792877],
            [15.100435043504355, 54.892289228922891],
            [15.117786778677875, 54.905290529052905],
            [15.143814381438148, 54.91829182918292],
            [15.299979997999806, 54.91829182918292],
            [15.655690569056908, 54.91829182918292],
            [15.846559655965599, 54.91829182918292],
            [15.863911391139119, 54.91829182918292],
            [16.002725272527258, 55.002800280027998],
            [16.07213221322133, 55.035303530353033],
            [16.236973697369741, 55.132813281328133],
            [16.505925592559258, 55.275827582758275],
            [16.714146414641469, 55.399339933993396],
            [16.878987898789887, 55.483848384838481],
            [17.147939793979404, 55.620362036203616],
            [17.165291529152924, 55.639863986398638],
            [17.191319131913197, 55.646364636463645],
            [17.547029702970299, 55.847884788478844],
            [17.946119611961201, 55.594359435943595],
            [18.163016301630165, 55.451345134513453],
            [18.388588858885896, 55.301830183018303],
            [18.388588858885896, 55.295329532953296],
            [18.397264726472656, 55.288828882888289],
            [18.449319931993202, 55.256325632563254],
            [18.900465046504657, 54.944294429442941],
            [18.961196119611969, 54.911791179117913],
            [18.995899589959002, 54.892289228922891],
            [19.004575457545762, 54.87278727872787],
            [19.195444544454453, 54.749274927492749],
            [19.290879087908799, 54.677767776777678],
            [19.351610161016104, 54.638763876387635],
            [19.403665366536657, 54.606260626062607],
            [19.45572057205721, 54.573757375737571],
            [19.577182718271835, 54.495749574957493],
            [19.655265526552661, 54.450245024502451],
            [19.872162216221625, 54.443744374437443],
            [20.00230023002301, 54.424242424242422],
            [20.496824682468251, 54.365736573657365],
            [20.696369636963702, 54.365736573657365],
            [21.468521852185226, 54.33323332333233],
            [22.171267126712678, 54.33323332333233],
            [22.795929592959304, 54.365736573657365],
            [23.056205620562061, 54.307230723072308],
            [23.40324032403241, 54.196719671967195],
            [23.741599159915999, 53.930193019301932],
            [23.550730073007308, 53.832683268326832],
            [23.628812881288134, 53.59215921592159],
            [23.767626762676272, 53.384138413841384],
            [23.897764776477651, 53.176117611761171],
            [23.984523452345243, 53.000600060006001],
            [23.923792379237931, 52.851085108510851],
            [23.932468246824691, 52.825082508250823],
            [23.932468246824691, 52.786078607860787],
            [23.168991899189926, 52.24002400240024],
            [23.212371237123719, 52.227022702270226],
            [23.40324032403241, 52.188018801880183],
            [23.550730073007308, 52.116511651165112],
            [23.663516351635167, 52.01900190019002],
            [23.594109410941101, 51.765476547654764],
            [23.542054205420548, 51.719971997199721],
            [23.559405940594068, 51.667966796679664],
            [23.620137013701374, 51.498949894989501],
            [23.646164616461654, 51.303930393039302],
            [24.097309730973109, 50.835883588358833],
            [24.053930393039309, 50.666866686668662],
            [24.062606260626069, 50.504350435043506],
            [23.828357835783585, 50.406840684068406],
            [23.481323132313236, 50.218321832183214],
            [23.212371237123719, 50.023302330233022],
            [23.134288428842893, 49.964796479647966],
            [23.056205620562061, 49.886788678867887],
            [22.943419341934202, 49.808780878087809],
            [22.657115711571166, 49.568256825682568],
            [22.657115711571166, 49.503250325032504],
            [22.735198519851991, 49.210721072107212],
            [22.735198519851991, 49.204220422042205],
            [22.830633063306337, 49.087208720872084],
            [22.769901990199024, 49.002700270026999],
            [22.570357035703573, 49.087208720872084],
            [21.442494249424946, 49.412241224122411],
            [21.329707970797088, 49.451245124512447],
            [21.225597559755983, 49.418741874187418],
            [21.199569956995703, 49.405740574057404],
            [21.095459545954604, 49.366736673667369],
            [21.008700870087011, 49.334233423342333],
            [20.930618061806186, 49.28872887288729],
            [20.705045504550462, 49.418741874187418],
            [20.253900390039007, 49.347234723472347],
            [20.175817581758182, 49.314731473147312],
            [20.097734773477356, 49.217221722172219],
            [20.00230023002301, 49.223722372237219],
            [19.854810481048112, 49.197719771977198],
            [19.802755275527559, 49.301730173017297],
            [19.794079407940799, 49.373237323732369],
            [19.637913791379141, 49.425242524252425],
            [19.646589658965901, 49.457745774577454],
            [19.47307230723073, 49.61376137613761],
            [19.256175617561759, 49.529252925292525],
            [19.204120412041206, 49.412241224122411],
            [18.978547854785482, 49.39273927392739],
            [18.961196119611969, 49.509750975097511],
            [18.848409840984104, 49.516251625162518],
            [18.805030503050311, 49.665766576657667],
            [18.63151315131514, 49.717771777177717],
            [18.570782078207827, 49.847784778477845],
            [18.562106210621067, 49.88028802880288],
            [18.466671667166722, 49.912791279127909],
            [18.336533653365343, 49.945294529452944],
            [18.345209520952103, 49.93229322932293],
            [18.319181918191823, 49.919291929192916],
            [18.284478447844791, 49.93229322932293],
            [18.275802580258031, 49.964796479647966],
            [18.215071507150718, 49.971297129712973],
            [18.215071507150718, 49.990799079907987],
            [18.197719771977205, 50.003800380038001],
            [18.180368036803685, 50.003800380038001],
            [18.163016301630165, 49.98429842984298],
            [18.119636963696372, 49.997299729972994],
            [18.0936093609361, 50.02980298029803],
            [18.006850685068514, 50.055805580558051],
            [17.998174817481754, 50.02980298029803],
            [17.954795479547961, 50.003800380038001],
            [17.928767876787681, 49.990799079907987],
            [17.868036803680376, 49.97779777977798],
            [17.78127812781279, 50.016801680168015],
            [17.74657465746575, 50.075307530753072],
            [17.711871187118717, 50.094809480948093],
            [17.607760776077612, 50.15331533153315],
            [17.590409040904099, 50.159815981598157],
            [17.73789878987899, 50.205320532053207],
            [17.74657465746575, 50.205320532053207],
            [17.74657465746575, 50.211821182118207],
            [17.74657465746575, 50.3028302830283],
            [17.694519451945197, 50.328832883288328],
            [17.633788378837892, 50.270327032703271],
            [17.468946894689473, 50.270327032703271],
            [17.451595159515954, 50.257325732573257],
            [17.199994999499957, 50.374337433743371],
            [16.90501550155016, 50.445844584458442],
            [16.974422442244233, 50.309330933093307],
            [16.991774177417746, 50.289828982898285],
            [17.017801780178026, 50.270327032703271],
            [17.017801780178026, 50.257325732573257],
            [17.009125912591266, 50.218321832183214],
            [16.983098309830986, 50.237823782378236],
            [16.852960296029607, 50.205320532053207],
            [16.688118811881196, 50.101310131013101],
            [16.627387738773884, 50.127312731273129],
            [16.566656665666571, 50.159815981598157],
            [16.557980798079811, 50.218321832183214],
            [16.505925592559258, 50.257325732573257],
            [16.471222122212225, 50.283328332833278],
            [16.427842784278432, 50.322332233223321],
            [16.306380638063814, 50.380838083808378],
            [16.202270227022709, 50.432843284328435],
            [16.419166916691672, 50.575857585758577],
            [16.445194519451952, 50.588858885888584],
            [16.410491049104913, 50.614861486148612],
            [16.332408240824087, 50.666866686668662],
            [16.236973697369741, 50.673367336733669],
            [16.219621962196221, 50.640864086408641],
            [16.184918491849189, 50.627862786278627],
            [16.150215021502156, 50.653865386538655],
            [16.098159815981603, 50.660366036603662],
            [16.089483948394843, 50.647364736473648],
            [15.673042304230428, 50.751375137513747],
            [15.52555255525553, 50.783878387838783],
            [15.378062806280631, 50.809880988098811],
            [15.291304130413046, 50.952895289528954],
            [15.239248924892493, 50.998399839983996],
            [15.091759175917595, 51.01140114011401],
            [15.057055705570562, 51.024402440244025],
            [15.031028102810289, 51.01140114011401],
            [15.022352235223529, 51.030903090309032],
            [14.970297029702976, 50.998399839983996],
            [15.005000500050009, 50.985398539853982],
            [15.013676367636769, 50.978897889788975],
            [14.996324632463249, 50.907390739073904],
            [15.005000500050009, 50.868386838683868],
            [14.866186618661871, 50.868386838683868],
            [14.822807280728078, 50.868386838683868],
            [14.822807280728078, 50.88788878887889],
            [14.857510751075111, 50.907390739073904],
            [14.926917691769184, 50.991899189918989],
            [14.987648764876489, 51.115411541154117],
            [15.013676367636769, 51.199919991999195],
            [14.961621162116217, 51.407940794079408],
            [14.996324632463249, 51.498949894989501],
            [14.736048604860493, 51.583458345834579],
            [14.718696869686973, 51.7004700470047],
            [14.66664166416642, 51.75247524752475],
            [14.614586458645867, 51.797979797979799],
            [14.631938193819387, 51.830483048304828],
            [14.67531753175318, 51.901990199019899],
            [14.753400340034005, 52.058005800580055],
            [14.67531753175318, 52.136013601360133],
            [14.65796579657966, 52.207520752075204],
            [14.579882988298834, 52.29202920292029],
            [14.536503650365042, 52.402540254025403],
            [14.640614061406147, 52.539053905390539],
            [14.605910591059114, 52.604060406040603],
            [14.527827782778282, 52.630063006300631],
            [14.371662166216623, 52.727572757275723],
            [14.241524152415245, 52.812081208120809],
            [14.154765476547659, 52.903090309030901],
            [14.33695869586959, 53.169616961696171],
            [14.380338033803383, 53.176117611761171],
            [14.406365636563663, 53.273627362736271],
            [14.415041504150423, 53.332133213321328],
            [14.406365636563663, 53.358135813581356],
            [14.389013901390143, 53.377637763776377],
            [14.328282828282831, 53.507650765076505],
            [14.310931093109318, 53.64416441644164],
            [14.276227622762278, 53.683168316831683],
            [14.293579357935798, 53.735173517351733],
            [14.276227622762278, 53.761176117611761],
            [14.224172417241732, 53.852185218521853],
            [14.224172417241732, 53.930193019301932],
            [14.224172417241732, 54.053705370537052],
            [14.258875887588765, 54.164216421642159],
            [14.284903490349038, 54.33323332333233],
            [14.328282828282831, 54.664766476647664],
            [14.33695869586959, 54.762276227622763],
            [14.35431043104311, 54.91829182918292],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LPPOFIR", name: "SANTA MARIA FIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.000700070006999, 44.998299829982997],
            [-13.000700070006999, 44.497749774977493],
            [-13.000700070006999, 43.997199719971995],
            [-13.000700070006999, 43.503150315031505],
            [-13.000700070006999, 43.002600260026],
            [-13.998424842484248, 42.502050205020502],
            [-14.996149614961492, 42.001500150015005],
            [-14.996149614961492, 41.000400040003996],
            [-14.996149614961492, 39.999299929993001],
            [-14.996149614961492, 39.498749874987496],
            [-14.996149614961492, 38.998199819981998],
            [-14.996149614961492, 38.497649764976501],
            [-14.996149614961492, 37.997099709970996],
            [-14.996149614961492, 37.002500250025001],
            [-14.996149614961492, 36.501950195019504],
            [-15.386563656365635, 36.196419641964198],
            [-16.236798679867984, 35.520352035203516],
            [-17.798454845484546, 34.168216821682165],
            [-18.067406740674066, 33.927692769276931],
            [-18.397089708970896, 32.985098509850985],
            [-18.128137813781375, 32.328532853285324],
            [-17.720372037203717, 31.834483448344834],
            [-17.460096009600957, 31.684968496849685],
            [-20.00212521252125, 30.001300130013],
            [-20.392539253925392, 30.001300130013],
            [-20.999849984998498, 30.001300130013],
            [-21.997574757475746, 30.001300130013],
            [-23.003975397539751, 30.001300130013],
            [-24.001700170017003, 30.001300130013],
            [-24.999424942494247, 30.001300130013],
            [-24.999424942494247, 29.000200020001998],
            [-24.999424942494247, 27.999099909990999],
            [-24.999424942494247, 26.997999799979997],
            [-24.999424942494247, 25.996899689968998],
            [-24.999424942494247, 25.002300230023003],
            [-24.999424942494247, 24.001200120012001],
            [-26.543729372937293, 23.221122112211219],
            [-27.506750675067508, 22.720572057205722],
            [-28.539178917891789, 22.174517451745174],
            [-29.797179717971794, 21.498449844984499],
            [-30.768876887688769, 20.958895889588959],
            [-31.723222322232225, 20.425842584258426],
            [-33.571182118211823, 19.366236623662367],
            [-35.445169516951694, 18.254625462546255],
            [-37.501350135013503, 17.0],
            [-40.0, 22.298029802980299],
            [-40.0, 30.001300130013],
            [-40.0, 45.011301130113011],
            [-37.995874587458744, 44.998299829982997],
            [-30.335083508350834, 44.998299829982997],
            [-29.996724672467245, 44.998299829982997],
            [-29.502200220022001, 44.998299829982997],
            [-28.998999899989997, 44.998299829982997],
            [-28.495799579957996, 44.998299829982997],
            [-28.001275127512748, 44.998299829982997],
            [-27.498074807480748, 44.998299829982997],
            [-27.0035503550355, 44.998299829982997],
            [-26.5003500350035, 44.998299829982997],
            [-25.997149714971496, 44.998299829982997],
            [-25.502625262526252, 44.998299829982997],
            [-24.999424942494247, 44.998299829982997],
            [-24.496224622462243, 44.998299829982997],
            [-24.001700170017003, 44.998299829982997],
            [-23.498499849984999, 44.998299829982997],
            [-23.003975397539751, 44.998299829982997],
            [-22.50077507750775, 44.998299829982997],
            [-21.997574757475746, 44.998299829982997],
            [-21.503050305030502, 44.998299829982997],
            [-20.999849984998498, 44.998299829982997],
            [-20.496649664966494, 44.998299829982997],
            [-20.00212521252125, 44.998299829982997],
            [-19.498924892489246, 44.998299829982997],
            [-19.247324732473245, 44.998299829982997],
            [-18.995724572457245, 44.998299829982997],
            [-18.501200120011998, 44.998299829982997],
            [-18.457820782078205, 44.998299829982997],
            [-17.997999799979997, 44.998299829982997],
            [-17.503475347534753, 44.998299829982997],
            [-17.000275027502749, 44.998299829982997],
            [-16.497074707470745, 44.998299829982997],
            [-16.002550255025501, 44.998299829982997],
            [-15.499349934993496, 44.998299829982997],
            [-15.187018701870183, 44.998299829982997],
            [-14.996149614961492, 44.998299829982997],
            [-14.518976897689768, 44.998299829982997],
            [-14.501625162516248, 44.998299829982997],
            [-14.380163016301626, 44.998299829982997],
            [-13.998424842484248, 44.998299829982997],
            [-13.686093609360935, 44.998299829982997],
            [-13.503900390039, 44.998299829982997],
            [-13.295679567956793, 44.998299829982997],
            [-13.000700070006999, 44.998299829982997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UDDDFIR", name: "YEREVAN FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.581033103310332, 39.797779777977794],
            [44.502950295029507, 39.999299929993001],
            [44.26870187018703, 40.05130513051305],
            [43.965046504650473, 40.018801880188022],
            [43.661391139113917, 40.116311631163114],
            [43.652715271527157, 40.382838283828377],
            [43.652715271527157, 40.564856485648562],
            [43.748149814981502, 40.707870787078704],
            [43.687418741874197, 40.837883788378832],
            [43.678742874287437, 40.883388338833882],
            [43.687418741874197, 40.902890289028903],
            [43.678742874287437, 40.935393539353939],
            [43.600660066006611, 40.987398739873981],
            [43.565956595659571, 40.993899389938989],
            [43.470522052205226, 41.130413041304131],
            [43.565956595659571, 41.149914991499145],
            [43.609335933593371, 41.123912391239124],
            [43.678742874287437, 41.136913691369131],
            [43.748149814981502, 41.110911091109109],
            [43.782853285328542, 41.136913691369131],
            [43.834908490849088, 41.156415641564152],
            [43.973722372237233, 41.156415641564152],
            [44.060481048104819, 41.188918891889188],
            [44.103860386038605, 41.188918891889188],
            [44.155915591559165, 41.195419541954195],
            [44.181943194319445, 41.221422142214223],
            [44.173267326732685, 41.247424742474252],
            [44.20797079707971, 41.234423442344237],
            [44.22532253225323, 41.247424742474252],
            [44.25135013501351, 41.214921492149216],
            [44.329432943294336, 41.208420842084209],
            [44.346784678467856, 41.234423442344237],
            [44.442219221922201, 41.188918891889188],
            [44.520302030203027, 41.22792279227923],
            [44.528977897789787, 41.221422142214223],
            [44.502950295029507, 41.188918891889188],
            [44.572357235723587, 41.201920192019202],
            [44.581033103310332, 41.188918891889188],
            [44.589708970897092, 41.195419541954195],
            [44.607060706070612, 41.234423442344237],
            [44.702495249524958, 41.221422142214223],
            [44.719846984698478, 41.208420842084209],
            [44.789253925392543, 41.221422142214223],
            [44.867336733673369, 41.214921492149216],
            [44.797929792979303, 41.253925392539252],
            [44.815281528152823, 41.299429942994294],
            [44.832633263326343, 41.27992799279928],
            [44.884688468846889, 41.266926692669266],
            [44.936743674367449, 41.253925392539252],
            [44.997474747474755, 41.292929292929287],
            [45.040854085408554, 41.234423442344237],
            [45.023502350235034, 41.195419541954195],
            [45.12761276127614, 41.214921492149216],
            [45.205695569556966, 41.149914991499145],
            [45.049529952995314, 41.065406540654067],
            [45.292454245424551, 40.980898089808981],
            [45.387888788878897, 41.026402640264024],
            [45.596109610961108, 40.863886388638861],
            [45.500675067506762, 40.766376637663768],
            [45.370537053705377, 40.63636363636364],
            [45.439943994399442, 40.564856485648562],
            [45.448619861986202, 40.519351935193519],
            [45.544054405440548, 40.460846084608463],
            [45.639488948894893, 40.389338933893384],
            [45.96049604960497, 40.259325932593256],
            [45.98652365236525, 40.213821382138214],
            [45.847709770977104, 39.999299929993001],
            [45.778302830283039, 40.031803180318036],
            [45.630813081308133, 40.025302530253029],
            [45.613461346134628, 39.986298629862986],
            [45.795654565456559, 39.934293429342929],
            [45.830358035803584, 39.836783678367837],
            [46.003875387538756, 39.771777177717766],
            [46.073282328232835, 39.687268726872688],
            [46.160041004100421, 39.648264826482645],
            [46.186068606860701, 39.589758975897588],
            [46.359585958595872, 39.641764176417638],
            [46.428992899289938, 39.622262226222617],
            [46.437668766876698, 39.576757675767574],
            [46.524427442744283, 39.589758975897588],
            [46.576482648264829, 39.563756375637567],
            [46.533103310331043, 39.46624662466246],
            [46.402965296529658, 39.453245324532453],
            [46.402965296529658, 39.414241424142418],
            [46.472372237223723, 39.336233623362332],
            [46.498399839984003, 39.349234923492347],
            [46.628537853785389, 39.225722572257226],
            [46.541779177917803, 39.167216721672162],
            [46.524427442744283, 39.212721272127212],
            [46.437668766876698, 39.186718671867183],
            [46.481048104810483, 39.147714771477148],
            [46.550455045504563, 39.050205020502048],
            [46.515751575157523, 38.959195919591963],
            [46.550455045504563, 38.868186818681863],
            [46.385613561356138, 38.900690069006899],
            [46.307530753075312, 38.894189418941892],
            [46.177392739273941, 38.822682268226821],
            [46.142689268926901, 38.835683568356835],
            [46.160041004100421, 38.887688768876885],
            [45.98652365236525, 39.206220622062204],
            [45.99519951995201, 39.29072907290729],
            [45.795654565456559, 39.368736873687368],
            [45.839033903390344, 39.485748574857482],
            [45.795654565456559, 39.563756375637567],
            [45.674192419241933, 39.583258325832581],
            [45.465971597159722, 39.498749874987496],
            [45.188343834383446, 39.622262226222617],
            [45.188343834383446, 39.680768076807681],
            [45.11893689368938, 39.771777177717766],
            [45.07555755575558, 39.791279127912787],
            [45.006150615061514, 39.752275227522752],
            [44.962771277127715, 39.73277327732773],
            [44.763226322632278, 39.713271327132716],
            [44.581033103310332, 39.797779777977794],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EDWWFIR", name: "BREMEN FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.50265026502651, 55.002800280027998],
            [6.658815881588161, 55.002800280027998],
            [7.248774877487755, 55.002800280027998],
            [7.413616361636166, 55.002800280027998],
            [7.621837183718377, 55.002800280027998],
            [7.639188918891897, 55.002800280027998],
            [7.699919991999202, 55.002800280027998],
            [7.708595859585962, 55.002800280027998],
            [7.717271727172722, 55.002800280027998],
            [7.830058005800588, 55.002800280027998],
            [7.873437343734381, 55.002800280027998],
            [7.951520152015206, 55.002800280027998],
            [8.003575357535759, 55.002800280027998],
            [8.072982298229824, 55.015801580158012],
            [8.107685768576864, 55.022302230223019],
            [8.229147914791483, 55.048304830483048],
            [8.333258325832588, 55.067806780678069],
            [8.393989398939901, 55.067806780678069],
            [8.420017001700174, 55.061306130613062],
            [8.446044604460454, 55.074307430743076],
            [8.454720472047207, 55.067806780678069],
            [8.515451545154519, 55.022302230223019],
            [8.662941294129418, 54.91829182918292],
            [8.784403440344036, 54.898789878987898],
            [8.853810381038109, 54.898789878987898],
            [9.001300130013007, 54.892289228922891],
            [9.01865186518652, 54.898789878987898],
            [9.192169216921698, 54.859785978597856],
            [9.226872687268731, 54.859785978597856],
            [9.235548554855491, 54.833783378337834],
            [9.287603760376044, 54.840284028402841],
            [9.322307230723077, 54.807780778077806],
            [9.513176317631768, 54.846784678467849],
            [9.738748874887492, 54.82078207820782],
            [9.747424742474252, 54.827282728272827],
            [9.851535153515357, 54.833783378337834],
            [9.88623862386239, 54.840284028402841],
            [9.89491449144915, 54.840284028402841],
            [9.964321432143215, 54.807780778077806],
            [9.999024902490255, 54.788278827882785],
            [10.051080108010808, 54.762276227622763],
            [10.146514651465154, 54.749274927492749],
            [10.163866386638666, 54.742774277427742],
            [10.198569856985706, 54.736273627362735],
            [10.337383738373845, 54.697269726972692],
            [10.502225222522256, 54.658265826582657],
            [10.580308030803081, 54.658265826582657],
            [10.667066706670674, 54.658265826582657],
            [10.684418441844187, 54.658265826582657],
            [10.831908190819085, 54.65176517651765],
            [10.996749674967504, 54.645264526452642],
            [11.083508350835089, 54.625762576257621],
            [11.170267026702675, 54.5997599759976],
            [11.204970497049707, 54.593259325932593],
            [11.27437743774378, 54.573757375737571],
            [11.317756775677573, 54.560756075607557],
            [11.335108510851093, 54.55425542554255],
            [11.404515451545159, 54.534753475347536],
            [11.499949994999504, 54.5022502250225],
            [11.647439743974402, 54.469746974697465],
            [11.664791479147922, 54.463246324632465],
            [11.690819081908195, 54.463246324632465],
            [11.803605360536061, 54.450245024502451],
            [11.829632963296334, 54.443744374437443],
            [11.838308830883093, 54.443744374437443],
            [12.003150315031505, 54.450245024502451],
            [12.063881388138817, 54.489248924892486],
            [12.289453945394541, 54.606260626062607],
            [12.515026502650272, 54.736273627362735],
            [12.601785178517858, 54.781778177817777],
            [12.783978397839789, 54.879287928792877],
            [12.853385338533862, 54.91829182918292],
            [13.000875087508753, 54.91829182918292],
            [13.382613261326135, 54.91829182918292],
            [13.564806480648073, 54.91829182918292],
            [13.660241024102412, 54.91829182918292],
            [13.746999699970004, 54.91829182918292],
            [13.764351435143517, 54.91829182918292],
            [14.163441344134419, 54.91829182918292],
            [14.34563456345635, 54.91829182918292],
            [14.35431043104311, 54.91829182918292],
            [14.33695869586959, 54.762276227622763],
            [14.328282828282831, 54.664766476647664],
            [14.284903490349038, 54.33323332333233],
            [14.258875887588765, 54.164216421642159],
            [14.224172417241732, 54.053705370537052],
            [14.224172417241732, 53.930193019301932],
            [14.224172417241732, 53.852185218521853],
            [14.276227622762278, 53.761176117611761],
            [14.293579357935798, 53.735173517351733],
            [14.276227622762278, 53.683168316831683],
            [14.310931093109318, 53.64416441644164],
            [14.328282828282831, 53.507650765076505],
            [14.389013901390143, 53.377637763776377],
            [14.406365636563663, 53.358135813581356],
            [14.415041504150423, 53.332133213321328],
            [14.406365636563663, 53.273627362736271],
            [14.380338033803383, 53.176117611761171],
            [14.33695869586959, 53.169616961696171],
            [14.154765476547659, 52.903090309030901],
            [14.241524152415245, 52.812081208120809],
            [14.371662166216623, 52.727572757275723],
            [14.527827782778282, 52.630063006300631],
            [14.605910591059114, 52.604060406040603],
            [14.640614061406147, 52.539053905390539],
            [14.536503650365042, 52.402540254025403],
            [14.579882988298834, 52.29202920292029],
            [14.65796579657966, 52.207520752075204],
            [14.67531753175318, 52.136013601360133],
            [14.753400340034005, 52.058005800580055],
            [14.67531753175318, 51.901990199019899],
            [14.631938193819387, 51.830483048304828],
            [14.614586458645867, 51.797979797979799],
            [14.66664166416642, 51.75247524752475],
            [14.718696869686973, 51.7004700470047],
            [13.746999699970004, 51.505450545054501],
            [13.339233923392342, 51.505450545054501],
            [13.252475247524757, 51.505450545054501],
            [13.122337233723378, 51.505450545054501],
            [13.096309630963098, 51.505450545054501],
            [12.636488648864891, 51.622462246224622],
            [12.202695269526956, 51.875987598759878],
            [12.003150315031505, 51.908490849084906],
            [11.204970497049707, 51.843484348434842],
            [10.701770177017707, 51.563956395639565],
            [10.389438943894397, 51.336433643364337],
            [10.059755975597561, 51.336433643364337],
            [9.938293829382943, 51.336433643364337],
            [9.58258325832584, 51.336433643364337],
            [9.521852185218528, 51.336433643364337],
            [9.166141614161418, 51.336433643364337],
            [8.905865586558662, 51.336433643364337],
            [8.767051705170523, 51.336433643364337],
            [8.879837983798382, 51.498949894989501],
            [8.923217321732174, 51.550955095509551],
            [8.940569056905694, 51.583458345834579],
            [8.957920792079214, 51.609460946094607],
            [9.001300130013007, 51.667966796679664],
            [9.001300130013007, 51.75247524752475],
            [9.001300130013007, 52.136013601360133],
            [8.741024102410243, 52.136013601360133],
            [8.584858485848592, 52.129512951295126],
            [8.003575357535759, 52.116511651165112],
            [8.003575357535759, 52.233523352335233],
            [8.003575357535759, 52.285528552855283],
            [7.856085608560861, 52.279027902790276],
            [7.266126612661267, 52.246524652465247],
            [7.057905790579063, 52.24002400240024],
            [7.066581658165823, 52.532553255325531],
            [7.057905790579063, 52.656065606560652],
            [7.083933393339336, 52.799079907990794],
            [7.196719671967202, 53.000600060006001],
            [7.196719671967202, 53.072107210721072],
            [7.196719671967202, 53.085108510851086],
            [7.205395539553962, 53.111111111111107],
            [7.214071407140722, 53.215121512151214],
            [7.196719671967202, 53.280128012801278],
            [7.188043804380442, 53.299629962996299],
            [7.066581658165823, 53.306130613061306],
            [6.997174717471751, 53.338633863386335],
            [6.919091909190925, 53.345134513451342],
            [6.953795379537958, 53.397139713971399],
            [6.910416041604165, 53.442644264426441],
            [6.867036703670372, 53.475147514751477],
            [6.81498149814982, 53.494649464946491],
            [6.745574557455747, 53.501150115011498],
            [6.641464146414648, 53.540154015401541],
            [6.50265026502651, 53.663666366636662],
            [6.50265026502651, 53.709170917091704],
            [6.50265026502651, 53.735173517351733],
            [6.50265026502651, 53.832683268326832],
            [6.50265026502651, 53.884688468846882],
            [6.50265026502651, 53.923692369236925],
            [6.50265026502651, 54.001700170017003],
            [6.50265026502651, 54.18371837183718],
            [6.50265026502651, 54.248724872487244],
            [6.50265026502651, 54.5022502250225],
            [6.50265026502651, 54.664766476647664],
            [6.50265026502651, 54.957295729572955],
            [6.50265026502651, 55.002800280027998],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EHAAFIR", name: "AMSTERDAM FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.999874987498757, 51.498949894989501],
            [2.051930193019302, 51.570457045704572],
            [2.103985398539855, 51.635463546354636],
            [2.156040604060408, 51.693969396939693],
            [2.164716471647168, 51.706970697069707],
            [2.173392339233928, 51.713471347134714],
            [2.225447544754481, 51.784978497849785],
            [2.294854485448546, 51.86948694869487],
            [2.355585558555859, 51.947494749474949],
            [2.477047704770484, 52.103510351035105],
            [2.485723572357237, 52.116511651165112],
            [2.494399439943997, 52.123012301230119],
            [2.581158115811583, 52.233523352335233],
            [2.598509850985103, 52.253025302530254],
            [2.641889188918896, 52.305030503050304],
            [2.650565056505656, 52.318031803180318],
            [2.693944394439448, 52.363536353635361],
            [2.780703070307034, 52.474047404740475],
            [2.832758275827587, 52.532553255325531],
            [2.902165216521659, 52.617061706170617],
            [2.997599759976005, 52.73407340734073],
            [3.067006700670071, 52.825082508250823],
            [3.145089508950896, 52.916091609160915],
            [3.162441244124416, 52.929092909290929],
            [3.257875787578762, 53.046104610461043],
            [3.301255125512554, 53.0981098109811],
            [3.361986198619867, 53.176117611761171],
            [3.431393139313933, 53.247624762476249],
            [3.561531153115318, 53.403640364036399],
            [3.613586358635871, 53.462146214621463],
            [3.648289828982904, 53.501150115011498],
            [3.709020902090217, 53.572657265726569],
            [3.9432693269327, 53.839183918391839],
            [3.969296929692973, 53.865186518651861],
            [4.082083208320839, 54.001700170017003],
            [4.177517751775184, 54.105710571057102],
            [4.533228322832286, 54.5022502250225],
            [5.001725172517254, 55.002800280027998],
            [5.279352935293531, 55.002800280027998],
            [5.565656565656568, 55.002800280027998],
            [5.851960196019604, 55.002800280027998],
            [5.860636063606364, 55.002800280027998],
            [5.869311931193124, 55.002800280027998],
            [5.930043004300437, 55.002800280027998],
            [5.98209820982099, 55.002800280027998],
            [6.042829282928295, 55.002800280027998],
            [6.146939693969401, 55.002800280027998],
            [6.346484648464852, 55.002800280027998],
            [6.424567456745677, 55.002800280027998],
            [6.50265026502651, 55.002800280027998],
            [6.50265026502651, 54.957295729572955],
            [6.50265026502651, 54.664766476647664],
            [6.50265026502651, 54.5022502250225],
            [6.50265026502651, 54.248724872487244],
            [6.50265026502651, 54.18371837183718],
            [6.50265026502651, 54.001700170017003],
            [6.50265026502651, 53.923692369236925],
            [6.50265026502651, 53.884688468846882],
            [6.50265026502651, 53.832683268326832],
            [6.50265026502651, 53.735173517351733],
            [6.50265026502651, 53.709170917091704],
            [6.50265026502651, 53.663666366636662],
            [6.641464146414648, 53.540154015401541],
            [6.745574557455747, 53.501150115011498],
            [6.81498149814982, 53.494649464946491],
            [6.867036703670372, 53.475147514751477],
            [6.910416041604165, 53.442644264426441],
            [6.953795379537958, 53.397139713971399],
            [6.919091909190925, 53.345134513451342],
            [6.997174717471751, 53.338633863386335],
            [7.066581658165823, 53.306130613061306],
            [7.188043804380442, 53.299629962996299],
            [7.196719671967202, 53.280128012801278],
            [7.214071407140722, 53.215121512151214],
            [7.205395539553962, 53.111111111111107],
            [7.196719671967202, 53.085108510851086],
            [7.196719671967202, 53.072107210721072],
            [7.196719671967202, 53.000600060006001],
            [7.083933393339336, 52.799079907990794],
            [7.057905790579063, 52.656065606560652],
            [7.066581658165823, 52.532553255325531],
            [7.057905790579063, 52.24002400240024],
            [6.841009100910092, 52.155515551555155],
            [6.884388438843885, 52.142514251425141],
            [6.875712571257132, 52.136013601360133],
            [6.81498149814982, 52.116511651165112],
            [6.762926292629267, 52.025502550255027],
            [6.788953895389547, 51.934493449344934],
            [6.780278027802787, 51.92149214921492],
            [6.719546954695474, 51.914991499149913],
            [6.658815881588161, 51.908490849084906],
            [6.615436543654369, 51.901990199019899],
            [6.589408940894096, 51.895489548954892],
            [6.50265026502651, 51.86948694869487],
            [6.433243324332437, 51.862986298629863],
            [6.372512251225125, 51.86948694869487],
            [6.407215721572165, 51.830483048304828],
            [6.389863986398645, 51.836983698369835],
            [6.146939693969401, 51.901990199019899],
            [6.103560356035608, 51.882488248824878],
            [6.060181018101815, 51.856485648564856],
            [6.025477547754782, 51.856485648564856],
            [5.94739473947395, 51.830483048304828],
            [6.025477547754782, 51.7004700470047],
            [6.034153415341535, 51.674467446744671],
            [6.103560356035608, 51.628962896289629],
            [6.129587958795881, 51.583458345834579],
            [6.198994899489954, 51.524952495249522],
            [6.225022502250226, 51.466446644664465],
            [6.225022502250226, 51.407940794079408],
            [6.216346634663473, 51.401440144014401],
            [6.216346634663473, 51.362436243624359],
            [6.129587958795881, 51.277927792779273],
            [6.120912091209128, 51.277927792779273],
            [6.077532753275335, 51.245424542454245],
            [6.086208620862088, 51.173917391739174],
            [6.129587958795881, 51.186918691869188],
            [6.103560356035608, 51.141414141414138],
            [6.060181018101815, 51.134913491349131],
            [6.008125812581262, 51.082908290829081],
            [5.96474647464747, 51.043904390439046],
            [5.96474647464747, 50.998399839983996],
            [5.990774077407742, 50.985398539853982],
            [6.025477547754782, 50.985398539853982],
            [6.016801680168022, 50.939893989398939],
            [6.034153415341535, 50.933393339333932],
            [6.060181018101815, 50.920392039203918],
            [6.060181018101815, 50.907390739073904],
            [6.068856885688575, 50.881388138813882],
            [6.077532753275335, 50.861886188618861],
            [6.016801680168022, 50.829382938293826],
            [6.025477547754782, 50.822882288228818],
            [6.025477547754782, 50.816381638163811],
            [6.025477547754782, 50.757875787578755],
            [5.999449944994502, 50.751375137513747],
            [5.930043004300437, 50.757875787578755],
            [5.912691269126917, 50.74487448744874],
            [5.860636063606364, 50.764376437643762],
            [5.843284328432844, 50.764376437643762],
            [5.695794579457953, 50.764376437643762],
            [5.652415241524153, 50.822882288228818],
            [5.652415241524153, 50.835883588358833],
            [5.6437393739374, 50.84238423842384],
            [5.678442844284433, 50.88788878887889],
            [5.739173917391746, 50.933393339333932],
            [5.747849784978499, 50.952895289528954],
            [5.765201520152019, 50.998399839983996],
            [5.791229122912299, 51.06340634063406],
            [5.791229122912299, 51.134913491349131],
            [5.773877387738779, 51.180418041804181],
            [5.721822182218226, 51.193419341934195],
            [5.600360036003607, 51.212921292129209],
            [5.556980698069815, 51.258425842584259],
            [5.548304830483055, 51.271427142714273],
            [5.496249624962502, 51.290929092909288],
            [5.426842684268429, 51.264926492649266],
            [5.418166816681669, 51.264926492649266],
            [5.383463346334636, 51.271427142714273],
            [5.340084008400844, 51.264926492649266],
            [5.279352935293531, 51.264926492649266],
            [5.235973597359738, 51.310431043104309],
            [5.131863186318633, 51.336433643364337],
            [5.10583558355836, 51.414441444144416],
            [5.0971597159716, 51.420942094209423],
            [5.08848384838484, 51.459945994599458],
            [5.062456245624567, 51.492449244924494],
            [5.019076907690774, 51.472947294729472],
            [4.993049304930494, 51.472947294729472],
            [4.967021702170221, 51.440444044404437],
            [4.906290629062909, 51.414441444144416],
            [4.836883688368843, 51.414441444144416],
            [4.810856085608563, 51.498949894989501],
            [4.802180218021803, 51.505450545054501],
            [4.680718071807185, 51.427442744274423],
            [4.637338733873392, 51.427442744274423],
            [4.576607660766079, 51.43394339433943],
            [4.550580058005806, 51.427442744274423],
            [4.533228322832286, 51.427442744274423],
            [4.541904190419046, 51.459945994599458],
            [4.515876587658767, 51.466446644664465],
            [4.507200720072014, 51.466446644664465],
            [4.385738573857388, 51.446944694469444],
            [4.368386838683875, 51.407940794079408],
            [4.333683368336835, 51.388438843884387],
            [4.342359235923595, 51.375437543754373],
            [4.351035103510355, 51.368936893689366],
            [4.333683368336835, 51.368936893689366],
            [4.281628162816283, 51.368936893689366],
            [4.23824882488249, 51.355935593559352],
            [4.220897089708977, 51.336433643364337],
            [4.203545354535457, 51.323432343234323],
            [4.151490149014904, 51.290929092909288],
            [4.108110811081112, 51.271427142714273],
            [4.021352135213526, 51.238923892389238],
            [3.873862386238628, 51.232423242324231],
            [3.656965696569664, 51.238923892389238],
            [3.622262226222624, 51.28442844284428],
            [3.587558755875591, 51.290929092909288],
            [3.561531153115318, 51.290929092909288],
            [3.518151815181525, 51.264926492649266],
            [3.466096609660973, 51.238923892389238],
            [3.40536553655366, 51.271427142714273],
            [3.37933793379338, 51.28442844284428],
            [3.370662066206627, 51.368936893689366],
            [3.006275627562758, 51.407940794079408],
            [2.503075307530757, 51.453445344534451],
            [2.320882088208826, 51.472947294729472],
            [2.277502750275033, 51.472947294729472],
            [2.086633663366342, 51.492449244924494],
            [1.999874987498757, 51.498949894989501],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LMMMFIR", name: "MALTA FIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.499949994999504, 36.501950195019504],
            [11.62141214121413, 36.501950195019504],
            [11.820957095709574, 36.501950195019504],
            [12.12461246124613, 36.501950195019504],
            [12.584433443344338, 36.501950195019504],
            [12.844709470947102, 36.501950195019504],
            [13.209095909590964, 36.501950195019504],
            [13.538778877887793, 36.501950195019504],
            [13.790379037903797, 36.501950195019504],
            [13.972572257225728, 36.501950195019504],
            [14.102710271027107, 36.501950195019504],
            [14.250200020002005, 36.501950195019504],
            [14.310931093109318, 36.501950195019504],
            [14.458420842084216, 36.501950195019504],
            [14.467096709670969, 36.501950195019504],
            [14.571207120712074, 36.501950195019504],
            [14.831483148314838, 36.501950195019504],
            [14.996324632463249, 36.501950195019504],
            [15.620987098709875, 36.501950195019504],
            [16.002725272527258, 36.501950195019504],
            [16.453870387038712, 36.501950195019504],
            [16.852960296029607, 36.501950195019504],
            [17.425567556755681, 36.501950195019504],
            [17.77260226022603, 36.501950195019504],
            [18.336533653365343, 36.501950195019504],
            [18.995899589959002, 36.501950195019504],
            [21.15619061906191, 35.520352035203516],
            [21.963046304630467, 35.130313031303132],
            [23.004150415041508, 34.62326232623262],
            [23.585433543354341, 34.330733073307329],
            [21.468521852185226, 34.330733073307329],
            [20.479472947294738, 34.330733073307329],
            [19.039278927892795, 34.330733073307329],
            [18.128312831283132, 34.330733073307329],
            [17.087208720872091, 34.330733073307329],
            [16.948394839483953, 34.330733073307329],
            [16.36711171117112, 34.330733073307329],
            [15.169841984198428, 34.330733073307329],
            [14.70134513451346, 34.330733073307329],
            [13.729647964796484, 34.330733073307329],
            [13.313206320632069, 34.330733073307329],
            [13.243799379937997, 34.330733073307329],
            [12.870737073707375, 34.330733073307329],
            [12.636488648864891, 34.330733073307329],
            [11.499949994999504, 34.330733073307329],
            [11.499949994999504, 34.525752575257528],
            [11.499949994999504, 35.045804580458046],
            [11.499949994999504, 35.656865686568658],
            [11.499949994999504, 35.825882588258821],
            [11.499949994999504, 36.501950195019504],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EKDKFIR", name: "COPENHAGEN FIR", fab: "4" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.50265026502651, 55.002800280027998],
            [6.424567456745677, 55.002800280027998],
            [6.346484648464852, 55.002800280027998],
            [6.146939693969401, 55.002800280027998],
            [6.042829282928295, 55.002800280027998],
            [5.98209820982099, 55.002800280027998],
            [5.930043004300437, 55.002800280027998],
            [5.869311931193124, 55.002800280027998],
            [5.860636063606364, 55.002800280027998],
            [5.851960196019604, 55.002800280027998],
            [5.565656565656568, 55.002800280027998],
            [5.279352935293531, 55.002800280027998],
            [5.001725172517254, 55.002800280027998],
            [5.001725172517254, 55.080808080808076],
            [5.001725172517254, 55.126312631263126],
            [5.001725172517254, 55.178317831783176],
            [5.001725172517254, 55.243324332433239],
            [5.001725172517254, 55.308330833083303],
            [5.001725172517254, 55.340834083408339],
            [5.001725172517254, 55.386338633863382],
            [5.001725172517254, 55.490349034903488],
            [5.001725172517254, 55.581358135813581],
            [5.001725172517254, 55.607360736073609],
            [5.001725172517254, 55.795879587958794],
            [5.001725172517254, 55.938893889388936],
            [5.001725172517254, 55.95189518951895],
            [5.001725172517254, 56.055905590559057],
            [5.001725172517254, 56.250925092509249],
            [5.001725172517254, 56.419941994199419],
            [5.001725172517254, 56.536953695369533],
            [5.001725172517254, 56.582458245824583],
            [5.001725172517254, 56.998499849984995],
            [5.175242524252432, 56.998499849984995],
            [5.504925492549262, 56.998499849984995],
            [5.583008300830087, 56.998499849984995],
            [5.687118711871193, 56.998499849984995],
            [5.773877387738779, 56.998499849984995],
            [5.96474647464747, 56.998499849984995],
            [5.999449944994502, 56.998499849984995],
            [6.45927092709271, 56.998499849984995],
            [6.693519351935201, 56.998499849984995],
            [6.728222822282234, 56.998499849984995],
            [6.80630563056306, 56.998499849984995],
            [6.83233323332334, 56.998499849984995],
            [6.936443644364438, 56.998499849984995],
            [7.231423142314235, 56.998499849984995],
            [7.3442094209421, 56.998499849984995],
            [7.456995699569958, 56.998499849984995],
            [7.500375037503751, 56.998499849984995],
            [7.604485448544857, 57.057005700570052],
            [7.899464946494653, 57.213021302130208],
            [8.046954695469552, 57.291029102910286],
            [8.576182618261832, 57.557555755575557],
            [8.662941294129418, 57.603060306030599],
            [8.749699969997003, 57.648564856485649],
            [8.793079307930796, 57.668066806680663],
            [8.801755175517556, 57.67456745674567],
            [9.05335533553356, 57.798079807980798],
            [9.192169216921698, 57.869586958695869],
            [9.226872687268731, 57.889088908890891],
            [9.435093509350942, 57.99309930993099],
            [9.452445244524455, 57.999599959995997],
            [9.495824582458248, 58.019101910191019],
            [9.521852185218528, 58.032103210321033],
            [9.591259125912593, 58.064606460646061],
            [9.617286728672873, 58.077607760776075],
            [9.782128212821284, 58.162116211621161],
            [9.868886888688877, 58.201120112011196],
            [10.007700770077015, 58.266126612661267],
            [10.129162916291634, 58.324632463246324],
            [10.146514651465154, 58.337633763376338],
            [10.215921592159219, 58.36363636363636],
            [10.502225222522256, 58.500150015001495],
            [10.562956295629569, 58.422142214221424],
            [10.892639263926398, 58.051605160516047],
            [10.927342734273431, 57.999599959995997],
            [10.936018601860191, 57.99309930993099],
            [10.962046204620464, 57.967096709670962],
            [11.144239423942402, 57.752575257525748],
            [11.144239423942402, 57.746074607460741],
            [11.222322232223227, 57.655065506550656],
            [11.343784378437846, 57.512051205120507],
            [11.404515451545159, 57.427542754275429],
            [11.482598259825984, 57.336533653365336],
            [11.499949994999504, 57.317031703170315],
            [11.569356935693577, 57.252025202520251],
            [11.647439743974402, 57.13501350135013],
            [11.768901890189021, 56.985498549854981],
            [12.072557255725577, 56.601960196019597],
            [12.12461246124613, 56.530453045304526],
            [12.228722872287236, 56.393939393939391],
            [12.237398739873996, 56.387438743874384],
            [12.272102210221028, 56.341934193419341],
            [12.332833283328341, 56.257425742574256],
            [12.367536753675374, 56.211921192119213],
            [12.43694369436944, 56.166416641664163],
            [12.480323032303232, 56.146914691469142],
            [12.497674767476752, 56.133913391339135],
            [12.567081708170825, 56.1014101410141],
            [12.653840384038411, 56.055905590559057],
            [12.653840384038411, 56.029902990299028],
            [12.653840384038411, 55.971397139713972],
            [12.653840384038411, 55.919391939193915],
            [12.671192119211923, 55.8998899889989],
            [12.697219721972203, 55.834883488348837],
            [12.810006000600062, 55.73087308730873],
            [12.896764676467654, 55.646364636463645],
            [12.879412941294134, 55.613861386138609],
            [12.775302530253029, 55.568356835683566],
            [12.723247324732476, 55.542354235423538],
            [12.714571457145716, 55.490349034903488],
            [12.705895589558963, 55.483848384838481],
            [12.662516251625171, 55.457845784578453],
            [12.610461046104618, 55.431843184318431],
            [12.627812781278131, 55.373337333733375],
            [12.645164516451651, 55.334333433343332],
            [12.679867986798683, 55.256325632563254],
            [12.688543854385443, 55.236823682368232],
            [12.731923192319236, 55.152315231523147],
            [12.757950795079516, 55.09380938093809],
            [12.783978397839789, 55.04180418041804],
            [12.801330133013309, 55.015801580158012],
            [12.853385338533862, 54.91829182918292],
            [12.783978397839789, 54.879287928792877],
            [12.601785178517858, 54.781778177817777],
            [12.515026502650272, 54.736273627362735],
            [12.289453945394541, 54.606260626062607],
            [12.063881388138817, 54.489248924892486],
            [12.003150315031505, 54.450245024502451],
            [11.838308830883093, 54.443744374437443],
            [11.829632963296334, 54.443744374437443],
            [11.803605360536061, 54.450245024502451],
            [11.690819081908195, 54.463246324632465],
            [11.664791479147922, 54.463246324632465],
            [11.647439743974402, 54.469746974697465],
            [11.499949994999504, 54.5022502250225],
            [11.404515451545159, 54.534753475347536],
            [11.335108510851093, 54.55425542554255],
            [11.317756775677573, 54.560756075607557],
            [11.27437743774378, 54.573757375737571],
            [11.204970497049707, 54.593259325932593],
            [11.170267026702675, 54.5997599759976],
            [11.083508350835089, 54.625762576257621],
            [10.996749674967504, 54.645264526452642],
            [10.831908190819085, 54.65176517651765],
            [10.684418441844187, 54.658265826582657],
            [10.667066706670674, 54.658265826582657],
            [10.580308030803081, 54.658265826582657],
            [10.502225222522256, 54.658265826582657],
            [10.337383738373845, 54.697269726972692],
            [10.198569856985706, 54.736273627362735],
            [10.163866386638666, 54.742774277427742],
            [10.146514651465154, 54.749274927492749],
            [10.051080108010808, 54.762276227622763],
            [9.999024902490255, 54.788278827882785],
            [9.964321432143215, 54.807780778077806],
            [9.89491449144915, 54.840284028402841],
            [9.88623862386239, 54.840284028402841],
            [9.851535153515357, 54.833783378337834],
            [9.747424742474252, 54.827282728272827],
            [9.738748874887492, 54.82078207820782],
            [9.513176317631768, 54.846784678467849],
            [9.322307230723077, 54.807780778077806],
            [9.287603760376044, 54.840284028402841],
            [9.235548554855491, 54.833783378337834],
            [9.226872687268731, 54.859785978597856],
            [9.192169216921698, 54.859785978597856],
            [9.01865186518652, 54.898789878987898],
            [9.001300130013007, 54.892289228922891],
            [8.853810381038109, 54.898789878987898],
            [8.784403440344036, 54.898789878987898],
            [8.662941294129418, 54.91829182918292],
            [8.515451545154519, 55.022302230223019],
            [8.454720472047207, 55.067806780678069],
            [8.446044604460454, 55.074307430743076],
            [8.420017001700174, 55.061306130613062],
            [8.393989398939901, 55.067806780678069],
            [8.333258325832588, 55.067806780678069],
            [8.229147914791483, 55.048304830483048],
            [8.107685768576864, 55.022302230223019],
            [8.072982298229824, 55.015801580158012],
            [8.003575357535759, 55.002800280027998],
            [7.951520152015206, 55.002800280027998],
            [7.873437343734381, 55.002800280027998],
            [7.830058005800588, 55.002800280027998],
            [7.717271727172722, 55.002800280027998],
            [7.708595859585962, 55.002800280027998],
            [7.699919991999202, 55.002800280027998],
            [7.639188918891897, 55.002800280027998],
            [7.621837183718377, 55.002800280027998],
            [7.413616361636166, 55.002800280027998],
            [7.248774877487755, 55.002800280027998],
            [6.658815881588161, 55.002800280027998],
            [6.50265026502651, 55.002800280027998],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LIRRUIR", name: "ROMA UIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.995899589959002, 38.881188118811878],
            [18.995899589959002, 38.725172517251721],
            [18.995899589959002, 38.517151715171515],
            [18.995899589959002, 38.341634163416344],
            [18.995899589959002, 37.750075007500747],
            [18.995899589959002, 37.399039903990399],
            [18.995899589959002, 36.963496349634966],
            [18.995899589959002, 36.768476847684767],
            [18.995899589959002, 36.501950195019504],
            [18.336533653365343, 36.501950195019504],
            [17.77260226022603, 36.501950195019504],
            [17.425567556755681, 36.501950195019504],
            [16.852960296029607, 36.501950195019504],
            [16.453870387038712, 36.501950195019504],
            [16.002725272527258, 36.501950195019504],
            [15.620987098709875, 36.501950195019504],
            [14.996324632463249, 36.501950195019504],
            [14.831483148314838, 36.501950195019504],
            [14.571207120712074, 36.501950195019504],
            [14.467096709670969, 36.501950195019504],
            [14.458420842084216, 36.501950195019504],
            [14.310931093109318, 36.501950195019504],
            [14.250200020002005, 36.501950195019504],
            [14.102710271027107, 36.501950195019504],
            [13.972572257225728, 36.501950195019504],
            [13.790379037903797, 36.501950195019504],
            [13.538778877887793, 36.501950195019504],
            [13.209095909590964, 36.501950195019504],
            [12.844709470947102, 36.501950195019504],
            [12.584433443344338, 36.501950195019504],
            [12.12461246124613, 36.501950195019504],
            [11.820957095709574, 36.501950195019504],
            [11.62141214121413, 36.501950195019504],
            [11.499949994999504, 36.501950195019504],
            [11.499949994999504, 36.605960596059603],
            [11.499949994999504, 36.618961896189617],
            [11.499949994999504, 36.839983998399838],
            [11.499949994999504, 37.191019101910186],
            [11.499949994999504, 37.503050305030499],
            [11.291729172917293, 37.594059405940598],
            [11.196294629462948, 37.639563956395634],
            [11.118211821182122, 37.672067206720669],
            [11.100860086008609, 37.685068506850683],
            [10.962046204620464, 37.743574357435747],
            [10.909990999099918, 37.769576957695769],
            [10.788528852885293, 37.821582158215818],
            [10.632363236323634, 37.88658865886589],
            [10.285328532853292, 38.036103610361039],
            [9.790804080408044, 38.257125712571252],
            [9.05335533553356, 38.569156915691565],
            [8.966596659665974, 38.6016601660166],
            [8.888513851385142, 38.634163416341636],
            [8.862486248624869, 38.64716471647165],
            [8.498099809980999, 38.796679667966799],
            [8.003575357535759, 38.998199819981998],
            [8.003575357535759, 39.147714771477148],
            [8.003575357535759, 39.212721272127212],
            [8.003575357535759, 39.700270027002702],
            [8.003575357535759, 39.999299929993001],
            [8.003575357535759, 40.350335033503349],
            [8.003575357535759, 40.42184218421842],
            [8.003575357535759, 40.610361036103612],
            [8.003575357535759, 40.746874687468747],
            [8.003575357535759, 40.863886388638861],
            [8.003575357535759, 40.915891589158917],
            [8.003575357535759, 41.000400040003996],
            [8.159740974097417, 41.162916291629159],
            [8.281203120312036, 41.286428642864287],
            [8.333258325832588, 41.33193319331933],
            [8.446044604460454, 41.33193319331933],
            [8.567506750675072, 41.33193319331933],
            [8.688968896889691, 41.33193319331933],
            [8.810431043104316, 41.33193319331933],
            [9.03600360036004, 41.33193319331933],
            [9.105410541054113, 41.33193319331933],
            [9.270252025202524, 41.33193319331933],
            [9.313631363136317, 41.33193319331933],
            [9.495824582458248, 41.33193319331933],
            [9.617286728672873, 41.33193319331933],
            [9.747424742474252, 41.33193319331933],
            [9.747424742474252, 41.663466346634664],
            [9.747424742474252, 41.734973497349735],
            [9.747424742474252, 41.747974797479742],
            [9.747424742474252, 41.793479347934792],
            [9.747424742474252, 41.936493649364934],
            [9.747424742474252, 42.326532653265325],
            [9.747424742474252, 42.41104110411041],
            [9.747424742474252, 42.502050205020502],
            [9.747424742474252, 42.619061906190616],
            [9.747424742474252, 42.820582058205815],
            [9.747424742474252, 42.957095709570957],
            [9.747424742474252, 43.165116511651163],
            [10.059755975597561, 43.405640564056405],
            [10.302680268026805, 43.587658765876583],
            [10.519576957695776, 43.620162016201618],
            [10.675742574257427, 43.63966396639664],
            [10.710446044604467, 43.646164616461647],
            [10.771177117711773, 43.659165916591661],
            [11.170267026702675, 43.717671767176718],
            [11.508625862586264, 43.691669166916689],
            [11.560681068106817, 43.685168516851682],
            [11.638763876387642, 43.678667866786682],
            [11.925067506750679, 43.652665266526654],
            [13.044254425442546, 43.54215421542154],
            [13.330558055805582, 43.516151615161519],
            [13.295854585458549, 43.36663666366637],
            [13.087633763376346, 42.918091809180922],
            [13.122337233723378, 42.879087908790879],
            [13.434668466846688, 42.534553455345531],
            [13.443344334433448, 42.51505150515051],
            [13.530103010301033, 42.417541754175417],
            [13.599509950995106, 42.346034603460346],
            [13.599509950995106, 42.339533953395339],
            [13.738323832383244, 42.19001900190019],
            [13.755675567556757, 42.164016401640161],
            [14.068006800680074, 41.812981298129813],
            [14.380338033803383, 41.448944894489443],
            [14.545179517951802, 41.396939693969401],
            [14.805455545554558, 41.305930593059301],
            [14.883538353835391, 41.266926692669266],
            [15.117786778677875, 41.201920192019202],
            [15.169841984198428, 41.110911091109109],
            [15.230573057305733, 41.006900690069003],
            [15.421442144214424, 40.688368836883683],
            [15.473497349734977, 40.603860386038605],
            [15.620987098709875, 40.343834383438342],
            [15.742449244924501, 40.135813581358136],
            [15.941994199419945, 39.778277827782773],
            [16.03742874287429, 39.61576157615761],
            [16.254325432543261, 39.232223222322233],
            [16.419166916691672, 39.076207620762077],
            [17.269401940194022, 38.660166016601664],
            [17.382188218821888, 38.7056705670567],
            [17.512326232623266, 38.75117511751175],
            [18.995899589959002, 38.881188118811878],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UKLVFIR", name: "LVOV FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.620137013701374, 51.498949894989501],
            [24.687268726872688, 51.901990199019899],
            [24.964896489648964, 51.882488248824878],
            [25.372662266226627, 51.927992799279927],
            [25.884538453845394, 51.92149214921492],
            [26.118786878687871, 51.895489548954892],
            [26.396414641464148, 51.849984998499849],
            [26.79550455045505, 51.765476547654764],
            [27.69779477947796, 51.479447944794479],
            [27.758525852585265, 51.472947294729472],
            [27.749849984998505, 51.38193819381938],
            [27.749849984998505, 51.375437543754373],
            [27.437518751875189, 50.848884888488847],
            [27.350760076007603, 50.705870587058705],
            [27.255325532553258, 50.543354335433541],
            [27.211946194619472, 50.465346534653463],
            [27.047104710471046, 50.179317931793179],
            [27.489573957395748, 49.951795179517951],
            [27.853960396039611, 49.763276327632759],
            [27.845284528452851, 49.39273927392739],
            [27.845284528452851, 49.152215221522148],
            [27.836608660866091, 49.048204820482049],
            [27.845284528452851, 48.97019701970197],
            [27.836608660866091, 48.593159315931587],
            [27.836608660866091, 48.417641764176416],
            [27.827932793279331, 48.424142414241423],
            [27.723822382238225, 48.450145014501445],
            [27.307380738073817, 48.417641764176416],
            [26.81285628562857, 48.417641764176416],
            [26.708745874587464, 48.320132013201317],
            [26.621987198719879, 48.248624862486245],
            [26.465821582158227, 48.222622262226224],
            [25.96262126212622, 47.949594959495947],
            [25.780428042804289, 47.943094309430947],
            [25.719696969696969, 47.930093009300933],
            [25.650290029002903, 47.917091709170919],
            [25.164441444144416, 47.865086508650862],
            [25.051655165516564, 47.735073507350734],
            [24.886813681368139, 47.735073507350734],
            [24.583158315831582, 47.956095609560954],
            [24.21009600960096, 47.910591059105911],
            [23.342509250925097, 48.027602760276025],
            [23.099584958495853, 48.014601460146011],
            [22.86533653365337, 47.949594959495947],
            [22.830633063306337, 48.118611861186118],
            [22.683143314331438, 48.099109910991096],
            [22.596384638463853, 48.131613161316132],
            [22.318756875687576, 48.339633963396338],
            [22.231998199819991, 48.411141114111409],
            [22.162591259125918, 48.43064306430643],
            [22.145239523952398, 48.463146314631459],
            [22.153915391539158, 48.469646964696466],
            [22.162591259125918, 48.541154115411544],
            [22.249349934993504, 48.632163216321629],
            [22.258025802580264, 48.651665166516651],
            [22.353460346034609, 48.768676867686764],
            [22.370812081208129, 48.820682068206821],
            [22.448894889488955, 48.950695069506949],
            [22.570357035703573, 49.087208720872084],
            [22.769901990199024, 49.002700270026999],
            [22.830633063306337, 49.087208720872084],
            [22.735198519851991, 49.204220422042205],
            [22.735198519851991, 49.210721072107212],
            [22.657115711571166, 49.503250325032504],
            [22.657115711571166, 49.568256825682568],
            [22.943419341934202, 49.808780878087809],
            [23.056205620562061, 49.886788678867887],
            [23.134288428842893, 49.964796479647966],
            [23.212371237123719, 50.023302330233022],
            [23.481323132313236, 50.218321832183214],
            [23.828357835783585, 50.406840684068406],
            [24.062606260626069, 50.504350435043506],
            [24.053930393039309, 50.666866686668662],
            [24.097309730973109, 50.835883588358833],
            [23.646164616461654, 51.303930393039302],
            [23.620137013701374, 51.498949894989501],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EDMMFIR", name: "MUNCHEN FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.790379037903797, 48.827182718271828],
            [13.80773077307731, 48.606160616061601],
            [13.738323832383244, 48.52815281528153],
            [13.720972097209724, 48.508650865086508],
            [13.504075407540761, 48.586658665866587],
            [13.452020202020208, 48.476147614761473],
            [13.425992599259928, 48.398139813981402],
            [13.321882188218829, 48.352635263526352],
            [13.183068306830691, 48.294129412941288],
            [13.165716571657171, 48.294129412941288],
            [13.096309630963098, 48.26162616261626],
            [13.018226822682273, 48.242124212421245],
            [12.96617161716172, 48.203120312031203],
            [12.862061206120615, 48.183618361836182],
            [12.836033603360342, 48.16411641164116],
            [12.766626662666269, 48.125112511251125],
            [12.757950795079516, 48.079607960796082],
            [12.801330133013309, 48.047104710471046],
            [12.853385338533862, 47.735073507350734],
            [12.740599059905996, 47.709070907090705],
            [12.593109310931098, 47.689568956895684],
            [12.46297129712972, 47.67006700670067],
            [12.202695269526956, 47.637563756375641],
            [12.167991799179923, 47.631063106310634],
            [12.063881388138817, 47.61806180618062],
            [12.046529652965305, 47.611561156115613],
            [11.786253625362541, 47.585558555855584],
            [11.60406040604061, 47.579057905790577],
            [11.499949994999504, 47.488048804880485],
            [11.491274127412744, 47.488048804880485],
            [11.343784378437846, 47.35153515351535],
            [11.109535953595362, 47.35153515351535],
            [10.996749674967504, 47.416541654165414],
            [10.979397939793984, 47.423042304230421],
            [10.849259925992605, 47.501050105010499],
            [10.72779777977798, 47.520552055205521],
            [10.710446044604467, 47.540054005400535],
            [10.667066706670674, 47.553055305530549],
            [10.484873487348743, 47.566056605660563],
            [10.450170017001703, 47.585558555855584],
            [10.441494149414943, 47.527052705270521],
            [10.441494149414943, 47.475047504750478],
            [10.43281828182819, 47.397039703970393],
            [10.43281828182819, 47.384038403840378],
            [10.346059605960598, 47.312531253125314],
            [10.311356135613565, 47.306030603060307],
            [10.233273327332739, 47.280028002800279],
            [10.172542254225426, 47.273527352735272],
            [10.198569856985706, 47.332033203320336],
            [10.215921592159219, 47.384038403840378],
            [10.085783578357841, 47.449044904490449],
            [10.085783578357841, 47.507550755075506],
            [9.946969696969703, 47.520552055205521],
            [9.816831683168324, 47.546554655465542],
            [9.799479947994804, 47.57255725572557],
            [9.782128212821284, 47.592059205920592],
            [9.764776477647771, 47.579057905790577],
            [9.730073007300732, 47.533553355335528],
            [9.55655565556556, 47.527052705270521],
            [9.5478797879788, 47.546554655465542],
            [9.452445244524455, 47.585558555855584],
            [9.5478797879788, 47.832583258325826],
            [9.5478797879788, 47.884588458845883],
            [9.5478797879788, 47.89108910891089],
            [9.5478797879788, 47.956095609560954],
            [9.5478797879788, 47.975597559755975],
            [9.5478797879788, 48.034103410341032],
            [9.5478797879788, 48.16411641164116],
            [9.5478797879788, 48.294129412941288],
            [9.5478797879788, 48.365636563656366],
            [9.5478797879788, 48.391639163916395],
            [9.5478797879788, 48.443644364436437],
            [9.5478797879788, 48.469646964696466],
            [9.5478797879788, 48.515151515151516],
            [9.5478797879788, 48.52815281528153],
            [9.599934993499353, 48.593159315931587],
            [9.625962596259633, 48.619161916191615],
            [9.686693669366939, 48.6971697169717],
            [9.773452345234531, 48.807680768076807],
            [9.868886888688877, 48.84668466846685],
            [10.094459445944601, 48.950695069506949],
            [10.259300930093012, 49.015701570157013],
            [10.302680268026805, 49.035203520352034],
            [10.476197619761983, 49.113211321132113],
            [10.588983898389841, 49.178217821782177],
            [10.658390839083914, 49.230223022302226],
            [10.667066706670674, 49.230223022302226],
            [10.840584058405845, 49.353735373537354],
            [10.970722072207224, 49.444744474447447],
            [10.658390839083914, 49.997299729972994],
            [10.285328532853292, 50.224822482248221],
            [10.311356135613565, 50.25082508250825],
            [10.493549354935496, 50.465346534653463],
            [10.519576957695776, 50.497849784978499],
            [10.562956295629569, 50.536853685368534],
            [10.701770177017707, 50.660366036603662],
            [10.493549354935496, 51.336433643364337],
            [10.389438943894397, 51.336433643364337],
            [10.701770177017707, 51.563956395639565],
            [11.204970497049707, 51.843484348434842],
            [12.003150315031505, 51.908490849084906],
            [12.202695269526956, 51.875987598759878],
            [12.636488648864891, 51.622462246224622],
            [13.096309630963098, 51.505450545054501],
            [13.122337233723378, 51.505450545054501],
            [13.252475247524757, 51.505450545054501],
            [13.339233923392342, 51.505450545054501],
            [13.746999699970004, 51.505450545054501],
            [14.718696869686973, 51.7004700470047],
            [14.736048604860493, 51.583458345834579],
            [14.996324632463249, 51.498949894989501],
            [14.961621162116217, 51.407940794079408],
            [15.013676367636769, 51.199919991999195],
            [14.987648764876489, 51.115411541154117],
            [14.926917691769184, 50.991899189918989],
            [14.857510751075111, 50.907390739073904],
            [14.822807280728078, 50.88788878887889],
            [14.822807280728078, 50.868386838683868],
            [14.796779677967805, 50.835883588358833],
            [14.631938193819387, 50.913891389138911],
            [14.579882988298834, 50.913891389138911],
            [14.571207120712074, 50.959395939593961],
            [14.562531253125314, 51.004900490049003],
            [14.397689768976903, 51.017901790179017],
            [14.267551755175525, 51.017901790179017],
            [14.302255225522558, 50.952895289528954],
            [14.397689768976903, 50.939893989398939],
            [14.397689768976903, 50.920392039203918],
            [14.389013901390143, 50.900890089008897],
            [14.33695869586959, 50.881388138813882],
            [13.764351435143517, 50.731873187318733],
            [13.625537553755379, 50.712371237123712],
            [13.009550955095513, 50.517351735173513],
            [12.914116411641167, 50.478347834783477],
            [12.749274927492756, 50.432843284328435],
            [12.636488648864891, 50.393839383938392],
            [12.593109310931098, 50.374337433743371],
            [12.488998899889992, 50.341834183418342],
            [12.43694369436944, 50.328832883288328],
            [12.384888488848887, 50.289828982898285],
            [12.350185018501854, 50.237823782378236],
            [12.341509150915094, 50.192319231923193],
            [12.315481548154821, 50.172817281728172],
            [12.211371137113716, 50.159815981598157],
            [12.211371137113716, 50.101310131013101],
            [12.263426342634268, 50.068806880688065],
            [12.306805680568061, 50.055805580558051],
            [12.324157415741581, 50.042804280428044],
            [12.324157415741581, 50.036303630363037],
            [12.384888488848887, 50.016801680168015],
            [12.45429542954296, 49.990799079907987],
            [12.497674767476752, 49.971297129712973],
            [12.488998899889992, 49.93229322932293],
            [12.46297129712972, 49.808780878087809],
            [12.4456195619562, 49.795779577957795],
            [12.402240224022407, 49.756775677567752],
            [12.471647164716479, 49.704770477047703],
            [12.541054105410545, 49.665766576657667],
            [12.584433443344338, 49.633263326332631],
            [12.575757575757578, 49.574757475747575],
            [12.584433443344338, 49.568256825682568],
            [12.610461046104618, 49.529252925292525],
            [13.096309630963098, 49.204220422042205],
            [13.113661366136618, 49.191219121912191],
            [13.261151115111517, 49.100210021002098],
            [13.686268626862692, 48.879187918791878],
            [13.790379037903797, 48.827182718271828],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LOVVFIR", name: "WIEN FIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.790379037903797, 48.827182718271828],
            [14.059330933093314, 48.671167116711672],
            [14.015951595159521, 48.638663866386636],
            [14.068006800680074, 48.599659965996594],
            [14.302255225522558, 48.567156715671565],
            [14.34563456345635, 48.560656065606558],
            [14.441069106910696, 48.612661266126608],
            [14.458420842084216, 48.645164516451644],
            [14.66664166416642, 48.58015801580158],
            [14.70134513451346, 48.58015801580158],
            [14.727372737273733, 48.677667766776679],
            [14.779427942794285, 48.723172317231722],
            [14.831483148314838, 48.781678167816779],
            [14.961621162116217, 48.762176217621757],
            [14.978972897289736, 48.775177517751771],
            [14.952945294529457, 48.788178817881786],
            [14.970297029702976, 48.885688568856885],
            [14.978972897289736, 48.963696369636963],
            [15.005000500050009, 49.002700270026999],
            [15.135138513851388, 48.989698969896992],
            [15.152490249024908, 48.989698969896992],
            [15.169841984198428, 48.944194419441942],
            [15.18719371937194, 48.950695069506949],
            [15.265276527652773, 48.963696369636963],
            [15.273952395239526, 48.983198319831985],
            [15.360711071107119, 48.983198319831985],
            [15.586283628362843, 48.898689868986892],
            [15.612311231123115, 48.885688568856885],
            [15.837883788378846, 48.872687268726871],
            [16.04610461046105, 48.762176217621757],
            [16.098159815981603, 48.749174917491743],
            [16.202270227022709, 48.749174917491743],
            [16.323732373237327, 48.736173617361736],
            [16.38446344634464, 48.736173617361736],
            [16.471222122212225, 48.807680768076807],
            [16.549304930493058, 48.807680768076807],
            [16.618711871187124, 48.781678167816779],
            [16.679442944294436, 48.768676867686764],
            [16.688118811881196, 48.729672967296729],
            [16.722822282228229, 48.736173617361736],
            [16.792229222922295, 48.710171017101707],
            [16.90501550155016, 48.716671667166715],
            [16.939718971897193, 48.612661266126608],
            [16.852960296029607, 48.450145014501445],
            [16.852960296029607, 48.43064306430643],
            [16.835608560856087, 48.385138513851388],
            [16.844284428442847, 48.372137213721373],
            [16.90501550155016, 48.307130713071302],
            [16.92236723672368, 48.268126812681267],
            [16.939718971897193, 48.242124212421245],
            [16.957070707070713, 48.216121612161217],
            [16.965746574657473, 48.190119011901189],
            [16.974422442244233, 48.177117711771174],
            [16.974422442244233, 48.170617061706167],
            [17.009125912591266, 48.125112511251125],
            [17.087208720872091, 48.105610561056103],
            [17.087208720872091, 48.066606660666068],
            [17.078532853285331, 48.027602760276025],
            [17.156615661566164, 48.008100810081004],
            [17.156615661566164, 47.982098209820975],
            [17.139263926392644, 47.93659365936594],
            [17.139263926392644, 47.923592359235926],
            [17.113236323632371, 47.832583258325826],
            [17.095884588458851, 47.767576757675769],
            [17.095884588458851, 47.754575457545755],
            [17.087208720872091, 47.709070907090705],
            [17.009125912591266, 47.676567656765677],
            [16.826932693269335, 47.598559855985599],
            [16.800905090509055, 47.585558555855584],
            [16.705470547054709, 47.546554655465542],
            [16.636063606360644, 47.468546854685471],
            [16.618711871187124, 47.449044904490449],
            [16.601360136013604, 47.429542954295428],
            [16.540629062906298, 47.358035803580357],
            [16.497249724972505, 47.312531253125314],
            [16.471222122212225, 47.286528652865286],
            [16.436518651865192, 47.241024102410236],
            [16.349759975997607, 47.143514351435144],
            [16.228297829782981, 47.000500050005002],
            [16.115511551155123, 46.870487048704874],
            [15.985373537353738, 46.831483148314831],
            [15.994049404940498, 46.805480548054803],
            [16.011401140114017, 46.714471447144717],
            [16.03742874287429, 46.655965596559653],
            [15.994049404940498, 46.681968196819682],
            [15.863911391139119, 46.720972097209724],
            [15.811856185618566, 46.70797079707971],
            [15.794504450445046, 46.70797079707971],
            [15.655690569056908, 46.70797079707971],
            [15.629662966296635, 46.681968196819682],
            [15.594959495949603, 46.688468846884689],
            [15.577607760776083, 46.681968196819682],
            [15.55158015801581, 46.668966896689668],
            [15.55158015801581, 46.649464946494646],
            [15.50820082008201, 46.616961696169611],
            [15.464821482148217, 46.616961696169611],
            [15.421442144214424, 46.655965596559653],
            [15.360711071107119, 46.649464946494646],
            [15.247924792479253, 46.642964296429639],
            [15.21322132213222, 46.649464946494646],
            [15.083083308330835, 46.642964296429639],
            [15.039703970397042, 46.649464946494646],
            [14.918241824182424, 46.610461046104611],
            [14.866186618661871, 46.603960396039604],
            [14.857510751075111, 46.590959095909589],
            [14.831483148314838, 46.551955195519554],
            [14.779427942794285, 46.512951295129511],
            [14.753400340034005, 46.499949994999497],
            [14.70134513451346, 46.480448044804476],
            [14.623262326232627, 46.447944794479447],
            [14.588558855885594, 46.434943494349433],
            [14.562531253125314, 46.376437643764376],
            [14.519151915191522, 46.421942194219426],
            [14.449744974497456, 46.421942194219426],
            [14.441069106910696, 46.44144414441444],
            [14.406365636563663, 46.447944794479447],
            [14.389013901390143, 46.44144414441444],
            [14.36298629862987, 46.434943494349433],
            [14.328282828282831, 46.428442844284426],
            [14.293579357935798, 46.428442844284426],
            [14.180793079307939, 46.434943494349433],
            [14.120062006200627, 46.480448044804476],
            [14.085358535853594, 46.480448044804476],
            [14.033303330333041, 46.486948694869483],
            [13.963896389638968, 46.499949994999497],
            [13.911841184118416, 46.519451945194518],
            [13.83375837583759, 46.519451945194518],
            [13.712296229622964, 46.525952595259525],
            [13.573482348234826, 46.53895389538954],
            [13.399964996499655, 46.571457145714575],
            [13.382613261326135, 46.564956495649568],
            [13.330558055805582, 46.558455845584561],
            [13.313206320632069, 46.558455845584561],
            [13.235123512351237, 46.558455845584561],
            [13.165716571657171, 46.590959095909589],
            [12.914116411641167, 46.610461046104611],
            [12.480323032303232, 46.675467546754675],
            [12.280778077807788, 46.805480548054803],
            [12.306805680568061, 46.824982498249824],
            [12.289453945394541, 46.870487048704874],
            [12.220047004700476, 46.876987698769881],
            [12.159315931593163, 46.909490949094909],
            [12.12461246124613, 47.000500050005002],
            [12.194019401940196, 47.02650265026503],
            [12.237398739873996, 47.065506550655066],
            [12.185343534353443, 47.08500850085008],
            [11.985798579857992, 47.046004600460044],
            [11.855660566056613, 47.013501350135016],
            [11.855660566056613, 47.000500050005002],
            [11.751550155015508, 47.020002000200023],
            [11.690819081908195, 47.020002000200023],
            [11.525977597759784, 47.020002000200023],
            [11.447894789478951, 46.987498749874987],
            [11.378487848784886, 46.98099809980998],
            [11.352460246024606, 46.98099809980998],
            [11.25702570257026, 46.974497449744973],
            [11.213646364636467, 46.941994199419938],
            [11.014101410141016, 46.772977297729767],
            [10.970722072207224, 46.772977297729767],
            [10.875287528752878, 46.76647664766476],
            [10.805880588058812, 46.779477947794774],
            [10.788528852885293, 46.792479247924788],
            [10.7451495149515, 46.792479247924788],
            [10.73647364736474, 46.792479247924788],
            [10.72779777977798, 46.798979897989796],
            [10.75382538253826, 46.831483148314831],
            [10.693094309430947, 46.850985098509852],
            [10.667066706670674, 46.876987698769881],
            [10.545604560456049, 46.837983798379838],
            [10.545604560456049, 46.850985098509852],
            [10.502225222522256, 46.844484448444845],
            [10.467521752175223, 46.863986398639867],
            [10.484873487348743, 46.948494849484945],
            [10.389438943894397, 47.000500050005002],
            [10.276652665266532, 46.909490949094909],
            [10.146514651465154, 46.81198119811981],
            [10.111811181118114, 46.844484448444845],
            [9.816831683168324, 47.020002000200023],
            [9.591259125912593, 47.052505250525051],
            [9.58258325832584, 47.052505250525051],
            [9.634638463846386, 47.13051305130513],
            [9.617286728672873, 47.156515651565158],
            [9.53920392039204, 47.267026702670265],
            [9.58258325832584, 47.312531253125314],
            [9.599934993499353, 47.358035803580357],
            [9.617286728672873, 47.371037103710371],
            [9.669341934193426, 47.390539053905385],
            [9.651990199019906, 47.410041004100407],
            [9.617286728672873, 47.449044904490449],
            [9.57390739073908, 47.488048804880485],
            [9.55655565556556, 47.527052705270521],
            [9.730073007300732, 47.533553355335528],
            [9.764776477647771, 47.579057905790577],
            [9.782128212821284, 47.592059205920592],
            [9.799479947994804, 47.57255725572557],
            [9.816831683168324, 47.546554655465542],
            [9.946969696969703, 47.520552055205521],
            [10.085783578357841, 47.507550755075506],
            [10.085783578357841, 47.449044904490449],
            [10.215921592159219, 47.384038403840378],
            [10.198569856985706, 47.332033203320336],
            [10.172542254225426, 47.273527352735272],
            [10.233273327332739, 47.280028002800279],
            [10.311356135613565, 47.306030603060307],
            [10.346059605960598, 47.312531253125314],
            [10.43281828182819, 47.384038403840378],
            [10.43281828182819, 47.397039703970393],
            [10.441494149414943, 47.475047504750478],
            [10.441494149414943, 47.527052705270521],
            [10.450170017001703, 47.585558555855584],
            [10.484873487348743, 47.566056605660563],
            [10.667066706670674, 47.553055305530549],
            [10.710446044604467, 47.540054005400535],
            [10.72779777977798, 47.520552055205521],
            [10.849259925992605, 47.501050105010499],
            [10.979397939793984, 47.423042304230421],
            [10.996749674967504, 47.416541654165414],
            [11.109535953595362, 47.35153515351535],
            [11.343784378437846, 47.35153515351535],
            [11.491274127412744, 47.488048804880485],
            [11.499949994999504, 47.488048804880485],
            [11.60406040604061, 47.579057905790577],
            [11.786253625362541, 47.585558555855584],
            [12.046529652965305, 47.611561156115613],
            [12.063881388138817, 47.61806180618062],
            [12.167991799179923, 47.631063106310634],
            [12.202695269526956, 47.637563756375641],
            [12.46297129712972, 47.67006700670067],
            [12.593109310931098, 47.689568956895684],
            [12.740599059905996, 47.709070907090705],
            [12.853385338533862, 47.735073507350734],
            [12.801330133013309, 48.047104710471046],
            [12.757950795079516, 48.079607960796082],
            [12.766626662666269, 48.125112511251125],
            [12.836033603360342, 48.16411641164116],
            [12.862061206120615, 48.183618361836182],
            [12.96617161716172, 48.203120312031203],
            [13.018226822682273, 48.242124212421245],
            [13.096309630963098, 48.26162616261626],
            [13.165716571657171, 48.294129412941288],
            [13.183068306830691, 48.294129412941288],
            [13.321882188218829, 48.352635263526352],
            [13.425992599259928, 48.398139813981402],
            [13.452020202020208, 48.476147614761473],
            [13.504075407540761, 48.586658665866587],
            [13.720972097209724, 48.508650865086508],
            [13.738323832383244, 48.52815281528153],
            [13.80773077307731, 48.606160616061601],
            [13.790379037903797, 48.827182718271828],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EDVVUIR", name: "HANNOVER UIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.003150315031505, 54.450245024502451],
            [11.638763876387642, 54.294229422942294],
            [11.25702570257026, 54.131713171317131],
            [11.230998099809987, 53.930193019301932],
            [11.204970497049707, 53.637663766376633],
            [11.196294629462948, 53.585658565856583],
            [11.170267026702675, 53.351635163516349],
            [11.135563556355642, 53.000600060006001],
            [11.109535953595362, 52.727572757275723],
            [11.083508350835089, 52.480548054805482],
            [11.083508350835089, 52.46104610461046],
            [11.066156615661569, 52.376537653765375],
            [11.074832483248329, 52.318031803180318],
            [11.083508350835089, 52.305030503050304],
            [11.083508350835089, 52.29202920292029],
            [11.118211821182122, 51.999499949994998],
            [11.118211821182122, 51.97349734973497],
            [11.126887688768882, 51.908490849084906],
            [11.204970497049707, 51.843484348434842],
            [11.005425542554264, 51.732973297329728],
            [10.988073807380744, 51.719971997199721],
            [10.701770177017707, 51.563956395639565],
            [10.606335633563361, 51.531453145314529],
            [10.389438943894397, 51.336433643364337],
            [10.059755975597561, 51.336433643364337],
            [9.938293829382943, 51.336433643364337],
            [9.58258325832584, 51.336433643364337],
            [9.521852185218528, 51.336433643364337],
            [9.166141614161418, 51.336433643364337],
            [8.905865586558662, 51.336433643364337],
            [8.767051705170523, 51.336433643364337],
            [8.662941294129418, 51.28442844284428],
            [8.550155015501552, 51.225922592259224],
            [8.489423942394247, 51.193419341934195],
            [8.324582458245828, 51.115411541154117],
            [8.263851385138516, 51.082908290829081],
            [7.968871887188726, 51.069906990699067],
            [7.882113211321133, 50.998399839983996],
            [7.951520152015206, 50.907390739073904],
            [8.029602960296032, 50.796879687968797],
            [7.994899489948999, 50.757875787578755],
            [7.864761476147621, 50.731873187318733],
            [7.812706270627068, 50.718871887188719],
            [7.725947594759482, 50.705870587058705],
            [7.64786478647865, 50.699369936993698],
            [7.595809580958097, 50.686368636863683],
            [7.526402640264031, 50.673367336733669],
            [7.378912891289133, 50.647364736473648],
            [7.222747274727475, 50.621362136213619],
            [7.214071407140722, 50.621362136213619],
            [7.127312731273129, 50.608360836083605],
            [7.057905790579063, 50.595359535953591],
            [6.997174717471751, 50.582358235823584],
            [6.927767776777685, 50.549854985498548],
            [6.901740174017405, 50.543354335433541],
            [6.81498149814982, 50.504350435043506],
            [6.745574557455747, 50.478347834783477],
            [6.632788278827888, 50.426342634263428],
            [6.50265026502651, 50.374337433743371],
            [6.48529852985299, 50.367836783678364],
            [6.398539853985405, 50.335333533353335],
            [6.398539853985405, 50.341834183418342],
            [6.372512251225125, 50.41984198419842],
            [6.337808780878092, 50.47184718471847],
            [6.329132913291332, 50.491349134913492],
            [6.233698369836986, 50.497849784978499],
            [6.198994899489954, 50.56935693569357],
            [6.233698369836986, 50.582358235823584],
            [6.242374237423746, 50.595359535953591],
            [6.251050105010506, 50.601860186018598],
            [6.277077707770779, 50.627862786278627],
            [6.216346634663473, 50.634363436343634],
            [6.207670767076714, 50.634363436343634],
            [6.138263826382641, 50.686368636863683],
            [6.138263826382641, 50.692869286928691],
            [6.077532753275335, 50.725372537253726],
            [6.034153415341535, 50.751375137513747],
            [6.025477547754782, 50.757875787578755],
            [6.025477547754782, 50.816381638163811],
            [6.025477547754782, 50.822882288228818],
            [6.016801680168022, 50.829382938293826],
            [6.077532753275335, 50.861886188618861],
            [6.068856885688575, 50.881388138813882],
            [6.060181018101815, 50.907390739073904],
            [6.060181018101815, 50.920392039203918],
            [6.034153415341535, 50.933393339333932],
            [6.016801680168022, 50.939893989398939],
            [6.025477547754782, 50.985398539853982],
            [5.990774077407742, 50.985398539853982],
            [5.96474647464747, 50.998399839983996],
            [5.96474647464747, 51.043904390439046],
            [6.008125812581262, 51.082908290829081],
            [6.060181018101815, 51.134913491349131],
            [6.103560356035608, 51.141414141414138],
            [6.129587958795881, 51.186918691869188],
            [6.086208620862088, 51.173917391739174],
            [6.077532753275335, 51.245424542454245],
            [6.120912091209128, 51.277927792779273],
            [6.129587958795881, 51.277927792779273],
            [6.216346634663473, 51.362436243624359],
            [6.216346634663473, 51.401440144014401],
            [6.225022502250226, 51.407940794079408],
            [6.225022502250226, 51.466446644664465],
            [6.198994899489954, 51.524952495249522],
            [6.129587958795881, 51.583458345834579],
            [6.103560356035608, 51.628962896289629],
            [6.034153415341535, 51.674467446744671],
            [6.025477547754782, 51.7004700470047],
            [5.94739473947395, 51.830483048304828],
            [6.025477547754782, 51.856485648564856],
            [6.060181018101815, 51.856485648564856],
            [6.103560356035608, 51.882488248824878],
            [6.146939693969401, 51.901990199019899],
            [6.389863986398645, 51.836983698369835],
            [6.407215721572165, 51.830483048304828],
            [6.372512251225125, 51.86948694869487],
            [6.433243324332437, 51.862986298629863],
            [6.50265026502651, 51.86948694869487],
            [6.589408940894096, 51.895489548954892],
            [6.615436543654369, 51.901990199019899],
            [6.658815881588161, 51.908490849084906],
            [6.719546954695474, 51.914991499149913],
            [6.780278027802787, 51.92149214921492],
            [6.788953895389547, 51.934493449344934],
            [6.762926292629267, 52.025502550255027],
            [6.81498149814982, 52.116511651165112],
            [6.875712571257132, 52.136013601360133],
            [6.884388438843885, 52.142514251425141],
            [6.841009100910092, 52.155515551555155],
            [7.057905790579063, 52.24002400240024],
            [7.066581658165823, 52.532553255325531],
            [7.057905790579063, 52.656065606560652],
            [7.083933393339336, 52.799079907990794],
            [7.196719671967202, 53.000600060006001],
            [7.196719671967202, 53.072107210721072],
            [7.196719671967202, 53.085108510851086],
            [7.205395539553962, 53.111111111111107],
            [7.214071407140722, 53.215121512151214],
            [7.196719671967202, 53.280128012801278],
            [7.188043804380442, 53.299629962996299],
            [7.066581658165823, 53.306130613061306],
            [6.997174717471751, 53.338633863386335],
            [6.919091909190925, 53.345134513451342],
            [6.953795379537958, 53.397139713971399],
            [6.910416041604165, 53.442644264426441],
            [6.867036703670372, 53.475147514751477],
            [6.81498149814982, 53.494649464946491],
            [6.745574557455747, 53.501150115011498],
            [6.641464146414648, 53.540154015401541],
            [6.50265026502651, 53.663666366636662],
            [6.50265026502651, 53.709170917091704],
            [6.50265026502651, 53.735173517351733],
            [6.50265026502651, 53.832683268326832],
            [6.50265026502651, 53.884688468846882],
            [6.50265026502651, 53.923692369236925],
            [6.50265026502651, 54.001700170017003],
            [6.50265026502651, 54.18371837183718],
            [6.50265026502651, 54.248724872487244],
            [6.50265026502651, 54.5022502250225],
            [6.50265026502651, 54.664766476647664],
            [6.50265026502651, 54.957295729572955],
            [6.50265026502651, 55.002800280027998],
            [6.658815881588161, 55.002800280027998],
            [7.248774877487755, 55.002800280027998],
            [7.413616361636166, 55.002800280027998],
            [7.621837183718377, 55.002800280027998],
            [7.639188918891897, 55.002800280027998],
            [7.699919991999202, 55.002800280027998],
            [7.708595859585962, 55.002800280027998],
            [7.717271727172722, 55.002800280027998],
            [7.830058005800588, 55.002800280027998],
            [7.873437343734381, 55.002800280027998],
            [7.951520152015206, 55.002800280027998],
            [8.003575357535759, 55.002800280027998],
            [8.072982298229824, 55.015801580158012],
            [8.107685768576864, 55.022302230223019],
            [8.229147914791483, 55.048304830483048],
            [8.333258325832588, 55.067806780678069],
            [8.393989398939901, 55.067806780678069],
            [8.420017001700174, 55.061306130613062],
            [8.446044604460454, 55.074307430743076],
            [8.454720472047207, 55.067806780678069],
            [8.515451545154519, 55.022302230223019],
            [8.662941294129418, 54.91829182918292],
            [8.784403440344036, 54.898789878987898],
            [8.853810381038109, 54.898789878987898],
            [9.001300130013007, 54.892289228922891],
            [9.01865186518652, 54.898789878987898],
            [9.192169216921698, 54.859785978597856],
            [9.226872687268731, 54.859785978597856],
            [9.235548554855491, 54.833783378337834],
            [9.287603760376044, 54.840284028402841],
            [9.322307230723077, 54.807780778077806],
            [9.513176317631768, 54.846784678467849],
            [9.738748874887492, 54.82078207820782],
            [9.747424742474252, 54.827282728272827],
            [9.851535153515357, 54.833783378337834],
            [9.88623862386239, 54.840284028402841],
            [9.89491449144915, 54.840284028402841],
            [9.964321432143215, 54.807780778077806],
            [9.999024902490255, 54.788278827882785],
            [10.051080108010808, 54.762276227622763],
            [10.146514651465154, 54.749274927492749],
            [10.163866386638666, 54.742774277427742],
            [10.198569856985706, 54.736273627362735],
            [10.337383738373845, 54.697269726972692],
            [10.502225222522256, 54.658265826582657],
            [10.580308030803081, 54.658265826582657],
            [10.667066706670674, 54.658265826582657],
            [10.684418441844187, 54.658265826582657],
            [10.831908190819085, 54.65176517651765],
            [10.996749674967504, 54.645264526452642],
            [11.083508350835089, 54.625762576257621],
            [11.170267026702675, 54.5997599759976],
            [11.204970497049707, 54.593259325932593],
            [11.27437743774378, 54.573757375737571],
            [11.317756775677573, 54.560756075607557],
            [11.335108510851093, 54.55425542554255],
            [11.404515451545159, 54.534753475347536],
            [11.499949994999504, 54.5022502250225],
            [11.647439743974402, 54.469746974697465],
            [11.664791479147922, 54.463246324632465],
            [11.690819081908195, 54.463246324632465],
            [11.803605360536061, 54.450245024502451],
            [11.829632963296334, 54.443744374437443],
            [11.838308830883093, 54.443744374437443],
            [12.003150315031505, 54.450245024502451],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LFEEFIR", name: "REIMS FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.094884488448848, 46.545454545454547],
            [6.008125812581262, 46.545454545454547],
            [5.348759875987604, 46.499949994999497],
            [5.166566656665672, 46.499949994999497],
            [5.019076907690774, 46.499949994999497],
            [4.949669966996701, 46.499949994999497],
            [4.836883688368843, 46.499949994999497],
            [4.333683368336835, 47.416541654165414],
            [4.585283528352839, 47.650565056505648],
            [4.75880088008801, 47.800080008000798],
            [4.932318231823189, 47.956095609560954],
            [4.949669966996701, 47.975597559755975],
            [5.001725172517254, 48.014601460146011],
            [5.12318731873188, 48.125112511251125],
            [5.166566656665672, 48.16411641164116],
            [5.496249624962502, 48.216121612161217],
            [5.730498049804986, 48.248624862486245],
            [4.802180218021803, 48.950695069506949],
            [5.556980698069815, 49.269226922692269],
            [5.808580858085811, 49.360236023602361],
            [5.999449944994502, 49.457745774577454],
            [6.051505150515055, 49.464246424642461],
            [6.112236223622368, 49.477247724772475],
            [6.181643164316434, 49.496749674967496],
            [6.207670767076714, 49.503250325032504],
            [6.225022502250226, 49.509750975097511],
            [6.346484648464852, 49.464246424642461],
            [6.389863986398645, 49.464246424642461],
            [6.398539853985405, 49.464246424642461],
            [6.46794679467947, 49.464246424642461],
            [6.546029602960303, 49.457745774577454],
            [6.563381338133816, 49.431743174317432],
            [6.684843484348441, 49.249724972497248],
            [6.728222822282234, 49.223722372237219],
            [7.040554055405543, 49.171717171717169],
            [7.162016201620169, 49.152215221522148],
            [7.3442094209421, 49.11971197119712],
            [7.587133713371344, 49.080708070807077],
            [7.717271727172722, 49.048204820482049],
            [7.830058005800588, 49.041704170417042],
            [8.003575357535759, 49.02220222022202],
            [8.064306430643072, 49.002700270026999],
            [8.116361636163617, 48.989698969896992],
            [8.220472047204723, 48.976697669766978],
            [8.151065106510657, 48.918191819181914],
            [8.020927092709279, 48.775177517751771],
            [7.968871887188726, 48.716671667166715],
            [7.804030403040308, 48.521652165216523],
            [7.795354535453548, 48.508650865086508],
            [7.734623462346242, 48.437143714371437],
            [7.699919991999202, 48.346134613461345],
            [7.65654065406541, 48.216121612161217],
            [7.64786478647865, 48.196619661966196],
            [7.578457845784584, 48.086108610861089],
            [7.613161316131617, 48.001600160015997],
            [7.613161316131617, 47.988598859885983],
            [7.587133713371344, 47.910591059105911],
            [7.561106110611064, 47.839083908390833],
            [7.561106110611064, 47.832583258325826],
            [7.535078507850791, 47.780578057805783],
            [7.535078507850791, 47.689568956895684],
            [7.500375037503751, 47.631063106310634],
            [7.578457845784584, 47.605060506050606],
            [7.474347434743478, 47.494549454945492],
            [7.430968096809686, 47.488048804880485],
            [7.378912891289133, 47.416541654165414],
            [7.205395539553962, 47.423042304230421],
            [7.144664466446649, 47.501050105010499],
            [6.988498849884991, 47.494549454945492],
            [6.997174717471751, 47.468546854685471],
            [6.962471247124718, 47.462046204620464],
            [6.901740174017405, 47.384038403840378],
            [7.066581658165823, 47.345034503450343],
            [7.005850585058511, 47.325532553255329],
            [6.953795379537958, 47.241024102410236],
            [6.81498149814982, 47.13051305130513],
            [6.719546954695474, 47.052505250525051],
            [6.641464146414648, 47.02650265026503],
            [6.520002000200023, 46.974497449744973],
            [6.47662266226623, 46.961496149614959],
            [6.433243324332437, 46.922492249224916],
            [6.45927092709271, 46.850985098509852],
            [6.398539853985405, 46.779477947794774],
            [6.441919191919197, 46.75997599759976],
            [6.407215721572165, 46.753475347534753],
            [6.389863986398645, 46.740474047404739],
            [6.320457045704572, 46.714471447144717],
            [6.294429442944299, 46.701470147014703],
            [6.277077707770779, 46.681968196819682],
            [6.251050105010506, 46.668966896689668],
            [6.164291429142921, 46.610461046104611],
            [6.094884488448848, 46.545454545454547],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LUUUFIR", name: "CHISINAU FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.621987198719879, 48.248624862486245],
            [26.708745874587464, 48.320132013201317],
            [26.81285628562857, 48.417641764176416],
            [27.307380738073817, 48.417641764176416],
            [27.723822382238225, 48.450145014501445],
            [27.827932793279331, 48.424142414241423],
            [27.836608660866091, 48.417641764176416],
            [28.730223022302241, 48.125112511251125],
            [29.137988798879888, 47.99509950995099],
            [29.233423342334234, 47.884588458845883],
            [29.276802680268034, 47.806580658065805],
            [29.224747474747488, 47.728572857285727],
            [29.207395739573968, 47.57255725572557],
            [29.190044004400448, 47.468546854685471],
            [29.32885788578858, 47.442544254425442],
            [29.389588958895899, 47.325532553255329],
            [29.441644164416445, 47.2995299529953],
            [29.485023502350245, 47.312531253125314],
            [29.511051105110511, 47.371037103710371],
            [29.58913391339135, 47.371037103710371],
            [29.59780978097811, 47.286528652865286],
            [29.528402840284031, 47.111011101110108],
            [29.623837383738376, 47.078507850785073],
            [29.615161516151616, 47.013501350135016],
            [29.632513251325136, 47.000500050005002],
            [29.58045804580459, 46.948494849484945],
            [29.727947794779482, 46.922492249224916],
            [29.762651265126522, 46.863986398639867],
            [29.875437543754387, 46.876987698769881],
            [29.884113411341147, 46.876987698769881],
            [29.996899689968998, 46.831483148314831],
            [29.944844484448453, 46.649464946494646],
            [29.901465146514653, 46.545454545454547],
            [29.979547954795493, 46.519451945194518],
            [30.074982498249838, 46.454445444544454],
            [30.074982498249838, 46.434943494349433],
            [30.118361836183624, 46.434943494349433],
            [30.127037703770384, 46.421942194219426],
            [30.135713571357144, 46.415441544154419],
            [30.066306630663078, 46.402440244024405],
            [29.996899689968998, 46.382938293829383],
            [29.944844484448453, 46.402440244024405],
            [29.884113411341147, 46.363436343634362],
            [29.832058205820587, 46.382938293829383],
            [29.797354735473547, 46.434943494349433],
            [29.753975397539762, 46.454445444544454],
            [29.675892589258936, 46.402440244024405],
            [29.693244324432456, 46.363436343634362],
            [29.32885788578858, 46.467446744674461],
            [29.085933593359343, 46.519451945194518],
            [29.051230123012303, 46.181418141814177],
            [28.955795579557957, 46.096909690969099],
            [28.869036903690372, 45.973397339733971],
            [28.730223022302241, 45.823882388238822],
            [28.50465046504651, 45.674367436743672],
            [28.548029802980309, 45.596359635963594],
            [28.50465046504651, 45.570357035703566],
            [28.51332633263327, 45.498849884988502],
            [28.435243524352444, 45.485848584858488],
            [28.313781378137818, 45.544354435443545],
            [28.200995099509953, 45.472847284728473],
            [28.157615761576167, 45.544354435443545],
            [28.088208820882087, 45.609360936093609],
            [28.157615761576167, 45.635363536353637],
            [28.096884688468847, 45.940894089408943],
            [28.096884688468847, 46.038403840384035],
            [28.096884688468847, 46.051405140514049],
            [28.122912291229127, 46.194419441944191],
            [28.114236423642367, 46.233423342334234],
            [28.148939893989407, 46.278927892789277],
            [28.200995099509953, 46.317931793179312],
            [28.200995099509953, 46.376437643764376],
            [28.235698569856993, 46.402440244024405],
            [28.253050305030513, 46.447944794479447],
            [28.235698569856993, 46.480448044804476],
            [28.235698569856993, 46.49344934493449],
            [28.227022702270233, 46.512951295129511],
            [28.244374437443753, 46.623462346234618],
            [28.174967496749687, 46.753475347534753],
            [28.166291629162927, 46.779477947794774],
            [28.114236423642367, 46.915991599159916],
            [28.105560556055607, 46.928992899289923],
            [27.862636263626371, 47.104510451045101],
            [27.853960396039611, 47.169516951695172],
            [27.758525852585265, 47.260526052605258],
            [27.6457395739574, 47.312531253125314],
            [27.585008500850094, 47.325532553255329],
            [27.585008500850094, 47.4035403540354],
            [27.550305030503054, 47.429542954295428],
            [27.472222222222229, 47.494549454945492],
            [27.454870487048709, 47.527052705270521],
            [27.411491149114923, 47.585558555855584],
            [27.264001400140017, 47.72207220722072],
            [27.290029002900297, 47.761076107610762],
            [27.246649664966498, 47.774077407740776],
            [27.229297929792992, 47.819581958195819],
            [27.237973797379738, 47.839083908390833],
            [27.211946194619472, 47.89108910891089],
            [27.203270327032712, 47.910591059105911],
            [27.159890989098912, 47.923592359235926],
            [27.177242724272432, 47.949594959495947],
            [27.151215121512152, 47.969096909690968],
            [27.159890989098912, 47.988598859885983],
            [27.099159915991606, 48.021102110211018],
            [27.029752975297541, 48.105610561056103],
            [27.029752975297541, 48.138113811381132],
            [26.969021902190221, 48.144614461446139],
            [26.942994299429955, 48.196619661966196],
            [26.83020802080209, 48.235623562356238],
            [26.621987198719879, 48.248624862486245],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LDZOFIR", name: "ZAGREB FIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.501800180018009, 43.503150315031505],
            [14.423717371737176, 43.561656165616562],
            [14.328282828282831, 43.652665266526654],
            [14.276227622762278, 43.698169816981697],
            [14.024627462746281, 43.925692569256924],
            [13.946544654465448, 43.977697769776981],
            [13.920517051705176, 44.016701670167016],
            [13.755675567556757, 44.159715971597159],
            [13.686268626862692, 44.224722472247223],
            [13.460696069606968, 44.413241324132414],
            [13.330558055805582, 44.530253025302528],
            [13.269826982698277, 44.634263426342635],
            [13.200420042004204, 44.783778377837784],
            [13.131013101310138, 44.900790079007905],
            [13.087633763376346, 44.985298529852983],
            [13.000875087508753, 45.160816081608161],
            [13.000875087508753, 45.167316731673168],
            [13.000875087508753, 45.180318031803182],
            [12.992199219922, 45.225822582258225],
            [12.992199219922, 45.26482648264826],
            [13.000875087508753, 45.297329732973296],
            [13.113661366136618, 45.375337533753374],
            [13.131013101310138, 45.388338833883388],
            [13.183068306830691, 45.420842084208417],
            [13.278502850285037, 45.485848584858488],
            [13.330558055805582, 45.524852485248523],
            [13.391289128912895, 45.557355735573552],
            [13.417316731673175, 45.544354435443545],
            [13.504075407540761, 45.511851185118509],
            [13.547454745474553, 45.492349234923495],
            [13.582158215821586, 45.472847284728473],
            [13.616861686168619, 45.466346634663466],
            [13.712296229622964, 45.466346634663466],
            [13.773027302730277, 45.466346634663466],
            [13.998599859986001, 45.518351835183516],
            [14.015951595159521, 45.524852485248523],
            [14.137413741374139, 45.472847284728473],
            [14.206820682068212, 45.472847284728473],
            [14.224172417241732, 45.498849884988502],
            [14.241524152415245, 45.505350535053509],
            [14.258875887588765, 45.505350535053509],
            [14.267551755175525, 45.479347934793481],
            [14.276227622762278, 45.492349234923495],
            [14.302255225522558, 45.492349234923495],
            [14.33695869586959, 45.479347934793481],
            [14.35431043104311, 45.485848584858488],
            [14.389013901390143, 45.492349234923495],
            [14.406365636563663, 45.505350535053509],
            [14.475772577257729, 45.53135313531353],
            [14.545179517951802, 45.62886288628863],
            [14.579882988298834, 45.641864186418644],
            [14.736048604860493, 45.524852485248523],
            [14.805455545554558, 45.492349234923495],
            [14.866186618661871, 45.466346634663466],
            [15.005000500050009, 45.492349234923495],
            [15.20454545454546, 45.466346634663466],
            [15.326007600760079, 45.453345334533452],
            [15.299979997999806, 45.576857685768573],
            [15.352035203520359, 45.635363536353637],
            [15.352035203520359, 45.661366136613665],
            [15.352035203520359, 45.687368736873687],
            [15.352035203520359, 45.771877187718772],
            [15.430118011801184, 45.791379137913793],
            [15.490849084908497, 45.804380438043808],
            [15.655690569056908, 45.843384338433843],
            [15.681718171817188, 45.862886288628857],
            [15.699069906990701, 45.973397339733971],
            [15.664366436643668, 46.070907090709071],
            [15.629662966296635, 46.168416841684163],
            [15.603635363536355, 46.194419441944191],
            [15.777152715271534, 46.226922692269227],
            [15.829207920792086, 46.26592659265927],
            [15.924642464246432, 46.285428542854284],
            [15.976697669766985, 46.311431143114305],
            [16.03742874287429, 46.369936993699369],
            [16.07213221322133, 46.408940894089412],
            [16.271677167716774, 46.376437643764376],
            [16.280353035303534, 46.395939593959397],
            [16.236973697369741, 46.506450645064504],
            [16.36711171117112, 46.571457145714575],
            [16.531953195319538, 46.499949994999497],
            [16.636063606360644, 46.473947394739469],
            [16.88766376637664, 46.291929192919291],
            [16.965746574657473, 46.239923992399241],
            [17.147939793979404, 46.168416841684163],
            [17.182643264326437, 46.12291229122912],
            [17.182643264326437, 46.11641164116412],
            [17.295429542954302, 45.992899289928992],
            [17.399539953995408, 45.94739473947395],
            [17.564381438143819, 45.934393439343935],
            [17.868036803680376, 45.771877187718772],
            [18.136988698869892, 45.758875887588758],
            [18.362561256125616, 45.752375237523751],
            [18.414616461646169, 45.745874587458744],
            [18.813706370637071, 45.869386938693864],
            [18.935168516851689, 45.817381738173815],
            [18.961196119611969, 45.674367436743672],
            [18.752975297529758, 45.58335833583358],
            [19.056630663066315, 45.53135313531353],
            [19.134713471347141, 45.505350535053509],
            [19.091334133413348, 45.485848584858488],
            [19.013251325132522, 45.401340134013395],
            [18.995899589959002, 45.349334933493353],
            [19.247499749975006, 45.271327132713267],
            [19.368961896189624, 45.232323232323232],
            [19.290879087908799, 45.206320632063203],
            [19.247499749975006, 45.154315431543154],
            [19.108685868586868, 45.134813481348132],
            [19.073982398239828, 45.128312831283125],
            [19.073982398239828, 45.108810881088104],
            [19.073982398239828, 45.069806980698068],
            [19.091334133413348, 45.004800480048004],
            [19.013251325132522, 44.868286828682869],
            [19.030603060306035, 44.848784878487848],
            [18.831058105810584, 44.848784878487848],
            [18.726947694769486, 44.998299829982997],
            [18.666216621662173, 45.082808280828083],
            [18.379912991299136, 45.102310231023097],
            [17.998174817481754, 45.134813481348132],
            [17.894064406440648, 45.063306330633061],
            [17.859360936093616, 45.043804380438047],
            [17.842009200920096, 45.043804380438047],
            [17.659815981598165, 45.115311531153111],
            [17.547029702970299, 45.115311531153111],
            [17.521002100210026, 45.102310231023097],
            [17.494974497449753, 45.115311531153111],
            [17.486298629862993, 45.134813481348132],
            [17.451595159515954, 45.128312831283125],
            [17.451595159515954, 45.160816081608161],
            [17.408215821582161, 45.134813481348132],
            [17.399539953995408, 45.134813481348132],
            [17.364836483648368, 45.134813481348132],
            [17.356160616061608, 45.141314131413139],
            [17.295429542954302, 45.173817381738175],
            [17.25205020502051, 45.154315431543154],
            [17.23469846984699, 45.141314131413139],
            [17.182643264326437, 45.147814781478147],
            [17.165291529152924, 45.154315431543154],
            [17.069856985698578, 45.199819981998203],
            [17.017801780178026, 45.225822582258225],
            [16.948394839483953, 45.238823882388239],
            [16.835608560856087, 45.219321932193218],
            [16.783553355335542, 45.186818681868189],
            [16.731498149814989, 45.193319331933196],
            [16.679442944294436, 45.199819981998203],
            [16.557980798079811, 45.219321932193218],
            [16.523277327732778, 45.225822582258225],
            [16.488573857385745, 45.199819981998203],
            [16.462546254625465, 45.147814781478147],
            [16.445194519451952, 45.128312831283125],
            [16.37578757875788, 45.050305030503054],
            [16.306380638063814, 44.998299829982997],
            [15.933318331833192, 45.219321932193218],
            [15.907290729072912, 45.225822582258225],
            [15.889938993899392, 45.219321932193218],
            [15.733773377337741, 45.193319331933196],
            [15.742449244924501, 44.939793979397933],
            [15.768476847684774, 44.77077707770777],
            [15.968021802180225, 44.679767976797677],
            [16.141539153915396, 44.445744574457443],
            [16.271677167716774, 44.166216621662166],
            [16.306380638063814, 44.153215321532151],
            [16.315056505650567, 44.12721272127213],
            [16.479897989798985, 44.029702970297024],
            [16.670767076707676, 43.873687368736874],
            [16.722822282228229, 43.821682168216824],
            [16.748849884988502, 43.802180218021803],
            [16.826932693269335, 43.737173717371732],
            [16.948394839483953, 43.633163316331633],
            [17.043829382938299, 43.561656165616562],
            [17.121912191219124, 43.516151615161519],
            [17.199994999499957, 43.496649664966498],
            [17.21734673467347, 43.496649664966498],
            [17.24337433743375, 43.483648364836483],
            [17.25205020502051, 43.451145114511448],
            [17.278077807780782, 43.353635363536355],
            [17.373512351235128, 43.27562756275627],
            [17.451595159515954, 43.210621062106213],
            [17.659815981598165, 43.041604160416043],
            [17.703195319531957, 42.95059505950595],
            [17.547029702970299, 42.905090509050908],
            [17.711871187118717, 42.840084008400837],
            [17.833333333333336, 42.866086608660865],
            [17.850685068506856, 42.853085308530851],
            [18.249774977497758, 42.599559955995602],
            [18.310506050605063, 42.612561256125616],
            [18.440644064406449, 42.541054105410538],
            [18.449319931993202, 42.502050205020502],
            [18.484023402340242, 42.450045004500453],
            [18.553430343034307, 42.41104110411041],
            [18.553430343034307, 42.352535253525346],
            [18.553430343034307, 42.346034603460346],
            [18.562106210621067, 42.339533953395339],
            [18.58813381338134, 42.216021602160211],
            [18.5968096809681, 42.203020302030197],
            [18.553430343034307, 42.09250925092509],
            [18.527402740274034, 42.014501450145012],
            [18.666216621662173, 41.929992999299927],
            [18.58813381338134, 41.864986498649863],
            [18.484023402340242, 41.884488448844884],
            [18.431968196819689, 41.741474147414742],
            [18.336533653365343, 41.494449444944493],
            [18.223747374737478, 41.442444244424436],
            [18.128312831283132, 41.5009500950095],
            [17.616436643664372, 41.754475447544749],
            [17.312781278127815, 41.884488448844884],
            [17.25205020502051, 41.929992999299927],
            [16.349759975997607, 42.346034603460346],
            [16.176242624262429, 42.333033303330332],
            [16.098159815981603, 42.378537853785375],
            [16.124187418741876, 42.469546954695467],
            [16.04610461046105, 42.508550855085502],
            [15.933318331833192, 42.580058005800581],
            [15.820532053205326, 42.658065806580652],
            [15.751125112511254, 42.703570357035701],
            [15.52555255525553, 42.846584658465844],
            [15.464821482148217, 42.885588558855886],
            [15.291304130413046, 42.996099609960993],
            [15.109110911091115, 43.113111311131114],
            [14.796779677967805, 43.314631463146313],
            [14.631938193819387, 43.418641864186412],
            [14.501800180018009, 43.503150315031505],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LIMMUIR", name: "MILANO UIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.467521752175223, 46.863986398639867],
            [10.502225222522256, 46.844484448444845],
            [10.545604560456049, 46.850985098509852],
            [10.545604560456049, 46.837983798379838],
            [10.667066706670674, 46.876987698769881],
            [10.693094309430947, 46.850985098509852],
            [10.75382538253826, 46.831483148314831],
            [10.72779777977798, 46.798979897989796],
            [10.73647364736474, 46.792479247924788],
            [10.7451495149515, 46.792479247924788],
            [10.788528852885293, 46.792479247924788],
            [10.805880588058812, 46.779477947794774],
            [10.875287528752878, 46.76647664766476],
            [10.970722072207224, 46.772977297729767],
            [11.014101410141016, 46.772977297729767],
            [11.213646364636467, 46.941994199419938],
            [11.25702570257026, 46.974497449744973],
            [11.352460246024606, 46.98099809980998],
            [11.378487848784886, 46.98099809980998],
            [11.447894789478951, 46.987498749874987],
            [11.525977597759784, 47.020002000200023],
            [11.690819081908195, 47.020002000200023],
            [11.751550155015508, 47.020002000200023],
            [11.855660566056613, 47.000500050005002],
            [11.855660566056613, 47.013501350135016],
            [11.985798579857992, 47.046004600460044],
            [12.185343534353443, 47.08500850085008],
            [12.237398739873996, 47.065506550655066],
            [12.194019401940196, 47.02650265026503],
            [12.12461246124613, 47.000500050005002],
            [12.159315931593163, 46.909490949094909],
            [12.220047004700476, 46.876987698769881],
            [12.289453945394541, 46.870487048704874],
            [12.306805680568061, 46.824982498249824],
            [12.280778077807788, 46.805480548054803],
            [12.480323032303232, 46.675467546754675],
            [12.914116411641167, 46.610461046104611],
            [13.165716571657171, 46.590959095909589],
            [13.235123512351237, 46.558455845584561],
            [13.313206320632069, 46.558455845584561],
            [13.330558055805582, 46.558455845584561],
            [13.382613261326135, 46.564956495649568],
            [13.399964996499655, 46.571457145714575],
            [13.573482348234826, 46.53895389538954],
            [13.712296229622964, 46.525952595259525],
            [13.703620362036212, 46.49344934493449],
            [13.694944494449452, 46.467446744674461],
            [13.625537553755379, 46.44144414441444],
            [13.530103010301033, 46.415441544154419],
            [13.408640864086415, 46.311431143114305],
            [13.469371937193728, 46.22042204220422],
            [13.608185818581866, 46.194419441944191],
            [13.694944494449452, 46.187918791879184],
            [13.608185818581866, 46.11641164116412],
            [13.504075407540761, 45.992899289928992],
            [13.599509950995106, 45.986398639863985],
            [13.608185818581866, 46.012401240124007],
            [13.668916891689172, 45.992899289928992],
            [13.634213421342139, 45.940894089408943],
            [13.590834083408346, 45.84988498849885],
            [13.703620362036212, 45.784878487848786],
            [13.799054905490557, 45.752375237523751],
            [13.920517051705176, 45.635363536353637],
            [13.83375837583759, 45.576857685768573],
            [13.729647964796484, 45.615861586158616],
            [13.530103010301033, 45.62886288628863],
            [13.504075407540761, 45.635363536353637],
            [13.391289128912895, 45.557355735573552],
            [13.330558055805582, 45.524852485248523],
            [13.278502850285037, 45.485848584858488],
            [13.183068306830691, 45.420842084208417],
            [13.131013101310138, 45.388338833883388],
            [13.113661366136618, 45.375337533753374],
            [13.000875087508753, 45.297329732973296],
            [12.992199219922, 45.26482648264826],
            [12.992199219922, 45.225822582258225],
            [13.000875087508753, 45.180318031803182],
            [13.000875087508753, 45.167316731673168],
            [13.000875087508753, 45.160816081608161],
            [13.087633763376346, 44.985298529852983],
            [13.131013101310138, 44.900790079007905],
            [13.200420042004204, 44.783778377837784],
            [13.269826982698277, 44.634263426342635],
            [13.330558055805582, 44.530253025302528],
            [13.460696069606968, 44.413241324132414],
            [13.686268626862692, 44.224722472247223],
            [13.755675567556757, 44.159715971597159],
            [13.920517051705176, 44.016701670167016],
            [13.946544654465448, 43.977697769776981],
            [14.024627462746281, 43.925692569256924],
            [14.276227622762278, 43.698169816981697],
            [14.328282828282831, 43.652665266526654],
            [14.423717371737176, 43.561656165616562],
            [14.501800180018009, 43.503150315031505],
            [13.660241024102412, 43.509650965096512],
            [13.330558055805582, 43.516151615161519],
            [13.044254425442546, 43.54215421542154],
            [11.925067506750679, 43.652665266526654],
            [11.638763876387642, 43.678667866786682],
            [11.560681068106817, 43.685168516851682],
            [11.508625862586264, 43.691669166916689],
            [11.170267026702675, 43.717671767176718],
            [10.771177117711773, 43.659165916591661],
            [10.710446044604467, 43.646164616461647],
            [10.675742574257427, 43.63966396639664],
            [10.519576957695776, 43.620162016201618],
            [10.302680268026805, 43.587658765876583],
            [10.059755975597561, 43.405640564056405],
            [9.747424742474252, 43.165116511651163],
            [9.174817481748178, 43.334133413341334],
            [8.957920792079214, 43.386138613861384],
            [8.819106910691076, 43.431643164316426],
            [8.801755175517556, 43.438143814381434],
            [8.71499649964997, 43.464146414641462],
            [8.602210221022105, 43.49014901490149],
            [8.541479147914799, 43.503150315031505],
            [8.324582458245828, 43.568156815681569],
            [7.838733873387341, 43.704670467046704],
            [7.717271727172722, 43.737173717371732],
            [7.682568256825689, 43.769676967696768],
            [7.535078507850791, 43.776177617761775],
            [7.587133713371344, 43.899689968996896],
            [7.665216521652169, 43.997199719971995],
            [7.708595859585962, 44.062206220622059],
            [7.621837183718377, 44.146714671467144],
            [7.283478347834787, 44.140214021402137],
            [7.083933393339336, 44.211721172117208],
            [7.005850585058511, 44.237723772377237],
            [6.919091909190925, 44.45224522452245],
            [7.005850585058511, 44.582258225822585],
            [7.040554055405543, 44.660266026602656],
            [7.014526452645271, 44.803280328032798],
            [6.901740174017405, 44.842284228422841],
            [6.780278027802787, 45.011301130113011],
            [6.641464146414648, 45.115311531153111],
            [6.962471247124718, 45.193319331933196],
            [7.083933393339336, 45.401340134013395],
            [7.023202320232031, 45.453345334533452],
            [7.031878187818783, 45.466346634663466],
            [7.040554055405543, 45.479347934793481],
            [6.867036703670372, 45.752375237523751],
            [7.049229922992303, 45.9018901890189],
            [7.101285128512856, 45.875887588758872],
            [7.448319831983206, 45.927892789278928],
            [7.543754375437551, 45.966896689668964],
            [7.725947594759482, 45.934393439343935],
            [7.847409740974101, 45.927892789278928],
            [7.864761476147621, 45.940894089408943],
            [7.873437343734381, 45.953895389538957],
            [7.908140814081413, 45.992899289928992],
            [7.968871887188726, 45.999399939993999],
            [8.029602960296032, 46.077407740774078],
            [8.133713371337137, 46.181418141814177],
            [8.081658165816584, 46.259425942594262],
            [8.090334033403344, 46.26592659265927],
            [8.229147914791483, 46.330933093309326],
            [8.315906590659068, 46.38943894389439],
            [8.289878987898796, 46.415441544154419],
            [8.437368736873694, 46.467446744674461],
            [8.446044604460454, 46.278927892789277],
            [8.446044604460454, 46.27242724272427],
            [8.463396339633967, 46.252925292529255],
            [8.480748074807487, 46.246424642464248],
            [8.506775677567759, 46.226922692269227],
            [8.567506750675072, 46.168416841684163],
            [8.662941294129418, 46.103410341034106],
            [8.853810381038109, 45.992899289928992],
            [8.905865586558662, 45.908390839083907],
            [8.966596659665974, 45.84988498849885],
            [9.03600360036004, 45.862886288628857],
            [9.070707070707073, 45.869386938693864],
            [9.03600360036004, 46.025402540254021],
            [9.209520952095211, 46.142414241424142],
            [9.226872687268731, 46.155415541554156],
            [9.296279627962804, 46.207420742074206],
            [9.278927892789284, 46.311431143114305],
            [9.278927892789284, 46.415441544154419],
            [9.278927892789284, 46.480448044804476],
            [9.365686568656869, 46.512951295129511],
            [9.504500450045008, 46.350435043504348],
            [9.521852185218528, 46.337433743374334],
            [9.5478797879788, 46.304930493049305],
            [9.90359035903591, 46.415441544154419],
            [10.042404240424048, 46.480448044804476],
            [10.059755975597561, 46.506450645064504],
            [10.146514651465154, 46.616961696169611],
            [10.198569856985706, 46.623462346234618],
            [10.215921592159219, 46.636463646364632],
            [10.337383738373845, 46.545454545454547],
            [10.476197619761983, 46.564956495649568],
            [10.467521752175223, 46.701470147014703],
            [10.467521752175223, 46.863986398639867],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LFBBFIR", name: "BORDEAUX FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.782803280328032, 43.581158115811576],
            [-1.765451545154512, 44.068706870687066],
            [-1.756775677567752, 44.198719871987194],
            [-1.72207220722072, 44.816281628162812],
            [-1.678692869286927, 45.563856385638559],
            [-1.670017001700167, 45.979897989798978],
            [-1.635313531353134, 46.499949994999497],
            [-1.583258325832581, 46.499949994999497],
            [-1.427092709270923, 46.499949994999497],
            [-1.349009900990097, 46.499949994999497],
            [-1.132113211321126, 46.499949994999497],
            [-0.889188918891882, 46.499949994999497],
            [-0.698319831983191, 46.499949994999497],
            [-0.247174717471744, 46.499949994999497],
            [-0.247174717471744, 46.623462346234618],
            [-0.247174717471744, 47.046004600460044],
            [-0.247174717471744, 47.169516951695172],
            [0.047804780478053, 47.169516951695172],
            [0.177942794279431, 47.169516951695172],
            [0.256025602560257, 47.169516951695172],
            [0.82863286328633, 47.169516951695172],
            [1.054205420542061, 47.169516951695172],
            [1.461971197119716, 47.169516951695172],
            [1.999874987498757, 47.169516951695172],
            [2.164716471647168, 47.08500850085008],
            [2.832758275827587, 46.746974697469746],
            [2.841434143414347, 46.727472747274724],
            [2.876137613761379, 46.53895389538954],
            [2.884813481348139, 46.499949994999497],
            [2.910841084108412, 46.376437643764376],
            [2.919516951695172, 46.330933093309326],
            [2.928192819281932, 46.252925292529255],
            [2.954220422042205, 46.064406440644063],
            [3.014951495149518, 45.401340134013395],
            [3.023627362736278, 45.225822582258225],
            [3.032303230323038, 45.147814781478147],
            [3.049654965496551, 44.874787478747876],
            [3.040979097909798, 44.666766676667663],
            [3.032303230323038, 44.614761476147613],
            [2.902165216521659, 44.250725072507251],
            [2.815406540654067, 44.023202320232024],
            [2.719971997199721, 43.717671767176718],
            [2.711296129612968, 43.210621062106213],
            [2.57248224822483, 43.256125612561256],
            [2.277502750275033, 43.002600260026],
            [2.598509850985103, 42.710071007100709],
            [2.745999599960001, 42.586558655865588],
            [2.832758275827587, 42.450045004500453],
            [2.693944394439448, 42.417541754175417],
            [2.52910291029103, 42.398039803980396],
            [2.216771677167721, 42.430543054305431],
            [2.164716471647168, 42.385038503850382],
            [2.103985398539855, 42.385038503850382],
            [2.103985398539855, 42.378537853785375],
            [2.00855085508551, 42.36553655365536],
            [1.730923092309233, 42.502050205020502],
            [1.67886788678868, 42.619061906190616],
            [1.644164416441647, 42.619061906190616],
            [1.479322932293236, 42.619061906190616],
            [1.375212521252131, 42.697069706970694],
            [1.227722772277232, 42.723072307230723],
            [1.114936493649367, 42.775077507750773],
            [0.993474347434748, 42.775077507750773],
            [0.86333633363337, 42.814081408140808],
            [0.750550055005505, 42.846584658465844],
            [0.733198319831985, 42.853085308530851],
            [0.559680968096814, 42.690569056905687],
            [0.420867086708675, 42.697069706970694],
            [0.368811881188122, 42.697069706970694],
            [0.151915191519159, 42.72957295729573],
            [-0.064981498149812, 42.697069706970694],
            [-0.203795379537951, 42.762076207620765],
            [-0.247174717471744, 42.788078807880787],
            [-0.299229922992296, 42.840084008400837],
            [-0.464071407140707, 42.814081408140808],
            [-0.654940494049399, 42.872587258725872],
            [-0.724347434743471, 42.8985898589859],
            [-0.84580958095809, 42.931093109310929],
            [-0.975947594759475, 42.970097009700964],
            [-1.106085608560853, 43.009100910091007],
            [-1.331658165816577, 43.04810481048105],
            [-1.453120312031203, 43.061106110611064],
            [-1.470472047204716, 43.288628862886284],
            [-1.487823782378236, 43.282128212821277],
            [-1.513851385138508, 43.295129512951291],
            [-1.565906590659061, 43.256125612561256],
            [-1.617961796179614, 43.288628862886284],
            [-1.72207220722072, 43.314631463146313],
            [-1.739423942394232, 43.353635363536355],
            [-1.782803280328032, 43.386138613861384],
            [-1.782803280328032, 43.581158115811576],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EBBUFIR", name: "BRUSSELS FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.999874987498757, 51.498949894989501],
            [2.086633663366342, 51.492449244924494],
            [2.277502750275033, 51.472947294729472],
            [2.320882088208826, 51.472947294729472],
            [2.503075307530757, 51.453445344534451],
            [3.006275627562758, 51.407940794079408],
            [3.370662066206627, 51.368936893689366],
            [3.37933793379338, 51.28442844284428],
            [3.40536553655366, 51.271427142714273],
            [3.466096609660973, 51.238923892389238],
            [3.518151815181525, 51.264926492649266],
            [3.561531153115318, 51.290929092909288],
            [3.587558755875591, 51.290929092909288],
            [3.622262226222624, 51.28442844284428],
            [3.656965696569664, 51.238923892389238],
            [3.873862386238628, 51.232423242324231],
            [4.021352135213526, 51.238923892389238],
            [4.108110811081112, 51.271427142714273],
            [4.151490149014904, 51.290929092909288],
            [4.203545354535457, 51.323432343234323],
            [4.220897089708977, 51.336433643364337],
            [4.23824882488249, 51.355935593559352],
            [4.281628162816283, 51.368936893689366],
            [4.333683368336835, 51.368936893689366],
            [4.351035103510355, 51.368936893689366],
            [4.342359235923595, 51.375437543754373],
            [4.333683368336835, 51.388438843884387],
            [4.368386838683875, 51.407940794079408],
            [4.385738573857388, 51.446944694469444],
            [4.507200720072014, 51.466446644664465],
            [4.515876587658767, 51.466446644664465],
            [4.541904190419046, 51.459945994599458],
            [4.533228322832286, 51.427442744274423],
            [4.550580058005806, 51.427442744274423],
            [4.576607660766079, 51.43394339433943],
            [4.637338733873392, 51.427442744274423],
            [4.680718071807185, 51.427442744274423],
            [4.802180218021803, 51.505450545054501],
            [4.810856085608563, 51.498949894989501],
            [4.836883688368843, 51.414441444144416],
            [4.906290629062909, 51.414441444144416],
            [4.967021702170221, 51.440444044404437],
            [4.993049304930494, 51.472947294729472],
            [5.019076907690774, 51.472947294729472],
            [5.062456245624567, 51.492449244924494],
            [5.08848384838484, 51.459945994599458],
            [5.0971597159716, 51.420942094209423],
            [5.10583558355836, 51.414441444144416],
            [5.131863186318633, 51.336433643364337],
            [5.235973597359738, 51.310431043104309],
            [5.279352935293531, 51.264926492649266],
            [5.340084008400844, 51.264926492649266],
            [5.383463346334636, 51.271427142714273],
            [5.418166816681669, 51.264926492649266],
            [5.426842684268429, 51.264926492649266],
            [5.496249624962502, 51.290929092909288],
            [5.548304830483055, 51.271427142714273],
            [5.556980698069815, 51.258425842584259],
            [5.600360036003607, 51.212921292129209],
            [5.721822182218226, 51.193419341934195],
            [5.773877387738779, 51.180418041804181],
            [5.791229122912299, 51.134913491349131],
            [5.791229122912299, 51.06340634063406],
            [5.765201520152019, 50.998399839983996],
            [5.747849784978499, 50.952895289528954],
            [5.739173917391746, 50.933393339333932],
            [5.678442844284433, 50.88788878887889],
            [5.6437393739374, 50.84238423842384],
            [5.652415241524153, 50.835883588358833],
            [5.652415241524153, 50.822882288228818],
            [5.695794579457953, 50.764376437643762],
            [5.843284328432844, 50.764376437643762],
            [5.860636063606364, 50.764376437643762],
            [5.912691269126917, 50.74487448744874],
            [5.930043004300437, 50.757875787578755],
            [5.999449944994502, 50.751375137513747],
            [6.025477547754782, 50.757875787578755],
            [6.034153415341535, 50.751375137513747],
            [6.077532753275335, 50.725372537253726],
            [6.138263826382641, 50.692869286928691],
            [6.138263826382641, 50.686368636863683],
            [6.207670767076714, 50.634363436343634],
            [6.216346634663473, 50.634363436343634],
            [6.277077707770779, 50.627862786278627],
            [6.251050105010506, 50.601860186018598],
            [6.242374237423746, 50.595359535953591],
            [6.233698369836986, 50.582358235823584],
            [6.198994899489954, 50.56935693569357],
            [6.233698369836986, 50.497849784978499],
            [6.329132913291332, 50.491349134913492],
            [6.337808780878092, 50.47184718471847],
            [6.372512251225125, 50.41984198419842],
            [6.398539853985405, 50.341834183418342],
            [6.398539853985405, 50.335333533353335],
            [6.372512251225125, 50.315831583158314],
            [6.285753575357539, 50.283328332833278],
            [6.277077707770779, 50.270327032703271],
            [6.251050105010506, 50.263826382638264],
            [6.242374237423746, 50.263826382638264],
            [6.225022502250226, 50.237823782378236],
            [6.216346634663473, 50.231323132313229],
            [6.181643164316434, 50.218321832183214],
            [6.190319031903194, 50.179317931793179],
            [6.155615561556161, 50.159815981598157],
            [6.146939693969401, 50.146814681468143],
            [6.138263826382641, 50.127312731273129],
            [6.120912091209128, 50.075307530753072],
            [6.129587958795881, 50.068806880688065],
            [6.164291429142921, 49.97779777977798],
            [6.164291429142921, 49.971297129712973],
            [6.198994899489954, 49.958295829582958],
            [6.198994899489954, 49.951795179517951],
            [6.216346634663473, 49.951795179517951],
            [6.225022502250226, 49.925792579257923],
            [6.242374237423746, 49.893289328932894],
            [6.355160516051612, 49.860786078607859],
            [6.398539853985405, 49.860786078607859],
            [6.381188118811885, 49.834783478347831],
            [6.424567456745677, 49.815281528152816],
            [6.537353735373543, 49.769776977697767],
            [6.520002000200023, 49.730773077307731],
            [6.45927092709271, 49.691769176917688],
            [6.433243324332437, 49.665766576657667],
            [6.424567456745677, 49.639763976397639],
            [6.381188118811885, 49.600760076007596],
            [6.381188118811885, 49.581258125812582],
            [6.398539853985405, 49.464246424642461],
            [6.389863986398645, 49.464246424642461],
            [6.346484648464852, 49.464246424642461],
            [6.225022502250226, 49.509750975097511],
            [6.207670767076714, 49.503250325032504],
            [6.181643164316434, 49.496749674967496],
            [6.112236223622368, 49.477247724772475],
            [6.051505150515055, 49.464246424642461],
            [5.999449944994502, 49.457745774577454],
            [5.843284328432844, 49.529252925292525],
            [5.834608460846091, 49.542254225422539],
            [5.825932593259331, 49.542254225422539],
            [5.817256725672571, 49.548754875487546],
            [5.756525652565259, 49.542254225422539],
            [5.747849784978499, 49.542254225422539],
            [5.574332433243328, 49.529252925292525],
            [5.435518551855189, 49.516251625162518],
            [5.452870287028709, 49.548754875487546],
            [5.444194419441949, 49.568256825682568],
            [5.418166816681669, 49.600760076007596],
            [5.383463346334636, 49.61376137613761],
            [5.374787478747876, 49.626762676267624],
            [5.314056405640571, 49.646264626462646],
            [5.288028802880291, 49.678767876787674],
            [5.262001200120018, 49.691769176917688],
            [5.166566656665672, 49.717771777177717],
            [5.12318731873188, 49.737273727372738],
            [5.11451145114512, 49.737273727372738],
            [4.984373437343741, 49.802280228022802],
            [4.940994099409949, 49.802280228022802],
            [4.854235423542356, 49.802280228022802],
            [4.854235423542356, 49.860786078607859],
            [4.862911291129116, 49.98429842984298],
            [4.567931793179319, 49.97779777977798],
            [4.533228322832286, 49.964796479647966],
            [4.481173117311734, 49.951795179517951],
            [4.368386838683875, 49.964796479647966],
            [4.151490149014904, 49.97779777977798],
            [4.142814281428144, 49.97779777977798],
            [4.168841884188424, 50.146814681468143],
            [4.177517751775184, 50.224822482248221],
            [4.116786678667872, 50.296329632963293],
            [4.090759075907592, 50.315831583158314],
            [3.969296929692973, 50.328832883288328],
            [3.92591759175918, 50.335333533353335],
            [3.873862386238628, 50.341834183418342],
            [3.787103710371042, 50.341834183418342],
            [3.674317431743177, 50.341834183418342],
            [3.665641564156417, 50.348334833483349],
            [3.665641564156417, 50.41984198419842],
            [3.648289828982904, 50.452345234523449],
            [3.630938093809384, 50.47184718471847],
            [3.604910491049111, 50.484848484848484],
            [3.552855285528558, 50.497849784978499],
            [3.509475947594765, 50.52385238523852],
            [3.483448344834486, 50.517351735173513],
            [3.37933793379338, 50.497849784978499],
            [3.361986198619867, 50.517351735173513],
            [3.283903390339034, 50.595359535953591],
            [3.266551655165522, 50.608360836083605],
            [3.153765376537656, 50.783878387838783],
            [2.910841084108412, 50.692869286928691],
            [2.902165216521659, 50.699369936993698],
            [2.650565056505656, 50.809880988098811],
            [2.650565056505656, 50.816381638163811],
            [2.589833983398343, 51.01140114011401],
            [2.54645464546455, 51.082908290829081],
            [2.242799279927993, 51.102410241024103],
            [1.999874987498757, 51.115411541154117],
            [1.999874987498757, 51.128412841284124],
            [1.999874987498757, 51.199919991999195],
            [1.999874987498757, 51.212921292129209],
            [1.999874987498757, 51.245424542454245],
            [1.999874987498757, 51.362436243624359],
            [1.999874987498757, 51.38193819381938],
            [1.999874987498757, 51.401440144014401],
            [1.999874987498757, 51.440444044404437],
            [1.999874987498757, 51.492449244924494],
            [1.999874987498757, 51.498949894989501],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LAAAFIR", name: "TIRANA FIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.995899589959002, 40.415341534153413],
            [18.995899589959002, 40.597359735973598],
            [18.995899589959002, 40.746874687468747],
            [18.952520252025209, 40.889888988898889],
            [18.865761576157624, 41.130413041304131],
            [19.100010001000108, 41.455445544554451],
            [19.1433893389339, 41.552955295529557],
            [19.316906690669072, 41.77397739773977],
            [19.368961896189624, 41.851985198519856],
            [19.377637763776384, 41.864986498649863],
            [19.386313631363144, 41.968996899689969],
            [19.386313631363144, 42.131513151315133],
            [19.334258425842592, 42.229022902290225],
            [19.533803380338043, 42.45654565456546],
            [19.603210321032108, 42.534553455345531],
            [19.750700070007007, 42.664566456645659],
            [19.854810481048112, 42.476047604760474],
            [19.863486348634872, 42.469546954695467],
            [20.141114111411149, 42.51505150515051],
            [20.496824682468251, 42.281028102810282],
            [20.592259225922596, 41.877987798779877],
            [20.531528152815284, 41.669966996699671],
            [20.496824682468251, 41.435943594359436],
            [20.496824682468251, 41.33193319331933],
            [20.85253525352536, 40.902890289028903],
            [21.000025002500259, 40.863886388638861],
            [20.956645664566466, 40.831383138313825],
            [21.052080208020804, 40.616861686168619],
            [21.034728472847291, 40.584358435843583],
            [20.956645664566466, 40.480348034803484],
            [20.791804180418048, 40.441344134413441],
            [20.705045504550462, 40.272327232723271],
            [20.670342034203429, 40.096809680968093],
            [20.592259225922596, 40.070807080708065],
            [20.470797079707978, 40.064306430643065],
            [20.401390139013905, 40.057805780578057],
            [20.392714271427145, 39.979797979797979],
            [20.279927992799287, 39.986298629862986],
            [20.366686668666873, 39.817281728172816],
            [20.2972797279728, 39.817281728172816],
            [20.245224522452254, 39.706770677067709],
            [20.210521052105214, 39.641764176417638],
            [20.123762376237629, 39.667766776677666],
            [20.00230023002301, 39.680768076807681],
            [19.97627262726273, 39.83028302830283],
            [19.846134613461352, 39.901790179017901],
            [19.750700070007007, 39.947294729472944],
            [19.585858585858588, 39.973297329732972],
            [19.585858585858588, 39.979797979797979],
            [19.44704470447045, 39.999299929993001],
            [18.995899589959002, 40.415341534153413],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "GMMMFIR", name: "CASABLANCA FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.103810381038102, 35.832383238323828],
            [-2.251300130013, 35.000300030003004],
            [-1.843534353435338, 34.850785078507855],
            [-1.626637663766374, 33.888688868886888],
            [-1.262251225122512, 32.998099809980999],
            [-1.080058005800574, 32.419541954195417],
            [-1.166816681668166, 31.996999699969997],
            [-2.954045404540452, 32.081508150815083],
            [-2.919341934193419, 31.749974997499748],
            [-3.648114811481143, 31.366436643664365],
            [-5.6522402240224, 30.31983198319832],
            [-7.621662166216616, 29.253725372537254],
            [-8.662766276627661, 28.668666866686667],
            [-8.662766276627661, 27.667566756675669],
            [-9.139938993899388, 27.667566756675669],
            [-10.519401940194015, 27.667566756675669],
            [-11.230823082308227, 27.667566756675669],
            [-12.592934293429341, 27.667566756675669],
            [-13.165541554155414, 27.667566756675669],
            [-13.000700070006999, 28.252625262526252],
            [-12.818506850685065, 28.909190919091909],
            [-12.714396439643963, 29.247224722472247],
            [-12.575582558255824, 29.72827282728273],
            [-12.497499749974995, 30.001300130013],
            [-12.714396439643963, 30.105310531053107],
            [-13.139513951395138, 30.306830683068306],
            [-13.573307330733069, 30.508350835083508],
            [-13.850935093509349, 30.63836383638364],
            [-13.989748974897488, 30.703370337033704],
            [-14.414866486648663, 30.898389838983896],
            [-14.857335733573354, 31.099909990999102],
            [-15.299804980498049, 31.301430143014301],
            [-15.7509500950095, 31.5029502950295],
            [-14.805280528052801, 31.996999699969997],
            [-14.614411441144114, 32.250525052505253],
            [-14.119886988698866, 32.998099809980999],
            [-13.425817581758174, 33.999199919991995],
            [-12.714396439643963, 35.000300030003004],
            [-12.315306530653064, 35.546354635463544],
            [-12.002975297529751, 35.968896889688963],
            [-11.447719771977194, 35.968896889688963],
            [-10.736298629862983, 35.968896889688963],
            [-10.024877487748771, 35.968896889688963],
            [-9.57373237323732, 35.968896889688963],
            [-9.547704770477043, 35.968896889688963],
            [-9.374187418741872, 35.968896889688963],
            [-9.25272527252725, 35.968896889688963],
            [-8.949069906990697, 35.968896889688963],
            [-8.315731573157311, 35.968896889688963],
            [-8.003400340034002, 35.968896889688963],
            [-7.88193819381938, 35.968896889688963],
            [-7.612986298629863, 35.968896889688963],
            [-7.387413741374132, 35.968896889688963],
            [-7.387413741374132, 35.832383238323828],
            [-6.996999699969997, 35.832383238323828],
            [-6.754075407540753, 35.832383238323828],
            [-6.719371937193714, 35.832383238323828],
            [-6.632613261326128, 35.832383238323828],
            [-6.450420042004197, 35.832383238323828],
            [-6.320282028202818, 35.832383238323828],
            [-6.242199219921986, 35.832383238323828],
            [-5.955895589558956, 35.832383238323828],
            [-5.695619561956192, 35.832383238323828],
            [-5.66959195919592, 35.832383238323828],
            [-5.140364036403639, 35.832383238323828],
            [-5.123012301230119, 35.832383238323828],
            [-4.897439743974395, 35.832383238323828],
            [-4.368211821182115, 35.832383238323828],
            [-4.003825382538253, 35.832383238323828],
            [-3.96044604460446, 35.832383238323828],
            [-3.700170017001696, 35.832383238323828],
            [-3.500625062506245, 35.832383238323828],
            [-3.439893989398939, 35.832383238323828],
            [-3.431218121812179, 35.832383238323828],
            [-3.266376637663761, 35.832383238323828],
            [-3.231673167316728, 35.832383238323828],
            [-3.049479947994797, 35.832383238323828],
            [-2.685093509350935, 35.832383238323828],
            [-2.112486248624862, 35.832383238323828],
            [-2.103810381038102, 35.832383238323828],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LCCCFIR", name: "NICOSIA FIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.670917091709171, 35.916891689168921],
            [35.53210321032104, 35.552855285528551],
            [35.714296429642971, 35.195319531953196],
            [35.748999899990011, 34.584258425842584],
            [35.289178917891803, 34.538753875387542],
            [35.046254625462552, 34.480248024802478],
            [34.872737273727381, 34.369736973697371],
            [34.742599259925996, 34.24622462246225],
            [34.621137113711384, 34.03170317031703],
            [34.603785378537864, 33.947194719471945],
            [34.595109510951104, 33.888688868886888],
            [34.586433643364344, 33.830183018301831],
            [34.612461246124624, 33.648164816481646],
            [34.933468346834687, 33.16711671167117],
            [34.864061406140621, 33.102110211021099],
            [34.603785378537864, 33.089108910891085],
            [34.551730173017305, 32.855085508550857],
            [34.543054305430545, 32.783578357835779],
            [34.352185218521853, 32.614561456145616],
            [34.196019601960202, 32.491049104910488],
            [34.169991999199922, 32.497549754975495],
            [34.057205720572071, 32.335033503350331],
            [33.996474647464751, 32.172517251725168],
            [33.979122912291231, 31.834483448344834],
            [33.102860286028616, 32.218021802180218],
            [32.07910791079108, 32.63406340634063],
            [31.662666266626672, 32.809580958095808],
            [30.951245124512454, 33.102110211021099],
            [29.996899689968998, 33.498649864986497],
            [29.996899689968998, 33.732673267326732],
            [29.996899689968998, 34.324232423242321],
            [29.996899689968998, 34.499749974997499],
            [29.996899689968998, 34.584258425842584],
            [29.996899689968998, 34.649264926492648],
            [29.996899689968998, 34.870287028702869],
            [29.996899689968998, 34.915791579157911],
            [29.996899689968998, 34.967796779677968],
            [29.996899689968998, 35.201820182018196],
            [29.996899689968998, 35.585358535853587],
            [29.996899689968998, 35.663366336633658],
            [29.996899689968998, 35.832383238323828],
            [29.996899689968998, 36.079407940794077],
            [30.708320832083217, 36.059905990599063],
            [30.821107110711083, 36.040404040404042],
            [31.549879987998807, 36.027402740274027],
            [32.99874987498751, 35.949394939493949],
            [33.285053505350547, 35.936393639363935],
            [33.319756975697572, 35.929892989298928],
            [33.441219121912198, 35.923392339233928],
            [33.545329532953303, 35.916891689168921],
            [34.013826382638271, 35.916891689168921],
            [34.66451645164517, 35.916891689168921],
            [35.670917091709171, 35.916891689168921],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UKFVFIR", name: "SIMFEROPOL FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.269551955195524, 42.72957295729573],
            [35.002875287528767, 42.749074907490751],
            [34.091909190919097, 42.78157815781578],
            [33.180943094309441, 42.801080108010801],
            [31.948969896989709, 42.801080108010801],
            [31.003300330033014, 42.801080108010801],
            [30.751700170017003, 42.801080108010801],
            [30.638913891389151, 43.256125612561256],
            [30.534803480348046, 43.685168516851682],
            [30.508775877587766, 43.802180218021803],
            [30.4393689368937, 44.088208820882087],
            [30.42201720172018, 44.166216621662166],
            [30.3959895989599, 44.250725072507251],
            [30.265851585158529, 44.497749774977493],
            [30.231148114811489, 44.608260826082606],
            [30.170417041704184, 44.712271227122713],
            [30.153065306530664, 44.764276427642763],
            [30.083658365836584, 44.913791379137912],
            [30.361286128612875, 44.874787478747876],
            [30.560831083108312, 44.978797879787976],
            [31.107410741074119, 45.258325832583253],
            [31.419741974197422, 45.407840784078402],
            [31.506500650065007, 45.453345334533452],
            [31.888238823882389, 45.641864186418644],
            [32.0964596459646, 45.745874587458744],
            [32.235273527352746, 45.810881088108815],
            [32.391439143914397, 45.882388238823879],
            [32.452170217021703, 45.914891489148914],
            [32.911991199119925, 46.12291229122912],
            [32.903315331533165, 46.213921392139213],
            [32.885963596359645, 46.733973397339732],
            [32.93801880188019, 47.08500850085008],
            [32.93801880188019, 47.098009800980094],
            [33.050805080508056, 47.735073507350734],
            [33.206970697069707, 47.709070907090705],
            [33.224322432243227, 47.702570257025698],
            [33.250350035003507, 47.702570257025698],
            [33.259025902590267, 47.696069606960691],
            [33.658115811581169, 47.637563756375641],
            [33.753550355035514, 47.61806180618062],
            [33.927067706770686, 47.592059205920592],
            [34.213371337133722, 47.514051405140513],
            [34.742599259925996, 47.35153515351535],
            [35.254475447544763, 47.195519551955194],
            [35.54945494549456, 47.104510451045101],
            [36.009275927592768, 46.967996799679966],
            [36.096034603460353, 46.915991599159916],
            [36.564531453145321, 46.655965596559653],
            [36.703345334533466, 46.584458445844582],
            [37.371387138713885, 46.571457145714575],
            [36.798779877987812, 45.635363536353637],
            [36.712021202120212, 45.505350535053509],
            [36.685993599359946, 45.446844684468445],
            [36.685993599359946, 45.375337533753374],
            [36.642614261426147, 45.349334933493353],
            [36.590559055905601, 45.303830383038303],
            [36.599234923492361, 45.245324532453239],
            [36.529827982798281, 45.206320632063203],
            [36.547179717971801, 45.160816081608161],
            [36.547179717971801, 45.154315431543154],
            [36.573207320732081, 45.069806980698068],
            [36.590559055905601, 44.998299829982997],
            [36.607910791079121, 44.939793979397933],
            [36.625262526252627, 44.829282928292827],
            [36.668641864186426, 44.517251725172514],
            [36.633938393839387, 44.348234823482343],
            [36.555855585558561, 44.036203620362031],
            [36.495124512451255, 43.743674367436739],
            [36.38233823382339, 43.249624962496249],
            [36.269551955195524, 42.72957295729573],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EBURUIR", name: "BRUSSELS UIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.999874987498757, 51.498949894989501],
            [2.086633663366342, 51.492449244924494],
            [2.277502750275033, 51.472947294729472],
            [2.320882088208826, 51.472947294729472],
            [2.503075307530757, 51.453445344534451],
            [3.006275627562758, 51.407940794079408],
            [3.370662066206627, 51.368936893689366],
            [3.37933793379338, 51.28442844284428],
            [3.40536553655366, 51.271427142714273],
            [3.466096609660973, 51.238923892389238],
            [3.518151815181525, 51.264926492649266],
            [3.561531153115318, 51.290929092909288],
            [3.587558755875591, 51.290929092909288],
            [3.622262226222624, 51.28442844284428],
            [3.656965696569664, 51.238923892389238],
            [3.873862386238628, 51.232423242324231],
            [4.021352135213526, 51.238923892389238],
            [4.108110811081112, 51.271427142714273],
            [4.151490149014904, 51.290929092909288],
            [4.203545354535457, 51.323432343234323],
            [4.220897089708977, 51.336433643364337],
            [4.23824882488249, 51.355935593559352],
            [4.281628162816283, 51.368936893689366],
            [4.333683368336835, 51.368936893689366],
            [4.351035103510355, 51.368936893689366],
            [4.342359235923595, 51.375437543754373],
            [4.333683368336835, 51.388438843884387],
            [4.368386838683875, 51.407940794079408],
            [4.385738573857388, 51.446944694469444],
            [4.507200720072014, 51.466446644664465],
            [4.515876587658767, 51.466446644664465],
            [4.541904190419046, 51.459945994599458],
            [4.533228322832286, 51.427442744274423],
            [4.550580058005806, 51.427442744274423],
            [4.576607660766079, 51.43394339433943],
            [4.637338733873392, 51.427442744274423],
            [4.680718071807185, 51.427442744274423],
            [4.802180218021803, 51.505450545054501],
            [4.810856085608563, 51.498949894989501],
            [4.836883688368843, 51.414441444144416],
            [4.906290629062909, 51.414441444144416],
            [4.967021702170221, 51.440444044404437],
            [4.993049304930494, 51.472947294729472],
            [5.019076907690774, 51.472947294729472],
            [5.062456245624567, 51.492449244924494],
            [5.08848384838484, 51.459945994599458],
            [5.0971597159716, 51.420942094209423],
            [5.10583558355836, 51.414441444144416],
            [5.131863186318633, 51.336433643364337],
            [5.235973597359738, 51.310431043104309],
            [5.279352935293531, 51.264926492649266],
            [5.340084008400844, 51.264926492649266],
            [5.383463346334636, 51.271427142714273],
            [5.418166816681669, 51.264926492649266],
            [5.426842684268429, 51.264926492649266],
            [5.496249624962502, 51.290929092909288],
            [5.548304830483055, 51.271427142714273],
            [5.556980698069815, 51.258425842584259],
            [5.600360036003607, 51.212921292129209],
            [5.721822182218226, 51.193419341934195],
            [5.773877387738779, 51.180418041804181],
            [5.791229122912299, 51.134913491349131],
            [5.791229122912299, 51.06340634063406],
            [5.765201520152019, 50.998399839983996],
            [5.747849784978499, 50.952895289528954],
            [5.739173917391746, 50.933393339333932],
            [5.678442844284433, 50.88788878887889],
            [5.6437393739374, 50.84238423842384],
            [5.652415241524153, 50.835883588358833],
            [5.652415241524153, 50.822882288228818],
            [5.695794579457953, 50.764376437643762],
            [5.843284328432844, 50.764376437643762],
            [5.860636063606364, 50.764376437643762],
            [5.912691269126917, 50.74487448744874],
            [5.930043004300437, 50.757875787578755],
            [5.999449944994502, 50.751375137513747],
            [6.025477547754782, 50.757875787578755],
            [6.034153415341535, 50.751375137513747],
            [6.077532753275335, 50.725372537253726],
            [6.138263826382641, 50.692869286928691],
            [6.138263826382641, 50.686368636863683],
            [6.207670767076714, 50.634363436343634],
            [6.216346634663473, 50.634363436343634],
            [6.277077707770779, 50.627862786278627],
            [6.251050105010506, 50.601860186018598],
            [6.242374237423746, 50.595359535953591],
            [6.233698369836986, 50.582358235823584],
            [6.198994899489954, 50.56935693569357],
            [6.233698369836986, 50.497849784978499],
            [6.329132913291332, 50.491349134913492],
            [6.337808780878092, 50.47184718471847],
            [6.372512251225125, 50.41984198419842],
            [6.398539853985405, 50.341834183418342],
            [6.398539853985405, 50.335333533353335],
            [6.372512251225125, 50.315831583158314],
            [6.285753575357539, 50.283328332833278],
            [6.277077707770779, 50.270327032703271],
            [6.251050105010506, 50.263826382638264],
            [6.242374237423746, 50.263826382638264],
            [6.225022502250226, 50.237823782378236],
            [6.216346634663473, 50.231323132313229],
            [6.181643164316434, 50.218321832183214],
            [6.190319031903194, 50.179317931793179],
            [6.155615561556161, 50.159815981598157],
            [6.146939693969401, 50.146814681468143],
            [6.138263826382641, 50.127312731273129],
            [6.120912091209128, 50.075307530753072],
            [6.129587958795881, 50.068806880688065],
            [6.164291429142921, 49.97779777977798],
            [6.164291429142921, 49.971297129712973],
            [6.198994899489954, 49.958295829582958],
            [6.198994899489954, 49.951795179517951],
            [6.216346634663473, 49.951795179517951],
            [6.225022502250226, 49.925792579257923],
            [6.242374237423746, 49.893289328932894],
            [6.355160516051612, 49.860786078607859],
            [6.398539853985405, 49.860786078607859],
            [6.381188118811885, 49.834783478347831],
            [6.424567456745677, 49.815281528152816],
            [6.537353735373543, 49.769776977697767],
            [6.520002000200023, 49.730773077307731],
            [6.45927092709271, 49.691769176917688],
            [6.433243324332437, 49.665766576657667],
            [6.424567456745677, 49.639763976397639],
            [6.381188118811885, 49.600760076007596],
            [6.381188118811885, 49.581258125812582],
            [6.398539853985405, 49.464246424642461],
            [6.389863986398645, 49.464246424642461],
            [6.346484648464852, 49.464246424642461],
            [6.225022502250226, 49.509750975097511],
            [6.207670767076714, 49.503250325032504],
            [6.181643164316434, 49.496749674967496],
            [6.112236223622368, 49.477247724772475],
            [6.051505150515055, 49.464246424642461],
            [5.999449944994502, 49.457745774577454],
            [5.843284328432844, 49.529252925292525],
            [5.834608460846091, 49.542254225422539],
            [5.825932593259331, 49.542254225422539],
            [5.817256725672571, 49.548754875487546],
            [5.756525652565259, 49.542254225422539],
            [5.747849784978499, 49.542254225422539],
            [5.574332433243328, 49.529252925292525],
            [5.435518551855189, 49.516251625162518],
            [5.452870287028709, 49.548754875487546],
            [5.444194419441949, 49.568256825682568],
            [5.418166816681669, 49.600760076007596],
            [5.383463346334636, 49.61376137613761],
            [5.374787478747876, 49.626762676267624],
            [5.314056405640571, 49.646264626462646],
            [5.288028802880291, 49.678767876787674],
            [5.262001200120018, 49.691769176917688],
            [5.166566656665672, 49.717771777177717],
            [5.12318731873188, 49.737273727372738],
            [5.11451145114512, 49.737273727372738],
            [4.984373437343741, 49.802280228022802],
            [4.940994099409949, 49.802280228022802],
            [4.854235423542356, 49.802280228022802],
            [4.854235423542356, 49.860786078607859],
            [4.862911291129116, 49.98429842984298],
            [4.567931793179319, 49.97779777977798],
            [4.533228322832286, 49.964796479647966],
            [4.481173117311734, 49.951795179517951],
            [4.368386838683875, 49.964796479647966],
            [4.151490149014904, 49.97779777977798],
            [4.142814281428144, 49.97779777977798],
            [4.168841884188424, 50.146814681468143],
            [4.177517751775184, 50.224822482248221],
            [4.116786678667872, 50.296329632963293],
            [4.090759075907592, 50.315831583158314],
            [3.969296929692973, 50.328832883288328],
            [3.92591759175918, 50.335333533353335],
            [3.873862386238628, 50.341834183418342],
            [3.787103710371042, 50.341834183418342],
            [3.674317431743177, 50.341834183418342],
            [3.665641564156417, 50.348334833483349],
            [3.665641564156417, 50.41984198419842],
            [3.648289828982904, 50.452345234523449],
            [3.630938093809384, 50.47184718471847],
            [3.604910491049111, 50.484848484848484],
            [3.552855285528558, 50.497849784978499],
            [3.509475947594765, 50.52385238523852],
            [3.483448344834486, 50.517351735173513],
            [3.37933793379338, 50.497849784978499],
            [3.361986198619867, 50.517351735173513],
            [3.283903390339034, 50.595359535953591],
            [3.266551655165522, 50.608360836083605],
            [3.153765376537656, 50.783878387838783],
            [2.910841084108412, 50.692869286928691],
            [2.902165216521659, 50.699369936993698],
            [2.650565056505656, 50.809880988098811],
            [2.650565056505656, 50.816381638163811],
            [2.589833983398343, 51.01140114011401],
            [2.54645464546455, 51.082908290829081],
            [2.242799279927993, 51.102410241024103],
            [1.999874987498757, 51.115411541154117],
            [1.999874987498757, 51.128412841284124],
            [1.999874987498757, 51.199919991999195],
            [1.999874987498757, 51.212921292129209],
            [1.999874987498757, 51.245424542454245],
            [1.999874987498757, 51.362436243624359],
            [1.999874987498757, 51.38193819381938],
            [1.999874987498757, 51.401440144014401],
            [1.999874987498757, 51.440444044404437],
            [1.999874987498757, 51.492449244924494],
            [1.999874987498757, 51.498949894989501],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EISNFIR", name: "SHANNON FIR", fab: "9" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.496074607460741, 53.917191719171917],
            [-5.496074607460741, 53.806680668066804],
            [-5.496074607460741, 53.767676767676768],
            [-5.496074607460741, 53.761176117611761],
            [-5.496074607460741, 53.68966896689669],
            [-5.496074607460741, 53.683168316831683],
            [-5.496074607460741, 53.663666366636662],
            [-5.496074607460741, 53.494649464946491],
            [-5.496074607460741, 53.481648164816477],
            [-5.496074607460741, 53.462146214621463],
            [-5.496074607460741, 53.384138413841384],
            [-5.496074607460741, 53.286628662866285],
            [-5.496074607460741, 53.280128012801278],
            [-5.496074607460741, 53.273627362736271],
            [-5.496074607460741, 53.247624762476249],
            [-5.496074607460741, 53.195619561956192],
            [-5.496074607460741, 53.182618261826178],
            [-5.496074607460741, 53.176117611761171],
            [-5.496074607460741, 53.085108510851086],
            [-5.496074607460741, 53.065606560656065],
            [-5.496074607460741, 53.05260526052605],
            [-5.496074607460741, 52.948594859485944],
            [-5.496074607460741, 52.753575357535752],
            [-5.496074607460741, 52.740574057405738],
            [-5.496074607460741, 52.669066906690666],
            [-5.496074607460741, 52.55855585558556],
            [-5.496074607460741, 52.552055205520553],
            [-5.496074607460741, 52.415541554155411],
            [-5.496074607460741, 52.331033103310332],
            [-5.66959195919592, 52.246524652465247],
            [-5.678267826782673, 52.24002400240024],
            [-5.686943694369432, 52.24002400240024],
            [-5.825757575757571, 52.168516851685169],
            [-5.843109310931091, 52.155515551555155],
            [-5.851785178517851, 52.155515551555155],
            [-6.120737073707367, 52.012501250125013],
            [-6.233523352335233, 51.960496049604956],
            [-6.242199219921986, 51.953995399539956],
            [-6.250875087508746, 51.947494749474949],
            [-6.407040704070404, 51.86948694869487],
            [-6.563206320632062, 51.784978497849785],
            [-6.710696069606961, 51.706970697069707],
            [-6.866861686168612, 51.615961596159615],
            [-7.092434243424343, 51.498949894989501],
            [-7.231248124812481, 51.427442744274423],
            [-7.413441344134412, 51.323432343234323],
            [-7.491524152415238, 51.277927792779273],
            [-7.508875887588758, 51.271427142714273],
            [-7.569606960696063, 51.238923892389238],
            [-7.682393239323929, 51.180418041804181],
            [-8.003400340034002, 50.998399839983996],
            [-8.497924792479246, 50.998399839983996],
            [-12.002975297529751, 50.998399839983996],
            [-12.497499749974995, 50.998399839983996],
            [-13.998424842484248, 50.998399839983996],
            [-14.996149614961492, 50.998399839983996],
            [-14.996149614961492, 51.251925192519252],
            [-14.996149614961492, 51.498949894989501],
            [-14.996149614961492, 51.75247524752475],
            [-14.996149614961492, 51.999499949994998],
            [-14.996149614961492, 52.064506450645062],
            [-14.996149614961492, 52.253025302530254],
            [-14.996149614961492, 52.500050005000496],
            [-14.996149614961492, 52.747074707470745],
            [-14.996149614961492, 53.000600060006001],
            [-14.996149614961492, 53.247624762476249],
            [-14.996149614961492, 53.501150115011498],
            [-14.996149614961492, 53.696169616961697],
            [-14.996149614961492, 53.748174817481747],
            [-14.996149614961492, 54.001700170017003],
            [-13.503900390039, 54.18371837183718],
            [-10.666891689168914, 54.5022502250225],
            [-9.998849884988495, 54.567256725672564],
            [-9.556380638063803, 54.65176517651765],
            [-8.949069906990697, 54.788278827882785],
            [-8.801580158015799, 54.905290529052905],
            [-8.740849084908486, 54.950795079507948],
            [-8.541304130413039, 55.106810681068104],
            [-8.454545454545453, 55.178317831783176],
            [-8.385138513851381, 55.217321732173218],
            [-8.246324632463242, 55.334333433343332],
            [-8.064131413141311, 55.347334733473346],
            [-7.907965796579653, 55.366836683668367],
            [-7.33535853585358, 55.418841884188417],
            [-6.918916891689165, 55.334333433343332],
            [-6.936268626862685, 55.321332133213318],
            [-6.988323832383237, 55.282328232823282],
            [-7.049054905490543, 55.236823682368232],
            [-7.187868786878688, 55.13931393139314],
            [-7.283303330333027, 55.067806780678069],
            [-7.647689768976896, 54.801280128012799],
            [-7.838558855885587, 54.658265826582657],
            [-7.89061406140614, 54.625762576257621],
            [-8.055455545554551, 54.5022502250225],
            [-8.098834883488347, 54.463246324632465],
            [-8.168241824182417, 54.417741774177415],
            [-7.977372737273726, 54.385238523852387],
            [-7.89061406140614, 54.365736573657365],
            [-7.699744974497449, 54.33323332333233],
            [-7.396089608960892, 54.28122812281228],
            [-7.127137713771376, 54.22922292229223],
            [-6.910241024102405, 54.190219021902188],
            [-6.832158215821579, 54.177217721772173],
            [-6.762751275127506, 54.164216421642159],
            [-6.649964996499648, 54.138213821382138],
            [-6.407040704070404, 54.092709270927088],
            [-6.268226822682266, 54.066706670667067],
            [-6.207495749574953, 54.053705370537052],
            [-6.068681868186815, 54.027702770277024],
            [-6.042654265426542, 54.021202120212017],
            [-5.738998899889985, 53.96269626962696],
            [-5.496074607460741, 53.917191719171917],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EFINFIR", name: "FINLAND FIR", fab: "7" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.548879887988804, 69.057205720572057],
            [21.086783678367844, 69.05070507050705],
            [21.234273427342742, 69.206720672067206],
            [21.329707970797088, 69.317231723172313],
            [21.798204820482056, 69.174217421742171],
            [21.937018701870194, 69.063706370637064],
            [22.370812081208129, 68.73217321732173],
            [22.952095209520955, 68.693169316931687],
            [23.316481648164824, 68.660666066606666],
            [23.602785278527861, 68.751675167516751],
            [23.845709570957105, 68.836183618361844],
            [24.001875187518763, 68.79067906790678],
            [24.600510051005102, 68.634663466346638],
            [24.886813681368139, 68.556655665566552],
            [25.311931193119321, 68.823182318231829],
            [25.459420942094212, 68.914191419141915],
            [25.598234823482358, 68.998699869986993],
            [25.754400440044009, 69.330233023302327],
            [25.91924192419242, 69.668266826682668],
            [25.97997299729974, 69.694269426942697],
            [26.144814481448151, 69.759275927592753],
            [26.231573157315736, 69.791779177917789],
            [26.422442244224428, 69.908790879087917],
            [26.500525052505253, 69.91529152915291],
            [26.78682868286829, 69.954295429542952],
            [27.029752975297541, 69.91529152915291],
            [27.576332633263334, 70.077807780778073],
            [28.001450145014502, 70.103810381038102],
            [28.010126012601262, 70.097309730973095],
            [28.166291629162927, 69.91529152915291],
            [28.331133113311338, 69.889288928892881],
            [28.530678067806789, 69.798279827982796],
            [28.799629962996306, 69.752775277527746],
            [28.886388638863892, 69.739773977397732],
            [29.129312931293143, 69.700770077007689],
            [29.181368136813688, 69.64226422642264],
            [29.242099209920994, 69.577257725772569],
            [29.33753375337534, 69.486248624862483],
            [28.834333433343346, 69.21972197219722],
            [28.834333433343346, 69.128712871287121],
            [28.834333433343346, 69.102710271027092],
            [29.051230123012303, 69.018201820182014],
            [28.574057405740575, 68.940194019401929],
            [28.417891789178924, 68.914191419141915],
            [28.799629962996306, 68.875187518751872],
            [28.461271127112724, 68.504650465046495],
            [29.33753375337534, 68.101610161016097],
            [29.996899689968998, 67.698569856985699],
            [29.085933593359343, 66.963996399639967],
            [29.615161516151616, 66.69746974697469],
            [29.762651265126522, 66.398439843984391],
            [29.866761676167627, 66.164416441644164],
            [29.953520352035213, 66.021402140214022],
            [30.170417041704184, 65.663866386638659],
            [29.814706470647067, 65.585858585858574],
            [29.623837383738376, 65.059305930593055],
            [29.615161516151616, 64.935793579357934],
            [30.083658365836584, 64.701770177017693],
            [30.500100010001006, 64.233723372337238],
            [30.014251425142518, 63.733173317331733],
            [31.176817681768185, 63.239123912391236],
            [31.26357635763577, 63.148114811481143],
            [31.497824782478261, 62.998599859985994],
            [31.549879987998807, 62.953095309530951],
            [31.107410741074119, 62.420042004200418],
            [30.274527452745275, 61.919491949194921],
            [30.083658365836584, 61.8024802480248],
            [29.944844484448453, 61.724472447244722],
            [29.649864986498656, 61.548954895489544],
            [29.450320032003205, 61.43194319431943],
            [29.111961196119623, 61.256425642564253],
            [28.756250625062506, 61.074407440744075],
            [28.339808980898098, 60.859885988598855],
            [28.166291629162927, 60.749374937493748],
            [27.6457395739574, 60.346334633463343],
            [27.62838783878388, 60.333333333333329],
            [27.472222222222229, 60.235823582358236],
            [27.298704870487057, 60.203320332033201],
            [27.220622062206232, 60.196819681968194],
            [26.942994299429955, 60.170817081708172],
            [26.83888388838885, 60.164316431643165],
            [26.717421742174224, 60.151315131513151],
            [26.665366536653679, 60.144814481448144],
            [26.552580258025813, 60.13181318131813],
            [26.110111011101111, 59.975797579757973],
            [25.867186718671874, 59.884788478847881],
            [25.658965896589663, 59.891289128912888],
            [25.615586558655878, 59.897789778977895],
            [25.580883088308838, 59.897789778977895],
            [25.416041604160426, 59.904290429042902],
            [25.329282928292841, 59.910791079107909],
            [25.251200120012001, 59.904290429042902],
            [25.042979297929804, 59.891289128912888],
            [25.034303430343044, 59.891289128912888],
            [24.999599959996004, 59.891289128912888],
            [24.852110211021113, 59.884788478847881],
            [24.661241124112422, 59.845784578457845],
            [24.453020302030211, 59.800280028002796],
            [24.166716671667174, 59.741774177417739],
            [23.993199319931996, 59.702770277027703],
            [23.880413041304138, 59.676767676767675],
            [23.811006100610065, 59.66376637663766],
            [23.151640164016406, 59.514251425142511],
            [22.986798679867995, 59.475247524752476],
            [22.943419341934202, 59.462246224622461],
            [22.926067606760682, 59.462246224622461],
            [22.804605460546057, 59.436243624362433],
            [22.743874387438751, 59.423242324232419],
            [22.179942994299438, 59.286728672867284],
            [22.136563656365645, 59.280228022802277],
            [21.945694569456954, 59.234723472347234],
            [21.754825482548263, 59.189218921892184],
            [21.000025002500259, 59.000700070006999],
            [20.947969796979706, 59.026702670267028],
            [20.913266326632666, 59.046204620462042],
            [20.81783178317832, 59.104710471047106],
            [20.809155915591568, 59.111211121112106],
            [20.791804180418048, 59.117711771177113],
            [20.783128312831288, 59.12421242124212],
            [20.739748974897495, 59.143714371437142],
            [20.731073107310735, 59.156715671567156],
            [20.687693769376942, 59.176217621762177],
            [20.679017901790182, 59.182718271827184],
            [20.540204020402044, 59.254225422542255],
            [20.427417741774185, 59.319231923192319],
            [20.149789978997909, 59.475247524752476],
            [20.019651965196523, 59.54025402540254],
            [20.019651965196523, 59.546754675467547],
            [20.010976097609763, 59.546754675467547],
            [20.00230023002301, 59.553255325532554],
            [19.98494849484949, 59.559755975597561],
            [19.924217421742178, 59.605260526052604],
            [19.880838083808385, 59.637763776377639],
            [19.802755275527559, 59.696269626962696],
            [19.724672467246734, 59.748274827482746],
            [19.663941394139421, 59.787278727872788],
            [19.620562056205628, 59.819781978197817],
            [19.46439643964397, 59.930293029302931],
            [19.412341234123417, 59.969296929692966],
            [19.403665366536657, 59.975797579757973],
            [19.394989498949897, 59.98229822982298],
            [19.299554955495552, 60.047304730473044],
            [19.264851485148519, 60.066806680668066],
            [19.204120412041206, 60.105810581058101],
            [19.134713471347141, 60.157815781578158],
            [19.100010001000108, 60.183818381838179],
            [19.082658265826588, 60.190319031903186],
            [19.108685868586868, 60.25532553255325],
            [19.134713471347141, 60.3008300830083],
            [19.15206520652066, 60.359335933593357],
            [19.15206520652066, 60.372337233723371],
            [19.178092809280933, 60.469846984698471],
            [19.238823882388246, 60.697369736973698],
            [19.299554955495552, 61.002900290029004],
            [19.316906690669072, 61.067906790679068],
            [19.412341234123417, 61.360436043604359],
            [19.48174817481749, 61.607460746074608],
            [19.490424042404243, 61.620462046204615],
            [19.499099909991003, 61.665966596659665],
            [19.525127512751283, 61.717971797179715],
            [19.542479247924796, 61.763476347634764],
            [19.629237923792388, 61.997499749974999],
            [19.724672467246734, 62.192519251925191],
            [19.820107010701072, 62.39403940394039],
            [19.820107010701072, 62.413541354135411],
            [19.837458745874592, 62.465546554655461],
            [20.010976097609763, 62.829582958295831],
            [20.167141714171422, 63.167616761676165],
            [20.574907490749084, 63.42114211421142],
            [20.609610961096116, 63.434143414341435],
            [20.670342034203429, 63.466646664666463],
            [20.887238723872393, 63.512151215121513],
            [21.034728472847291, 63.538153815381534],
            [21.16486648664867, 63.564156415641563],
            [21.19089408940895, 63.564156415641563],
            [21.312356235623568, 63.583658365836584],
            [21.433818381838186, 63.603160316031598],
            [21.503225322532259, 63.616161616161612],
            [21.581308130813085, 63.681168116811676],
            [21.815556555655569, 63.863186318631861],
            [21.841584158415849, 63.882688268826882],
            [21.954370437043707, 63.960696069606961],
            [21.971722172217227, 63.973697369736975],
            [22.03245324532454, 64.025702570257025],
            [22.084508450845092, 64.064706470647053],
            [22.110536053605365, 64.090709070907081],
            [22.153915391539158, 64.11671167116711],
            [22.587708770877093, 64.448244824482444],
            [22.709170917091718, 64.532753275327536],
            [22.735198519851991, 64.552255225522543],
            [22.795929592959304, 64.597759775977593],
            [22.821957195719577, 64.617261726172615],
            [22.926067606760682, 64.682268226822686],
            [22.969446944694475, 64.701770177017693],
            [23.37721272127213, 65.000800080007991],
            [23.568081808180821, 65.143814381438148],
            [23.724247424742479, 65.254325432543254],
            [23.784978497849792, 65.286828682868276],
            [23.906440644064411, 65.371337133713368],
            [24.027902790279029, 65.45584558455846],
            [24.140689068906894, 65.527352735273524],
            [24.158040804080414, 65.592359235923595],
            [24.140689068906894, 65.767876787678773],
            [24.079957995799589, 65.943394339433951],
            [23.941144114411451, 66.0929092909291],
            [23.819681968196825, 66.177417741774178],
            [23.802330233023305, 66.196919691969185],
            [23.71557155715572, 66.268426842684264],
            [23.70689568956896, 66.274927492749271],
            [23.654840484048414, 66.32043204320432],
            [23.689543954395447, 66.385438543854377],
            [23.741599159915999, 66.46994699469947],
            [23.767626762676272, 66.521952195219512],
            [23.802330233023305, 66.573957395739569],
            [23.889088908890898, 66.736473647364733],
            [23.984523452345243, 66.801480148014804],
            [23.949819981998203, 66.840484048404846],
            [23.837033703370345, 66.931493149314932],
            [23.71557155715572, 67.035503550355031],
            [23.611461146114614, 67.217521752175216],
            [23.767626762676272, 67.33453345334533],
            [23.784978497849792, 67.425542554255429],
            [23.498674867486756, 67.692069206920692],
            [23.576757675767581, 67.893589358935884],
            [23.628812881288134, 67.965096509650962],
            [23.489998999899996, 68.023602360236026],
            [23.342509250925097, 68.127612761276126],
            [23.325157515751584, 68.14061406140614],
            [23.108260826082613, 68.257625762576254],
            [22.500950095009507, 68.439643964396438],
            [21.390439043904394, 68.771177117711773],
            [21.17354235423543, 68.836183618361844],
            [20.548879887988804, 69.057205720572057],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LYBAFIR", name: "BEOGRAD FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.030603060306035, 44.848784878487848],
            [19.013251325132522, 44.868286828682869],
            [19.091334133413348, 45.004800480048004],
            [19.073982398239828, 45.069806980698068],
            [19.073982398239828, 45.108810881088104],
            [19.073982398239828, 45.128312831283125],
            [19.108685868586868, 45.134813481348132],
            [19.247499749975006, 45.154315431543154],
            [19.290879087908799, 45.206320632063203],
            [19.368961896189624, 45.232323232323232],
            [19.247499749975006, 45.271327132713267],
            [18.995899589959002, 45.349334933493353],
            [19.013251325132522, 45.401340134013395],
            [19.091334133413348, 45.485848584858488],
            [19.134713471347141, 45.505350535053509],
            [19.056630663066315, 45.53135313531353],
            [18.752975297529758, 45.58335833583358],
            [18.961196119611969, 45.674367436743672],
            [18.935168516851689, 45.817381738173815],
            [18.813706370637071, 45.869386938693864],
            [18.848409840984104, 45.9018901890189],
            [18.926492649264929, 45.934393439343935],
            [19.082658265826588, 45.966896689668964],
            [19.282203220322039, 45.999399939993999],
            [19.351610161016104, 46.044904490449042],
            [19.48174817481749, 46.103410341034106],
            [19.533803380338043, 46.155415541554156],
            [19.603210321032108, 46.17491749174917],
            [19.698644864486454, 46.181418141814177],
            [19.724672467246734, 46.168416841684163],
            [19.768051805180527, 46.135913591359135],
            [19.872162216221625, 46.148914891489149],
            [19.941569156915698, 46.17491749174917],
            [20.097734773477356, 46.161916191619156],
            [20.262576257625767, 46.129412941294127],
            [20.626962696269629, 45.85638563856385],
            [21.138838883888397, 45.381838183818381],
            [21.312356235623568, 45.238823882388239],
            [21.407790779077914, 44.985298529852983],
            [21.433818381838186, 44.848784878487848],
            [21.529252925292532, 44.77077707770777],
            [22.457570757075715, 44.621262126212621],
            [22.639763976397646, 44.595259525952599],
            [22.55300530053006, 44.309230923092308],
            [22.665791579157919, 44.198719871987194],
            [22.613736373637373, 44.08170817081708],
            [22.431543154315435, 43.997199719971995],
            [22.388163816381642, 43.91269126912691],
            [22.370812081208129, 43.828182818281832],
            [22.362136213621369, 43.795679567956796],
            [22.414191419141922, 43.685168516851682],
            [22.500950095009507, 43.633163316331633],
            [22.492274227422747, 43.561656165616562],
            [22.52697769776978, 43.496649664966498],
            [22.665791579157919, 43.431643164316426],
            [22.769901990199024, 43.373137313731377],
            [22.769901990199024, 43.353635363536355],
            [22.830633063306337, 43.327632763276327],
            [23.004150415041508, 43.165116511651163],
            [22.995474547454748, 43.158615861586156],
            [22.986798679867995, 43.126112611261121],
            [22.986798679867995, 43.113111311131114],
            [22.934743474347442, 43.067606760676071],
            [22.89136413641365, 43.035103510351036],
            [22.795929592959304, 42.970097009700964],
            [22.752550255025511, 42.892089208920893],
            [22.639763976397646, 42.879087908790879],
            [22.596384638463853, 42.892089208920893],
            [22.5443294329433, 42.866086608660865],
            [22.492274227422747, 42.846584658465844],
            [22.440219021902195, 42.801080108010801],
            [22.448894889488955, 42.599559955995602],
            [22.466246624662475, 42.567056705670566],
            [22.55300530053006, 42.476047604760474],
            [22.55300530053006, 42.417541754175417],
            [22.362136213621369, 42.313531353135318],
            [22.240674067406744, 42.307030703070311],
            [22.119211921192125, 42.300530053005303],
            [22.101860186018605, 42.300530053005303],
            [21.780853085308536, 42.281028102810282],
            [21.66806680668067, 42.248524852485247],
            [21.616011601160125, 42.229022902290225],
            [21.399114911491154, 42.157515751575161],
            [21.329707970797088, 42.13801380138014],
            [21.321032103210328, 42.131513151315133],
            [21.052080208020804, 42.112011201120112],
            [20.895914591459153, 42.099009900990097],
            [20.592259225922596, 41.877987798779877],
            [20.496824682468251, 42.281028102810282],
            [20.141114111411149, 42.51505150515051],
            [19.863486348634872, 42.469546954695467],
            [19.854810481048112, 42.476047604760474],
            [19.750700070007007, 42.664566456645659],
            [19.603210321032108, 42.534553455345531],
            [19.533803380338043, 42.45654565456546],
            [19.334258425842592, 42.229022902290225],
            [19.386313631363144, 42.131513151315133],
            [19.386313631363144, 41.968996899689969],
            [19.377637763776384, 41.864986498649863],
            [19.368961896189624, 41.851985198519856],
            [19.316906690669072, 41.77397739773977],
            [19.1433893389339, 41.552955295529557],
            [19.100010001000108, 41.455445544554451],
            [18.865761576157624, 41.130413041304131],
            [18.553430343034307, 41.292929292929287],
            [18.223747374737478, 41.442444244424436],
            [18.336533653365343, 41.494449444944493],
            [18.431968196819689, 41.741474147414742],
            [18.484023402340242, 41.884488448844884],
            [18.58813381338134, 41.864986498649863],
            [18.666216621662173, 41.929992999299927],
            [18.527402740274034, 42.014501450145012],
            [18.553430343034307, 42.09250925092509],
            [18.5968096809681, 42.203020302030197],
            [18.58813381338134, 42.216021602160211],
            [18.562106210621067, 42.339533953395339],
            [18.553430343034307, 42.346034603460346],
            [18.553430343034307, 42.352535253525346],
            [18.553430343034307, 42.41104110411041],
            [18.484023402340242, 42.450045004500453],
            [18.553430343034307, 42.586558655865588],
            [18.501375137513755, 42.996099609960993],
            [18.666216621662173, 43.035103510351036],
            [18.761651165116518, 43.295129512951291],
            [18.917816781678177, 43.334133413341334],
            [19.047954795479555, 43.249624962496249],
            [19.073982398239828, 43.308130813081306],
            [19.212796279627966, 43.334133413341334],
            [18.926492649264929, 43.522652265226526],
            [19.1433893389339, 43.529152915291533],
            [19.499099909991003, 43.581158115811576],
            [19.525127512751283, 43.672167216721675],
            [19.533803380338043, 43.691669166916689],
            [19.507775777577763, 43.717671767176718],
            [19.360286028602864, 43.867186718671867],
            [19.299554955495552, 43.997199719971995],
            [19.46439643964397, 43.964696469646967],
            [19.603210321032108, 44.010201020102009],
            [19.620562056205628, 44.042704270427038],
            [19.585858585858588, 44.062206220622059],
            [19.559830983098315, 44.068706870687066],
            [19.46439643964397, 44.133713371337137],
            [19.368961896189624, 44.18571857185718],
            [19.126037603760381, 44.322232223222322],
            [19.108685868586868, 44.595259525952599],
            [19.299554955495552, 44.686268626862685],
            [19.360286028602864, 44.887788778877891],
            [19.030603060306035, 44.848784878487848],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "ESAAFIR", name: "SWEDEN FIR", fab: "4" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.548879887988804, 69.057205720572057],
            [21.17354235423543, 68.836183618361844],
            [21.390439043904394, 68.771177117711773],
            [22.500950095009507, 68.439643964396438],
            [23.108260826082613, 68.257625762576254],
            [23.325157515751584, 68.14061406140614],
            [23.342509250925097, 68.127612761276126],
            [23.489998999899996, 68.023602360236026],
            [23.628812881288134, 67.965096509650962],
            [23.576757675767581, 67.893589358935884],
            [23.498674867486756, 67.692069206920692],
            [23.784978497849792, 67.425542554255429],
            [23.767626762676272, 67.33453345334533],
            [23.611461146114614, 67.217521752175216],
            [23.71557155715572, 67.035503550355031],
            [23.837033703370345, 66.931493149314932],
            [23.949819981998203, 66.840484048404846],
            [23.984523452345243, 66.801480148014804],
            [23.889088908890898, 66.736473647364733],
            [23.802330233023305, 66.573957395739569],
            [23.767626762676272, 66.521952195219512],
            [23.741599159915999, 66.46994699469947],
            [23.689543954395447, 66.385438543854377],
            [23.654840484048414, 66.32043204320432],
            [23.70689568956896, 66.274927492749271],
            [23.71557155715572, 66.268426842684264],
            [23.802330233023305, 66.196919691969185],
            [23.819681968196825, 66.177417741774178],
            [23.941144114411451, 66.0929092909291],
            [24.079957995799589, 65.943394339433951],
            [24.140689068906894, 65.767876787678773],
            [24.158040804080414, 65.592359235923595],
            [24.140689068906894, 65.527352735273524],
            [24.027902790279029, 65.45584558455846],
            [23.906440644064411, 65.371337133713368],
            [23.784978497849792, 65.286828682868276],
            [23.724247424742479, 65.254325432543254],
            [23.568081808180821, 65.143814381438148],
            [23.37721272127213, 65.000800080007991],
            [22.969446944694475, 64.701770177017693],
            [22.926067606760682, 64.682268226822686],
            [22.821957195719577, 64.617261726172615],
            [22.795929592959304, 64.597759775977593],
            [22.735198519851991, 64.552255225522543],
            [22.709170917091718, 64.532753275327536],
            [22.587708770877093, 64.448244824482444],
            [22.153915391539158, 64.11671167116711],
            [22.110536053605365, 64.090709070907081],
            [22.084508450845092, 64.064706470647053],
            [22.03245324532454, 64.025702570257025],
            [21.971722172217227, 63.973697369736975],
            [21.954370437043707, 63.960696069606961],
            [21.841584158415849, 63.882688268826882],
            [21.815556555655569, 63.863186318631861],
            [21.581308130813085, 63.681168116811676],
            [21.503225322532259, 63.616161616161612],
            [21.433818381838186, 63.603160316031598],
            [21.312356235623568, 63.583658365836584],
            [21.19089408940895, 63.564156415641563],
            [21.16486648664867, 63.564156415641563],
            [21.034728472847291, 63.538153815381534],
            [20.887238723872393, 63.512151215121513],
            [20.670342034203429, 63.466646664666463],
            [20.609610961096116, 63.434143414341435],
            [20.574907490749084, 63.42114211421142],
            [20.167141714171422, 63.167616761676165],
            [20.010976097609763, 62.829582958295831],
            [19.837458745874592, 62.465546554655461],
            [19.820107010701072, 62.413541354135411],
            [19.820107010701072, 62.39403940394039],
            [19.724672467246734, 62.192519251925191],
            [19.629237923792388, 61.997499749974999],
            [19.542479247924796, 61.763476347634764],
            [19.525127512751283, 61.717971797179715],
            [19.499099909991003, 61.665966596659665],
            [19.490424042404243, 61.620462046204615],
            [19.48174817481749, 61.607460746074608],
            [19.412341234123417, 61.360436043604359],
            [19.316906690669072, 61.067906790679068],
            [19.299554955495552, 61.002900290029004],
            [19.238823882388246, 60.697369736973698],
            [19.178092809280933, 60.469846984698471],
            [19.15206520652066, 60.372337233723371],
            [19.15206520652066, 60.359335933593357],
            [19.134713471347141, 60.3008300830083],
            [19.108685868586868, 60.25532553255325],
            [19.082658265826588, 60.190319031903186],
            [19.100010001000108, 60.183818381838179],
            [19.134713471347141, 60.157815781578158],
            [19.204120412041206, 60.105810581058101],
            [19.264851485148519, 60.066806680668066],
            [19.299554955495552, 60.047304730473044],
            [19.394989498949897, 59.98229822982298],
            [19.403665366536657, 59.975797579757973],
            [19.412341234123417, 59.969296929692966],
            [19.46439643964397, 59.930293029302931],
            [19.620562056205628, 59.819781978197817],
            [19.663941394139421, 59.787278727872788],
            [19.724672467246734, 59.748274827482746],
            [19.802755275527559, 59.696269626962696],
            [19.880838083808385, 59.637763776377639],
            [19.924217421742178, 59.605260526052604],
            [19.98494849484949, 59.559755975597561],
            [20.00230023002301, 59.553255325532554],
            [20.010976097609763, 59.546754675467547],
            [20.019651965196523, 59.546754675467547],
            [20.019651965196523, 59.54025402540254],
            [20.149789978997909, 59.475247524752476],
            [20.427417741774185, 59.319231923192319],
            [20.540204020402044, 59.254225422542255],
            [20.679017901790182, 59.182718271827184],
            [20.687693769376942, 59.176217621762177],
            [20.731073107310735, 59.156715671567156],
            [20.739748974897495, 59.143714371437142],
            [20.783128312831288, 59.12421242124212],
            [20.791804180418048, 59.117711771177113],
            [20.809155915591568, 59.111211121112106],
            [20.81783178317832, 59.104710471047106],
            [20.913266326632666, 59.046204620462042],
            [20.947969796979706, 59.026702670267028],
            [21.000025002500259, 59.000700070006999],
            [20.939293929392946, 58.9031903190319],
            [20.774452445244528, 58.63016301630163],
            [20.644314431443149, 58.415641564156417],
            [20.574907490749084, 58.305130513051303],
            [20.496824682468251, 58.168616861686168],
            [20.418741874187425, 58.025602560256026],
            [20.271252125212527, 57.785078507850784],
            [20.063031303130316, 57.414541454145414],
            [19.837458745874592, 56.998499849984995],
            [19.568506850685075, 56.868486848684867],
            [19.073982398239828, 56.627962796279625],
            [18.874437443744377, 56.530453045304526],
            [18.700920092009206, 56.439443944394441],
            [18.510051005100514, 56.348434843484348],
            [18.284478447844791, 56.231423142314227],
            [18.06758175817582, 56.120912091209121],
            [18.015526552655274, 56.094909490949092],
            [17.703195319531957, 55.932393239323929],
            [17.547029702970299, 55.847884788478844],
            [17.191319131913197, 55.646364636463645],
            [17.165291529152924, 55.639863986398638],
            [17.147939793979404, 55.620362036203616],
            [16.878987898789887, 55.483848384838481],
            [16.714146414641469, 55.399339933993396],
            [16.505925592559258, 55.275827582758275],
            [16.236973697369741, 55.132813281328133],
            [16.07213221322133, 55.035303530353033],
            [16.002725272527258, 55.002800280027998],
            [15.863911391139119, 54.91829182918292],
            [15.846559655965599, 54.91829182918292],
            [15.655690569056908, 54.91829182918292],
            [15.299979997999806, 54.91829182918292],
            [15.143814381438148, 54.91829182918292],
            [15.117786778677875, 54.905290529052905],
            [15.100435043504355, 54.892289228922891],
            [15.083083308330835, 54.879287928792877],
            [15.057055705570562, 54.866286628662863],
            [15.039703970397042, 54.853285328532849],
            [15.013676367636769, 54.846784678467849],
            [14.987648764876489, 54.833783378337834],
            [14.961621162116217, 54.827282728272827],
            [14.944269426942697, 54.82078207820782],
            [14.909565956595664, 54.814281428142813],
            [14.883538353835391, 54.807780778077806],
            [14.857510751075111, 54.807780778077806],
            [14.822807280728078, 54.801280128012799],
            [14.796779677967805, 54.801280128012799],
            [14.770752075207525, 54.801280128012799],
            [14.736048604860493, 54.794779477947792],
            [14.710021002100213, 54.801280128012799],
            [14.67531753175318, 54.801280128012799],
            [14.649289928992907, 54.801280128012799],
            [14.623262326232627, 54.807780778077806],
            [14.597234723472354, 54.814281428142813],
            [14.562531253125314, 54.82078207820782],
            [14.536503650365042, 54.827282728272827],
            [14.510476047604769, 54.833783378337834],
            [14.484448444844489, 54.840284028402841],
            [14.467096709670969, 54.853285328532849],
            [14.441069106910696, 54.859785978597856],
            [14.423717371737176, 54.87278727872787],
            [14.397689768976903, 54.885788578857884],
            [14.380338033803383, 54.898789878987898],
            [14.35431043104311, 54.91829182918292],
            [14.34563456345635, 54.91829182918292],
            [14.163441344134419, 54.91829182918292],
            [13.764351435143517, 54.91829182918292],
            [13.746999699970004, 54.91829182918292],
            [13.660241024102412, 54.91829182918292],
            [13.564806480648073, 54.91829182918292],
            [13.382613261326135, 54.91829182918292],
            [13.000875087508753, 54.91829182918292],
            [12.853385338533862, 54.91829182918292],
            [12.801330133013309, 55.015801580158012],
            [12.783978397839789, 55.04180418041804],
            [12.757950795079516, 55.09380938093809],
            [12.731923192319236, 55.152315231523147],
            [12.688543854385443, 55.236823682368232],
            [12.679867986798683, 55.256325632563254],
            [12.645164516451651, 55.334333433343332],
            [12.627812781278131, 55.373337333733375],
            [12.610461046104618, 55.431843184318431],
            [12.662516251625171, 55.457845784578453],
            [12.705895589558963, 55.483848384838481],
            [12.714571457145716, 55.490349034903488],
            [12.723247324732476, 55.542354235423538],
            [12.775302530253029, 55.568356835683566],
            [12.879412941294134, 55.613861386138609],
            [12.896764676467654, 55.646364636463645],
            [12.810006000600062, 55.73087308730873],
            [12.697219721972203, 55.834883488348837],
            [12.671192119211923, 55.8998899889989],
            [12.653840384038411, 55.919391939193915],
            [12.653840384038411, 55.971397139713972],
            [12.653840384038411, 56.029902990299028],
            [12.653840384038411, 56.055905590559057],
            [12.567081708170825, 56.1014101410141],
            [12.497674767476752, 56.133913391339135],
            [12.480323032303232, 56.146914691469142],
            [12.43694369436944, 56.166416641664163],
            [12.367536753675374, 56.211921192119213],
            [12.332833283328341, 56.257425742574256],
            [12.272102210221028, 56.341934193419341],
            [12.237398739873996, 56.387438743874384],
            [12.228722872287236, 56.393939393939391],
            [12.12461246124613, 56.530453045304526],
            [12.072557255725577, 56.601960196019597],
            [11.768901890189021, 56.985498549854981],
            [11.647439743974402, 57.13501350135013],
            [11.569356935693577, 57.252025202520251],
            [11.499949994999504, 57.317031703170315],
            [11.482598259825984, 57.336533653365336],
            [11.404515451545159, 57.427542754275429],
            [11.343784378437846, 57.512051205120507],
            [11.222322232223227, 57.655065506550656],
            [11.144239423942402, 57.746074607460741],
            [11.144239423942402, 57.752575257525748],
            [10.962046204620464, 57.967096709670962],
            [10.936018601860191, 57.99309930993099],
            [10.927342734273431, 57.999599959995997],
            [10.892639263926398, 58.051605160516047],
            [10.562956295629569, 58.422142214221424],
            [10.502225222522256, 58.500150015001495],
            [10.528252825282536, 58.58465846584658],
            [10.571632163216329, 58.695169516951694],
            [10.597659765976601, 58.760176017601758],
            [10.606335633563361, 58.8056805680568],
            [10.719121912191227, 58.909690969096907],
            [10.831908190819085, 58.935693569356935],
            [10.970722072207224, 58.955195519551957],
            [11.118211821182122, 59.013701370137014],
            [11.239673967396747, 59.091709170917092],
            [11.352460246024606, 59.111211121112106],
            [11.465246524652471, 58.883688368836879],
            [11.664791479147922, 58.9031903190319],
            [11.768901890189021, 59.12421242124212],
            [11.820957095709574, 59.319231923192319],
            [11.794929492949301, 59.364736473647362],
            [11.725522552255228, 59.520752075207518],
            [11.699494949494955, 59.566256625662561],
            [11.699494949494955, 59.598759875987597],
            [11.777577757775781, 59.644264426442639],
            [11.864336433643366, 59.650765076507646],
            [11.916391639163919, 59.702770277027703],
            [11.925067506750679, 59.793779377937788],
            [11.829632963296334, 59.832783278327831],
            [11.916391639163919, 59.884788478847881],
            [12.003150315031505, 59.897789778977895],
            [12.020502050205025, 59.891289128912888],
            [12.046529652965305, 59.891289128912888],
            [12.09858485848585, 59.891289128912888],
            [12.167991799179923, 59.897789778977895],
            [12.393564356435647, 60.014801480148016],
            [12.497674767476752, 60.099309930993094],
            [12.532378237823785, 60.177317731773172],
            [12.497674767476752, 60.3008300830083],
            [12.497674767476752, 60.307330733073307],
            [12.584433443344338, 60.378837883788378],
            [12.610461046104618, 60.515351535153513],
            [12.393564356435647, 60.736373637363734],
            [12.350185018501854, 60.833883388338833],
            [12.289453945394541, 60.918391839183919],
            [12.237398739873996, 61.002900290029004],
            [12.723247324732476, 61.152415241524153],
            [12.836033603360342, 61.366936693669366],
            [12.671192119211923, 61.496949694969494],
            [12.584433443344338, 61.568456845684565],
            [12.341509150915094, 61.639963996399636],
            [12.220047004700476, 61.678967896789679],
            [12.220047004700476, 61.782978297829779],
            [12.211371137113716, 61.919491949194921],
            [12.220047004700476, 61.997499749974999],
            [12.246074607460748, 62.101510151015098],
            [12.315481548154821, 62.264026402640262],
            [12.263426342634268, 62.322532253225319],
            [12.237398739873996, 62.361536153615361],
            [12.185343534353443, 62.433043304330432],
            [12.167991799179923, 62.498049804980496],
            [12.046529652965305, 62.667066706670667],
            [12.081233123312337, 62.914091409140909],
            [12.12461246124613, 62.940094009400937],
            [12.246074607460748, 62.998599859985994],
            [12.003150315031505, 63.284628462846285],
            [12.055205520552057, 63.330133013301328],
            [12.081233123312337, 63.349634963496349],
            [12.202695269526956, 63.434143414341435],
            [12.246074607460748, 63.466646664666463],
            [12.167991799179923, 63.603160316031598],
            [12.810006000600062, 63.960696069606961],
            [12.98352335233524, 64.05820582058206],
            [13.000875087508753, 64.064706470647053],
            [13.191744174417444, 64.071207120712074],
            [13.373937393739382, 64.077707770777067],
            [13.599509950995106, 64.05820582058206],
            [13.790379037903797, 64.032203220322032],
            [13.929192919291935, 63.999699969996996],
            [14.163441344134419, 64.201220122012202],
            [14.111386138613867, 64.461246124612458],
            [13.937868786878695, 64.500250025002501],
            [13.738323832383244, 64.558755875587565],
            [13.668916891689172, 64.597759775977593],
            [13.712296229622964, 64.643264326432643],
            [13.720972097209724, 64.64976497649765],
            [13.746999699970004, 64.675767576757664],
            [13.955220522052208, 64.844784478447849],
            [14.302255225522558, 65.098309830983098],
            [14.415041504150423, 65.267326732673268],
            [14.501800180018009, 65.51435143514351],
            [14.510476047604769, 65.55985598559856],
            [14.510476047604769, 65.585858585858574],
            [14.579882988298834, 65.748374837483738],
            [14.597234723472354, 65.800380038003794],
            [14.536503650365042, 66.131913191319128],
            [14.597234723472354, 66.138413841384136],
            [15.039703970397042, 66.15141514151415],
            [15.083083308330835, 66.164416441644164],
            [15.395414541454151, 66.255425542554264],
            [15.499524952495257, 66.268426842684264],
            [15.464821482148217, 66.333433343334335],
            [15.412766276627671, 66.502450245024505],
            [15.673042304230428, 66.63896389638964],
            [15.855235523552359, 66.768976897689768],
            [15.872587258725879, 66.781978197819782],
            [15.985373537353738, 66.833983398339825],
            [16.150215021502156, 66.91199119911991],
            [16.297704770477054, 67.00300030003001],
            [16.419166916691672, 67.068006800680067],
            [16.332408240824087, 67.263026302630266],
            [16.106835683568363, 67.458045804580451],
            [16.158890889088916, 67.516551655165514],
            [16.36711171117112, 67.536053605360536],
            [16.748849884988502, 67.91309130913092],
            [16.983098309830986, 67.997599759975998],
            [17.026477647764779, 68.004100410041005],
            [17.104560456045611, 68.030103010301019],
            [17.286753675367542, 68.121112111211119],
            [17.442919291929201, 68.082108210821076],
            [17.633788378837892, 68.049604960496055],
            [17.902740274027408, 67.971597159715969],
            [18.163016301630165, 68.205620562056197],
            [18.102285228522859, 68.41364136413641],
            [18.102285228522859, 68.426642664266424],
            [18.128312831283132, 68.537153715371545],
            [18.414616461646169, 68.582658265826581],
            [18.62283728372838, 68.504650465046495],
            [19.100010001000108, 68.498149814981502],
            [19.169416941694173, 68.485148514851488],
            [19.915541554155418, 68.361636163616367],
            [19.958920892089218, 68.348634863486353],
            [20.262576257625767, 68.491649164916481],
            [19.97627262726273, 68.563156315631559],
            [20.31463146314632, 68.771177117711773],
            [20.32330733073308, 68.79067906790678],
            [20.33198319831984, 68.881688168816879],
            [20.3406590659066, 68.927192719271915],
            [20.548879887988804, 69.057205720572057],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EGGXFIR", name: "SHANWICK FIR", fab: "9" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.998849884988495, 61.002900290029004],
            [-9.998849884988495, 60.664866486648663],
            [-9.998849884988495, 60.502350235023499],
            [-9.998849884988495, 60.476347634763478],
            [-9.998849884988495, 60.001800180018002],
            [-9.998849884988495, 59.98229822982298],
            [-9.998849884988495, 59.501250125012497],
            [-9.998849884988495, 59.481748174817483],
            [-9.998849884988495, 59.462246224622461],
            [-9.998849884988495, 59.332233223322334],
            [-9.998849884988495, 59.16971697169717],
            [-9.998849884988495, 59.000700070006999],
            [-9.998849884988495, 58.981198119811978],
            [-9.998849884988495, 58.974697469746971],
            [-9.998849884988495, 58.961696169616957],
            [-9.998849884988495, 58.669166916691665],
            [-9.998849884988495, 58.649664966496651],
            [-9.998849884988495, 58.500150015001495],
            [-9.998849884988495, 58.480648064806481],
            [-9.998849884988495, 58.253125312531253],
            [-9.998849884988495, 58.097109710971097],
            [-9.998849884988495, 57.999599959995997],
            [-9.998849884988495, 57.986598659865983],
            [-9.998849884988495, 57.804580458045805],
            [-9.998849884988495, 57.765576557655763],
            [-9.998849884988495, 57.752575257525748],
            [-9.998849884988495, 57.551055105510549],
            [-9.998849884988495, 57.4990499049905],
            [-9.998849884988495, 57.466546654665464],
            [-9.998849884988495, 57.4015401540154],
            [-9.998849884988495, 57.336533653365336],
            [-9.998849884988495, 57.271527152715272],
            [-9.998849884988495, 57.252025202520251],
            [-9.998849884988495, 57.018001800180016],
            [-9.998849884988495, 56.998499849984995],
            [-9.998849884988495, 56.764476447644761],
            [-9.998849884988495, 56.666966696669668],
            [-9.998849884988495, 56.58895889588959],
            [-9.998849884988495, 56.582458245824583],
            [-9.998849884988495, 56.497949794979498],
            [-9.998849884988495, 56.439443944394441],
            [-9.998849884988495, 56.335433543354334],
            [-9.998849884988495, 56.263926392639263],
            [-9.998849884988495, 56.250925092509249],
            [-9.998849884988495, 56.133913391339135],
            [-9.998849884988495, 56.094909490949092],
            [-9.998849884988495, 55.997399739973993],
            [-9.998849884988495, 55.821882188218822],
            [-9.998849884988495, 55.769876987698765],
            [-9.998849884988495, 55.756875687568758],
            [-9.998849884988495, 55.750375037503751],
            [-9.998849884988495, 55.665866586658666],
            [-9.998849884988495, 55.496849684968495],
            [-9.998849884988495, 55.236823682368232],
            [-9.998849884988495, 55.119811981198119],
            [-9.998849884988495, 55.002800280027998],
            [-9.998849884988495, 54.749274927492749],
            [-9.998849884988495, 54.723272327232721],
            [-9.998849884988495, 54.5997599759976],
            [-9.998849884988495, 54.567256725672564],
            [-10.666891689168914, 54.5022502250225],
            [-13.503900390039, 54.18371837183718],
            [-14.996149614961492, 54.001700170017003],
            [-14.996149614961492, 53.748174817481747],
            [-14.996149614961492, 53.696169616961697],
            [-14.996149614961492, 53.501150115011498],
            [-14.996149614961492, 53.247624762476249],
            [-14.996149614961492, 53.000600060006001],
            [-14.996149614961492, 52.747074707470745],
            [-14.996149614961492, 52.500050005000496],
            [-14.996149614961492, 52.253025302530254],
            [-14.996149614961492, 52.064506450645062],
            [-14.996149614961492, 51.999499949994998],
            [-14.996149614961492, 51.75247524752475],
            [-14.996149614961492, 51.498949894989501],
            [-14.996149614961492, 51.251925192519252],
            [-14.996149614961492, 50.998399839983996],
            [-13.998424842484248, 50.998399839983996],
            [-12.497499749974995, 50.998399839983996],
            [-12.002975297529751, 50.998399839983996],
            [-8.497924792479246, 50.998399839983996],
            [-8.003400340034002, 50.998399839983996],
            [-8.003400340034002, 50.751375137513747],
            [-8.003400340034002, 50.660366036603662],
            [-8.003400340034002, 50.367836783678364],
            [-8.003400340034002, 50.25082508250825],
            [-8.003400340034002, 50.075307530753072],
            [-8.003400340034002, 49.997299729972994],
            [-8.003400340034002, 49.581258125812582],
            [-8.003400340034002, 49.503250325032504],
            [-8.003400340034002, 49.308230823082305],
            [-8.003400340034002, 49.249724972497248],
            [-8.003400340034002, 49.165216521652162],
            [-8.003400340034002, 49.002700270026999],
            [-8.003400340034002, 48.833683368336835],
            [-8.003400340034002, 48.58015801580158],
            [-8.003400340034002, 48.502150215021501],
            [-8.003400340034002, 48.489148914891487],
            [-8.003400340034002, 48.313631363136309],
            [-8.003400340034002, 48.001600160015997],
            [-8.003400340034002, 47.826082608260826],
            [-8.003400340034002, 47.501050105010499],
            [-8.003400340034002, 47.247524752475243],
            [-8.003400340034002, 47.000500050005002],
            [-8.003400340034002, 46.499949994999497],
            [-8.003400340034002, 45.999399939993999],
            [-8.003400340034002, 45.498849884988502],
            [-8.003400340034002, 44.998299829982997],
            [-8.749524952495246, 44.998299829982997],
            [-9.001125112511247, 44.998299829982997],
            [-9.131263126312628, 44.998299829982997],
            [-9.495649564956494, 44.998299829982997],
            [-9.83400840084008, 44.998299829982997],
            [-9.998849884988495, 44.998299829982997],
            [-10.502050205020499, 44.998299829982997],
            [-10.996574657465743, 44.998299829982997],
            [-11.499774977497747, 44.998299829982997],
            [-11.569181918191816, 44.998299829982997],
            [-12.002975297529751, 44.998299829982997],
            [-12.280603060306028, 44.998299829982997],
            [-12.497499749974995, 44.998299829982997],
            [-12.584258425842581, 44.998299829982997],
            [-12.91394139413941, 44.998299829982997],
            [-13.000700070006999, 44.998299829982997],
            [-13.295679567956793, 44.998299829982997],
            [-13.503900390039, 44.998299829982997],
            [-13.686093609360935, 44.998299829982997],
            [-13.998424842484248, 44.998299829982997],
            [-14.380163016301626, 44.998299829982997],
            [-14.501625162516248, 44.998299829982997],
            [-14.518976897689768, 44.998299829982997],
            [-14.996149614961492, 44.998299829982997],
            [-15.187018701870183, 44.998299829982997],
            [-15.499349934993496, 44.998299829982997],
            [-16.002550255025501, 44.998299829982997],
            [-16.497074707470745, 44.998299829982997],
            [-17.000275027502749, 44.998299829982997],
            [-17.503475347534753, 44.998299829982997],
            [-17.997999799979997, 44.998299829982997],
            [-18.457820782078205, 44.998299829982997],
            [-18.501200120011998, 44.998299829982997],
            [-18.995724572457245, 44.998299829982997],
            [-19.247324732473245, 44.998299829982997],
            [-19.498924892489246, 44.998299829982997],
            [-20.00212521252125, 44.998299829982997],
            [-20.496649664966494, 44.998299829982997],
            [-20.999849984998498, 44.998299829982997],
            [-21.503050305030502, 44.998299829982997],
            [-21.997574757475746, 44.998299829982997],
            [-22.50077507750775, 44.998299829982997],
            [-23.003975397539751, 44.998299829982997],
            [-23.498499849984999, 44.998299829982997],
            [-24.001700170017003, 44.998299829982997],
            [-24.496224622462243, 44.998299829982997],
            [-24.999424942494247, 44.998299829982997],
            [-25.502625262526252, 44.998299829982997],
            [-25.997149714971496, 44.998299829982997],
            [-26.5003500350035, 44.998299829982997],
            [-27.0035503550355, 44.998299829982997],
            [-27.498074807480748, 44.998299829982997],
            [-28.001275127512748, 44.998299829982997],
            [-28.495799579957996, 44.998299829982997],
            [-28.998999899989997, 44.998299829982997],
            [-29.502200220022001, 44.998299829982997],
            [-29.996724672467245, 44.998299829982997],
            [-29.996724672467245, 45.498849884988502],
            [-29.996724672467245, 45.986398639863985],
            [-29.996724672467245, 45.999399939993999],
            [-29.996724672467245, 46.499949994999497],
            [-29.996724672467245, 47.000500050005002],
            [-29.996724672467245, 47.501050105010499],
            [-29.996724672467245, 48.001600160015997],
            [-29.996724672467245, 48.333133313331331],
            [-29.996724672467245, 48.502150215021501],
            [-29.996724672467245, 49.002700270026999],
            [-29.996724672467245, 49.431743174317432],
            [-29.996724672467245, 49.503250325032504],
            [-29.996724672467245, 49.98429842984298],
            [-29.996724672467245, 49.997299729972994],
            [-29.996724672467245, 50.497849784978499],
            [-29.996724672467245, 50.998399839983996],
            [-29.996724672467245, 51.498949894989501],
            [-29.996724672467245, 51.999499949994998],
            [-29.996724672467245, 52.500050005000496],
            [-29.996724672467245, 53.000600060006001],
            [-29.996724672467245, 53.501150115011498],
            [-29.996724672467245, 54.001700170017003],
            [-29.996724672467245, 54.5022502250225],
            [-29.996724672467245, 55.002800280027998],
            [-29.996724672467245, 55.496849684968495],
            [-29.996724672467245, 55.997399739973993],
            [-29.996724672467245, 56.497949794979498],
            [-29.996724672467245, 56.998499849984995],
            [-29.996724672467245, 57.4990499049905],
            [-29.996724672467245, 57.999599959995997],
            [-29.996724672467245, 58.500150015001495],
            [-29.996724672467245, 59.000700070006999],
            [-29.996724672467245, 59.501250125012497],
            [-29.996724672467245, 60.001800180018002],
            [-29.996724672467245, 60.502350235023499],
            [-29.996724672467245, 61.002900290029004],
            [-29.502200220022001, 61.002900290029004],
            [-28.998999899989997, 61.002900290029004],
            [-28.495799579957996, 61.002900290029004],
            [-28.001275127512748, 61.002900290029004],
            [-27.498074807480748, 61.002900290029004],
            [-27.0035503550355, 61.002900290029004],
            [-26.5003500350035, 61.002900290029004],
            [-25.997149714971496, 61.002900290029004],
            [-25.502625262526252, 61.002900290029004],
            [-24.999424942494247, 61.002900290029004],
            [-24.496224622462243, 61.002900290029004],
            [-24.001700170017003, 61.002900290029004],
            [-23.498499849984999, 61.002900290029004],
            [-23.003975397539751, 61.002900290029004],
            [-22.50077507750775, 61.002900290029004],
            [-21.997574757475746, 61.002900290029004],
            [-21.503050305030502, 61.002900290029004],
            [-20.999849984998498, 61.002900290029004],
            [-20.496649664966494, 61.002900290029004],
            [-20.00212521252125, 61.002900290029004],
            [-19.498924892489246, 61.002900290029004],
            [-18.995724572457245, 61.002900290029004],
            [-18.856910691069103, 61.002900290029004],
            [-18.501200120011998, 61.002900290029004],
            [-17.997999799979997, 61.002900290029004],
            [-17.503475347534753, 61.002900290029004],
            [-17.468771877187717, 61.002900290029004],
            [-17.000275027502749, 61.002900290029004],
            [-16.497074707470745, 61.002900290029004],
            [-16.392964296429639, 61.002900290029004],
            [-16.045929592959293, 61.002900290029004],
            [-16.002550255025501, 61.002900290029004],
            [-15.499349934993496, 61.002900290029004],
            [-14.996149614961492, 61.002900290029004],
            [-14.501625162516248, 61.002900290029004],
            [-13.998424842484248, 61.002900290029004],
            [-13.503900390039, 61.002900290029004],
            [-13.000700070006999, 61.002900290029004],
            [-12.497499749974995, 61.002900290029004],
            [-12.002975297529751, 61.002900290029004],
            [-11.499774977497747, 61.002900290029004],
            [-10.996574657465743, 61.002900290029004],
            [-10.666891689168914, 61.002900290029004],
            [-10.502050205020499, 61.002900290029004],
            [-9.998849884988495, 61.002900290029004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UKBVUIR", name: "KIEV UIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.276802680268034, 47.806580658065805],
            [29.233423342334234, 47.884588458845883],
            [29.137988798879888, 47.99509950995099],
            [28.730223022302241, 48.125112511251125],
            [27.836608660866091, 48.417641764176416],
            [27.827932793279331, 48.424142414241423],
            [27.723822382238225, 48.450145014501445],
            [27.307380738073817, 48.417641764176416],
            [26.81285628562857, 48.417641764176416],
            [26.708745874587464, 48.320132013201317],
            [26.621987198719879, 48.248624862486245],
            [26.465821582158227, 48.222622262226224],
            [25.96262126212622, 47.949594959495947],
            [25.780428042804289, 47.943094309430947],
            [25.719696969696969, 47.930093009300933],
            [25.650290029002903, 47.917091709170919],
            [25.164441444144416, 47.865086508650862],
            [25.051655165516564, 47.735073507350734],
            [24.886813681368139, 47.735073507350734],
            [24.583158315831582, 47.956095609560954],
            [24.21009600960096, 47.910591059105911],
            [23.342509250925097, 48.027602760276025],
            [23.099584958495853, 48.014601460146011],
            [22.86533653365337, 47.949594959495947],
            [22.830633063306337, 48.118611861186118],
            [22.683143314331438, 48.099109910991096],
            [22.596384638463853, 48.131613161316132],
            [22.318756875687576, 48.339633963396338],
            [22.231998199819991, 48.411141114111409],
            [22.162591259125918, 48.43064306430643],
            [22.145239523952398, 48.463146314631459],
            [22.153915391539158, 48.469646964696466],
            [22.162591259125918, 48.541154115411544],
            [22.249349934993504, 48.632163216321629],
            [22.258025802580264, 48.651665166516651],
            [22.353460346034609, 48.768676867686764],
            [22.370812081208129, 48.820682068206821],
            [22.448894889488955, 48.950695069506949],
            [22.570357035703573, 49.087208720872084],
            [22.769901990199024, 49.002700270026999],
            [22.830633063306337, 49.087208720872084],
            [22.735198519851991, 49.204220422042205],
            [22.735198519851991, 49.210721072107212],
            [22.657115711571166, 49.503250325032504],
            [22.657115711571166, 49.568256825682568],
            [22.943419341934202, 49.808780878087809],
            [23.056205620562061, 49.886788678867887],
            [23.134288428842893, 49.964796479647966],
            [23.212371237123719, 50.023302330233022],
            [23.481323132313236, 50.218321832183214],
            [23.828357835783585, 50.406840684068406],
            [24.062606260626069, 50.504350435043506],
            [24.053930393039309, 50.666866686668662],
            [24.097309730973109, 50.835883588358833],
            [23.646164616461654, 51.303930393039302],
            [23.620137013701374, 51.498949894989501],
            [24.687268726872688, 51.901990199019899],
            [24.964896489648964, 51.882488248824878],
            [25.372662266226627, 51.927992799279927],
            [25.884538453845394, 51.92149214921492],
            [26.118786878687871, 51.895489548954892],
            [26.396414641464148, 51.849984998499849],
            [26.79550455045505, 51.765476547654764],
            [27.69779477947796, 51.479447944794479],
            [27.758525852585265, 51.472947294729472],
            [28.46994699469947, 51.550955095509551],
            [28.678167816781681, 51.563956395639565],
            [28.678167816781681, 51.446944694469444],
            [28.747574757475761, 51.459945994599458],
            [28.782278227822786, 51.420942094209423],
            [28.790954095409546, 51.498949894989501],
            [28.834333433343346, 51.557455745574558],
            [28.912416241624172, 51.589958995899586],
            [29.033878387838797, 51.589958995899586],
            [29.085933593359343, 51.667966796679664],
            [29.33753375337534, 51.563956395639565],
            [29.389588958895899, 51.401440144014401],
            [29.58913391339135, 51.466446644664465],
            [29.927492749274933, 51.459945994599458],
            [30.265851585158529, 51.479447944794479],
            [30.378637863786381, 51.323432343234323],
            [30.560831083108312, 51.264926492649266],
            [30.604210421042112, 51.316931693169316],
            [30.569506950695072, 51.511951195119508],
            [30.560831083108312, 51.64846484648465],
            [30.630238023802391, 51.713471347134714],
            [31.011976197619774, 52.084008400840084],
            [31.411066106610662, 52.142514251425141],
            [31.680018001800192, 52.136013601360133],
            [31.784128412841284, 52.136013601360133],
            [31.792804280428044, 52.116511651165112],
            [31.948969896989709, 52.084008400840084],
            [32.252625262526266, 52.097009700970098],
            [32.330708070807091, 52.298529852985297],
            [32.669066906690674, 52.272527252725268],
            [33.311081108110812, 52.350535053505347],
            [33.901040104010406, 52.324532453245325],
            [34.117936793679377, 51.999499949994998],
            [34.169991999199922, 51.966996699669963],
            [34.499674967496759, 51.7004700470047],
            [34.083233323332337, 51.64846484648465],
            [34.447619761976199, 51.232423242324231],
            [35.332558255825589, 51.069906990699067],
            [35.436668666866694, 50.848884888488847],
            [35.445344534453454, 50.816381638163811],
            [35.644889488948905, 50.374337433743371],
            [35.696944694469451, 50.354835483548356],
            [36.295579557955804, 50.322332233223321],
            [36.40836583658367, 50.315831583158314],
            [36.486448644864495, 50.315831583158314],
            [36.824807480748078, 50.315831583158314],
            [37.466821682168231, 50.432843284328435],
            [37.640339033903402, 50.3028302830283],
            [37.761801180118013, 50.094809480948093],
            [38.03942894289429, 49.906290629062902],
            [38.143539353935395, 49.93229322932293],
            [38.178242824282435, 49.951795179517951],
            [38.195594559455955, 50.068806880688065],
            [38.230298029802981, 50.075307530753072],
            [39.496974697469753, 49.743774377437745],
            [40.121637163716386, 49.607260726072603],
            [39.861361136113615, 48.515151515151516],
            [39.939443944394441, 48.196619661966196],
            [39.887388738873895, 48.131613161316132],
            [39.809305930593069, 47.949594959495947],
            [39.679167916791684, 47.832583258325826],
            [39.219346934693476, 47.858585858585855],
            [38.91569156915692, 47.819581958195819],
            [38.317056705670581, 47.553055305530549],
            [38.221622162216235, 47.111011101110108],
            [38.334408440844086, 46.98099809980998],
            [37.501525152515256, 46.785978597859781],
            [37.423442344234431, 46.655965596559653],
            [37.371387138713885, 46.571457145714575],
            [36.798779877987812, 45.635363536353637],
            [36.712021202120212, 45.505350535053509],
            [36.685993599359946, 45.446844684468445],
            [36.685993599359946, 45.375337533753374],
            [36.642614261426147, 45.349334933493353],
            [36.590559055905601, 45.303830383038303],
            [36.599234923492361, 45.245324532453239],
            [36.529827982798281, 45.206320632063203],
            [36.547179717971801, 45.160816081608161],
            [36.547179717971801, 45.154315431543154],
            [36.573207320732081, 45.069806980698068],
            [36.590559055905601, 44.998299829982997],
            [36.607910791079121, 44.939793979397933],
            [36.625262526252627, 44.829282928292827],
            [36.668641864186426, 44.517251725172514],
            [36.633938393839387, 44.348234823482343],
            [36.555855585558561, 44.036203620362031],
            [36.495124512451255, 43.743674367436739],
            [36.38233823382339, 43.249624962496249],
            [36.269551955195524, 42.72957295729573],
            [35.002875287528767, 42.749074907490751],
            [34.091909190919097, 42.78157815781578],
            [33.180943094309441, 42.801080108010801],
            [31.948969896989709, 42.801080108010801],
            [31.003300330033014, 42.801080108010801],
            [30.751700170017003, 42.801080108010801],
            [30.638913891389151, 43.256125612561256],
            [30.534803480348046, 43.685168516851682],
            [30.508775877587766, 43.802180218021803],
            [30.4393689368937, 44.088208820882087],
            [30.42201720172018, 44.166216621662166],
            [30.3959895989599, 44.250725072507251],
            [30.265851585158529, 44.497749774977493],
            [30.231148114811489, 44.608260826082606],
            [30.170417041704184, 44.712271227122713],
            [30.153065306530664, 44.764276427642763],
            [30.083658365836584, 44.913791379137912],
            [30.048954895489558, 44.978797879787976],
            [29.970872087208733, 45.147814781478147],
            [29.806030603060307, 45.186818681868189],
            [29.667216721672176, 45.219321932193218],
            [29.502375237523765, 45.41434143414341],
            [29.111961196119623, 45.375337533753374],
            [28.721547154715481, 45.225822582258225],
            [28.331133113311338, 45.336333633363338],
            [28.322457245724578, 45.349334933493353],
            [28.200995099509953, 45.472847284728473],
            [28.313781378137818, 45.544354435443545],
            [28.435243524352444, 45.485848584858488],
            [28.51332633263327, 45.498849884988502],
            [28.50465046504651, 45.570357035703566],
            [28.548029802980309, 45.596359635963594],
            [28.50465046504651, 45.674367436743672],
            [28.730223022302241, 45.823882388238822],
            [28.869036903690372, 45.973397339733971],
            [28.955795579557957, 46.096909690969099],
            [29.051230123012303, 46.181418141814177],
            [29.085933593359343, 46.519451945194518],
            [29.32885788578858, 46.467446744674461],
            [29.693244324432456, 46.363436343634362],
            [29.675892589258936, 46.402440244024405],
            [29.753975397539762, 46.454445444544454],
            [29.797354735473547, 46.434943494349433],
            [29.832058205820587, 46.382938293829383],
            [29.884113411341147, 46.363436343634362],
            [29.944844484448453, 46.402440244024405],
            [29.996899689968998, 46.382938293829383],
            [30.066306630663078, 46.402440244024405],
            [30.135713571357144, 46.415441544154419],
            [30.127037703770384, 46.421942194219426],
            [30.118361836183624, 46.434943494349433],
            [30.074982498249838, 46.434943494349433],
            [30.074982498249838, 46.454445444544454],
            [29.979547954795493, 46.519451945194518],
            [29.901465146514653, 46.545454545454547],
            [29.944844484448453, 46.649464946494646],
            [29.996899689968998, 46.831483148314831],
            [29.884113411341147, 46.876987698769881],
            [29.875437543754387, 46.876987698769881],
            [29.762651265126522, 46.863986398639867],
            [29.727947794779482, 46.922492249224916],
            [29.58045804580459, 46.948494849484945],
            [29.632513251325136, 47.000500050005002],
            [29.615161516151616, 47.013501350135016],
            [29.623837383738376, 47.078507850785073],
            [29.528402840284031, 47.111011101110108],
            [29.59780978097811, 47.286528652865286],
            [29.58913391339135, 47.371037103710371],
            [29.511051105110511, 47.371037103710371],
            [29.485023502350245, 47.312531253125314],
            [29.441644164416445, 47.2995299529953],
            [29.389588958895899, 47.325532553255329],
            [29.32885788578858, 47.442544254425442],
            [29.190044004400448, 47.468546854685471],
            [29.207395739573968, 47.57255725572557],
            [29.224747474747488, 47.728572857285727],
            [29.276802680268034, 47.806580658065805],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "ENOBFIR", name: "BODO OCEANIC FIR", fab: "7" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.996899689968998, 71.000900090008997],
            [29.988223822382238, 71.000900090008997],
            [29.701920192019202, 71.052905290529054],
            [28.001450145014502, 71.332433243324331],
            [27.593684368436854, 71.332433243324331],
            [27.350760076007603, 71.332433243324331],
            [26.864911491149115, 71.332433243324331],
            [26.344359435943602, 71.332433243324331],
            [24.999599959996004, 71.332433243324331],
            [24.635213521352142, 71.299929992999296],
            [24.001875187518763, 71.208920892089196],
            [23.498674867486756, 71.156915691569154],
            [22.943419341934202, 71.117911791179125],
            [21.251625162516255, 70.916391639163919],
            [19.013251325132522, 70.623862386238613],
            [18.544754475447547, 70.558855885588557],
            [17.998174817481754, 70.467846784678471],
            [16.297704770477054, 70.214321432143208],
            [16.219621962196221, 70.201320132013194],
            [14.996324632463249, 69.999799979997988],
            [14.33695869586959, 69.713771377137704],
            [14.041979197919794, 69.58375837583759],
            [13.82508250825083, 69.486248624862483],
            [13.434668466846688, 69.310731073107306],
            [12.810006000600062, 69.018201820182014],
            [11.829632963296334, 68.543654365436538],
            [11.734198419841988, 68.498149814981502],
            [11.178942894289435, 68.199119911991204],
            [10.962046204620464, 68.09510951095109],
            [10.571632163216329, 67.893589358935884],
            [10.302680268026805, 67.744074407440735],
            [9.721397139713979, 67.419041904190408],
            [9.417741774177422, 67.250025002500252],
            [9.183493349334938, 67.107010701070095],
            [9.06203120312032, 67.042004200420038],
            [8.610886088608865, 66.768976897689768],
            [8.107685768576864, 66.463446344634463],
            [7.968871887188726, 66.378937893789384],
            [7.708595859585962, 66.2099209920992],
            [7.439643964396446, 66.040904090409043],
            [6.997174717471751, 65.748374837483738],
            [6.884388438843885, 65.657365736573652],
            [6.841009100910092, 65.618361836183624],
            [6.771602160216027, 65.546854685468546],
            [6.303105310531059, 65.163316331633155],
            [6.268401840184019, 65.130813081308133],
            [6.259725972597266, 65.130813081308133],
            [5.799904990499051, 64.721272127212728],
            [5.565656565656568, 64.513251325132501],
            [5.478897889788982, 64.428742874287423],
            [5.426842684268429, 64.383238323832387],
            [5.296704670467051, 64.266226622662259],
            [5.0971597159716, 64.071207120712074],
            [5.001725172517254, 63.999699969996996],
            [4.802180218021803, 63.798179817981797],
            [4.715421542154218, 63.720172017201719],
            [4.559255925592566, 63.564156415641563],
            [4.550580058005806, 63.557655765576555],
            [4.359710971097115, 63.362636263626364],
            [4.229572957295737, 63.239123912391236],
            [4.203545354535457, 63.206620662066207],
            [4.108110811081112, 63.109110911091108],
            [4.073407340734079, 63.070107010701065],
            [4.004000400040006, 62.998599859985994],
            [3.717696769676969, 62.998599859985994],
            [3.067006700670071, 62.998599859985994],
            [1.999874987498757, 62.998599859985994],
            [1.279777977797785, 62.998599859985994],
            [0.524977497749781, 62.998599859985994],
            [0.030453045304533, 62.998599859985994],
            [-0.0042504250425, 63.018101810181015],
            [-0.0042504250425, 63.25212521252125],
            [-0.0042504250425, 63.47314731473147],
            [-0.0042504250425, 63.499149914991499],
            [-0.0042504250425, 63.999699969996996],
            [-0.0042504250425, 64.500250025002501],
            [-0.0042504250425, 65.000800080007991],
            [-0.0042504250425, 66.001900190019001],
            [-0.0042504250425, 66.502450245024505],
            [-0.0042504250425, 67.00300030003001],
            [-0.0042504250425, 67.497049704970493],
            [-0.0042504250425, 67.997599759975998],
            [-0.0042504250425, 68.498149814981502],
            [-0.0042504250425, 68.998699869986993],
            [-0.0042504250425, 69.330233023302327],
            [-0.0042504250425, 69.499249924992498],
            [-0.0042504250425, 69.999799979997988],
            [-0.0042504250425, 70.500350035003493],
            [-0.0042504250425, 71.000900090008997],
            [-0.0042504250425, 72.002000200020007],
            [-0.0042504250425, 72.385538553855383],
            [-0.0042504250425, 73.003100310031002],
            [-0.0042504250425, 73.997699769977004],
            [-0.0042504250425, 74.998799879987999],
            [-0.0042504250425, 76.168916891689165],
            [-0.0042504250425, 77.001000100010003],
            [-0.0042504250425, 78.002100210020998],
            [-0.0042504250425, 78.502650265026503],
            [-0.0042504250425, 79.003200320031993],
            [-0.0042504250425, 79.997799779977996],
            [-0.0042504250425, 80.998899889988991],
            [-0.0042504250425, 82.0],
            [5.001725172517254, 82.0],
            [9.999024902490255, 82.0],
            [14.996324632463249, 82.0],
            [20.00230023002301, 82.0],
            [24.999599959996004, 82.0],
            [29.996899689968998, 82.0],
            [29.996899689968998, 81.9089908990899],
            [29.996899689968998, 81.830983098309829],
            [29.996899689968998, 78.502650265026503],
            [29.996899689968998, 78.002100210020998],
            [29.996899689968998, 77.501550155015508],
            [29.996899689968998, 77.001000100010003],
            [29.996899689968998, 76.500450045004499],
            [29.996899689968998, 75.999899989998994],
            [29.996899689968998, 75.499349934993489],
            [29.996899689968998, 74.998799879987999],
            [29.996899689968998, 74.498249824982494],
            [29.996899689968998, 73.997699769977004],
            [29.996899689968998, 73.497149714971499],
            [29.996899689968998, 73.003100310031002],
            [29.996899689968998, 72.502550255025497],
            [29.988223822382238, 72.112511251125113],
            [29.996899689968998, 72.073507350735071],
            [29.996899689968998, 71.988998899889992],
            [29.996899689968998, 71.332433243324331],
            [29.996899689968998, 71.117911791179125],
            [29.996899689968998, 71.013901390139011],
            [29.996899689968998, 71.000900090008997],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EGPXUIR", name: "SCOTTISH UIR", fab: "9" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.998849884988495, 61.002900290029004],
            [-8.064131413141311, 61.002900290029004],
            [-7.161841184118408, 61.002900290029004],
            [-6.996999699969997, 61.002900290029004],
            [-6.50247524752475, 61.002900290029004],
            [-6.48512351235123, 61.002900290029004],
            [-6.077357735773575, 61.002900290029004],
            [-5.999274927492749, 61.002900290029004],
            [-5.496074607460741, 61.002900290029004],
            [-5.149039903990399, 61.002900290029004],
            [-4.836708670867083, 61.002900290029004],
            [-4.585108510851079, 61.002900290029004],
            [-4.003825382538253, 61.002900290029004],
            [-1.852210221022098, 61.002900290029004],
            [-0.0042504250425, 61.002900290029004],
            [-0.0042504250425, 60.001800180018002],
            [0.01310131013102, 59.988798879887987],
            [0.672467246724679, 59.637763776377639],
            [1.652840284028407, 59.085208520852085],
            [1.739598959895993, 59.033203320332028],
            [2.156040604060408, 58.792679267926793],
            [2.598509850985103, 58.519651965196516],
            [2.832758275827587, 58.389638963896388],
            [2.997599759976005, 58.298629862986296],
            [3.162441244124416, 58.175117511751175],
            [3.587558755875591, 57.921592159215919],
            [3.604910491049111, 57.908590859085905],
            [4.047379737973799, 57.622562256225621],
            [4.134138413841391, 57.570557055705571],
            [4.498524852485254, 57.336533653365336],
            [5.001725172517254, 56.998499849984995],
            [5.001725172517254, 56.582458245824583],
            [5.001725172517254, 56.536953695369533],
            [5.001725172517254, 56.419941994199419],
            [5.001725172517254, 56.250925092509249],
            [5.001725172517254, 56.055905590559057],
            [5.001725172517254, 55.95189518951895],
            [5.001725172517254, 55.938893889388936],
            [5.001725172517254, 55.795879587958794],
            [5.001725172517254, 55.607360736073609],
            [5.001725172517254, 55.581358135813581],
            [5.001725172517254, 55.490349034903488],
            [5.001725172517254, 55.386338633863382],
            [5.001725172517254, 55.340834083408339],
            [5.001725172517254, 55.308330833083303],
            [5.001725172517254, 55.243324332433239],
            [5.001725172517254, 55.178317831783176],
            [5.001725172517254, 55.126312631263126],
            [5.001725172517254, 55.080808080808076],
            [5.001725172517254, 55.002800280027998],
            [4.038703870387046, 55.002800280027998],
            [3.500800080008005, 55.002800280027998],
            [3.119061906190623, 55.002800280027998],
            [3.049654965496551, 55.002800280027998],
            [2.980248024802485, 55.002800280027998],
            [-0.785078507850784, 55.002800280027998],
            [-0.86316131613161, 55.002800280027998],
            [-0.984623462346228, 55.002800280027998],
            [-1.270927092709265, 55.002800280027998],
            [-1.505175517551748, 55.002800280027998],
            [-1.912941294129411, 55.002800280027998],
            [-2.121162116211615, 55.002800280027998],
            [-2.468196819681964, 55.002800280027998],
            [-2.711121112111208, 55.002800280027998],
            [-3.439893989398939, 55.002800280027998],
            [-3.517976797679765, 55.002800280027998],
            [-4.003825382538253, 55.002800280027998],
            [-4.897439743974395, 55.002800280027998],
            [-5.496074607460741, 55.002800280027998],
            [-5.496074607460741, 54.898789878987898],
            [-5.496074607460741, 53.917191719171917],
            [-5.738998899889985, 53.96269626962696],
            [-6.042654265426542, 54.021202120212017],
            [-6.068681868186815, 54.027702770277024],
            [-6.207495749574953, 54.053705370537052],
            [-6.268226822682266, 54.066706670667067],
            [-6.407040704070404, 54.092709270927088],
            [-6.649964996499648, 54.138213821382138],
            [-6.762751275127506, 54.164216421642159],
            [-6.832158215821579, 54.177217721772173],
            [-6.910241024102405, 54.190219021902188],
            [-7.127137713771376, 54.22922292229223],
            [-7.396089608960892, 54.28122812281228],
            [-7.699744974497449, 54.33323332333233],
            [-7.89061406140614, 54.365736573657365],
            [-7.977372737273726, 54.385238523852387],
            [-8.168241824182417, 54.417741774177415],
            [-8.098834883488347, 54.463246324632465],
            [-8.055455545554551, 54.5022502250225],
            [-7.89061406140614, 54.625762576257621],
            [-7.838558855885587, 54.658265826582657],
            [-7.647689768976896, 54.801280128012799],
            [-7.283303330333027, 55.067806780678069],
            [-7.187868786878688, 55.13931393139314],
            [-7.049054905490543, 55.236823682368232],
            [-6.988323832383237, 55.282328232823282],
            [-6.936268626862685, 55.321332133213318],
            [-6.918916891689165, 55.334333433343332],
            [-7.33535853585358, 55.418841884188417],
            [-7.907965796579653, 55.366836683668367],
            [-8.064131413141311, 55.347334733473346],
            [-8.246324632463242, 55.334333433343332],
            [-8.385138513851381, 55.217321732173218],
            [-8.454545454545453, 55.178317831783176],
            [-8.541304130413039, 55.106810681068104],
            [-8.740849084908486, 54.950795079507948],
            [-8.801580158015799, 54.905290529052905],
            [-8.949069906990697, 54.788278827882785],
            [-9.556380638063803, 54.65176517651765],
            [-9.998849884988495, 54.567256725672564],
            [-9.998849884988495, 54.5997599759976],
            [-9.998849884988495, 54.723272327232721],
            [-9.998849884988495, 54.749274927492749],
            [-9.998849884988495, 55.002800280027998],
            [-9.998849884988495, 55.119811981198119],
            [-9.998849884988495, 55.236823682368232],
            [-9.998849884988495, 55.496849684968495],
            [-9.998849884988495, 55.665866586658666],
            [-9.998849884988495, 55.750375037503751],
            [-9.998849884988495, 55.756875687568758],
            [-9.998849884988495, 55.769876987698765],
            [-9.998849884988495, 55.821882188218822],
            [-9.998849884988495, 55.997399739973993],
            [-9.998849884988495, 56.094909490949092],
            [-9.998849884988495, 56.133913391339135],
            [-9.998849884988495, 56.250925092509249],
            [-9.998849884988495, 56.263926392639263],
            [-9.998849884988495, 56.335433543354334],
            [-9.998849884988495, 56.439443944394441],
            [-9.998849884988495, 56.497949794979498],
            [-9.998849884988495, 56.582458245824583],
            [-9.998849884988495, 56.58895889588959],
            [-9.998849884988495, 56.666966696669668],
            [-9.998849884988495, 56.764476447644761],
            [-9.998849884988495, 56.998499849984995],
            [-9.998849884988495, 57.018001800180016],
            [-9.998849884988495, 57.252025202520251],
            [-9.998849884988495, 57.271527152715272],
            [-9.998849884988495, 57.336533653365336],
            [-9.998849884988495, 57.4015401540154],
            [-9.998849884988495, 57.466546654665464],
            [-9.998849884988495, 57.4990499049905],
            [-9.998849884988495, 57.551055105510549],
            [-9.998849884988495, 57.752575257525748],
            [-9.998849884988495, 57.765576557655763],
            [-9.998849884988495, 57.804580458045805],
            [-9.998849884988495, 57.986598659865983],
            [-9.998849884988495, 57.999599959995997],
            [-9.998849884988495, 58.097109710971097],
            [-9.998849884988495, 58.253125312531253],
            [-9.998849884988495, 58.480648064806481],
            [-9.998849884988495, 58.500150015001495],
            [-9.998849884988495, 58.649664966496651],
            [-9.998849884988495, 58.669166916691665],
            [-9.998849884988495, 58.961696169616957],
            [-9.998849884988495, 58.974697469746971],
            [-9.998849884988495, 58.981198119811978],
            [-9.998849884988495, 59.000700070006999],
            [-9.998849884988495, 59.16971697169717],
            [-9.998849884988495, 59.332233223322334],
            [-9.998849884988495, 59.462246224622461],
            [-9.998849884988495, 59.481748174817483],
            [-9.998849884988495, 59.501250125012497],
            [-9.998849884988495, 59.98229822982298],
            [-9.998849884988495, 60.001800180018002],
            [-9.998849884988495, 60.476347634763478],
            [-9.998849884988495, 60.502350235023499],
            [-9.998849884988495, 60.664866486648663],
            [-9.998849884988495, 61.002900290029004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EFINUIR", name: "FINLAND UIR", fab: "7" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.548879887988804, 69.057205720572057],
            [21.086783678367844, 69.05070507050705],
            [21.234273427342742, 69.206720672067206],
            [21.329707970797088, 69.317231723172313],
            [21.798204820482056, 69.174217421742171],
            [21.937018701870194, 69.063706370637064],
            [22.370812081208129, 68.73217321732173],
            [22.952095209520955, 68.693169316931687],
            [23.316481648164824, 68.660666066606666],
            [23.602785278527861, 68.751675167516751],
            [23.845709570957105, 68.836183618361844],
            [24.001875187518763, 68.79067906790678],
            [24.600510051005102, 68.634663466346638],
            [24.886813681368139, 68.556655665566552],
            [25.311931193119321, 68.823182318231829],
            [25.459420942094212, 68.914191419141915],
            [25.598234823482358, 68.998699869986993],
            [25.754400440044009, 69.330233023302327],
            [25.91924192419242, 69.668266826682668],
            [25.97997299729974, 69.694269426942697],
            [26.144814481448151, 69.759275927592753],
            [26.231573157315736, 69.791779177917789],
            [26.422442244224428, 69.908790879087917],
            [26.500525052505253, 69.91529152915291],
            [26.78682868286829, 69.954295429542952],
            [27.029752975297541, 69.91529152915291],
            [27.576332633263334, 70.077807780778073],
            [28.001450145014502, 70.103810381038102],
            [28.010126012601262, 70.097309730973095],
            [28.166291629162927, 69.91529152915291],
            [28.331133113311338, 69.889288928892881],
            [28.530678067806789, 69.798279827982796],
            [28.799629962996306, 69.752775277527746],
            [28.886388638863892, 69.739773977397732],
            [29.129312931293143, 69.700770077007689],
            [29.181368136813688, 69.64226422642264],
            [29.242099209920994, 69.577257725772569],
            [29.33753375337534, 69.486248624862483],
            [28.834333433343346, 69.21972197219722],
            [28.834333433343346, 69.128712871287121],
            [28.834333433343346, 69.102710271027092],
            [29.051230123012303, 69.018201820182014],
            [28.574057405740575, 68.940194019401929],
            [28.417891789178924, 68.914191419141915],
            [28.799629962996306, 68.875187518751872],
            [28.461271127112724, 68.504650465046495],
            [29.33753375337534, 68.101610161016097],
            [29.996899689968998, 67.698569856985699],
            [29.085933593359343, 66.963996399639967],
            [29.615161516151616, 66.69746974697469],
            [29.762651265126522, 66.398439843984391],
            [29.866761676167627, 66.164416441644164],
            [29.953520352035213, 66.021402140214022],
            [30.170417041704184, 65.663866386638659],
            [29.814706470647067, 65.585858585858574],
            [29.623837383738376, 65.059305930593055],
            [29.615161516151616, 64.935793579357934],
            [30.083658365836584, 64.701770177017693],
            [30.500100010001006, 64.233723372337238],
            [30.014251425142518, 63.733173317331733],
            [31.176817681768185, 63.239123912391236],
            [31.26357635763577, 63.148114811481143],
            [31.497824782478261, 62.998599859985994],
            [31.549879987998807, 62.953095309530951],
            [31.107410741074119, 62.420042004200418],
            [30.274527452745275, 61.919491949194921],
            [30.083658365836584, 61.8024802480248],
            [29.944844484448453, 61.724472447244722],
            [29.649864986498656, 61.548954895489544],
            [29.450320032003205, 61.43194319431943],
            [29.111961196119623, 61.256425642564253],
            [28.756250625062506, 61.074407440744075],
            [28.339808980898098, 60.859885988598855],
            [28.166291629162927, 60.749374937493748],
            [27.6457395739574, 60.346334633463343],
            [27.62838783878388, 60.333333333333329],
            [27.472222222222229, 60.235823582358236],
            [27.298704870487057, 60.203320332033201],
            [27.220622062206232, 60.196819681968194],
            [26.942994299429955, 60.170817081708172],
            [26.83888388838885, 60.164316431643165],
            [26.717421742174224, 60.151315131513151],
            [26.665366536653679, 60.144814481448144],
            [26.552580258025813, 60.13181318131813],
            [26.110111011101111, 59.975797579757973],
            [25.867186718671874, 59.884788478847881],
            [25.658965896589663, 59.891289128912888],
            [25.615586558655878, 59.897789778977895],
            [25.580883088308838, 59.897789778977895],
            [25.416041604160426, 59.904290429042902],
            [25.329282928292841, 59.910791079107909],
            [25.251200120012001, 59.904290429042902],
            [25.042979297929804, 59.891289128912888],
            [25.034303430343044, 59.891289128912888],
            [24.999599959996004, 59.891289128912888],
            [24.852110211021113, 59.884788478847881],
            [24.661241124112422, 59.845784578457845],
            [24.453020302030211, 59.800280028002796],
            [24.166716671667174, 59.741774177417739],
            [23.993199319931996, 59.702770277027703],
            [23.880413041304138, 59.676767676767675],
            [23.811006100610065, 59.66376637663766],
            [23.151640164016406, 59.514251425142511],
            [22.986798679867995, 59.475247524752476],
            [22.943419341934202, 59.462246224622461],
            [22.926067606760682, 59.462246224622461],
            [22.804605460546057, 59.436243624362433],
            [22.743874387438751, 59.423242324232419],
            [22.179942994299438, 59.286728672867284],
            [22.136563656365645, 59.280228022802277],
            [21.945694569456954, 59.234723472347234],
            [21.754825482548263, 59.189218921892184],
            [21.000025002500259, 59.000700070006999],
            [20.947969796979706, 59.026702670267028],
            [20.913266326632666, 59.046204620462042],
            [20.81783178317832, 59.104710471047106],
            [20.809155915591568, 59.111211121112106],
            [20.791804180418048, 59.117711771177113],
            [20.783128312831288, 59.12421242124212],
            [20.739748974897495, 59.143714371437142],
            [20.731073107310735, 59.156715671567156],
            [20.687693769376942, 59.176217621762177],
            [20.679017901790182, 59.182718271827184],
            [20.540204020402044, 59.254225422542255],
            [20.427417741774185, 59.319231923192319],
            [20.149789978997909, 59.475247524752476],
            [20.019651965196523, 59.54025402540254],
            [20.019651965196523, 59.546754675467547],
            [20.010976097609763, 59.546754675467547],
            [20.00230023002301, 59.553255325532554],
            [19.98494849484949, 59.559755975597561],
            [19.924217421742178, 59.605260526052604],
            [19.880838083808385, 59.637763776377639],
            [19.802755275527559, 59.696269626962696],
            [19.724672467246734, 59.748274827482746],
            [19.663941394139421, 59.787278727872788],
            [19.620562056205628, 59.819781978197817],
            [19.46439643964397, 59.930293029302931],
            [19.412341234123417, 59.969296929692966],
            [19.403665366536657, 59.975797579757973],
            [19.394989498949897, 59.98229822982298],
            [19.299554955495552, 60.047304730473044],
            [19.264851485148519, 60.066806680668066],
            [19.204120412041206, 60.105810581058101],
            [19.134713471347141, 60.157815781578158],
            [19.100010001000108, 60.183818381838179],
            [19.082658265826588, 60.190319031903186],
            [19.108685868586868, 60.25532553255325],
            [19.134713471347141, 60.3008300830083],
            [19.15206520652066, 60.359335933593357],
            [19.15206520652066, 60.372337233723371],
            [19.178092809280933, 60.469846984698471],
            [19.238823882388246, 60.697369736973698],
            [19.299554955495552, 61.002900290029004],
            [19.316906690669072, 61.067906790679068],
            [19.412341234123417, 61.360436043604359],
            [19.48174817481749, 61.607460746074608],
            [19.490424042404243, 61.620462046204615],
            [19.499099909991003, 61.665966596659665],
            [19.525127512751283, 61.717971797179715],
            [19.542479247924796, 61.763476347634764],
            [19.629237923792388, 61.997499749974999],
            [19.724672467246734, 62.192519251925191],
            [19.820107010701072, 62.39403940394039],
            [19.820107010701072, 62.413541354135411],
            [19.837458745874592, 62.465546554655461],
            [20.010976097609763, 62.829582958295831],
            [20.167141714171422, 63.167616761676165],
            [20.574907490749084, 63.42114211421142],
            [20.609610961096116, 63.434143414341435],
            [20.670342034203429, 63.466646664666463],
            [20.887238723872393, 63.512151215121513],
            [21.034728472847291, 63.538153815381534],
            [21.16486648664867, 63.564156415641563],
            [21.19089408940895, 63.564156415641563],
            [21.312356235623568, 63.583658365836584],
            [21.433818381838186, 63.603160316031598],
            [21.503225322532259, 63.616161616161612],
            [21.581308130813085, 63.681168116811676],
            [21.815556555655569, 63.863186318631861],
            [21.841584158415849, 63.882688268826882],
            [21.954370437043707, 63.960696069606961],
            [21.971722172217227, 63.973697369736975],
            [22.03245324532454, 64.025702570257025],
            [22.084508450845092, 64.064706470647053],
            [22.110536053605365, 64.090709070907081],
            [22.153915391539158, 64.11671167116711],
            [22.587708770877093, 64.448244824482444],
            [22.709170917091718, 64.532753275327536],
            [22.735198519851991, 64.552255225522543],
            [22.795929592959304, 64.597759775977593],
            [22.821957195719577, 64.617261726172615],
            [22.926067606760682, 64.682268226822686],
            [22.969446944694475, 64.701770177017693],
            [23.37721272127213, 65.000800080007991],
            [23.568081808180821, 65.143814381438148],
            [23.724247424742479, 65.254325432543254],
            [23.784978497849792, 65.286828682868276],
            [23.906440644064411, 65.371337133713368],
            [24.027902790279029, 65.45584558455846],
            [24.140689068906894, 65.527352735273524],
            [24.158040804080414, 65.592359235923595],
            [24.140689068906894, 65.767876787678773],
            [24.079957995799589, 65.943394339433951],
            [23.941144114411451, 66.0929092909291],
            [23.819681968196825, 66.177417741774178],
            [23.802330233023305, 66.196919691969185],
            [23.71557155715572, 66.268426842684264],
            [23.70689568956896, 66.274927492749271],
            [23.654840484048414, 66.32043204320432],
            [23.689543954395447, 66.385438543854377],
            [23.741599159915999, 66.46994699469947],
            [23.767626762676272, 66.521952195219512],
            [23.802330233023305, 66.573957395739569],
            [23.889088908890898, 66.736473647364733],
            [23.984523452345243, 66.801480148014804],
            [23.949819981998203, 66.840484048404846],
            [23.837033703370345, 66.931493149314932],
            [23.71557155715572, 67.035503550355031],
            [23.611461146114614, 67.217521752175216],
            [23.767626762676272, 67.33453345334533],
            [23.784978497849792, 67.425542554255429],
            [23.498674867486756, 67.692069206920692],
            [23.576757675767581, 67.893589358935884],
            [23.628812881288134, 67.965096509650962],
            [23.489998999899996, 68.023602360236026],
            [23.342509250925097, 68.127612761276126],
            [23.325157515751584, 68.14061406140614],
            [23.108260826082613, 68.257625762576254],
            [22.500950095009507, 68.439643964396438],
            [21.390439043904394, 68.771177117711773],
            [21.17354235423543, 68.836183618361844],
            [20.548879887988804, 69.057205720572057],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UGGGFIR", name: "TIBILISI FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.997474747474755, 41.292929292929287],
            [44.936743674367449, 41.253925392539252],
            [44.884688468846889, 41.266926692669266],
            [44.832633263326343, 41.27992799279928],
            [44.815281528152823, 41.299429942994294],
            [44.797929792979303, 41.253925392539252],
            [44.867336733673369, 41.214921492149216],
            [44.789253925392543, 41.221422142214223],
            [44.719846984698478, 41.208420842084209],
            [44.702495249524958, 41.221422142214223],
            [44.607060706070612, 41.234423442344237],
            [44.589708970897092, 41.195419541954195],
            [44.581033103310332, 41.188918891889188],
            [44.572357235723587, 41.201920192019202],
            [44.502950295029507, 41.188918891889188],
            [44.528977897789787, 41.221422142214223],
            [44.520302030203027, 41.22792279227923],
            [44.442219221922201, 41.188918891889188],
            [44.346784678467856, 41.234423442344237],
            [44.329432943294336, 41.208420842084209],
            [44.25135013501351, 41.214921492149216],
            [44.22532253225323, 41.247424742474252],
            [44.20797079707971, 41.234423442344237],
            [44.173267326732685, 41.247424742474252],
            [44.181943194319445, 41.221422142214223],
            [44.155915591559165, 41.195419541954195],
            [44.103860386038605, 41.188918891889188],
            [44.060481048104819, 41.188918891889188],
            [43.973722372237233, 41.156415641564152],
            [43.834908490849088, 41.156415641564152],
            [43.782853285328542, 41.136913691369131],
            [43.748149814981502, 41.110911091109109],
            [43.678742874287437, 41.136913691369131],
            [43.609335933593371, 41.123912391239124],
            [43.565956595659571, 41.149914991499145],
            [43.470522052205226, 41.130413041304131],
            [43.4358185818582, 41.18241824182418],
            [43.37508750875088, 41.201920192019202],
            [43.201570157015709, 41.247424742474252],
            [42.975997599759978, 41.429442944294429],
            [42.837183718371847, 41.552955295529557],
            [42.58558355835585, 41.598459845984593],
            [42.51617661766177, 41.442444244424436],
            [42.004300430043017, 41.533453345334536],
            [41.969596959695977, 41.526952695269529],
            [41.830783078307832, 41.435943594359436],
            [41.544479447944795, 41.526952695269529],
            [41.449044904490464, 41.54645464546455],
            [41.284203420342038, 41.598459845984593],
            [40.980548054805482, 41.695969596959699],
            [40.659540954095419, 41.799979997999799],
            [40.329857985798583, 41.897489748974891],
            [40.147664766476652, 42.489048904890488],
            [39.982823282328241, 42.989598959895986],
            [39.913416341634175, 43.184618461846185],
            [40.01752675267528, 43.386138613861384],
            [40.130313031303132, 43.548654865486547],
            [42.715721572157221, 43.165116511651163],
            [42.889238923892393, 43.178117811781178],
            [43.036728672867298, 43.087108710871085],
            [43.279652965296535, 42.918091809180922],
            [44.190619061906204, 42.63206320632063],
            [44.867336733673369, 42.749074907490751],
            [45.804330433043319, 42.476047604760474],
            [45.656840684068413, 42.177017701770176],
            [46.281503150315046, 41.955995599559955],
            [46.446344634463458, 41.897489748974891],
            [46.446344634463458, 41.890989098909891],
            [46.298854885488552, 41.786978697869785],
            [46.298854885488552, 41.585458545854586],
            [46.75, 41.299429942994294],
            [46.645889588958909, 41.05890589058906],
            [46.489723972397243, 41.045904590459045],
            [45.292454245424551, 41.461946194619458],
            [45.197019701970206, 41.396939693969401],
            [45.08423342334234, 41.357935793579358],
            [44.997474747474755, 41.292929292929287],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LFRRFIR", name: "BREST FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.003400340034002, 48.833683368336835],
            [-7.578282828282823, 48.931193119311928],
            [-7.213896389638961, 49.009200920092006],
            [-6.14676467646764, 49.236723672367233],
            [-5.860461046104611, 49.28872887288729],
            [-5.270502050205017, 49.412241224122411],
            [-5.036253625362534, 49.457745774577454],
            [-5.001550155015501, 49.464246424642461],
            [-4.914791479147908, 49.477247724772475],
            [-4.44629462946294, 49.568256825682568],
            [-4.333508350835082, 49.587758775877589],
            [-4.159990999099904, 49.620262026202617],
            [-4.003825382538253, 49.652765276527653],
            [-3.821632163216321, 49.685268526852681],
            [-3.526652665266525, 49.737273727372738],
            [-3.257700770077001, 49.782778277827781],
            [-3.170942094209416, 49.802280228022802],
            [-3.162266226622656, 49.802280228022802],
            [-3.06683168316831, 49.821782178217823],
            [-3.032128212821277, 49.821782178217823],
            [-2.997424742474244, 49.834783478347831],
            [-2.511576157615757, 49.912791279127909],
            [-2.372762276227618, 49.938793879387937],
            [-2.24262426242624, 49.958295829582958],
            [-2.173217321732167, 49.971297129712973],
            [-2.164541454145414, 49.971297129712973],
            [-2.103810381038102, 49.98429842984298],
            [-2.008375837583756, 49.997299729972994],
            [-1.999699969996996, 49.997299729972994],
            [-1.973672367236723, 49.997299729972994],
            [-1.886913691369131, 49.997299729972994],
            [-1.860886088608858, 49.997299729972994],
            [-1.843534353435338, 49.997299729972994],
            [-1.782803280328032, 49.997299729972994],
            [-1.71339633963396, 49.997299729972994],
            [-1.687368736873687, 49.997299729972994],
            [-1.513851385138508, 49.997299729972994],
            [-1.496499649964996, 49.997299729972994],
            [-1.38371337133713, 49.997299729972994],
            [-1.253575357535752, 49.997299729972994],
            [-1.192844284428439, 49.997299729972994],
            [-1.054030403040301, 49.997299729972994],
            [-1.001975197519748, 49.997299729972994],
            [-0.837133713371337, 49.997299729972994],
            [-0.53347834783478, 49.997299729972994],
            [-0.368636863686362, 49.997299729972994],
            [-0.247174717471744, 49.997299729972994],
            [-0.247174717471744, 49.815281528152816],
            [-0.247174717471744, 49.529252925292525],
            [-0.247174717471744, 49.444744474447447],
            [-0.247174717471744, 49.165216521652162],
            [-0.247174717471744, 48.950695069506949],
            [-0.247174717471744, 48.931193119311928],
            [-0.247174717471744, 48.840184018401843],
            [-0.247174717471744, 48.736173617361736],
            [-0.247174717471744, 48.658165816581658],
            [-0.247174717471744, 48.547654765476551],
            [-0.247174717471744, 48.534653465346537],
            [-0.247174717471744, 48.463146314631459],
            [-0.247174717471744, 48.359135913591359],
            [-0.247174717471744, 48.170617061706167],
            [-0.247174717471744, 47.93659365936594],
            [-0.247174717471744, 47.852085208520847],
            [-0.247174717471744, 47.416541654165414],
            [-0.247174717471744, 47.169516951695172],
            [-0.247174717471744, 47.046004600460044],
            [-0.247174717471744, 46.623462346234618],
            [-0.247174717471744, 46.499949994999497],
            [-0.698319831983191, 46.499949994999497],
            [-0.889188918891882, 46.499949994999497],
            [-1.132113211321126, 46.499949994999497],
            [-1.349009900990097, 46.499949994999497],
            [-1.427092709270923, 46.499949994999497],
            [-1.583258325832581, 46.499949994999497],
            [-1.635313531353134, 46.499949994999497],
            [-1.670017001700167, 45.979897989798978],
            [-1.678692869286927, 45.563856385638559],
            [-1.72207220722072, 44.816281628162812],
            [-1.756775677567752, 44.198719871987194],
            [-1.765451545154512, 44.068706870687066],
            [-1.782803280328032, 43.581158115811576],
            [-1.964996499649963, 43.646164616461647],
            [-2.017051705170516, 43.665666566656668],
            [-2.069106910691069, 43.685168516851682],
            [-2.164541454145414, 43.717671767176718],
            [-2.520252025202517, 43.841184118411839],
            [-2.728472847284728, 43.91269126912691],
            [-2.737148714871481, 43.91269126912691],
            [-2.841259125912586, 43.951695169516952],
            [-2.910666066606659, 43.971197119711974],
            [-3.153590359035903, 44.049204920492045],
            [-3.891039103910387, 44.296229622962294],
            [-3.986473647364733, 44.328732873287329],
            [-4.229397939793976, 44.374237423742372],
            [-4.437618761876188, 44.413241324132414],
            [-4.611136113611359, 44.445744574457443],
            [-4.949494949494948, 44.5042504250425],
            [-5.140364036403639, 44.543254325432542],
            [-5.62621262126212, 44.621262126212621],
            [-5.834433443344331, 44.660266026602656],
            [-6.918916891689165, 44.835783578357834],
            [-8.003400340034002, 44.998299829982997],
            [-8.003400340034002, 45.498849884988502],
            [-8.003400340034002, 45.999399939993999],
            [-8.003400340034002, 46.499949994999497],
            [-8.003400340034002, 47.000500050005002],
            [-8.003400340034002, 47.247524752475243],
            [-8.003400340034002, 47.501050105010499],
            [-8.003400340034002, 47.826082608260826],
            [-8.003400340034002, 48.001600160015997],
            [-8.003400340034002, 48.313631363136309],
            [-8.003400340034002, 48.489148914891487],
            [-8.003400340034002, 48.502150215021501],
            [-8.003400340034002, 48.58015801580158],
            [-8.003400340034002, 48.833683368336835],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LECBUIR", name: "BARCELONA UIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.103810381038102, 35.832383238323828],
            [-1.886913691369131, 36.690469046904688],
            [-1.860886088608858, 36.774977497749774],
            [-1.834858485848578, 36.852985298529852],
            [-1.774127412741272, 37.087008700870086],
            [-1.565906590659061, 37.880088008800882],
            [-1.557230723072301, 37.906090609060904],
            [-1.453120312031203, 38.270127012701266],
            [-1.357685768576857, 38.712171217121707],
            [-1.331658165816577, 38.835683568356835],
            [-1.296954695469545, 39.004700470047005],
            [-1.262251225122512, 39.02420242024202],
            [-1.253575357535752, 39.147714771477148],
            [-1.036678667866781, 39.914791479147915],
            [-1.001975197519748, 39.999299929993001],
            [-0.967271727172715, 40.090309030903086],
            [-0.923892389238922, 40.233323332333228],
            [-0.776402640264024, 40.701370137013697],
            [-0.620237023702366, 41.117411741174116],
            [-0.394664466446642, 41.780478047804777],
            [-0.290554055405536, 42.099009900990097],
            [-0.064981498149812, 42.697069706970694],
            [0.151915191519159, 42.72957295729573],
            [0.368811881188122, 42.697069706970694],
            [0.420867086708675, 42.697069706970694],
            [0.559680968096814, 42.690569056905687],
            [0.733198319831985, 42.853085308530851],
            [0.750550055005505, 42.846584658465844],
            [0.86333633363337, 42.814081408140808],
            [0.993474347434748, 42.775077507750773],
            [1.114936493649367, 42.775077507750773],
            [1.227722772277232, 42.723072307230723],
            [1.375212521252131, 42.697069706970694],
            [1.479322932293236, 42.619061906190616],
            [1.644164416441647, 42.619061906190616],
            [1.67886788678868, 42.619061906190616],
            [1.730923092309233, 42.502050205020502],
            [2.00855085508551, 42.36553655365536],
            [2.103985398539855, 42.378537853785375],
            [2.103985398539855, 42.385038503850382],
            [2.164716471647168, 42.385038503850382],
            [2.216771677167721, 42.430543054305431],
            [2.52910291029103, 42.398039803980396],
            [2.693944394439448, 42.417541754175417],
            [2.832758275827587, 42.450045004500453],
            [2.910841084108412, 42.463046304630467],
            [2.980248024802485, 42.482548254825481],
            [3.127737773777383, 42.430543054305431],
            [3.162441244124416, 42.430543054305431],
            [3.179792979297936, 42.430543054305431],
            [3.197144714471449, 42.430543054305431],
            [3.37933793379338, 42.372037203720367],
            [3.500800080008005, 42.333033303330332],
            [3.830483048304835, 42.261526152615261],
            [3.92591759175918, 42.216021602160211],
            [4.004000400040006, 42.209520952095204],
            [4.038703870387046, 42.196519651965197],
            [4.116786678667872, 42.170517051705168],
            [4.333683368336835, 42.099009900990097],
            [4.411766176617668, 42.079507950795076],
            [4.437793779377941, 42.066506650665062],
            [4.663366336633665, 42.001500150015005],
            [4.663366336633665, 41.734973497349735],
            [4.663366336633665, 41.416441644164415],
            [4.663366336633665, 41.05890589058906],
            [4.663366336633665, 40.857385738573853],
            [4.663366336633665, 40.551855185518548],
            [4.663366336633665, 40.499849984998498],
            [4.663366336633665, 40.389338933893384],
            [4.663366336633665, 40.285328532853285],
            [4.663366336633665, 40.181318131813185],
            [4.663366336633665, 40.064306430643065],
            [4.663366336633665, 40.031803180318036],
            [4.663366336633665, 39.953795379537951],
            [4.663366336633665, 39.719771977197723],
            [4.663366336633665, 39.583258325832581],
            [4.680718071807185, 38.998199819981998],
            [3.951945194519453, 38.478147814781479],
            [3.752400240024009, 38.335133513351337],
            [3.552855285528558, 38.263626362636259],
            [3.42271727172718, 38.211621162116209],
            [3.205820582058209, 38.133613361336131],
            [3.153765376537656, 38.11411141114111],
            [2.589833983398343, 37.899589958995904],
            [2.329557955795586, 37.802080208020797],
            [2.121337133713375, 37.717571757175719],
            [1.496674667466749, 37.444544454445449],
            [1.123612361236127, 37.275527552755278],
            [0.099859985998606, 36.917991799179916],
            [-0.02160216021602, 36.872487248724873],
            [-1.019326932693268, 36.456445644564454],
            [-1.140789078907886, 36.404440444044404],
            [-1.496499649964996, 36.254925492549255],
            [-2.025727572757269, 35.884388438843885],
            [-1.999699969996996, 35.832383238323828],
            [-2.103810381038102, 35.832383238323828],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LECMFIR", name: "MADRID FIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.000700070006999, 43.002600260026],
            [-13.000700070006999, 43.503150315031505],
            [-13.000700070006999, 43.997199719971995],
            [-13.000700070006999, 44.497749774977493],
            [-13.000700070006999, 44.998299829982997],
            [-12.91394139413941, 44.998299829982997],
            [-12.584258425842581, 44.998299829982997],
            [-12.497499749974995, 44.998299829982997],
            [-12.280603060306028, 44.998299829982997],
            [-12.002975297529751, 44.998299829982997],
            [-11.569181918191816, 44.998299829982997],
            [-11.499774977497747, 44.998299829982997],
            [-10.996574657465743, 44.998299829982997],
            [-10.502050205020499, 44.998299829982997],
            [-9.998849884988495, 44.998299829982997],
            [-9.83400840084008, 44.998299829982997],
            [-9.495649564956494, 44.998299829982997],
            [-9.131263126312628, 44.998299829982997],
            [-9.001125112511247, 44.998299829982997],
            [-8.749524952495246, 44.998299829982997],
            [-8.003400340034002, 44.998299829982997],
            [-6.918916891689165, 44.835783578357834],
            [-5.834433443344331, 44.660266026602656],
            [-5.62621262126212, 44.621262126212621],
            [-5.140364036403639, 44.543254325432542],
            [-4.949494949494948, 44.5042504250425],
            [-4.611136113611359, 44.445744574457443],
            [-4.437618761876188, 44.413241324132414],
            [-4.229397939793976, 44.374237423742372],
            [-3.986473647364733, 44.328732873287329],
            [-3.891039103910387, 44.296229622962294],
            [-3.153590359035903, 44.049204920492045],
            [-2.910666066606659, 43.971197119711974],
            [-2.841259125912586, 43.951695169516952],
            [-2.737148714871481, 43.91269126912691],
            [-2.728472847284728, 43.91269126912691],
            [-2.520252025202517, 43.841184118411839],
            [-2.164541454145414, 43.717671767176718],
            [-2.069106910691069, 43.685168516851682],
            [-2.017051705170516, 43.665666566656668],
            [-1.964996499649963, 43.646164616461647],
            [-1.782803280328032, 43.581158115811576],
            [-1.782803280328032, 43.386138613861384],
            [-1.739423942394232, 43.353635363536355],
            [-1.72207220722072, 43.314631463146313],
            [-1.617961796179614, 43.288628862886284],
            [-1.565906590659061, 43.256125612561256],
            [-1.513851385138508, 43.295129512951291],
            [-1.487823782378236, 43.282128212821277],
            [-1.470472047204716, 43.288628862886284],
            [-1.453120312031203, 43.061106110611064],
            [-1.331658165816577, 43.04810481048105],
            [-1.106085608560853, 43.009100910091007],
            [-0.975947594759475, 42.970097009700964],
            [-0.84580958095809, 42.931093109310929],
            [-0.724347434743471, 42.8985898589859],
            [-0.654940494049399, 42.872587258725872],
            [-0.464071407140707, 42.814081408140808],
            [-0.299229922992296, 42.840084008400837],
            [-0.247174717471744, 42.788078807880787],
            [-0.203795379537951, 42.762076207620765],
            [-0.064981498149812, 42.697069706970694],
            [-0.290554055405536, 42.099009900990097],
            [-0.394664466446642, 41.780478047804777],
            [-0.620237023702366, 41.117411741174116],
            [-0.776402640264024, 40.701370137013697],
            [-0.923892389238922, 40.233323332333228],
            [-0.967271727172715, 40.090309030903086],
            [-1.001975197519748, 39.999299929993001],
            [-1.036678667866781, 39.914791479147915],
            [-1.253575357535752, 39.147714771477148],
            [-1.262251225122512, 39.02420242024202],
            [-1.296954695469545, 39.004700470047005],
            [-1.331658165816577, 38.835683568356835],
            [-1.357685768576857, 38.712171217121707],
            [-1.453120312031203, 38.270127012701266],
            [-1.557230723072301, 37.906090609060904],
            [-1.565906590659061, 37.880088008800882],
            [-1.774127412741272, 37.087008700870086],
            [-1.834858485848578, 36.852985298529852],
            [-1.860886088608858, 36.774977497749774],
            [-1.886913691369131, 36.690469046904688],
            [-2.103810381038102, 35.832383238323828],
            [-2.112486248624862, 35.832383238323828],
            [-2.685093509350935, 35.832383238323828],
            [-3.049479947994797, 35.832383238323828],
            [-3.231673167316728, 35.832383238323828],
            [-3.266376637663761, 35.832383238323828],
            [-3.431218121812179, 35.832383238323828],
            [-3.439893989398939, 35.832383238323828],
            [-3.500625062506245, 35.832383238323828],
            [-3.700170017001696, 35.832383238323828],
            [-3.96044604460446, 35.832383238323828],
            [-4.003825382538253, 35.832383238323828],
            [-4.368211821182115, 35.832383238323828],
            [-4.897439743974395, 35.832383238323828],
            [-5.123012301230119, 35.832383238323828],
            [-5.140364036403639, 35.832383238323828],
            [-5.66959195919592, 35.832383238323828],
            [-5.695619561956192, 35.832383238323828],
            [-5.955895589558956, 35.832383238323828],
            [-6.242199219921986, 35.832383238323828],
            [-6.320282028202818, 35.832383238323828],
            [-6.450420042004197, 35.832383238323828],
            [-6.632613261326128, 35.832383238323828],
            [-6.719371937193714, 35.832383238323828],
            [-6.754075407540753, 35.832383238323828],
            [-6.996999699969997, 35.832383238323828],
            [-7.387413741374132, 35.832383238323828],
            [-7.387413741374132, 35.968896889688963],
            [-7.387413741374132, 36.027402740274027],
            [-7.387413741374132, 36.664466446644667],
            [-7.387413741374132, 36.768476847684767],
            [-7.387413741374132, 36.833483348334838],
            [-7.387413741374132, 36.917991799179916],
            [-7.387413741374132, 37.03500350035003],
            [-7.387413741374132, 37.074007400740072],
            [-7.387413741374132, 37.126012601260129],
            [-7.430793079307925, 37.243024302430243],
            [-7.422117211721172, 37.347034703470342],
            [-7.430793079307925, 37.41854185418542],
            [-7.500200020001998, 37.581058105810584],
            [-7.318006800680067, 37.828082808280826],
            [-7.248599859985994, 37.893089308930897],
            [-7.127137713771376, 38.003600360036003],
            [-7.101110111011096, 38.023102310231025],
            [-6.996999699969997, 38.081608160816081],
            [-7.02302730273027, 38.127112711271124],
            [-6.996999699969997, 38.153115311531153],
            [-7.170517051705168, 38.348134813481352],
            [-7.300655065506547, 38.497649764976501],
            [-7.283303330333027, 38.549654965496543],
            [-7.248599859985994, 38.634163416341636],
            [-7.222572257225721, 38.731673167316728],
            [-7.153165316531648, 38.803180318031806],
            [-7.066406640664063, 38.900690069006899],
            [-6.970972097209717, 38.998199819981998],
            [-6.953620362036197, 39.02420242024202],
            [-6.962296229622957, 39.037203720372034],
            [-6.962296229622957, 39.056705670567055],
            [-6.970972097209717, 39.076207620762077],
            [-7.00567556755675, 39.102210221022105],
            [-7.01435143514351, 39.115211521152119],
            [-7.03170317031703, 39.108710871087112],
            [-7.057730773077303, 39.128212821282126],
            [-7.153165316531648, 39.160716071607155],
            [-7.222572257225721, 39.219221922192219],
            [-7.300655065506547, 39.329732973297325],
            [-7.370062006200619, 39.531253125312531],
            [-7.387413741374132, 39.537753775377539],
            [-7.500200020001998, 39.661266126612659],
            [-6.996999699969997, 39.667766776677666],
            [-6.996999699969997, 39.687268726872688],
            [-6.875537553755372, 39.87578757875788],
            [-6.875537553755372, 39.895289528952894],
            [-6.875537553755372, 39.992799279927993],
            [-6.988323832383237, 40.122812281228121],
            [-6.780103010301026, 40.363336333633363],
            [-6.814806480648059, 40.402340234023399],
            [-6.797454745474546, 40.519351935193519],
            [-6.719371937193714, 40.623362336233626],
            [-6.762751275127506, 40.668866886688669],
            [-6.814806480648059, 40.766376637663768],
            [-6.832158215821579, 40.831383138313825],
            [-6.780103010301026, 41.130413041304131],
            [-6.389688968896884, 41.377437743774379],
            [-6.276902690269026, 41.481448144814479],
            [-6.250875087508746, 41.585458545854586],
            [-6.372337233723371, 41.676467646764678],
            [-6.50247524752475, 41.767476747674763],
            [-6.563206320632062, 41.936493649364934],
            [-6.623937393739368, 41.942994299429941],
            [-7.118461846184616, 41.936493649364934],
            [-7.265951595159514, 41.851985198519856],
            [-7.474172417241718, 41.832483248324834],
            [-7.604310431043103, 41.858485848584856],
            [-7.777827782778274, 41.910491049104905],
            [-8.081483148314828, 41.812981298129813],
            [-8.228972897289726, 42.13801380138014],
            [-8.567331733173315, 42.066506650665062],
            [-8.844959495949592, 41.864986498649863],
            [-8.923042304230421, 41.87148714871487],
            [-9.365511551155112, 41.92349234923492],
            [-9.400215021502149, 41.936493649364934],
            [-9.998849884988495, 42.001500150015005],
            [-10.068256825682564, 42.021002100210019],
            [-10.814381438143812, 42.281028102810282],
            [-12.002975297529751, 42.677567756775673],
            [-12.497499749974995, 42.833583358335829],
            [-13.000700070006999, 43.002600260026],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LMMMUIR", name: "MALTA UIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.499949994999504, 36.501950195019504],
            [11.62141214121413, 36.501950195019504],
            [11.820957095709574, 36.501950195019504],
            [12.12461246124613, 36.501950195019504],
            [12.584433443344338, 36.501950195019504],
            [12.844709470947102, 36.501950195019504],
            [13.209095909590964, 36.501950195019504],
            [13.538778877887793, 36.501950195019504],
            [13.790379037903797, 36.501950195019504],
            [13.972572257225728, 36.501950195019504],
            [14.102710271027107, 36.501950195019504],
            [14.250200020002005, 36.501950195019504],
            [14.310931093109318, 36.501950195019504],
            [14.458420842084216, 36.501950195019504],
            [14.467096709670969, 36.501950195019504],
            [14.571207120712074, 36.501950195019504],
            [14.831483148314838, 36.501950195019504],
            [14.996324632463249, 36.501950195019504],
            [15.620987098709875, 36.501950195019504],
            [16.002725272527258, 36.501950195019504],
            [16.453870387038712, 36.501950195019504],
            [16.852960296029607, 36.501950195019504],
            [17.425567556755681, 36.501950195019504],
            [17.77260226022603, 36.501950195019504],
            [18.336533653365343, 36.501950195019504],
            [18.995899589959002, 36.501950195019504],
            [21.15619061906191, 35.520352035203516],
            [21.963046304630467, 35.130313031303132],
            [23.004150415041508, 34.62326232623262],
            [23.585433543354341, 34.330733073307329],
            [21.468521852185226, 34.330733073307329],
            [20.479472947294738, 34.330733073307329],
            [19.039278927892795, 34.330733073307329],
            [18.128312831283132, 34.330733073307329],
            [17.087208720872091, 34.330733073307329],
            [16.948394839483953, 34.330733073307329],
            [16.36711171117112, 34.330733073307329],
            [15.169841984198428, 34.330733073307329],
            [14.70134513451346, 34.330733073307329],
            [13.729647964796484, 34.330733073307329],
            [13.313206320632069, 34.330733073307329],
            [13.243799379937997, 34.330733073307329],
            [12.870737073707375, 34.330733073307329],
            [12.636488648864891, 34.330733073307329],
            [11.499949994999504, 34.330733073307329],
            [11.499949994999504, 34.525752575257528],
            [11.499949994999504, 35.045804580458046],
            [11.499949994999504, 35.656865686568658],
            [11.499949994999504, 35.825882588258821],
            [11.499949994999504, 36.501950195019504],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LSASFIR", name: "SWITZERLAND FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.578457845784584, 47.605060506050606],
            [7.682568256825689, 47.566056605660563],
            [7.699919991999202, 47.540054005400535],
            [7.778002800280035, 47.553055305530549],
            [7.830058005800588, 47.579057905790577],
            [7.847409740974101, 47.579057905790577],
            [7.856085608560861, 47.592059205920592],
            [7.899464946494653, 47.585558555855584],
            [7.908140814081413, 47.579057905790577],
            [7.942844284428446, 47.546554655465542],
            [8.081658165816584, 47.559555955595556],
            [8.20312031203121, 47.61806180618062],
            [8.281203120312036, 47.605060506050606],
            [8.454720472047207, 47.57255725572557],
            [8.524127412741279, 47.631063106310634],
            [8.524127412741279, 47.644064406440641],
            [8.411341134113414, 47.676567656765677],
            [8.498099809980999, 47.767576757675769],
            [8.628237823782385, 47.767576757675769],
            [8.888513851385142, 47.657065706570656],
            [9.001300130013007, 47.676567656765677],
            [9.03600360036004, 47.683068306830677],
            [9.365686568656869, 47.605060506050606],
            [9.452445244524455, 47.585558555855584],
            [9.5478797879788, 47.546554655465542],
            [9.55655565556556, 47.527052705270521],
            [9.57390739073908, 47.488048804880485],
            [9.617286728672873, 47.449044904490449],
            [9.651990199019906, 47.410041004100407],
            [9.669341934193426, 47.390539053905385],
            [9.617286728672873, 47.371037103710371],
            [9.599934993499353, 47.358035803580357],
            [9.58258325832584, 47.312531253125314],
            [9.53920392039204, 47.267026702670265],
            [9.617286728672873, 47.156515651565158],
            [9.634638463846386, 47.13051305130513],
            [9.58258325832584, 47.052505250525051],
            [9.591259125912593, 47.052505250525051],
            [9.816831683168324, 47.020002000200023],
            [10.111811181118114, 46.844484448444845],
            [10.146514651465154, 46.81198119811981],
            [10.276652665266532, 46.909490949094909],
            [10.389438943894397, 47.000500050005002],
            [10.484873487348743, 46.948494849484945],
            [10.467521752175223, 46.863986398639867],
            [10.467521752175223, 46.701470147014703],
            [10.476197619761983, 46.564956495649568],
            [10.337383738373845, 46.545454545454547],
            [10.215921592159219, 46.636463646364632],
            [10.198569856985706, 46.623462346234618],
            [10.146514651465154, 46.616961696169611],
            [10.059755975597561, 46.506450645064504],
            [10.042404240424048, 46.480448044804476],
            [9.90359035903591, 46.415441544154419],
            [9.5478797879788, 46.304930493049305],
            [9.521852185218528, 46.337433743374334],
            [9.504500450045008, 46.350435043504348],
            [9.365686568656869, 46.512951295129511],
            [9.278927892789284, 46.480448044804476],
            [9.278927892789284, 46.415441544154419],
            [9.278927892789284, 46.311431143114305],
            [9.296279627962804, 46.207420742074206],
            [9.226872687268731, 46.155415541554156],
            [9.209520952095211, 46.142414241424142],
            [9.03600360036004, 46.025402540254021],
            [9.070707070707073, 45.869386938693864],
            [9.03600360036004, 45.862886288628857],
            [8.966596659665974, 45.84988498849885],
            [8.905865586558662, 45.908390839083907],
            [8.853810381038109, 45.992899289928992],
            [8.662941294129418, 46.103410341034106],
            [8.567506750675072, 46.168416841684163],
            [8.506775677567759, 46.226922692269227],
            [8.480748074807487, 46.246424642464248],
            [8.463396339633967, 46.252925292529255],
            [8.446044604460454, 46.27242724272427],
            [8.446044604460454, 46.278927892789277],
            [8.437368736873694, 46.467446744674461],
            [8.289878987898796, 46.415441544154419],
            [8.315906590659068, 46.38943894389439],
            [8.229147914791483, 46.330933093309326],
            [8.090334033403344, 46.26592659265927],
            [8.081658165816584, 46.259425942594262],
            [8.133713371337137, 46.181418141814177],
            [8.029602960296032, 46.077407740774078],
            [7.968871887188726, 45.999399939993999],
            [7.908140814081413, 45.992899289928992],
            [7.873437343734381, 45.953895389538957],
            [7.864761476147621, 45.940894089408943],
            [7.847409740974101, 45.927892789278928],
            [7.725947594759482, 45.934393439343935],
            [7.543754375437551, 45.966896689668964],
            [7.448319831983206, 45.927892789278928],
            [7.101285128512856, 45.875887588758872],
            [7.049229922992303, 45.9018901890189],
            [6.988498849884991, 46.005900590058999],
            [6.80630563056306, 46.142414241424142],
            [6.81498149814982, 46.239923992399241],
            [6.780278027802787, 46.343934393439341],
            [6.745574557455747, 46.343934393439341],
            [6.658815881588161, 46.363436343634362],
            [6.528677867786783, 46.356935693569355],
            [6.47662266226623, 46.382938293829383],
            [6.294429442944299, 46.317931793179312],
            [6.216346634663473, 46.207420742074206],
            [6.077532753275335, 46.187918791879184],
            [6.008125812581262, 46.142414241424142],
            [5.97342234223423, 46.148914891489149],
            [5.97342234223423, 46.194419441944191],
            [6.120912091209128, 46.330933093309326],
            [6.068856885688575, 46.415441544154419],
            [6.094884488448848, 46.525952595259525],
            [6.094884488448848, 46.545454545454547],
            [6.164291429142921, 46.610461046104611],
            [6.251050105010506, 46.668966896689668],
            [6.277077707770779, 46.681968196819682],
            [6.294429442944299, 46.701470147014703],
            [6.320457045704572, 46.714471447144717],
            [6.389863986398645, 46.740474047404739],
            [6.407215721572165, 46.753475347534753],
            [6.441919191919197, 46.75997599759976],
            [6.398539853985405, 46.779477947794774],
            [6.45927092709271, 46.850985098509852],
            [6.433243324332437, 46.922492249224916],
            [6.47662266226623, 46.961496149614959],
            [6.520002000200023, 46.974497449744973],
            [6.641464146414648, 47.02650265026503],
            [6.719546954695474, 47.052505250525051],
            [6.81498149814982, 47.13051305130513],
            [6.953795379537958, 47.241024102410236],
            [7.005850585058511, 47.325532553255329],
            [7.066581658165823, 47.345034503450343],
            [6.901740174017405, 47.384038403840378],
            [6.962471247124718, 47.462046204620464],
            [6.997174717471751, 47.468546854685471],
            [6.988498849884991, 47.494549454945492],
            [7.144664466446649, 47.501050105010499],
            [7.205395539553962, 47.423042304230421],
            [7.378912891289133, 47.416541654165414],
            [7.430968096809686, 47.488048804880485],
            [7.474347434743478, 47.494549454945492],
            [7.578457845784584, 47.605060506050606],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LCCCUIR", name: "NICOSIA UIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.670917091709171, 35.916891689168921],
            [35.53210321032104, 35.552855285528551],
            [35.714296429642971, 35.195319531953196],
            [35.748999899990011, 34.584258425842584],
            [35.289178917891803, 34.538753875387542],
            [35.046254625462552, 34.480248024802478],
            [34.872737273727381, 34.369736973697371],
            [34.742599259925996, 34.24622462246225],
            [34.621137113711384, 34.03170317031703],
            [34.603785378537864, 33.947194719471945],
            [34.595109510951104, 33.888688868886888],
            [34.586433643364344, 33.830183018301831],
            [34.612461246124624, 33.648164816481646],
            [34.933468346834687, 33.16711671167117],
            [34.864061406140621, 33.102110211021099],
            [34.603785378537864, 33.089108910891085],
            [34.551730173017305, 32.855085508550857],
            [34.543054305430545, 32.783578357835779],
            [34.352185218521853, 32.614561456145616],
            [34.196019601960202, 32.491049104910488],
            [34.169991999199922, 32.497549754975495],
            [34.057205720572071, 32.335033503350331],
            [33.996474647464751, 32.172517251725168],
            [33.979122912291231, 31.834483448344834],
            [33.102860286028616, 32.218021802180218],
            [32.07910791079108, 32.63406340634063],
            [31.662666266626672, 32.809580958095808],
            [30.951245124512454, 33.102110211021099],
            [29.996899689968998, 33.498649864986497],
            [29.996899689968998, 33.732673267326732],
            [29.996899689968998, 34.324232423242321],
            [29.996899689968998, 34.499749974997499],
            [29.996899689968998, 34.584258425842584],
            [29.996899689968998, 34.649264926492648],
            [29.996899689968998, 34.870287028702869],
            [29.996899689968998, 34.915791579157911],
            [29.996899689968998, 34.967796779677968],
            [29.996899689968998, 35.201820182018196],
            [29.996899689968998, 35.585358535853587],
            [29.996899689968998, 35.663366336633658],
            [29.996899689968998, 35.832383238323828],
            [29.996899689968998, 36.079407940794077],
            [30.708320832083217, 36.059905990599063],
            [30.821107110711083, 36.040404040404042],
            [31.549879987998807, 36.027402740274027],
            [32.99874987498751, 35.949394939493949],
            [33.285053505350547, 35.936393639363935],
            [33.319756975697572, 35.929892989298928],
            [33.441219121912198, 35.923392339233928],
            [33.545329532953303, 35.916891689168921],
            [34.013826382638271, 35.916891689168921],
            [34.66451645164517, 35.916891689168921],
            [35.670917091709171, 35.916891689168921],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LECBFIR", name: "BARCELONA FIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.103810381038102, 35.832383238323828],
            [-1.886913691369131, 36.690469046904688],
            [-1.860886088608858, 36.774977497749774],
            [-1.834858485848578, 36.852985298529852],
            [-1.774127412741272, 37.087008700870086],
            [-1.565906590659061, 37.880088008800882],
            [-1.557230723072301, 37.906090609060904],
            [-1.453120312031203, 38.270127012701266],
            [-1.357685768576857, 38.712171217121707],
            [-1.331658165816577, 38.835683568356835],
            [-1.296954695469545, 39.004700470047005],
            [-1.262251225122512, 39.02420242024202],
            [-1.253575357535752, 39.147714771477148],
            [-1.036678667866781, 39.914791479147915],
            [-1.001975197519748, 39.999299929993001],
            [-0.967271727172715, 40.090309030903086],
            [-0.923892389238922, 40.233323332333228],
            [-0.776402640264024, 40.701370137013697],
            [-0.620237023702366, 41.117411741174116],
            [-0.394664466446642, 41.780478047804777],
            [-0.290554055405536, 42.099009900990097],
            [-0.064981498149812, 42.697069706970694],
            [0.151915191519159, 42.72957295729573],
            [0.368811881188122, 42.697069706970694],
            [0.420867086708675, 42.697069706970694],
            [0.559680968096814, 42.690569056905687],
            [0.733198319831985, 42.853085308530851],
            [0.750550055005505, 42.846584658465844],
            [0.86333633363337, 42.814081408140808],
            [0.993474347434748, 42.775077507750773],
            [1.114936493649367, 42.775077507750773],
            [1.227722772277232, 42.723072307230723],
            [1.375212521252131, 42.697069706970694],
            [1.479322932293236, 42.619061906190616],
            [1.644164416441647, 42.619061906190616],
            [1.67886788678868, 42.619061906190616],
            [1.730923092309233, 42.502050205020502],
            [2.00855085508551, 42.36553655365536],
            [2.103985398539855, 42.378537853785375],
            [2.103985398539855, 42.385038503850382],
            [2.164716471647168, 42.385038503850382],
            [2.216771677167721, 42.430543054305431],
            [2.52910291029103, 42.398039803980396],
            [2.693944394439448, 42.417541754175417],
            [2.832758275827587, 42.450045004500453],
            [2.910841084108412, 42.463046304630467],
            [2.980248024802485, 42.482548254825481],
            [3.127737773777383, 42.430543054305431],
            [3.162441244124416, 42.430543054305431],
            [3.179792979297936, 42.430543054305431],
            [3.197144714471449, 42.430543054305431],
            [3.37933793379338, 42.372037203720367],
            [3.500800080008005, 42.333033303330332],
            [3.830483048304835, 42.261526152615261],
            [3.92591759175918, 42.216021602160211],
            [4.004000400040006, 42.209520952095204],
            [4.038703870387046, 42.196519651965197],
            [4.116786678667872, 42.170517051705168],
            [4.333683368336835, 42.099009900990097],
            [4.411766176617668, 42.079507950795076],
            [4.437793779377941, 42.066506650665062],
            [4.663366336633665, 42.001500150015005],
            [4.663366336633665, 41.734973497349735],
            [4.663366336633665, 41.416441644164415],
            [4.663366336633665, 41.05890589058906],
            [4.663366336633665, 40.857385738573853],
            [4.663366336633665, 40.551855185518548],
            [4.663366336633665, 40.499849984998498],
            [4.663366336633665, 40.389338933893384],
            [4.663366336633665, 40.285328532853285],
            [4.663366336633665, 40.181318131813185],
            [4.663366336633665, 40.064306430643065],
            [4.663366336633665, 40.031803180318036],
            [4.663366336633665, 39.953795379537951],
            [4.663366336633665, 39.719771977197723],
            [4.663366336633665, 39.583258325832581],
            [4.680718071807185, 38.998199819981998],
            [3.951945194519453, 38.478147814781479],
            [3.752400240024009, 38.335133513351337],
            [3.552855285528558, 38.263626362636259],
            [3.42271727172718, 38.211621162116209],
            [3.205820582058209, 38.133613361336131],
            [3.153765376537656, 38.11411141114111],
            [2.589833983398343, 37.899589958995904],
            [2.329557955795586, 37.802080208020797],
            [2.121337133713375, 37.717571757175719],
            [1.496674667466749, 37.444544454445449],
            [1.123612361236127, 37.275527552755278],
            [0.099859985998606, 36.917991799179916],
            [-0.02160216021602, 36.872487248724873],
            [-1.019326932693268, 36.456445644564454],
            [-1.140789078907886, 36.404440444044404],
            [-1.496499649964996, 36.254925492549255],
            [-2.025727572757269, 35.884388438843885],
            [-1.999699969996996, 35.832383238323828],
            [-2.103810381038102, 35.832383238323828],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EYVLFIR", name: "VILNIUS FIR", fab: "1" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.630663066306639, 55.67886788678868],
            [26.552580258025813, 55.457845784578453],
            [26.474497449744987, 55.13931393139314],
            [25.780428042804289, 54.859785978597856],
            [25.737048704870489, 54.690769076907692],
            [25.763076307630769, 54.573757375737571],
            [25.615586558655878, 54.469746974697465],
            [25.589558955895598, 54.385238523852387],
            [25.554855485548558, 54.326732673267323],
            [25.806455645564569, 54.235723572357237],
            [25.780428042804289, 54.164216421642159],
            [25.546179617961798, 54.157715771577159],
            [25.520152015201532, 54.294229422942294],
            [25.268551855185521, 54.274727472747273],
            [25.225172517251735, 54.261726172617259],
            [25.199144914491455, 54.216221622162216],
            [25.06900690069007, 54.190219021902188],
            [24.774027402740273, 54.118711871187116],
            [24.808730873087313, 54.053705370537052],
            [24.687268726872688, 53.982198219821981],
            [24.140689068906894, 53.956195619561953],
            [23.897764776477651, 53.949694969496946],
            [23.741599159915999, 53.930193019301932],
            [23.40324032403241, 54.196719671967195],
            [23.056205620562061, 54.307230723072308],
            [22.752550255025511, 54.65176517651765],
            [22.735198519851991, 54.944294429442941],
            [22.596384638463853, 55.054805480548055],
            [21.720122012201223, 55.13931393139314],
            [21.433818381838186, 55.236823682368232],
            [20.947969796979706, 55.282328232823282],
            [20.913266326632666, 55.301830183018303],
            [20.2972797279728, 55.470847084708467],
            [20.288603860386047, 55.477347734773474],
            [19.837458745874592, 55.600860086008602],
            [19.377637763776384, 55.73087308730873],
            [19.325582558255832, 55.743874387438744],
            [19.091334133413348, 55.808880888088808],
            [18.015526552655274, 56.094909490949092],
            [18.06758175817582, 56.120912091209121],
            [18.284478447844791, 56.231423142314227],
            [18.510051005100514, 56.348434843484348],
            [18.900465046504657, 56.296429642964291],
            [19.256175617561759, 56.250925092509249],
            [19.282203220322039, 56.250925092509249],
            [19.577182718271835, 56.211921192119213],
            [20.080383038303836, 56.146914691469142],
            [20.184493449344941, 56.133913391339135],
            [20.227872787278734, 56.127412741274128],
            [20.496824682468251, 56.088408840884085],
            [20.670342034203429, 56.068906890689064],
            [20.748424842484255, 56.068906890689064],
            [21.060756075607564, 56.068906890689064],
            [21.095459545954604, 56.075407540754071],
            [21.225597559755983, 56.153415341534149],
            [21.277652765276535, 56.179417941794178],
            [21.312356235623568, 56.205420542054206],
            [21.433818381838186, 56.244424442444242],
            [21.555280528052812, 56.309430943094306],
            [21.607335733573365, 56.32243224322432],
            [21.850260026002609, 56.36793679367937],
            [21.945694569456954, 56.37443744374437],
            [21.997749774977507, 56.413441344134412],
            [22.162591259125918, 56.419941994199419],
            [22.353460346034609, 56.393939393939391],
            [22.613736373637373, 56.380938093809377],
            [23.264426442644272, 56.37443744374437],
            [23.429267926792683, 56.361436143614362],
            [23.758950895089512, 56.37443744374437],
            [23.845709570957105, 56.341934193419341],
            [24.027902790279029, 56.296429642964291],
            [24.062606260626069, 56.276927692769277],
            [24.505075507550757, 56.276927692769277],
            [24.513751375137517, 56.283428342834284],
            [24.626537653765382, 56.36793679367937],
            [24.895489548954899, 56.445944594459441],
            [24.956220622062219, 56.36793679367937],
            [25.06900690069007, 56.244424442444242],
            [25.155765576557656, 56.172917291729171],
            [25.363986398639867, 56.153415341534149],
            [25.572207220722078, 56.140414041404142],
            [25.867186718671874, 55.997399739973993],
            [26.309655965596562, 55.802380238023801],
            [26.630663066306639, 55.67886788678868],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LGGGFIR", name: "ATHINAI FIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.995899589959002, 38.881188118811878],
            [18.995899589959002, 39.29072907290729],
            [18.995899589959002, 39.394739473947396],
            [18.995899589959002, 39.55725572557256],
            [18.995899589959002, 39.817281728172816],
            [18.995899589959002, 39.934293429342929],
            [18.995899589959002, 40.057805780578057],
            [18.995899589959002, 40.246324632463242],
            [18.995899589959002, 40.350335033503349],
            [18.995899589959002, 40.415341534153413],
            [19.44704470447045, 39.999299929993001],
            [19.585858585858588, 39.979797979797979],
            [19.585858585858588, 39.973297329732972],
            [19.750700070007007, 39.947294729472944],
            [19.846134613461352, 39.901790179017901],
            [19.97627262726273, 39.83028302830283],
            [20.00230023002301, 39.680768076807681],
            [20.123762376237629, 39.667766776677666],
            [20.210521052105214, 39.641764176417638],
            [20.245224522452254, 39.706770677067709],
            [20.2972797279728, 39.817281728172816],
            [20.366686668666873, 39.817281728172816],
            [20.279927992799287, 39.986298629862986],
            [20.392714271427145, 39.979797979797979],
            [20.401390139013905, 40.057805780578057],
            [20.470797079707978, 40.064306430643065],
            [20.592259225922596, 40.070807080708065],
            [20.670342034203429, 40.096809680968093],
            [20.705045504550462, 40.272327232723271],
            [20.791804180418048, 40.441344134413441],
            [20.956645664566466, 40.480348034803484],
            [21.034728472847291, 40.584358435843583],
            [21.052080208020804, 40.616861686168619],
            [20.956645664566466, 40.831383138313825],
            [21.000025002500259, 40.863886388638861],
            [21.121487148714877, 40.857385738573853],
            [21.355735573557361, 40.876887688768875],
            [21.425142514251434, 40.922392239223925],
            [21.69409440944095, 40.935393539353939],
            [21.780853085308536, 40.935393539353939],
            [21.919666966696674, 41.078407840784081],
            [21.997749774977507, 41.130413041304131],
            [22.03245324532454, 41.130413041304131],
            [22.179942994299438, 41.169416941694166],
            [22.205970597059711, 41.175917591759173],
            [22.431543154315435, 41.117411741174116],
            [22.483598359835987, 41.117411741174116],
            [22.518301830183027, 41.130413041304131],
            [22.613736373637373, 41.175917591759173],
            [22.761226122612264, 41.247424742474252],
            [22.787253725372544, 41.33193319331933],
            [22.986798679867995, 41.351435143514351],
            [23.281778177817785, 41.390439043904394],
            [23.455295529552963, 41.403440344034401],
            [23.507350735073516, 41.383938393839387],
            [23.637488748874894, 41.364436443644365],
            [24.036578657865789, 41.455445544554451],
            [24.123337333733375, 41.54645464546455],
            [24.166716671667174, 41.54645464546455],
            [24.27082708270828, 41.578957895789578],
            [24.314206420642066, 41.520452045204522],
            [24.400965096509651, 41.54645464546455],
            [24.461696169616971, 41.533453345334536],
            [24.600510051005102, 41.448944894489443],
            [24.704620462046208, 41.422942294229422],
            [24.800055005500553, 41.416441644164415],
            [24.878137813781379, 41.396939693969401],
            [25.051655165516564, 41.364436443644365],
            [25.09503450345035, 41.344934493449344],
            [25.12106210621063, 41.351435143514351],
            [25.242524252425255, 41.253925392539252],
            [25.329282928292841, 41.240924092409244],
            [25.485448544854492, 41.292929292929287],
            [25.537503750375038, 41.286428642864287],
            [25.572207220722078, 41.325432543254323],
            [25.624262426242623, 41.305930593059301],
            [25.658965896589663, 41.318931893189315],
            [25.719696969696969, 41.299429942994294],
            [25.737048704870489, 41.325432543254323],
            [25.832483248324834, 41.351435143514351],
            [25.893214321432154, 41.312431243124308],
            [25.99732473247326, 41.33193319331933],
            [26.136138613861391, 41.364436443644365],
            [26.170842084208431, 41.416441644164415],
            [26.066731673167325, 41.734973497349735],
            [26.300980098009802, 41.734973497349735],
            [26.552580258025813, 41.630963096309628],
            [26.595959595959599, 41.33193319331933],
            [26.318331833183322, 41.247424742474252],
            [26.292304230423042, 41.065406540654067],
            [26.318331833183322, 40.948394839483946],
            [26.248924892489256, 40.883388338833882],
            [25.901890189018914, 40.668866886688669],
            [25.901890189018914, 40.564856485648562],
            [25.884538453845394, 40.499849984998498],
            [25.91924192419242, 40.382838283828377],
            [25.554855485548558, 40.096809680968093],
            [25.615586558655878, 39.901790179017901],
            [25.884538453845394, 39.453245324532453],
            [26.153490349034911, 39.368736873687368],
            [26.370387038703882, 39.433743374337432],
            [26.431118111811188, 39.414241424142418],
            [26.500525052505253, 39.329732973297325],
            [26.535228522852293, 39.284228422842283],
            [26.526552655265533, 39.264726472647268],
            [26.752125212521264, 38.998199819981998],
            [26.630663066306639, 38.881188118811878],
            [26.431118111811188, 38.835683568356835],
            [26.318331833183322, 38.6016601660166],
            [26.300980098009802, 38.484648464846487],
            [26.196869686968697, 38.348134813481352],
            [26.170842084208431, 38.244124412441245],
            [26.248924892489256, 38.166116611661167],
            [26.300980098009802, 38.107610761076103],
            [26.526552655265533, 37.984098409840982],
            [26.873587358735875, 37.958095809580954],
            [27.003725372537261, 37.951595159515946],
            [27.029752975297541, 37.88658865886589],
            [27.047104710471046, 37.84108410841084],
            [27.081808180818086, 37.698069806980698],
            [26.977697769776981, 37.652565256525648],
            [26.986373637363741, 37.626562656265627],
            [27.116511651165126, 37.431543154315435],
            [27.099159915991606, 37.132513251325136],
            [27.177242724272432, 37.048004800480044],
            [27.402815281528163, 36.865986598659866],
            [27.229297929792992, 36.716471647164717],
            [27.359435943594363, 36.61246124612461],
            [27.532953295329534, 36.566956695669568],
            [27.723822382238225, 36.638463846384639],
            [27.862636263626371, 36.696969696969695],
            [27.914691469146916, 36.664466446644667],
            [27.992774277427756, 36.495449544954496],
            [28.053505350535062, 36.482448244824482],
            [28.209670967096713, 36.534453445344532],
            [28.48729872987299, 36.547454745474546],
            [28.548029802980309, 36.384938493849383],
            [28.816981698169826, 36.215921592159219],
            [29.250775077507754, 36.085908590859084],
            [29.32018201820182, 36.183418341834184],
            [29.502375237523765, 36.170417041704169],
            [29.58045804580459, 36.144414441444141],
            [29.710596059605962, 36.085908590859084],
            [29.806030603060307, 36.072907290729077],
            [29.996899689968998, 36.079407940794077],
            [29.996899689968998, 35.832383238323828],
            [29.996899689968998, 35.663366336633658],
            [29.996899689968998, 35.585358535853587],
            [29.996899689968998, 35.201820182018196],
            [29.996899689968998, 34.967796779677968],
            [29.996899689968998, 34.915791579157911],
            [29.996899689968998, 34.870287028702869],
            [29.996899689968998, 34.649264926492648],
            [29.996899689968998, 34.584258425842584],
            [29.996899689968998, 34.499749974997499],
            [29.996899689968998, 34.324232423242321],
            [29.996899689968998, 33.732673267326732],
            [29.996899689968998, 33.498649864986497],
            [28.747574757475761, 33.71317131713171],
            [28.270402040204033, 33.797679767976796],
            [27.61971197119712, 33.921192119211923],
            [27.333408340834083, 33.953695369536952],
            [27.168566856685672, 33.999199919991995],
            [26.81285628562857, 33.999199919991995],
            [25.502800280028012, 33.999199919991995],
            [25.14708970897091, 33.999199919991995],
            [24.453020302030211, 33.999199919991995],
            [24.166716671667174, 33.999199919991995],
            [23.585433543354341, 34.330733073307329],
            [23.004150415041508, 34.62326232623262],
            [21.963046304630467, 35.130313031303132],
            [21.15619061906191, 35.520352035203516],
            [18.995899589959002, 36.501950195019504],
            [18.995899589959002, 36.768476847684767],
            [18.995899589959002, 36.963496349634966],
            [18.995899589959002, 37.399039903990399],
            [18.995899589959002, 37.750075007500747],
            [18.995899589959002, 38.341634163416344],
            [18.995899589959002, 38.517151715171515],
            [18.995899589959002, 38.725172517251721],
            [18.995899589959002, 38.881188118811878],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LTAAFIR", name: "ANKARA FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.670917091709171, 35.916891689168921],
            [34.66451645164517, 35.916891689168921],
            [34.013826382638271, 35.916891689168921],
            [33.545329532953303, 35.916891689168921],
            [33.441219121912198, 35.923392339233928],
            [33.319756975697572, 35.929892989298928],
            [33.285053505350547, 35.936393639363935],
            [32.99874987498751, 35.949394939493949],
            [31.549879987998807, 36.027402740274027],
            [30.821107110711083, 36.040404040404042],
            [30.708320832083217, 36.059905990599063],
            [29.996899689968998, 36.079407940794077],
            [29.996899689968998, 36.319931993199319],
            [29.996899689968998, 36.703470347034703],
            [29.996899689968998, 36.833483348334838],
            [29.996899689968998, 36.989498949894987],
            [29.996899689968998, 37.230023002300229],
            [29.996899689968998, 37.431543154315435],
            [29.996899689968998, 37.581058105810584],
            [29.996899689968998, 37.906090609060904],
            [29.996899689968998, 38.016601660166017],
            [29.988223822382238, 38.133613361336131],
            [30.005575557555758, 38.270127012701266],
            [30.907865786578668, 38.653665366536657],
            [30.647589758975897, 39.043704370437041],
            [30.569506950695072, 39.167216721672162],
            [30.534803480348046, 39.251725172517254],
            [31.003300330033014, 39.498749874987496],
            [31.003300330033014, 40.031803180318036],
            [31.003300330033014, 41.000400040003996],
            [31.003300330033014, 42.801080108010801],
            [31.948969896989709, 42.801080108010801],
            [33.180943094309441, 42.801080108010801],
            [34.091909190919097, 42.78157815781578],
            [35.002875287528767, 42.749074907490751],
            [36.269551955195524, 42.72957295729573],
            [36.503800380038015, 42.723072307230723],
            [36.616586658665867, 42.723072307230723],
            [36.911566156615663, 42.710071007100709],
            [37.119786978697874, 42.703570357035701],
            [37.666366636663668, 42.68406840684068],
            [37.709745974597467, 42.68406840684068],
            [37.805180518051813, 42.651565156515652],
            [39.401540154015407, 42.183518351835183],
            [40.000175017501761, 42.001500150015005],
            [40.329857985798583, 41.897489748974891],
            [40.659540954095419, 41.799979997999799],
            [40.980548054805482, 41.695969596959699],
            [41.284203420342038, 41.598459845984593],
            [41.449044904490464, 41.54645464546455],
            [41.544479447944795, 41.526952695269529],
            [41.830783078307832, 41.435943594359436],
            [41.969596959695977, 41.526952695269529],
            [42.004300430043017, 41.533453345334536],
            [42.51617661766177, 41.442444244424436],
            [42.58558355835585, 41.598459845984593],
            [42.837183718371847, 41.552955295529557],
            [42.975997599759978, 41.429442944294429],
            [43.201570157015709, 41.247424742474252],
            [43.37508750875088, 41.201920192019202],
            [43.4358185818582, 41.18241824182418],
            [43.470522052205226, 41.130413041304131],
            [43.565956595659571, 40.993899389938989],
            [43.600660066006611, 40.987398739873981],
            [43.678742874287437, 40.935393539353939],
            [43.687418741874197, 40.902890289028903],
            [43.678742874287437, 40.883388338833882],
            [43.687418741874197, 40.837883788378832],
            [43.748149814981502, 40.707870787078704],
            [43.652715271527157, 40.564856485648562],
            [43.652715271527157, 40.382838283828377],
            [43.661391139113917, 40.116311631163114],
            [43.965046504650473, 40.018801880188022],
            [44.26870187018703, 40.05130513051305],
            [44.502950295029507, 39.999299929993001],
            [44.581033103310332, 39.797779777977794],
            [44.450895089508961, 39.381738173817382],
            [44.086508650865099, 39.323232323232318],
            [44.20797079707971, 38.907190719071906],
            [44.502950295029507, 38.322132213221323],
            [44.303405340534056, 38.049104910491053],
            [44.23399839983999, 37.899589958995904],
            [44.624412441244132, 37.711071107110712],
            [44.667791779177932, 37.106510651065108],
            [44.26870187018703, 36.943994399439944],
            [44.164591459145925, 37.308030803080307],
            [43.644039403940397, 37.223522352235221],
            [43.218921892189229, 37.347034703470342],
            [43.114811481148124, 37.379537953795378],
            [42.819831983198327, 37.308030803080307],
            [42.646314631463156, 37.24952495249525],
            [42.333983398339839, 37.080508050805079],
            [42.247224722472254, 37.269026902690271],
            [41.787403740374046, 37.106510651065108],
            [41.501100110011009, 37.002500250025001],
            [41.232148214821493, 37.074007400740072],
            [41.197444744474453, 37.03500350035003],
            [40.83305830583059, 37.03500350035003],
            [39.332133213321342, 36.664466446644667],
            [39.028477847784785, 36.70997099709971],
            [38.750850085008508, 36.664466446644667],
            [38.369111911191126, 36.898489848984894],
            [37.380063006300631, 36.651465146514653],
            [37.067731773177329, 36.651465146514653],
            [36.668641864186426, 36.833483348334838],
            [36.642614261426147, 36.781478147814781],
            [36.590559055905601, 36.501950195019504],
            [36.703345334533466, 36.235423542354233],
            [36.36498649864987, 36.163916391639162],
            [36.182793279327939, 35.864886488648864],
            [36.035303530353048, 35.884388438843885],
            [35.670917091709171, 35.916891689168921],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "GMMMUIR", name: "CASABLANCA UIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.103810381038102, 35.832383238323828],
            [-2.251300130013, 35.000300030003004],
            [-1.843534353435338, 34.850785078507855],
            [-1.626637663766374, 33.888688868886888],
            [-1.262251225122512, 32.998099809980999],
            [-1.080058005800574, 32.419541954195417],
            [-1.166816681668166, 31.996999699969997],
            [-2.954045404540452, 32.081508150815083],
            [-2.919341934193419, 31.749974997499748],
            [-3.648114811481143, 31.366436643664365],
            [-5.6522402240224, 30.31983198319832],
            [-7.621662166216616, 29.253725372537254],
            [-8.662766276627661, 28.668666866686667],
            [-8.662766276627661, 27.667566756675669],
            [-9.139938993899388, 27.667566756675669],
            [-10.519401940194015, 27.667566756675669],
            [-11.230823082308227, 27.667566756675669],
            [-12.592934293429341, 27.667566756675669],
            [-13.165541554155414, 27.667566756675669],
            [-13.000700070006999, 28.252625262526252],
            [-12.818506850685065, 28.909190919091909],
            [-12.714396439643963, 29.247224722472247],
            [-12.575582558255824, 29.72827282728273],
            [-12.497499749974995, 30.001300130013],
            [-12.714396439643963, 30.105310531053107],
            [-13.139513951395138, 30.306830683068306],
            [-13.573307330733069, 30.508350835083508],
            [-13.850935093509349, 30.63836383638364],
            [-13.989748974897488, 30.703370337033704],
            [-14.414866486648663, 30.898389838983896],
            [-14.857335733573354, 31.099909990999102],
            [-15.299804980498049, 31.301430143014301],
            [-15.7509500950095, 31.5029502950295],
            [-14.805280528052801, 31.996999699969997],
            [-14.614411441144114, 32.250525052505253],
            [-14.119886988698866, 32.998099809980999],
            [-13.425817581758174, 33.999199919991995],
            [-12.714396439643963, 35.000300030003004],
            [-12.315306530653064, 35.546354635463544],
            [-12.002975297529751, 35.968896889688963],
            [-11.447719771977194, 35.968896889688963],
            [-10.736298629862983, 35.968896889688963],
            [-10.024877487748771, 35.968896889688963],
            [-9.57373237323732, 35.968896889688963],
            [-9.547704770477043, 35.968896889688963],
            [-9.374187418741872, 35.968896889688963],
            [-9.25272527252725, 35.968896889688963],
            [-8.949069906990697, 35.968896889688963],
            [-8.315731573157311, 35.968896889688963],
            [-8.003400340034002, 35.968896889688963],
            [-7.88193819381938, 35.968896889688963],
            [-7.612986298629863, 35.968896889688963],
            [-7.387413741374132, 35.968896889688963],
            [-7.387413741374132, 35.832383238323828],
            [-6.996999699969997, 35.832383238323828],
            [-6.754075407540753, 35.832383238323828],
            [-6.719371937193714, 35.832383238323828],
            [-6.632613261326128, 35.832383238323828],
            [-6.450420042004197, 35.832383238323828],
            [-6.320282028202818, 35.832383238323828],
            [-6.242199219921986, 35.832383238323828],
            [-5.955895589558956, 35.832383238323828],
            [-5.695619561956192, 35.832383238323828],
            [-5.66959195919592, 35.832383238323828],
            [-5.140364036403639, 35.832383238323828],
            [-5.123012301230119, 35.832383238323828],
            [-4.897439743974395, 35.832383238323828],
            [-4.368211821182115, 35.832383238323828],
            [-4.003825382538253, 35.832383238323828],
            [-3.96044604460446, 35.832383238323828],
            [-3.700170017001696, 35.832383238323828],
            [-3.500625062506245, 35.832383238323828],
            [-3.439893989398939, 35.832383238323828],
            [-3.431218121812179, 35.832383238323828],
            [-3.266376637663761, 35.832383238323828],
            [-3.231673167316728, 35.832383238323828],
            [-3.049479947994797, 35.832383238323828],
            [-2.685093509350935, 35.832383238323828],
            [-2.112486248624862, 35.832383238323828],
            [-2.103810381038102, 35.832383238323828],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LPPCFIR", name: "LISBOA FIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.387413741374132, 35.968896889688963],
            [-7.612986298629863, 35.968896889688963],
            [-7.88193819381938, 35.968896889688963],
            [-8.003400340034002, 35.968896889688963],
            [-8.315731573157311, 35.968896889688963],
            [-8.949069906990697, 35.968896889688963],
            [-9.25272527252725, 35.968896889688963],
            [-9.374187418741872, 35.968896889688963],
            [-9.547704770477043, 35.968896889688963],
            [-9.57373237323732, 35.968896889688963],
            [-10.024877487748771, 35.968896889688963],
            [-10.736298629862983, 35.968896889688963],
            [-11.447719771977194, 35.968896889688963],
            [-12.002975297529751, 35.968896889688963],
            [-12.315306530653064, 35.546354635463544],
            [-12.714396439643963, 35.000300030003004],
            [-13.425817581758174, 33.999199919991995],
            [-14.119886988698866, 32.998099809980999],
            [-14.614411441144114, 32.250525052505253],
            [-14.805280528052801, 31.996999699969997],
            [-15.7509500950095, 31.5029502950295],
            [-16.028577857785777, 31.418441844184418],
            [-16.557805780578057, 31.405440544054407],
            [-16.800730073007298, 31.45094509450945],
            [-16.939543954395436, 31.483448344834482],
            [-16.991599159915989, 31.5029502950295],
            [-17.416716671667164, 31.652465246524653],
            [-17.460096009600957, 31.684968496849685],
            [-17.720372037203717, 31.834483448344834],
            [-18.128137813781375, 32.328532853285324],
            [-18.397089708970896, 32.985098509850985],
            [-18.067406740674066, 33.927692769276931],
            [-17.798454845484546, 34.168216821682165],
            [-16.236798679867984, 35.520352035203516],
            [-15.386563656365635, 36.196419641964198],
            [-14.996149614961492, 36.501950195019504],
            [-14.996149614961492, 37.002500250025001],
            [-14.996149614961492, 37.997099709970996],
            [-14.996149614961492, 38.497649764976501],
            [-14.996149614961492, 38.998199819981998],
            [-14.996149614961492, 39.498749874987496],
            [-14.996149614961492, 39.999299929993001],
            [-14.996149614961492, 41.000400040003996],
            [-14.996149614961492, 42.001500150015005],
            [-13.998424842484248, 42.502050205020502],
            [-13.000700070006999, 43.002600260026],
            [-12.497499749974995, 42.833583358335829],
            [-12.002975297529751, 42.677567756775673],
            [-10.814381438143812, 42.281028102810282],
            [-10.068256825682564, 42.021002100210019],
            [-9.998849884988495, 42.001500150015005],
            [-9.400215021502149, 41.936493649364934],
            [-9.365511551155112, 41.92349234923492],
            [-8.923042304230421, 41.87148714871487],
            [-8.844959495949592, 41.864986498649863],
            [-8.567331733173315, 42.066506650665062],
            [-8.228972897289726, 42.13801380138014],
            [-8.081483148314828, 41.812981298129813],
            [-7.777827782778274, 41.910491049104905],
            [-7.604310431043103, 41.858485848584856],
            [-7.474172417241718, 41.832483248324834],
            [-7.265951595159514, 41.851985198519856],
            [-7.118461846184616, 41.936493649364934],
            [-6.623937393739368, 41.942994299429941],
            [-6.563206320632062, 41.936493649364934],
            [-6.50247524752475, 41.767476747674763],
            [-6.372337233723371, 41.676467646764678],
            [-6.250875087508746, 41.585458545854586],
            [-6.276902690269026, 41.481448144814479],
            [-6.389688968896884, 41.377437743774379],
            [-6.780103010301026, 41.130413041304131],
            [-6.832158215821579, 40.831383138313825],
            [-6.814806480648059, 40.766376637663768],
            [-6.762751275127506, 40.668866886688669],
            [-6.719371937193714, 40.623362336233626],
            [-6.797454745474546, 40.519351935193519],
            [-6.814806480648059, 40.402340234023399],
            [-6.780103010301026, 40.363336333633363],
            [-6.988323832383237, 40.122812281228121],
            [-6.875537553755372, 39.992799279927993],
            [-6.875537553755372, 39.895289528952894],
            [-6.875537553755372, 39.87578757875788],
            [-6.996999699969997, 39.687268726872688],
            [-6.996999699969997, 39.667766776677666],
            [-7.500200020001998, 39.661266126612659],
            [-7.387413741374132, 39.537753775377539],
            [-7.370062006200619, 39.531253125312531],
            [-7.300655065506547, 39.329732973297325],
            [-7.222572257225721, 39.219221922192219],
            [-7.153165316531648, 39.160716071607155],
            [-7.057730773077303, 39.128212821282126],
            [-7.03170317031703, 39.108710871087112],
            [-7.01435143514351, 39.115211521152119],
            [-7.00567556755675, 39.102210221022105],
            [-6.970972097209717, 39.076207620762077],
            [-6.962296229622957, 39.056705670567055],
            [-6.962296229622957, 39.037203720372034],
            [-6.953620362036197, 39.02420242024202],
            [-6.970972097209717, 38.998199819981998],
            [-7.066406640664063, 38.900690069006899],
            [-7.153165316531648, 38.803180318031806],
            [-7.222572257225721, 38.731673167316728],
            [-7.248599859985994, 38.634163416341636],
            [-7.283303330333027, 38.549654965496543],
            [-7.300655065506547, 38.497649764976501],
            [-7.170517051705168, 38.348134813481352],
            [-6.996999699969997, 38.153115311531153],
            [-7.02302730273027, 38.127112711271124],
            [-6.996999699969997, 38.081608160816081],
            [-7.101110111011096, 38.023102310231025],
            [-7.127137713771376, 38.003600360036003],
            [-7.248599859985994, 37.893089308930897],
            [-7.318006800680067, 37.828082808280826],
            [-7.500200020001998, 37.581058105810584],
            [-7.430793079307925, 37.41854185418542],
            [-7.422117211721172, 37.347034703470342],
            [-7.430793079307925, 37.243024302430243],
            [-7.387413741374132, 37.126012601260129],
            [-7.387413741374132, 37.074007400740072],
            [-7.387413741374132, 37.03500350035003],
            [-7.387413741374132, 36.917991799179916],
            [-7.387413741374132, 36.833483348334838],
            [-7.387413741374132, 36.768476847684767],
            [-7.387413741374132, 36.664466446644667],
            [-7.387413741374132, 36.027402740274027],
            [-7.387413741374132, 35.968896889688963],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "GCCCUIR", name: "CANARIS UIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.7509500950095, 31.5029502950295],
            [-15.299804980498049, 31.301430143014301],
            [-14.857335733573354, 31.099909990999102],
            [-14.414866486648663, 30.898389838983896],
            [-13.989748974897488, 30.703370337033704],
            [-13.850935093509349, 30.63836383638364],
            [-13.573307330733069, 30.508350835083508],
            [-13.139513951395138, 30.306830683068306],
            [-12.714396439643963, 30.105310531053107],
            [-12.497499749974995, 30.001300130013],
            [-12.575582558255824, 29.72827282728273],
            [-12.714396439643963, 29.247224722472247],
            [-12.818506850685065, 28.909190919091909],
            [-13.000700070006999, 28.252625262526252],
            [-13.165541554155414, 27.667566756675669],
            [-12.592934293429341, 27.667566756675669],
            [-11.230823082308227, 27.667566756675669],
            [-12.002975297529751, 25.996899689968998],
            [-12.428092809280926, 25.002300230023003],
            [-12.983348334833479, 23.702170217021703],
            [-13.963721372137211, 21.335933593359336],
            [-14.996149614961492, 21.335933593359336],
            [-16.045929592959293, 21.335933593359336],
            [-16.870137013701367, 21.335933593359336],
            [-16.93086808680868, 21.335933593359336],
            [-17.034978497849782, 20.913391339133913],
            [-17.034978497849782, 20.763876387638764],
            [-17.746399639963993, 20.165816581658166],
            [-17.997999799979997, 19.918791879187918],
            [-18.995724572457245, 19.002200220022001],
            [-19.73317331733173, 19.58075807580758],
            [-20.00212521252125, 19.834283428342836],
            [-20.696194619461945, 20.367336733673369],
            [-21.97154715471547, 21.34893489348935],
            [-23.602610261026101, 22.915591559155914],
            [-24.999424942494247, 24.001200120012001],
            [-24.999424942494247, 25.002300230023003],
            [-24.999424942494247, 25.996899689968998],
            [-24.999424942494247, 26.997999799979997],
            [-24.999424942494247, 27.999099909990999],
            [-24.999424942494247, 29.000200020001998],
            [-24.999424942494247, 30.001300130013],
            [-24.001700170017003, 30.001300130013],
            [-23.003975397539751, 30.001300130013],
            [-21.997574757475746, 30.001300130013],
            [-20.999849984998498, 30.001300130013],
            [-20.392539253925392, 30.001300130013],
            [-20.00212521252125, 30.001300130013],
            [-17.460096009600957, 31.684968496849685],
            [-17.416716671667164, 31.652465246524653],
            [-16.991599159915989, 31.5029502950295],
            [-16.939543954395436, 31.483448344834482],
            [-16.800730073007298, 31.45094509450945],
            [-16.557805780578057, 31.405440544054407],
            [-16.028577857785777, 31.418441844184418],
            [-15.7509500950095, 31.5029502950295],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LBSRFIR", name: "SOFIA FIR", fab: "3" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.665791579157919, 44.198719871987194],
            [23.021502150215028, 44.101210121012102],
            [22.847984798479857, 43.867186718671867],
            [22.986798679867995, 43.802180218021803],
            [23.247074707470752, 43.834683468346839],
            [23.498674867486756, 43.80868086808681],
            [23.689543954395447, 43.795679567956796],
            [23.802330233023305, 43.782678267826782],
            [23.828357835783585, 43.776177617761775],
            [23.889088908890898, 43.763176317631761],
            [24.001875187518763, 43.730673067306725],
            [24.088633863386349, 43.717671767176718],
            [24.23612361236124, 43.691669166916689],
            [24.357585758575865, 43.724172417241718],
            [24.531103110311037, 43.769676967696768],
            [24.713296329632968, 43.717671767176718],
            [24.852110211021113, 43.698169816981697],
            [25.12973797379739, 43.698169816981697],
            [25.320607060706081, 43.652665266526654],
            [25.363986398639867, 43.633163316331633],
            [25.416041604160426, 43.626662666266625],
            [25.546179617961798, 43.63966396639664],
            [25.572207220722078, 43.646164616461647],
            [25.641614161416143, 43.685168516851682],
            [25.650290029002903, 43.698169816981697],
            [25.815131513151314, 43.750175017501746],
            [25.95394539453946, 43.86068606860686],
            [25.97997299729974, 43.867186718671867],
            [25.99732473247326, 43.880188018801874],
            [26.214221422142217, 43.984198419841988],
            [26.500525052505253, 44.042704270427038],
            [26.578607860786079, 44.055705570557052],
            [26.83020802080209, 44.08170817081708],
            [26.995049504950501, 44.133713371337137],
            [27.021077107710781, 44.140214021402137],
            [27.281353135313537, 44.114211421142116],
            [27.420167016701683, 44.016701670167016],
            [27.550305030503054, 44.016701670167016],
            [27.65441544154416, 44.049204920492045],
            [27.749849984998505, 43.951695169516952],
            [27.793229322932305, 43.95819581958196],
            [27.932043204320436, 43.997199719971995],
            [28.001450145014502, 43.834683468346839],
            [28.235698569856993, 43.763176317631761],
            [28.452595259525964, 43.730673067306725],
            [28.50465046504651, 43.737173717371732],
            [28.626112611261135, 43.743674367436739],
            [28.808305830583066, 43.698169816981697],
            [28.999174917491757, 43.665666566656668],
            [29.033878387838797, 43.730673067306725],
            [29.146664666466648, 43.730673067306725],
            [29.441644164416445, 43.724172417241718],
            [29.632513251325136, 43.717671767176718],
            [30.534803480348046, 43.685168516851682],
            [30.638913891389151, 43.256125612561256],
            [30.751700170017003, 42.801080108010801],
            [30.595534553455352, 42.736073607360737],
            [30.265851585158529, 42.612561256125616],
            [30.014251425142518, 42.51505150515051],
            [29.866761676167627, 42.463046304630467],
            [29.623837383738376, 42.36553655365536],
            [29.363561356135619, 42.261526152615261],
            [29.276802680268034, 42.222522252225218],
            [29.146664666466648, 42.170517051705168],
            [28.999174917491757, 42.118511851185119],
            [28.764926492649266, 42.079507950795076],
            [28.48729872987299, 42.014501450145012],
            [28.313781378137818, 41.981998199819984],
            [28.001450145014502, 41.981998199819984],
            [27.853960396039611, 41.994999499949998],
            [27.775877587758785, 41.968996899689969],
            [27.567656765676574, 41.903990399039898],
            [27.420167016701683, 41.988498849884991],
            [27.281353135313537, 42.066506650665062],
            [27.211946194619472, 42.066506650665062],
            [27.133863386338646, 42.086008600860083],
            [27.012401240124021, 42.04050405040504],
            [26.76947694769477, 41.968996899689969],
            [26.613311331133119, 41.981998199819984],
            [26.569931993199333, 41.87148714871487],
            [26.300980098009802, 41.734973497349735],
            [26.066731673167325, 41.734973497349735],
            [26.170842084208431, 41.416441644164415],
            [26.136138613861391, 41.364436443644365],
            [25.99732473247326, 41.33193319331933],
            [25.893214321432154, 41.312431243124308],
            [25.832483248324834, 41.351435143514351],
            [25.737048704870489, 41.325432543254323],
            [25.719696969696969, 41.299429942994294],
            [25.658965896589663, 41.318931893189315],
            [25.624262426242623, 41.305930593059301],
            [25.572207220722078, 41.325432543254323],
            [25.537503750375038, 41.286428642864287],
            [25.485448544854492, 41.292929292929287],
            [25.329282928292841, 41.240924092409244],
            [25.242524252425255, 41.253925392539252],
            [25.12106210621063, 41.351435143514351],
            [25.09503450345035, 41.344934493449344],
            [25.051655165516564, 41.364436443644365],
            [24.878137813781379, 41.396939693969401],
            [24.800055005500553, 41.416441644164415],
            [24.704620462046208, 41.422942294229422],
            [24.600510051005102, 41.448944894489443],
            [24.461696169616971, 41.533453345334536],
            [24.400965096509651, 41.54645464546455],
            [24.314206420642066, 41.520452045204522],
            [24.27082708270828, 41.578957895789578],
            [24.166716671667174, 41.54645464546455],
            [24.123337333733375, 41.54645464546455],
            [24.036578657865789, 41.455445544554451],
            [23.637488748874894, 41.364436443644365],
            [23.507350735073516, 41.383938393839387],
            [23.455295529552963, 41.403440344034401],
            [23.281778177817785, 41.390439043904394],
            [22.986798679867995, 41.351435143514351],
            [22.934743474347442, 41.468446844684465],
            [23.030178017801788, 41.715471547154714],
            [22.969446944694475, 41.767476747674763],
            [22.88268826882689, 41.968996899689969],
            [22.605060506050613, 42.099009900990097],
            [22.596384638463853, 42.118511851185119],
            [22.362136213621369, 42.313531353135318],
            [22.55300530053006, 42.417541754175417],
            [22.55300530053006, 42.476047604760474],
            [22.466246624662475, 42.567056705670566],
            [22.448894889488955, 42.599559955995602],
            [22.440219021902195, 42.801080108010801],
            [22.492274227422747, 42.846584658465844],
            [22.5443294329433, 42.866086608660865],
            [22.596384638463853, 42.892089208920893],
            [22.639763976397646, 42.879087908790879],
            [22.752550255025511, 42.892089208920893],
            [22.795929592959304, 42.970097009700964],
            [22.89136413641365, 43.035103510351036],
            [22.934743474347442, 43.067606760676071],
            [22.986798679867995, 43.113111311131114],
            [22.986798679867995, 43.126112611261121],
            [22.995474547454748, 43.158615861586156],
            [23.004150415041508, 43.165116511651163],
            [22.830633063306337, 43.327632763276327],
            [22.769901990199024, 43.353635363536355],
            [22.769901990199024, 43.373137313731377],
            [22.665791579157919, 43.431643164316426],
            [22.52697769776978, 43.496649664966498],
            [22.492274227422747, 43.561656165616562],
            [22.500950095009507, 43.633163316331633],
            [22.414191419141922, 43.685168516851682],
            [22.362136213621369, 43.795679567956796],
            [22.370812081208129, 43.828182818281832],
            [22.388163816381642, 43.91269126912691],
            [22.431543154315435, 43.997199719971995],
            [22.613736373637373, 44.08170817081708],
            [22.665791579157919, 44.198719871987194],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EGTTUIR", name: "LONDON UIR", fab: "9" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.001725172517254, 55.002800280027998],
            [4.533228322832286, 54.5022502250225],
            [4.177517751775184, 54.105710571057102],
            [4.082083208320839, 54.001700170017003],
            [3.969296929692973, 53.865186518651861],
            [3.9432693269327, 53.839183918391839],
            [3.709020902090217, 53.572657265726569],
            [3.648289828982904, 53.501150115011498],
            [3.613586358635871, 53.462146214621463],
            [3.561531153115318, 53.403640364036399],
            [3.431393139313933, 53.247624762476249],
            [3.361986198619867, 53.176117611761171],
            [3.301255125512554, 53.0981098109811],
            [3.257875787578762, 53.046104610461043],
            [3.162441244124416, 52.929092909290929],
            [3.145089508950896, 52.916091609160915],
            [3.067006700670071, 52.825082508250823],
            [2.997599759976005, 52.73407340734073],
            [2.902165216521659, 52.617061706170617],
            [2.832758275827587, 52.532553255325531],
            [2.780703070307034, 52.474047404740475],
            [2.693944394439448, 52.363536353635361],
            [2.650565056505656, 52.318031803180318],
            [2.641889188918896, 52.305030503050304],
            [2.598509850985103, 52.253025302530254],
            [2.581158115811583, 52.233523352335233],
            [2.494399439943997, 52.123012301230119],
            [2.485723572357237, 52.116511651165112],
            [2.477047704770484, 52.103510351035105],
            [2.355585558555859, 51.947494749474949],
            [2.294854485448546, 51.86948694869487],
            [2.225447544754481, 51.784978497849785],
            [2.173392339233928, 51.713471347134714],
            [2.164716471647168, 51.706970697069707],
            [2.156040604060408, 51.693969396939693],
            [2.103985398539855, 51.635463546354636],
            [2.051930193019302, 51.570457045704572],
            [1.999874987498757, 51.498949894989501],
            [1.999874987498757, 51.492449244924494],
            [1.999874987498757, 51.440444044404437],
            [1.999874987498757, 51.401440144014401],
            [1.999874987498757, 51.38193819381938],
            [1.999874987498757, 51.362436243624359],
            [1.999874987498757, 51.245424542454245],
            [1.999874987498757, 51.212921292129209],
            [1.999874987498757, 51.199919991999195],
            [1.999874987498757, 51.128412841284124],
            [1.999874987498757, 51.115411541154117],
            [1.730923092309233, 51.056905690569053],
            [1.670192019201927, 51.043904390439046],
            [1.618136813681375, 51.030903090309032],
            [1.557405740574062, 51.017901790179017],
            [1.470647064706476, 50.998399839983996],
            [1.470647064706476, 50.965896589658968],
            [1.470647064706476, 50.959395939593961],
            [1.470647064706476, 50.920392039203918],
            [1.470647064706476, 50.913891389138911],
            [1.470647064706476, 50.907390739073904],
            [1.470647064706476, 50.855385538553854],
            [1.470647064706476, 50.777377737773776],
            [1.470647064706476, 50.751375137513747],
            [1.470647064706476, 50.666866686668662],
            [1.453295329532956, 50.660366036603662],
            [1.401240124012403, 50.647364736473648],
            [1.366536653665371, 50.634363436343634],
            [1.227722772277232, 50.575857585758577],
            [1.097584758475854, 50.530353035303527],
            [1.062881288128814, 50.517351735173513],
            [1.002150215021508, 50.497849784978499],
            [0.967446744674469, 50.478347834783477],
            [0.889363936393643, 50.445844584458442],
            [0.81995699569957, 50.41984198419842],
            [0.767901790179025, 50.400340034003399],
            [0.637763776377639, 50.354835483548356],
            [0.446894689468948, 50.276827682768278],
            [0.264701470147017, 50.205320532053207],
            [0.099859985998606, 50.133813381338129],
            [-0.0042504250425, 50.101310131013101],
            [-0.108360836083605, 50.055805580558051],
            [-0.247174717471744, 49.997299729972994],
            [-0.368636863686362, 49.997299729972994],
            [-0.53347834783478, 49.997299729972994],
            [-0.837133713371337, 49.997299729972994],
            [-1.001975197519748, 49.997299729972994],
            [-1.054030403040301, 49.997299729972994],
            [-1.192844284428439, 49.997299729972994],
            [-1.253575357535752, 49.997299729972994],
            [-1.38371337133713, 49.997299729972994],
            [-1.496499649964996, 49.997299729972994],
            [-1.513851385138508, 49.997299729972994],
            [-1.687368736873687, 49.997299729972994],
            [-1.71339633963396, 49.997299729972994],
            [-1.782803280328032, 49.997299729972994],
            [-1.843534353435338, 49.997299729972994],
            [-1.860886088608858, 49.997299729972994],
            [-1.886913691369131, 49.997299729972994],
            [-1.973672367236723, 49.997299729972994],
            [-1.999699969996996, 49.997299729972994],
            [-2.008375837583756, 49.997299729972994],
            [-2.103810381038102, 49.98429842984298],
            [-2.164541454145414, 49.971297129712973],
            [-2.173217321732167, 49.971297129712973],
            [-2.24262426242624, 49.958295829582958],
            [-2.372762276227618, 49.938793879387937],
            [-2.511576157615757, 49.912791279127909],
            [-2.997424742474244, 49.834783478347831],
            [-3.032128212821277, 49.821782178217823],
            [-3.06683168316831, 49.821782178217823],
            [-3.162266226622656, 49.802280228022802],
            [-3.170942094209416, 49.802280228022802],
            [-3.257700770077001, 49.782778277827781],
            [-3.526652665266525, 49.737273727372738],
            [-3.821632163216321, 49.685268526852681],
            [-4.003825382538253, 49.652765276527653],
            [-4.159990999099904, 49.620262026202617],
            [-4.333508350835082, 49.587758775877589],
            [-4.44629462946294, 49.568256825682568],
            [-4.914791479147908, 49.477247724772475],
            [-5.001550155015501, 49.464246424642461],
            [-5.036253625362534, 49.457745774577454],
            [-5.270502050205017, 49.412241224122411],
            [-5.860461046104611, 49.28872887288729],
            [-6.14676467646764, 49.236723672367233],
            [-7.213896389638961, 49.009200920092006],
            [-7.578282828282823, 48.931193119311928],
            [-8.003400340034002, 48.833683368336835],
            [-8.003400340034002, 49.002700270026999],
            [-8.003400340034002, 49.165216521652162],
            [-8.003400340034002, 49.249724972497248],
            [-8.003400340034002, 49.308230823082305],
            [-8.003400340034002, 49.503250325032504],
            [-8.003400340034002, 49.581258125812582],
            [-8.003400340034002, 49.997299729972994],
            [-8.003400340034002, 50.075307530753072],
            [-8.003400340034002, 50.25082508250825],
            [-8.003400340034002, 50.367836783678364],
            [-8.003400340034002, 50.660366036603662],
            [-8.003400340034002, 50.751375137513747],
            [-8.003400340034002, 50.998399839983996],
            [-7.682393239323929, 51.180418041804181],
            [-7.569606960696063, 51.238923892389238],
            [-7.508875887588758, 51.271427142714273],
            [-7.491524152415238, 51.277927792779273],
            [-7.413441344134412, 51.323432343234323],
            [-7.231248124812481, 51.427442744274423],
            [-7.092434243424343, 51.498949894989501],
            [-6.866861686168612, 51.615961596159615],
            [-6.710696069606961, 51.706970697069707],
            [-6.563206320632062, 51.784978497849785],
            [-6.407040704070404, 51.86948694869487],
            [-6.250875087508746, 51.947494749474949],
            [-6.242199219921986, 51.953995399539956],
            [-6.233523352335233, 51.960496049604956],
            [-6.120737073707367, 52.012501250125013],
            [-5.851785178517851, 52.155515551555155],
            [-5.843109310931091, 52.155515551555155],
            [-5.825757575757571, 52.168516851685169],
            [-5.686943694369432, 52.24002400240024],
            [-5.678267826782673, 52.24002400240024],
            [-5.66959195919592, 52.246524652465247],
            [-5.496074607460741, 52.331033103310332],
            [-5.496074607460741, 52.415541554155411],
            [-5.496074607460741, 52.552055205520553],
            [-5.496074607460741, 52.55855585558556],
            [-5.496074607460741, 52.669066906690666],
            [-5.496074607460741, 52.740574057405738],
            [-5.496074607460741, 52.753575357535752],
            [-5.496074607460741, 52.948594859485944],
            [-5.496074607460741, 53.05260526052605],
            [-5.496074607460741, 53.065606560656065],
            [-5.496074607460741, 53.085108510851086],
            [-5.496074607460741, 53.176117611761171],
            [-5.496074607460741, 53.182618261826178],
            [-5.496074607460741, 53.195619561956192],
            [-5.496074607460741, 53.247624762476249],
            [-5.496074607460741, 53.273627362736271],
            [-5.496074607460741, 53.280128012801278],
            [-5.496074607460741, 53.286628662866285],
            [-5.496074607460741, 53.384138413841384],
            [-5.496074607460741, 53.462146214621463],
            [-5.496074607460741, 53.481648164816477],
            [-5.496074607460741, 53.494649464946491],
            [-5.496074607460741, 53.663666366636662],
            [-5.496074607460741, 53.683168316831683],
            [-5.496074607460741, 53.68966896689669],
            [-5.496074607460741, 53.761176117611761],
            [-5.496074607460741, 53.767676767676768],
            [-5.496074607460741, 53.806680668066804],
            [-5.496074607460741, 53.917191719171917],
            [-5.496074607460741, 54.898789878987898],
            [-5.496074607460741, 55.002800280027998],
            [-4.897439743974395, 55.002800280027998],
            [-4.003825382538253, 55.002800280027998],
            [-3.517976797679765, 55.002800280027998],
            [-3.439893989398939, 55.002800280027998],
            [-2.711121112111208, 55.002800280027998],
            [-2.468196819681964, 55.002800280027998],
            [-2.121162116211615, 55.002800280027998],
            [-1.912941294129411, 55.002800280027998],
            [-1.505175517551748, 55.002800280027998],
            [-1.270927092709265, 55.002800280027998],
            [-0.984623462346228, 55.002800280027998],
            [-0.86316131613161, 55.002800280027998],
            [-0.785078507850784, 55.002800280027998],
            [2.980248024802485, 55.002800280027998],
            [3.049654965496551, 55.002800280027998],
            [3.119061906190623, 55.002800280027998],
            [3.500800080008005, 55.002800280027998],
            [4.038703870387046, 55.002800280027998],
            [5.001725172517254, 55.002800280027998],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LIRRFIR", name: "ROMA FIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.995899589959002, 38.881188118811878],
            [18.995899589959002, 38.725172517251721],
            [18.995899589959002, 38.517151715171515],
            [18.995899589959002, 38.341634163416344],
            [18.995899589959002, 37.750075007500747],
            [18.995899589959002, 37.399039903990399],
            [18.995899589959002, 36.963496349634966],
            [18.995899589959002, 36.768476847684767],
            [18.995899589959002, 36.501950195019504],
            [18.336533653365343, 36.501950195019504],
            [17.77260226022603, 36.501950195019504],
            [17.425567556755681, 36.501950195019504],
            [16.852960296029607, 36.501950195019504],
            [16.453870387038712, 36.501950195019504],
            [16.002725272527258, 36.501950195019504],
            [15.620987098709875, 36.501950195019504],
            [14.996324632463249, 36.501950195019504],
            [14.831483148314838, 36.501950195019504],
            [14.571207120712074, 36.501950195019504],
            [14.467096709670969, 36.501950195019504],
            [14.458420842084216, 36.501950195019504],
            [14.310931093109318, 36.501950195019504],
            [14.250200020002005, 36.501950195019504],
            [14.102710271027107, 36.501950195019504],
            [13.972572257225728, 36.501950195019504],
            [13.790379037903797, 36.501950195019504],
            [13.538778877887793, 36.501950195019504],
            [13.209095909590964, 36.501950195019504],
            [12.844709470947102, 36.501950195019504],
            [12.584433443344338, 36.501950195019504],
            [12.12461246124613, 36.501950195019504],
            [11.820957095709574, 36.501950195019504],
            [11.62141214121413, 36.501950195019504],
            [11.499949994999504, 36.501950195019504],
            [11.499949994999504, 36.605960596059603],
            [11.499949994999504, 36.618961896189617],
            [11.499949994999504, 36.839983998399838],
            [11.499949994999504, 37.191019101910186],
            [11.499949994999504, 37.503050305030499],
            [11.291729172917293, 37.594059405940598],
            [11.196294629462948, 37.639563956395634],
            [11.118211821182122, 37.672067206720669],
            [11.100860086008609, 37.685068506850683],
            [10.962046204620464, 37.743574357435747],
            [10.909990999099918, 37.769576957695769],
            [10.788528852885293, 37.821582158215818],
            [10.632363236323634, 37.88658865886589],
            [10.285328532853292, 38.036103610361039],
            [9.790804080408044, 38.257125712571252],
            [9.05335533553356, 38.569156915691565],
            [8.966596659665974, 38.6016601660166],
            [8.888513851385142, 38.634163416341636],
            [8.862486248624869, 38.64716471647165],
            [8.498099809980999, 38.796679667966799],
            [8.003575357535759, 38.998199819981998],
            [8.003575357535759, 39.147714771477148],
            [8.003575357535759, 39.212721272127212],
            [8.003575357535759, 39.700270027002702],
            [8.003575357535759, 39.999299929993001],
            [8.003575357535759, 40.350335033503349],
            [8.003575357535759, 40.42184218421842],
            [8.003575357535759, 40.610361036103612],
            [8.003575357535759, 40.746874687468747],
            [8.003575357535759, 40.863886388638861],
            [8.003575357535759, 40.915891589158917],
            [8.003575357535759, 41.000400040003996],
            [8.159740974097417, 41.162916291629159],
            [8.281203120312036, 41.286428642864287],
            [8.333258325832588, 41.33193319331933],
            [8.446044604460454, 41.33193319331933],
            [8.567506750675072, 41.33193319331933],
            [8.688968896889691, 41.33193319331933],
            [8.810431043104316, 41.33193319331933],
            [9.03600360036004, 41.33193319331933],
            [9.105410541054113, 41.33193319331933],
            [9.270252025202524, 41.33193319331933],
            [9.313631363136317, 41.33193319331933],
            [9.495824582458248, 41.33193319331933],
            [9.617286728672873, 41.33193319331933],
            [9.747424742474252, 41.33193319331933],
            [9.747424742474252, 41.663466346634664],
            [9.747424742474252, 41.734973497349735],
            [9.747424742474252, 41.747974797479742],
            [9.747424742474252, 41.793479347934792],
            [9.747424742474252, 41.936493649364934],
            [9.747424742474252, 42.326532653265325],
            [9.747424742474252, 42.41104110411041],
            [9.747424742474252, 42.502050205020502],
            [9.747424742474252, 42.619061906190616],
            [9.747424742474252, 42.820582058205815],
            [9.747424742474252, 42.957095709570957],
            [9.747424742474252, 43.165116511651163],
            [10.059755975597561, 43.405640564056405],
            [10.302680268026805, 43.587658765876583],
            [10.519576957695776, 43.620162016201618],
            [10.675742574257427, 43.63966396639664],
            [10.710446044604467, 43.646164616461647],
            [10.771177117711773, 43.659165916591661],
            [11.170267026702675, 43.717671767176718],
            [11.508625862586264, 43.691669166916689],
            [11.560681068106817, 43.685168516851682],
            [11.638763876387642, 43.678667866786682],
            [11.925067506750679, 43.652665266526654],
            [13.044254425442546, 43.54215421542154],
            [13.330558055805582, 43.516151615161519],
            [13.295854585458549, 43.36663666366637],
            [13.087633763376346, 42.918091809180922],
            [13.122337233723378, 42.879087908790879],
            [13.434668466846688, 42.534553455345531],
            [13.443344334433448, 42.51505150515051],
            [13.530103010301033, 42.417541754175417],
            [13.599509950995106, 42.346034603460346],
            [13.599509950995106, 42.339533953395339],
            [13.738323832383244, 42.19001900190019],
            [13.755675567556757, 42.164016401640161],
            [14.068006800680074, 41.812981298129813],
            [14.380338033803383, 41.448944894489443],
            [14.545179517951802, 41.396939693969401],
            [14.805455545554558, 41.305930593059301],
            [14.883538353835391, 41.266926692669266],
            [15.117786778677875, 41.201920192019202],
            [15.169841984198428, 41.110911091109109],
            [15.230573057305733, 41.006900690069003],
            [15.421442144214424, 40.688368836883683],
            [15.473497349734977, 40.603860386038605],
            [15.620987098709875, 40.343834383438342],
            [15.742449244924501, 40.135813581358136],
            [15.941994199419945, 39.778277827782773],
            [16.03742874287429, 39.61576157615761],
            [16.254325432543261, 39.232223222322233],
            [16.419166916691672, 39.076207620762077],
            [17.269401940194022, 38.660166016601664],
            [17.382188218821888, 38.7056705670567],
            [17.512326232623266, 38.75117511751175],
            [18.995899589959002, 38.881188118811878],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LWSSFIR", name: "SKOPJE FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.362136213621369, 42.313531353135318],
            [22.596384638463853, 42.118511851185119],
            [22.605060506050613, 42.099009900990097],
            [22.88268826882689, 41.968996899689969],
            [22.969446944694475, 41.767476747674763],
            [23.030178017801788, 41.715471547154714],
            [22.934743474347442, 41.468446844684465],
            [22.986798679867995, 41.351435143514351],
            [22.787253725372544, 41.33193319331933],
            [22.761226122612264, 41.247424742474252],
            [22.613736373637373, 41.175917591759173],
            [22.518301830183027, 41.130413041304131],
            [22.483598359835987, 41.117411741174116],
            [22.431543154315435, 41.117411741174116],
            [22.205970597059711, 41.175917591759173],
            [22.179942994299438, 41.169416941694166],
            [22.03245324532454, 41.130413041304131],
            [21.997749774977507, 41.130413041304131],
            [21.919666966696674, 41.078407840784081],
            [21.780853085308536, 40.935393539353939],
            [21.69409440944095, 40.935393539353939],
            [21.425142514251434, 40.922392239223925],
            [21.355735573557361, 40.876887688768875],
            [21.121487148714877, 40.857385738573853],
            [21.000025002500259, 40.863886388638861],
            [20.85253525352536, 40.902890289028903],
            [20.496824682468251, 41.33193319331933],
            [20.496824682468251, 41.435943594359436],
            [20.531528152815284, 41.669966996699671],
            [20.592259225922596, 41.877987798779877],
            [20.895914591459153, 42.099009900990097],
            [21.052080208020804, 42.112011201120112],
            [21.321032103210328, 42.131513151315133],
            [21.329707970797088, 42.13801380138014],
            [21.399114911491154, 42.157515751575161],
            [21.616011601160125, 42.229022902290225],
            [21.66806680668067, 42.248524852485247],
            [21.780853085308536, 42.281028102810282],
            [22.101860186018605, 42.300530053005303],
            [22.119211921192125, 42.300530053005303],
            [22.240674067406744, 42.307030703070311],
            [22.362136213621369, 42.313531353135318],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EDGGFIR", name: "LANGEN FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.398539853985405, 49.464246424642461],
            [6.381188118811885, 49.581258125812582],
            [6.381188118811885, 49.600760076007596],
            [6.424567456745677, 49.639763976397639],
            [6.433243324332437, 49.665766576657667],
            [6.45927092709271, 49.691769176917688],
            [6.520002000200023, 49.730773077307731],
            [6.537353735373543, 49.769776977697767],
            [6.424567456745677, 49.815281528152816],
            [6.381188118811885, 49.834783478347831],
            [6.398539853985405, 49.860786078607859],
            [6.355160516051612, 49.860786078607859],
            [6.242374237423746, 49.893289328932894],
            [6.225022502250226, 49.925792579257923],
            [6.216346634663473, 49.951795179517951],
            [6.198994899489954, 49.951795179517951],
            [6.198994899489954, 49.958295829582958],
            [6.164291429142921, 49.971297129712973],
            [6.164291429142921, 49.97779777977798],
            [6.129587958795881, 50.068806880688065],
            [6.120912091209128, 50.075307530753072],
            [6.138263826382641, 50.127312731273129],
            [6.146939693969401, 50.146814681468143],
            [6.155615561556161, 50.159815981598157],
            [6.190319031903194, 50.179317931793179],
            [6.181643164316434, 50.218321832183214],
            [6.216346634663473, 50.231323132313229],
            [6.225022502250226, 50.237823782378236],
            [6.242374237423746, 50.263826382638264],
            [6.251050105010506, 50.263826382638264],
            [6.277077707770779, 50.270327032703271],
            [6.285753575357539, 50.283328332833278],
            [6.372512251225125, 50.315831583158314],
            [6.398539853985405, 50.335333533353335],
            [6.398539853985405, 50.341834183418342],
            [6.372512251225125, 50.41984198419842],
            [6.337808780878092, 50.47184718471847],
            [6.329132913291332, 50.491349134913492],
            [6.233698369836986, 50.497849784978499],
            [6.198994899489954, 50.56935693569357],
            [6.233698369836986, 50.582358235823584],
            [6.242374237423746, 50.595359535953591],
            [6.251050105010506, 50.601860186018598],
            [6.277077707770779, 50.627862786278627],
            [6.216346634663473, 50.634363436343634],
            [6.207670767076714, 50.634363436343634],
            [6.138263826382641, 50.686368636863683],
            [6.138263826382641, 50.692869286928691],
            [6.077532753275335, 50.725372537253726],
            [6.034153415341535, 50.751375137513747],
            [6.025477547754782, 50.757875787578755],
            [6.025477547754782, 50.816381638163811],
            [6.025477547754782, 50.822882288228818],
            [6.016801680168022, 50.829382938293826],
            [6.077532753275335, 50.861886188618861],
            [6.068856885688575, 50.881388138813882],
            [6.060181018101815, 50.907390739073904],
            [6.060181018101815, 50.920392039203918],
            [6.034153415341535, 50.933393339333932],
            [6.016801680168022, 50.939893989398939],
            [6.025477547754782, 50.985398539853982],
            [5.990774077407742, 50.985398539853982],
            [5.96474647464747, 50.998399839983996],
            [5.96474647464747, 51.043904390439046],
            [6.008125812581262, 51.082908290829081],
            [6.060181018101815, 51.134913491349131],
            [6.103560356035608, 51.141414141414138],
            [6.129587958795881, 51.186918691869188],
            [6.086208620862088, 51.173917391739174],
            [6.077532753275335, 51.245424542454245],
            [6.120912091209128, 51.277927792779273],
            [6.129587958795881, 51.277927792779273],
            [6.216346634663473, 51.362436243624359],
            [6.216346634663473, 51.401440144014401],
            [6.225022502250226, 51.407940794079408],
            [6.225022502250226, 51.466446644664465],
            [6.198994899489954, 51.524952495249522],
            [6.129587958795881, 51.583458345834579],
            [6.103560356035608, 51.628962896289629],
            [6.034153415341535, 51.674467446744671],
            [6.025477547754782, 51.7004700470047],
            [5.94739473947395, 51.830483048304828],
            [6.025477547754782, 51.856485648564856],
            [6.060181018101815, 51.856485648564856],
            [6.103560356035608, 51.882488248824878],
            [6.146939693969401, 51.901990199019899],
            [6.389863986398645, 51.836983698369835],
            [6.407215721572165, 51.830483048304828],
            [6.372512251225125, 51.86948694869487],
            [6.433243324332437, 51.862986298629863],
            [6.50265026502651, 51.86948694869487],
            [6.589408940894096, 51.895489548954892],
            [6.615436543654369, 51.901990199019899],
            [6.658815881588161, 51.908490849084906],
            [6.719546954695474, 51.914991499149913],
            [6.780278027802787, 51.92149214921492],
            [6.788953895389547, 51.934493449344934],
            [6.762926292629267, 52.025502550255027],
            [6.81498149814982, 52.116511651165112],
            [6.875712571257132, 52.136013601360133],
            [6.884388438843885, 52.142514251425141],
            [6.841009100910092, 52.155515551555155],
            [7.057905790579063, 52.24002400240024],
            [7.266126612661267, 52.246524652465247],
            [7.856085608560861, 52.279027902790276],
            [8.003575357535759, 52.285528552855283],
            [8.003575357535759, 52.233523352335233],
            [8.003575357535759, 52.116511651165112],
            [8.584858485848592, 52.129512951295126],
            [8.741024102410243, 52.136013601360133],
            [9.001300130013007, 52.136013601360133],
            [9.001300130013007, 51.75247524752475],
            [9.001300130013007, 51.667966796679664],
            [8.957920792079214, 51.609460946094607],
            [8.940569056905694, 51.583458345834579],
            [8.923217321732174, 51.550955095509551],
            [8.879837983798382, 51.498949894989501],
            [8.767051705170523, 51.336433643364337],
            [8.905865586558662, 51.336433643364337],
            [9.166141614161418, 51.336433643364337],
            [9.521852185218528, 51.336433643364337],
            [9.58258325832584, 51.336433643364337],
            [9.938293829382943, 51.336433643364337],
            [10.059755975597561, 51.336433643364337],
            [10.389438943894397, 51.336433643364337],
            [10.493549354935496, 51.336433643364337],
            [10.701770177017707, 50.660366036603662],
            [10.562956295629569, 50.536853685368534],
            [10.519576957695776, 50.497849784978499],
            [10.493549354935496, 50.465346534653463],
            [10.311356135613565, 50.25082508250825],
            [10.285328532853292, 50.224822482248221],
            [10.658390839083914, 49.997299729972994],
            [10.970722072207224, 49.444744474447447],
            [10.840584058405845, 49.353735373537354],
            [10.667066706670674, 49.230223022302226],
            [10.658390839083914, 49.230223022302226],
            [10.588983898389841, 49.178217821782177],
            [10.476197619761983, 49.113211321132113],
            [10.302680268026805, 49.035203520352034],
            [10.259300930093012, 49.015701570157013],
            [10.094459445944601, 48.950695069506949],
            [9.868886888688877, 48.84668466846685],
            [9.773452345234531, 48.807680768076807],
            [9.686693669366939, 48.6971697169717],
            [9.625962596259633, 48.619161916191615],
            [9.599934993499353, 48.593159315931587],
            [9.5478797879788, 48.52815281528153],
            [9.5478797879788, 48.515151515151516],
            [9.5478797879788, 48.469646964696466],
            [9.5478797879788, 48.443644364436437],
            [9.5478797879788, 48.391639163916395],
            [9.5478797879788, 48.365636563656366],
            [9.5478797879788, 48.294129412941288],
            [9.5478797879788, 48.16411641164116],
            [9.5478797879788, 48.034103410341032],
            [9.5478797879788, 47.975597559755975],
            [9.5478797879788, 47.956095609560954],
            [9.5478797879788, 47.89108910891089],
            [9.5478797879788, 47.884588458845883],
            [9.5478797879788, 47.832583258325826],
            [9.452445244524455, 47.585558555855584],
            [9.365686568656869, 47.605060506050606],
            [9.03600360036004, 47.683068306830677],
            [9.001300130013007, 47.676567656765677],
            [8.888513851385142, 47.657065706570656],
            [8.628237823782385, 47.767576757675769],
            [8.498099809980999, 47.767576757675769],
            [8.411341134113414, 47.676567656765677],
            [8.524127412741279, 47.644064406440641],
            [8.524127412741279, 47.631063106310634],
            [8.454720472047207, 47.57255725572557],
            [8.281203120312036, 47.605060506050606],
            [8.20312031203121, 47.61806180618062],
            [8.081658165816584, 47.559555955595556],
            [7.942844284428446, 47.546554655465542],
            [7.908140814081413, 47.579057905790577],
            [7.899464946494653, 47.585558555855584],
            [7.856085608560861, 47.592059205920592],
            [7.847409740974101, 47.579057905790577],
            [7.830058005800588, 47.579057905790577],
            [7.778002800280035, 47.553055305530549],
            [7.699919991999202, 47.540054005400535],
            [7.682568256825689, 47.566056605660563],
            [7.578457845784584, 47.605060506050606],
            [7.500375037503751, 47.631063106310634],
            [7.535078507850791, 47.689568956895684],
            [7.535078507850791, 47.780578057805783],
            [7.561106110611064, 47.832583258325826],
            [7.561106110611064, 47.839083908390833],
            [7.587133713371344, 47.910591059105911],
            [7.613161316131617, 47.988598859885983],
            [7.613161316131617, 48.001600160015997],
            [7.578457845784584, 48.086108610861089],
            [7.64786478647865, 48.196619661966196],
            [7.65654065406541, 48.216121612161217],
            [7.699919991999202, 48.346134613461345],
            [7.734623462346242, 48.437143714371437],
            [7.795354535453548, 48.508650865086508],
            [7.804030403040308, 48.521652165216523],
            [7.968871887188726, 48.716671667166715],
            [8.020927092709279, 48.775177517751771],
            [8.151065106510657, 48.918191819181914],
            [8.220472047204723, 48.976697669766978],
            [8.116361636163617, 48.989698969896992],
            [8.064306430643072, 49.002700270026999],
            [8.003575357535759, 49.02220222022202],
            [7.830058005800588, 49.041704170417042],
            [7.717271727172722, 49.048204820482049],
            [7.587133713371344, 49.080708070807077],
            [7.3442094209421, 49.11971197119712],
            [7.162016201620169, 49.152215221522148],
            [7.040554055405543, 49.171717171717169],
            [6.728222822282234, 49.223722372237219],
            [6.684843484348441, 49.249724972497248],
            [6.563381338133816, 49.431743174317432],
            [6.546029602960303, 49.457745774577454],
            [6.46794679467947, 49.464246424642461],
            [6.398539853985405, 49.464246424642461],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UGGGUIR", name: "TIBILISI UIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.997474747474755, 41.292929292929287],
            [44.936743674367449, 41.253925392539252],
            [44.884688468846889, 41.266926692669266],
            [44.832633263326343, 41.27992799279928],
            [44.815281528152823, 41.299429942994294],
            [44.797929792979303, 41.253925392539252],
            [44.867336733673369, 41.214921492149216],
            [44.789253925392543, 41.221422142214223],
            [44.719846984698478, 41.208420842084209],
            [44.702495249524958, 41.221422142214223],
            [44.607060706070612, 41.234423442344237],
            [44.589708970897092, 41.195419541954195],
            [44.581033103310332, 41.188918891889188],
            [44.572357235723587, 41.201920192019202],
            [44.502950295029507, 41.188918891889188],
            [44.528977897789787, 41.221422142214223],
            [44.520302030203027, 41.22792279227923],
            [44.442219221922201, 41.188918891889188],
            [44.346784678467856, 41.234423442344237],
            [44.329432943294336, 41.208420842084209],
            [44.25135013501351, 41.214921492149216],
            [44.22532253225323, 41.247424742474252],
            [44.20797079707971, 41.234423442344237],
            [44.173267326732685, 41.247424742474252],
            [44.181943194319445, 41.221422142214223],
            [44.155915591559165, 41.195419541954195],
            [44.103860386038605, 41.188918891889188],
            [44.060481048104819, 41.188918891889188],
            [43.973722372237233, 41.156415641564152],
            [43.834908490849088, 41.156415641564152],
            [43.782853285328542, 41.136913691369131],
            [43.748149814981502, 41.110911091109109],
            [43.678742874287437, 41.136913691369131],
            [43.609335933593371, 41.123912391239124],
            [43.565956595659571, 41.149914991499145],
            [43.470522052205226, 41.130413041304131],
            [43.4358185818582, 41.18241824182418],
            [43.37508750875088, 41.201920192019202],
            [43.201570157015709, 41.247424742474252],
            [42.975997599759978, 41.429442944294429],
            [42.837183718371847, 41.552955295529557],
            [42.58558355835585, 41.598459845984593],
            [42.51617661766177, 41.442444244424436],
            [42.004300430043017, 41.533453345334536],
            [41.969596959695977, 41.526952695269529],
            [41.830783078307832, 41.435943594359436],
            [41.544479447944795, 41.526952695269529],
            [41.449044904490464, 41.54645464546455],
            [41.284203420342038, 41.598459845984593],
            [40.980548054805482, 41.695969596959699],
            [40.659540954095419, 41.799979997999799],
            [40.329857985798583, 41.897489748974891],
            [40.147664766476652, 42.489048904890488],
            [39.982823282328241, 42.989598959895986],
            [39.913416341634175, 43.184618461846185],
            [40.01752675267528, 43.386138613861384],
            [40.130313031303132, 43.548654865486547],
            [42.715721572157221, 43.165116511651163],
            [42.889238923892393, 43.178117811781178],
            [43.036728672867298, 43.087108710871085],
            [43.279652965296535, 42.918091809180922],
            [44.190619061906204, 42.63206320632063],
            [44.867336733673369, 42.749074907490751],
            [45.804330433043319, 42.476047604760474],
            [45.656840684068413, 42.177017701770176],
            [46.281503150315046, 41.955995599559955],
            [46.446344634463458, 41.897489748974891],
            [46.446344634463458, 41.890989098909891],
            [46.298854885488552, 41.786978697869785],
            [46.298854885488552, 41.585458545854586],
            [46.75, 41.299429942994294],
            [46.645889588958909, 41.05890589058906],
            [46.489723972397243, 41.045904590459045],
            [45.292454245424551, 41.461946194619458],
            [45.197019701970206, 41.396939693969401],
            [45.08423342334234, 41.357935793579358],
            [44.997474747474755, 41.292929292929287],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LKAAFIR", name: "PRAHA FIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.939718971897193, 48.612661266126608],
            [16.90501550155016, 48.716671667166715],
            [16.792229222922295, 48.710171017101707],
            [16.722822282228229, 48.736173617361736],
            [16.688118811881196, 48.729672967296729],
            [16.679442944294436, 48.768676867686764],
            [16.618711871187124, 48.781678167816779],
            [16.549304930493058, 48.807680768076807],
            [16.471222122212225, 48.807680768076807],
            [16.38446344634464, 48.736173617361736],
            [16.323732373237327, 48.736173617361736],
            [16.202270227022709, 48.749174917491743],
            [16.098159815981603, 48.749174917491743],
            [16.04610461046105, 48.762176217621757],
            [15.837883788378846, 48.872687268726871],
            [15.612311231123115, 48.885688568856885],
            [15.586283628362843, 48.898689868986892],
            [15.360711071107119, 48.983198319831985],
            [15.273952395239526, 48.983198319831985],
            [15.265276527652773, 48.963696369636963],
            [15.18719371937194, 48.950695069506949],
            [15.169841984198428, 48.944194419441942],
            [15.152490249024908, 48.989698969896992],
            [15.135138513851388, 48.989698969896992],
            [15.005000500050009, 49.002700270026999],
            [14.978972897289736, 48.963696369636963],
            [14.970297029702976, 48.885688568856885],
            [14.952945294529457, 48.788178817881786],
            [14.978972897289736, 48.775177517751771],
            [14.961621162116217, 48.762176217621757],
            [14.831483148314838, 48.781678167816779],
            [14.779427942794285, 48.723172317231722],
            [14.727372737273733, 48.677667766776679],
            [14.70134513451346, 48.58015801580158],
            [14.66664166416642, 48.58015801580158],
            [14.458420842084216, 48.645164516451644],
            [14.441069106910696, 48.612661266126608],
            [14.34563456345635, 48.560656065606558],
            [14.302255225522558, 48.567156715671565],
            [14.068006800680074, 48.599659965996594],
            [14.015951595159521, 48.638663866386636],
            [14.059330933093314, 48.671167116711672],
            [13.790379037903797, 48.827182718271828],
            [13.686268626862692, 48.879187918791878],
            [13.261151115111517, 49.100210021002098],
            [13.113661366136618, 49.191219121912191],
            [13.096309630963098, 49.204220422042205],
            [12.610461046104618, 49.529252925292525],
            [12.584433443344338, 49.568256825682568],
            [12.575757575757578, 49.574757475747575],
            [12.584433443344338, 49.633263326332631],
            [12.541054105410545, 49.665766576657667],
            [12.471647164716479, 49.704770477047703],
            [12.402240224022407, 49.756775677567752],
            [12.4456195619562, 49.795779577957795],
            [12.46297129712972, 49.808780878087809],
            [12.488998899889992, 49.93229322932293],
            [12.497674767476752, 49.971297129712973],
            [12.45429542954296, 49.990799079907987],
            [12.384888488848887, 50.016801680168015],
            [12.324157415741581, 50.036303630363037],
            [12.324157415741581, 50.042804280428044],
            [12.306805680568061, 50.055805580558051],
            [12.263426342634268, 50.068806880688065],
            [12.211371137113716, 50.101310131013101],
            [12.211371137113716, 50.159815981598157],
            [12.315481548154821, 50.172817281728172],
            [12.341509150915094, 50.192319231923193],
            [12.350185018501854, 50.237823782378236],
            [12.384888488848887, 50.289828982898285],
            [12.43694369436944, 50.328832883288328],
            [12.488998899889992, 50.341834183418342],
            [12.593109310931098, 50.374337433743371],
            [12.636488648864891, 50.393839383938392],
            [12.749274927492756, 50.432843284328435],
            [12.914116411641167, 50.478347834783477],
            [13.009550955095513, 50.517351735173513],
            [13.625537553755379, 50.712371237123712],
            [13.764351435143517, 50.731873187318733],
            [14.33695869586959, 50.881388138813882],
            [14.389013901390143, 50.900890089008897],
            [14.397689768976903, 50.920392039203918],
            [14.397689768976903, 50.939893989398939],
            [14.302255225522558, 50.952895289528954],
            [14.267551755175525, 51.017901790179017],
            [14.397689768976903, 51.017901790179017],
            [14.562531253125314, 51.004900490049003],
            [14.571207120712074, 50.959395939593961],
            [14.579882988298834, 50.913891389138911],
            [14.631938193819387, 50.913891389138911],
            [14.796779677967805, 50.835883588358833],
            [14.822807280728078, 50.868386838683868],
            [14.866186618661871, 50.868386838683868],
            [15.005000500050009, 50.868386838683868],
            [14.996324632463249, 50.907390739073904],
            [15.013676367636769, 50.978897889788975],
            [15.005000500050009, 50.985398539853982],
            [14.970297029702976, 50.998399839983996],
            [15.022352235223529, 51.030903090309032],
            [15.031028102810289, 51.01140114011401],
            [15.057055705570562, 51.024402440244025],
            [15.091759175917595, 51.01140114011401],
            [15.239248924892493, 50.998399839983996],
            [15.291304130413046, 50.952895289528954],
            [15.378062806280631, 50.809880988098811],
            [15.52555255525553, 50.783878387838783],
            [15.673042304230428, 50.751375137513747],
            [16.089483948394843, 50.647364736473648],
            [16.098159815981603, 50.660366036603662],
            [16.150215021502156, 50.653865386538655],
            [16.184918491849189, 50.627862786278627],
            [16.219621962196221, 50.640864086408641],
            [16.236973697369741, 50.673367336733669],
            [16.332408240824087, 50.666866686668662],
            [16.410491049104913, 50.614861486148612],
            [16.445194519451952, 50.588858885888584],
            [16.419166916691672, 50.575857585758577],
            [16.202270227022709, 50.432843284328435],
            [16.306380638063814, 50.380838083808378],
            [16.427842784278432, 50.322332233223321],
            [16.471222122212225, 50.283328332833278],
            [16.505925592559258, 50.257325732573257],
            [16.557980798079811, 50.218321832183214],
            [16.566656665666571, 50.159815981598157],
            [16.627387738773884, 50.127312731273129],
            [16.688118811881196, 50.101310131013101],
            [16.852960296029607, 50.205320532053207],
            [16.983098309830986, 50.237823782378236],
            [17.009125912591266, 50.218321832183214],
            [17.017801780178026, 50.257325732573257],
            [17.017801780178026, 50.270327032703271],
            [16.991774177417746, 50.289828982898285],
            [16.974422442244233, 50.309330933093307],
            [16.90501550155016, 50.445844584458442],
            [17.199994999499957, 50.374337433743371],
            [17.451595159515954, 50.257325732573257],
            [17.468946894689473, 50.270327032703271],
            [17.633788378837892, 50.270327032703271],
            [17.694519451945197, 50.328832883288328],
            [17.74657465746575, 50.3028302830283],
            [17.74657465746575, 50.211821182118207],
            [17.74657465746575, 50.205320532053207],
            [17.73789878987899, 50.205320532053207],
            [17.590409040904099, 50.159815981598157],
            [17.607760776077612, 50.15331533153315],
            [17.711871187118717, 50.094809480948093],
            [17.74657465746575, 50.075307530753072],
            [17.78127812781279, 50.016801680168015],
            [17.868036803680376, 49.97779777977798],
            [17.928767876787681, 49.990799079907987],
            [17.954795479547961, 50.003800380038001],
            [17.998174817481754, 50.02980298029803],
            [18.006850685068514, 50.055805580558051],
            [18.0936093609361, 50.02980298029803],
            [18.119636963696372, 49.997299729972994],
            [18.163016301630165, 49.98429842984298],
            [18.180368036803685, 50.003800380038001],
            [18.197719771977205, 50.003800380038001],
            [18.215071507150718, 49.990799079907987],
            [18.215071507150718, 49.971297129712973],
            [18.275802580258031, 49.964796479647966],
            [18.284478447844791, 49.93229322932293],
            [18.319181918191823, 49.919291929192916],
            [18.345209520952103, 49.93229322932293],
            [18.336533653365343, 49.945294529452944],
            [18.466671667166722, 49.912791279127909],
            [18.562106210621067, 49.88028802880288],
            [18.570782078207827, 49.847784778477845],
            [18.63151315131514, 49.717771777177717],
            [18.805030503050311, 49.665766576657667],
            [18.848409840984104, 49.516251625162518],
            [18.700920092009206, 49.496749674967496],
            [18.562106210621067, 49.509750975097511],
            [18.544754475447547, 49.431743174317432],
            [18.449319931993202, 49.39273927392739],
            [18.423292329232929, 49.399239923992397],
            [18.371237123712376, 49.327732773277326],
            [18.284478447844791, 49.301730173017297],
            [18.180368036803685, 49.28872887288729],
            [18.163016301630165, 49.243224322432241],
            [18.154340434043412, 49.230223022302226],
            [18.154340434043412, 49.217221722172219],
            [18.145664566456652, 49.197719771977198],
            [18.119636963696372, 49.178217821782177],
            [18.119636963696372, 49.152215221522148],
            [18.110961096109619, 49.126212621262127],
            [18.0936093609361, 49.067706770677063],
            [18.06758175817582, 49.035203520352034],
            [17.972147214721474, 49.02220222022202],
            [17.946119611961201, 49.02220222022202],
            [17.911416141614168, 49.002700270026999],
            [17.902740274027408, 48.97019701970197],
            [17.711871187118717, 48.866186618661864],
            [17.677167716771685, 48.859685968596857],
            [17.607760776077612, 48.840184018401843],
            [17.538353835383546, 48.820682068206821],
            [17.408215821582161, 48.820682068206821],
            [17.364836483648368, 48.814181418141814],
            [17.173967396739677, 48.859685968596857],
            [17.165291529152924, 48.853185318531857],
            [17.078532853285331, 48.781678167816779],
            [16.939718971897193, 48.612661266126608],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LQSBUIR", name: "SARAJEVO UIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.030603060306035, 44.848784878487848],
            [19.360286028602864, 44.887788778877891],
            [19.299554955495552, 44.686268626862685],
            [19.108685868586868, 44.595259525952599],
            [19.126037603760381, 44.322232223222322],
            [19.368961896189624, 44.18571857185718],
            [19.46439643964397, 44.133713371337137],
            [19.559830983098315, 44.068706870687066],
            [19.585858585858588, 44.062206220622059],
            [19.620562056205628, 44.042704270427038],
            [19.603210321032108, 44.010201020102009],
            [19.46439643964397, 43.964696469646967],
            [19.299554955495552, 43.997199719971995],
            [19.360286028602864, 43.867186718671867],
            [19.507775777577763, 43.717671767176718],
            [19.533803380338043, 43.691669166916689],
            [19.525127512751283, 43.672167216721675],
            [19.499099909991003, 43.581158115811576],
            [19.1433893389339, 43.529152915291533],
            [18.926492649264929, 43.522652265226526],
            [19.212796279627966, 43.334133413341334],
            [19.073982398239828, 43.308130813081306],
            [19.047954795479555, 43.249624962496249],
            [18.917816781678177, 43.334133413341334],
            [18.761651165116518, 43.295129512951291],
            [18.666216621662173, 43.035103510351036],
            [18.501375137513755, 42.996099609960993],
            [18.553430343034307, 42.586558655865588],
            [18.484023402340242, 42.450045004500453],
            [18.449319931993202, 42.502050205020502],
            [18.440644064406449, 42.541054105410538],
            [18.310506050605063, 42.612561256125616],
            [18.249774977497758, 42.599559955995602],
            [17.850685068506856, 42.853085308530851],
            [17.833333333333336, 42.866086608660865],
            [17.711871187118717, 42.840084008400837],
            [17.547029702970299, 42.905090509050908],
            [17.703195319531957, 42.95059505950595],
            [17.659815981598165, 43.041604160416043],
            [17.451595159515954, 43.210621062106213],
            [17.373512351235128, 43.27562756275627],
            [17.278077807780782, 43.353635363536355],
            [17.25205020502051, 43.451145114511448],
            [17.24337433743375, 43.483648364836483],
            [17.21734673467347, 43.496649664966498],
            [17.199994999499957, 43.496649664966498],
            [17.121912191219124, 43.516151615161519],
            [17.043829382938299, 43.561656165616562],
            [16.948394839483953, 43.633163316331633],
            [16.826932693269335, 43.737173717371732],
            [16.748849884988502, 43.802180218021803],
            [16.722822282228229, 43.821682168216824],
            [16.670767076707676, 43.873687368736874],
            [16.479897989798985, 44.029702970297024],
            [16.315056505650567, 44.12721272127213],
            [16.306380638063814, 44.153215321532151],
            [16.271677167716774, 44.166216621662166],
            [16.141539153915396, 44.445744574457443],
            [15.968021802180225, 44.679767976797677],
            [15.768476847684774, 44.77077707770777],
            [15.742449244924501, 44.939793979397933],
            [15.733773377337741, 45.193319331933196],
            [15.889938993899392, 45.219321932193218],
            [15.907290729072912, 45.225822582258225],
            [15.933318331833192, 45.219321932193218],
            [16.306380638063814, 44.998299829982997],
            [16.37578757875788, 45.050305030503054],
            [16.445194519451952, 45.128312831283125],
            [16.462546254625465, 45.147814781478147],
            [16.488573857385745, 45.199819981998203],
            [16.523277327732778, 45.225822582258225],
            [16.557980798079811, 45.219321932193218],
            [16.679442944294436, 45.199819981998203],
            [16.731498149814989, 45.193319331933196],
            [16.783553355335542, 45.186818681868189],
            [16.835608560856087, 45.219321932193218],
            [16.948394839483953, 45.238823882388239],
            [17.017801780178026, 45.225822582258225],
            [17.069856985698578, 45.199819981998203],
            [17.165291529152924, 45.154315431543154],
            [17.182643264326437, 45.147814781478147],
            [17.23469846984699, 45.141314131413139],
            [17.25205020502051, 45.154315431543154],
            [17.295429542954302, 45.173817381738175],
            [17.356160616061608, 45.141314131413139],
            [17.364836483648368, 45.134813481348132],
            [17.399539953995408, 45.134813481348132],
            [17.408215821582161, 45.134813481348132],
            [17.451595159515954, 45.160816081608161],
            [17.451595159515954, 45.128312831283125],
            [17.486298629862993, 45.134813481348132],
            [17.494974497449753, 45.115311531153111],
            [17.521002100210026, 45.102310231023097],
            [17.547029702970299, 45.115311531153111],
            [17.659815981598165, 45.115311531153111],
            [17.842009200920096, 45.043804380438047],
            [17.859360936093616, 45.043804380438047],
            [17.894064406440648, 45.063306330633061],
            [17.998174817481754, 45.134813481348132],
            [18.379912991299136, 45.102310231023097],
            [18.666216621662173, 45.082808280828083],
            [18.726947694769486, 44.998299829982997],
            [18.831058105810584, 44.848784878487848],
            [19.030603060306035, 44.848784878487848],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LYBAUIR", name: "BEOGRAD UIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.030603060306035, 44.848784878487848],
            [19.013251325132522, 44.868286828682869],
            [19.091334133413348, 45.004800480048004],
            [19.073982398239828, 45.069806980698068],
            [19.073982398239828, 45.108810881088104],
            [19.073982398239828, 45.128312831283125],
            [19.108685868586868, 45.134813481348132],
            [19.247499749975006, 45.154315431543154],
            [19.290879087908799, 45.206320632063203],
            [19.368961896189624, 45.232323232323232],
            [19.247499749975006, 45.271327132713267],
            [18.995899589959002, 45.349334933493353],
            [19.013251325132522, 45.401340134013395],
            [19.091334133413348, 45.485848584858488],
            [19.134713471347141, 45.505350535053509],
            [19.056630663066315, 45.53135313531353],
            [18.752975297529758, 45.58335833583358],
            [18.961196119611969, 45.674367436743672],
            [18.935168516851689, 45.817381738173815],
            [18.813706370637071, 45.869386938693864],
            [18.848409840984104, 45.9018901890189],
            [18.926492649264929, 45.934393439343935],
            [19.082658265826588, 45.966896689668964],
            [19.282203220322039, 45.999399939993999],
            [19.351610161016104, 46.044904490449042],
            [19.48174817481749, 46.103410341034106],
            [19.533803380338043, 46.155415541554156],
            [19.603210321032108, 46.17491749174917],
            [19.698644864486454, 46.181418141814177],
            [19.724672467246734, 46.168416841684163],
            [19.768051805180527, 46.135913591359135],
            [19.872162216221625, 46.148914891489149],
            [19.941569156915698, 46.17491749174917],
            [20.097734773477356, 46.161916191619156],
            [20.262576257625767, 46.129412941294127],
            [20.626962696269629, 45.85638563856385],
            [21.138838883888397, 45.381838183818381],
            [21.312356235623568, 45.238823882388239],
            [21.407790779077914, 44.985298529852983],
            [21.433818381838186, 44.848784878487848],
            [21.529252925292532, 44.77077707770777],
            [22.457570757075715, 44.621262126212621],
            [22.639763976397646, 44.595259525952599],
            [22.55300530053006, 44.309230923092308],
            [22.665791579157919, 44.198719871987194],
            [22.613736373637373, 44.08170817081708],
            [22.431543154315435, 43.997199719971995],
            [22.388163816381642, 43.91269126912691],
            [22.370812081208129, 43.828182818281832],
            [22.362136213621369, 43.795679567956796],
            [22.414191419141922, 43.685168516851682],
            [22.500950095009507, 43.633163316331633],
            [22.492274227422747, 43.561656165616562],
            [22.52697769776978, 43.496649664966498],
            [22.665791579157919, 43.431643164316426],
            [22.769901990199024, 43.373137313731377],
            [22.769901990199024, 43.353635363536355],
            [22.830633063306337, 43.327632763276327],
            [23.004150415041508, 43.165116511651163],
            [22.995474547454748, 43.158615861586156],
            [22.986798679867995, 43.126112611261121],
            [22.986798679867995, 43.113111311131114],
            [22.934743474347442, 43.067606760676071],
            [22.89136413641365, 43.035103510351036],
            [22.795929592959304, 42.970097009700964],
            [22.752550255025511, 42.892089208920893],
            [22.639763976397646, 42.879087908790879],
            [22.596384638463853, 42.892089208920893],
            [22.5443294329433, 42.866086608660865],
            [22.492274227422747, 42.846584658465844],
            [22.440219021902195, 42.801080108010801],
            [22.448894889488955, 42.599559955995602],
            [22.466246624662475, 42.567056705670566],
            [22.55300530053006, 42.476047604760474],
            [22.55300530053006, 42.417541754175417],
            [22.362136213621369, 42.313531353135318],
            [22.240674067406744, 42.307030703070311],
            [22.119211921192125, 42.300530053005303],
            [22.101860186018605, 42.300530053005303],
            [21.780853085308536, 42.281028102810282],
            [21.66806680668067, 42.248524852485247],
            [21.616011601160125, 42.229022902290225],
            [21.399114911491154, 42.157515751575161],
            [21.329707970797088, 42.13801380138014],
            [21.321032103210328, 42.131513151315133],
            [21.052080208020804, 42.112011201120112],
            [20.895914591459153, 42.099009900990097],
            [20.592259225922596, 41.877987798779877],
            [20.496824682468251, 42.281028102810282],
            [20.141114111411149, 42.51505150515051],
            [19.863486348634872, 42.469546954695467],
            [19.854810481048112, 42.476047604760474],
            [19.750700070007007, 42.664566456645659],
            [19.603210321032108, 42.534553455345531],
            [19.533803380338043, 42.45654565456546],
            [19.334258425842592, 42.229022902290225],
            [19.386313631363144, 42.131513151315133],
            [19.386313631363144, 41.968996899689969],
            [19.377637763776384, 41.864986498649863],
            [19.368961896189624, 41.851985198519856],
            [19.316906690669072, 41.77397739773977],
            [19.1433893389339, 41.552955295529557],
            [19.100010001000108, 41.455445544554451],
            [18.865761576157624, 41.130413041304131],
            [18.553430343034307, 41.292929292929287],
            [18.223747374737478, 41.442444244424436],
            [18.336533653365343, 41.494449444944493],
            [18.431968196819689, 41.741474147414742],
            [18.484023402340242, 41.884488448844884],
            [18.58813381338134, 41.864986498649863],
            [18.666216621662173, 41.929992999299927],
            [18.527402740274034, 42.014501450145012],
            [18.553430343034307, 42.09250925092509],
            [18.5968096809681, 42.203020302030197],
            [18.58813381338134, 42.216021602160211],
            [18.562106210621067, 42.339533953395339],
            [18.553430343034307, 42.346034603460346],
            [18.553430343034307, 42.352535253525346],
            [18.553430343034307, 42.41104110411041],
            [18.484023402340242, 42.450045004500453],
            [18.553430343034307, 42.586558655865588],
            [18.501375137513755, 42.996099609960993],
            [18.666216621662173, 43.035103510351036],
            [18.761651165116518, 43.295129512951291],
            [18.917816781678177, 43.334133413341334],
            [19.047954795479555, 43.249624962496249],
            [19.073982398239828, 43.308130813081306],
            [19.212796279627966, 43.334133413341334],
            [18.926492649264929, 43.522652265226526],
            [19.1433893389339, 43.529152915291533],
            [19.499099909991003, 43.581158115811576],
            [19.525127512751283, 43.672167216721675],
            [19.533803380338043, 43.691669166916689],
            [19.507775777577763, 43.717671767176718],
            [19.360286028602864, 43.867186718671867],
            [19.299554955495552, 43.997199719971995],
            [19.46439643964397, 43.964696469646967],
            [19.603210321032108, 44.010201020102009],
            [19.620562056205628, 44.042704270427038],
            [19.585858585858588, 44.062206220622059],
            [19.559830983098315, 44.068706870687066],
            [19.46439643964397, 44.133713371337137],
            [19.368961896189624, 44.18571857185718],
            [19.126037603760381, 44.322232223222322],
            [19.108685868586868, 44.595259525952599],
            [19.299554955495552, 44.686268626862685],
            [19.360286028602864, 44.887788778877891],
            [19.030603060306035, 44.848784878487848],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LIBBFIR", name: "BRINDISI FIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.330558055805582, 43.516151615161519],
            [13.660241024102412, 43.509650965096512],
            [14.501800180018009, 43.503150315031505],
            [14.631938193819387, 43.418641864186412],
            [14.796779677967805, 43.314631463146313],
            [15.109110911091115, 43.113111311131114],
            [15.291304130413046, 42.996099609960993],
            [15.464821482148217, 42.885588558855886],
            [15.52555255525553, 42.846584658465844],
            [15.751125112511254, 42.703570357035701],
            [15.820532053205326, 42.658065806580652],
            [15.933318331833192, 42.580058005800581],
            [16.04610461046105, 42.508550855085502],
            [16.124187418741876, 42.469546954695467],
            [16.098159815981603, 42.378537853785375],
            [16.176242624262429, 42.333033303330332],
            [16.349759975997607, 42.346034603460346],
            [17.25205020502051, 41.929992999299927],
            [17.312781278127815, 41.884488448844884],
            [17.616436643664372, 41.754475447544749],
            [18.128312831283132, 41.5009500950095],
            [18.223747374737478, 41.442444244424436],
            [18.553430343034307, 41.292929292929287],
            [18.865761576157624, 41.130413041304131],
            [18.952520252025209, 40.889888988898889],
            [18.995899589959002, 40.746874687468747],
            [18.995899589959002, 40.597359735973598],
            [18.995899589959002, 40.415341534153413],
            [18.995899589959002, 40.350335033503349],
            [18.995899589959002, 40.246324632463242],
            [18.995899589959002, 40.057805780578057],
            [18.995899589959002, 39.934293429342929],
            [18.995899589959002, 39.817281728172816],
            [18.995899589959002, 39.55725572557256],
            [18.995899589959002, 39.394739473947396],
            [18.995899589959002, 39.29072907290729],
            [18.995899589959002, 38.881188118811878],
            [17.512326232623266, 38.75117511751175],
            [17.382188218821888, 38.7056705670567],
            [17.269401940194022, 38.660166016601664],
            [16.419166916691672, 39.076207620762077],
            [16.254325432543261, 39.232223222322233],
            [16.03742874287429, 39.61576157615761],
            [15.941994199419945, 39.778277827782773],
            [15.742449244924501, 40.135813581358136],
            [15.620987098709875, 40.343834383438342],
            [15.473497349734977, 40.603860386038605],
            [15.421442144214424, 40.688368836883683],
            [15.230573057305733, 41.006900690069003],
            [15.169841984198428, 41.110911091109109],
            [15.117786778677875, 41.201920192019202],
            [14.883538353835391, 41.266926692669266],
            [14.805455545554558, 41.305930593059301],
            [14.545179517951802, 41.396939693969401],
            [14.380338033803383, 41.448944894489443],
            [14.068006800680074, 41.812981298129813],
            [13.755675567556757, 42.164016401640161],
            [13.738323832383244, 42.19001900190019],
            [13.599509950995106, 42.339533953395339],
            [13.599509950995106, 42.346034603460346],
            [13.530103010301033, 42.417541754175417],
            [13.443344334433448, 42.51505150515051],
            [13.434668466846688, 42.534553455345531],
            [13.122337233723378, 42.879087908790879],
            [13.087633763376346, 42.918091809180922],
            [13.295854585458549, 43.36663666366637],
            [13.330558055805582, 43.516151615161519],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LSASUIR", name: "SWITZERLAND UIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.578457845784584, 47.605060506050606],
            [7.682568256825689, 47.566056605660563],
            [7.699919991999202, 47.540054005400535],
            [7.778002800280035, 47.553055305530549],
            [7.830058005800588, 47.579057905790577],
            [7.847409740974101, 47.579057905790577],
            [7.856085608560861, 47.592059205920592],
            [7.899464946494653, 47.585558555855584],
            [7.908140814081413, 47.579057905790577],
            [7.942844284428446, 47.546554655465542],
            [8.081658165816584, 47.559555955595556],
            [8.20312031203121, 47.61806180618062],
            [8.281203120312036, 47.605060506050606],
            [8.454720472047207, 47.57255725572557],
            [8.524127412741279, 47.631063106310634],
            [8.524127412741279, 47.644064406440641],
            [8.411341134113414, 47.676567656765677],
            [8.498099809980999, 47.767576757675769],
            [8.628237823782385, 47.767576757675769],
            [8.888513851385142, 47.657065706570656],
            [9.001300130013007, 47.676567656765677],
            [9.03600360036004, 47.683068306830677],
            [9.365686568656869, 47.605060506050606],
            [9.452445244524455, 47.585558555855584],
            [9.5478797879788, 47.546554655465542],
            [9.55655565556556, 47.527052705270521],
            [9.57390739073908, 47.488048804880485],
            [9.617286728672873, 47.449044904490449],
            [9.651990199019906, 47.410041004100407],
            [9.669341934193426, 47.390539053905385],
            [9.617286728672873, 47.371037103710371],
            [9.599934993499353, 47.358035803580357],
            [9.58258325832584, 47.312531253125314],
            [9.53920392039204, 47.267026702670265],
            [9.617286728672873, 47.156515651565158],
            [9.634638463846386, 47.13051305130513],
            [9.58258325832584, 47.052505250525051],
            [9.591259125912593, 47.052505250525051],
            [9.816831683168324, 47.020002000200023],
            [10.111811181118114, 46.844484448444845],
            [10.146514651465154, 46.81198119811981],
            [10.276652665266532, 46.909490949094909],
            [10.389438943894397, 47.000500050005002],
            [10.484873487348743, 46.948494849484945],
            [10.467521752175223, 46.863986398639867],
            [10.467521752175223, 46.701470147014703],
            [10.476197619761983, 46.564956495649568],
            [10.337383738373845, 46.545454545454547],
            [10.215921592159219, 46.636463646364632],
            [10.198569856985706, 46.623462346234618],
            [10.146514651465154, 46.616961696169611],
            [10.059755975597561, 46.506450645064504],
            [10.042404240424048, 46.480448044804476],
            [9.90359035903591, 46.415441544154419],
            [9.5478797879788, 46.304930493049305],
            [9.521852185218528, 46.337433743374334],
            [9.504500450045008, 46.350435043504348],
            [9.365686568656869, 46.512951295129511],
            [9.278927892789284, 46.480448044804476],
            [9.278927892789284, 46.415441544154419],
            [9.278927892789284, 46.311431143114305],
            [9.296279627962804, 46.207420742074206],
            [9.226872687268731, 46.155415541554156],
            [9.209520952095211, 46.142414241424142],
            [9.03600360036004, 46.025402540254021],
            [9.070707070707073, 45.869386938693864],
            [9.03600360036004, 45.862886288628857],
            [8.966596659665974, 45.84988498849885],
            [8.905865586558662, 45.908390839083907],
            [8.853810381038109, 45.992899289928992],
            [8.662941294129418, 46.103410341034106],
            [8.567506750675072, 46.168416841684163],
            [8.506775677567759, 46.226922692269227],
            [8.480748074807487, 46.246424642464248],
            [8.463396339633967, 46.252925292529255],
            [8.446044604460454, 46.27242724272427],
            [8.446044604460454, 46.278927892789277],
            [8.437368736873694, 46.467446744674461],
            [8.289878987898796, 46.415441544154419],
            [8.315906590659068, 46.38943894389439],
            [8.229147914791483, 46.330933093309326],
            [8.090334033403344, 46.26592659265927],
            [8.081658165816584, 46.259425942594262],
            [8.133713371337137, 46.181418141814177],
            [8.029602960296032, 46.077407740774078],
            [7.968871887188726, 45.999399939993999],
            [7.908140814081413, 45.992899289928992],
            [7.873437343734381, 45.953895389538957],
            [7.864761476147621, 45.940894089408943],
            [7.847409740974101, 45.927892789278928],
            [7.725947594759482, 45.934393439343935],
            [7.543754375437551, 45.966896689668964],
            [7.448319831983206, 45.927892789278928],
            [7.101285128512856, 45.875887588758872],
            [7.049229922992303, 45.9018901890189],
            [6.988498849884991, 46.005900590058999],
            [6.80630563056306, 46.142414241424142],
            [6.81498149814982, 46.239923992399241],
            [6.780278027802787, 46.343934393439341],
            [6.745574557455747, 46.343934393439341],
            [6.658815881588161, 46.363436343634362],
            [6.528677867786783, 46.356935693569355],
            [6.47662266226623, 46.382938293829383],
            [6.294429442944299, 46.317931793179312],
            [6.216346634663473, 46.207420742074206],
            [6.077532753275335, 46.187918791879184],
            [6.008125812581262, 46.142414241424142],
            [5.97342234223423, 46.148914891489149],
            [5.97342234223423, 46.194419441944191],
            [6.120912091209128, 46.330933093309326],
            [6.068856885688575, 46.415441544154419],
            [6.094884488448848, 46.525952595259525],
            [6.094884488448848, 46.545454545454547],
            [6.164291429142921, 46.610461046104611],
            [6.251050105010506, 46.668966896689668],
            [6.277077707770779, 46.681968196819682],
            [6.294429442944299, 46.701470147014703],
            [6.320457045704572, 46.714471447144717],
            [6.389863986398645, 46.740474047404739],
            [6.407215721572165, 46.753475347534753],
            [6.441919191919197, 46.75997599759976],
            [6.398539853985405, 46.779477947794774],
            [6.45927092709271, 46.850985098509852],
            [6.433243324332437, 46.922492249224916],
            [6.47662266226623, 46.961496149614959],
            [6.520002000200023, 46.974497449744973],
            [6.641464146414648, 47.02650265026503],
            [6.719546954695474, 47.052505250525051],
            [6.81498149814982, 47.13051305130513],
            [6.953795379537958, 47.241024102410236],
            [7.005850585058511, 47.325532553255329],
            [7.066581658165823, 47.345034503450343],
            [6.901740174017405, 47.384038403840378],
            [6.962471247124718, 47.462046204620464],
            [6.997174717471751, 47.468546854685471],
            [6.988498849884991, 47.494549454945492],
            [7.144664466446649, 47.501050105010499],
            [7.205395539553962, 47.423042304230421],
            [7.378912891289133, 47.416541654165414],
            [7.430968096809686, 47.488048804880485],
            [7.474347434743478, 47.494549454945492],
            [7.578457845784584, 47.605060506050606],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LECMUIR", name: "MADRID UIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.000700070006999, 43.002600260026],
            [-13.000700070006999, 43.503150315031505],
            [-13.000700070006999, 43.997199719971995],
            [-13.000700070006999, 44.497749774977493],
            [-13.000700070006999, 44.998299829982997],
            [-12.91394139413941, 44.998299829982997],
            [-12.584258425842581, 44.998299829982997],
            [-12.497499749974995, 44.998299829982997],
            [-12.280603060306028, 44.998299829982997],
            [-12.002975297529751, 44.998299829982997],
            [-11.569181918191816, 44.998299829982997],
            [-11.499774977497747, 44.998299829982997],
            [-10.996574657465743, 44.998299829982997],
            [-10.502050205020499, 44.998299829982997],
            [-9.998849884988495, 44.998299829982997],
            [-9.83400840084008, 44.998299829982997],
            [-9.495649564956494, 44.998299829982997],
            [-9.131263126312628, 44.998299829982997],
            [-9.001125112511247, 44.998299829982997],
            [-8.749524952495246, 44.998299829982997],
            [-8.003400340034002, 44.998299829982997],
            [-6.918916891689165, 44.835783578357834],
            [-5.834433443344331, 44.660266026602656],
            [-5.62621262126212, 44.621262126212621],
            [-5.140364036403639, 44.543254325432542],
            [-4.949494949494948, 44.5042504250425],
            [-4.611136113611359, 44.445744574457443],
            [-4.437618761876188, 44.413241324132414],
            [-4.229397939793976, 44.374237423742372],
            [-3.986473647364733, 44.328732873287329],
            [-3.891039103910387, 44.296229622962294],
            [-3.153590359035903, 44.049204920492045],
            [-2.910666066606659, 43.971197119711974],
            [-2.841259125912586, 43.951695169516952],
            [-2.737148714871481, 43.91269126912691],
            [-2.728472847284728, 43.91269126912691],
            [-2.520252025202517, 43.841184118411839],
            [-2.164541454145414, 43.717671767176718],
            [-2.069106910691069, 43.685168516851682],
            [-2.017051705170516, 43.665666566656668],
            [-1.964996499649963, 43.646164616461647],
            [-1.782803280328032, 43.581158115811576],
            [-1.782803280328032, 43.386138613861384],
            [-1.739423942394232, 43.353635363536355],
            [-1.72207220722072, 43.314631463146313],
            [-1.617961796179614, 43.288628862886284],
            [-1.565906590659061, 43.256125612561256],
            [-1.513851385138508, 43.295129512951291],
            [-1.487823782378236, 43.282128212821277],
            [-1.470472047204716, 43.288628862886284],
            [-1.453120312031203, 43.061106110611064],
            [-1.331658165816577, 43.04810481048105],
            [-1.106085608560853, 43.009100910091007],
            [-0.975947594759475, 42.970097009700964],
            [-0.84580958095809, 42.931093109310929],
            [-0.724347434743471, 42.8985898589859],
            [-0.654940494049399, 42.872587258725872],
            [-0.464071407140707, 42.814081408140808],
            [-0.299229922992296, 42.840084008400837],
            [-0.247174717471744, 42.788078807880787],
            [-0.203795379537951, 42.762076207620765],
            [-0.064981498149812, 42.697069706970694],
            [-0.290554055405536, 42.099009900990097],
            [-0.394664466446642, 41.780478047804777],
            [-0.620237023702366, 41.117411741174116],
            [-0.776402640264024, 40.701370137013697],
            [-0.923892389238922, 40.233323332333228],
            [-0.967271727172715, 40.090309030903086],
            [-1.001975197519748, 39.999299929993001],
            [-1.036678667866781, 39.914791479147915],
            [-1.253575357535752, 39.147714771477148],
            [-1.262251225122512, 39.02420242024202],
            [-1.296954695469545, 39.004700470047005],
            [-1.331658165816577, 38.835683568356835],
            [-1.357685768576857, 38.712171217121707],
            [-1.453120312031203, 38.270127012701266],
            [-1.557230723072301, 37.906090609060904],
            [-1.565906590659061, 37.880088008800882],
            [-1.774127412741272, 37.087008700870086],
            [-1.834858485848578, 36.852985298529852],
            [-1.860886088608858, 36.774977497749774],
            [-1.886913691369131, 36.690469046904688],
            [-2.103810381038102, 35.832383238323828],
            [-2.112486248624862, 35.832383238323828],
            [-2.685093509350935, 35.832383238323828],
            [-3.049479947994797, 35.832383238323828],
            [-3.231673167316728, 35.832383238323828],
            [-3.266376637663761, 35.832383238323828],
            [-3.431218121812179, 35.832383238323828],
            [-3.439893989398939, 35.832383238323828],
            [-3.500625062506245, 35.832383238323828],
            [-3.700170017001696, 35.832383238323828],
            [-3.96044604460446, 35.832383238323828],
            [-4.003825382538253, 35.832383238323828],
            [-4.368211821182115, 35.832383238323828],
            [-4.897439743974395, 35.832383238323828],
            [-5.123012301230119, 35.832383238323828],
            [-5.140364036403639, 35.832383238323828],
            [-5.66959195919592, 35.832383238323828],
            [-5.695619561956192, 35.832383238323828],
            [-5.955895589558956, 35.832383238323828],
            [-6.242199219921986, 35.832383238323828],
            [-6.320282028202818, 35.832383238323828],
            [-6.450420042004197, 35.832383238323828],
            [-6.632613261326128, 35.832383238323828],
            [-6.719371937193714, 35.832383238323828],
            [-6.754075407540753, 35.832383238323828],
            [-6.996999699969997, 35.832383238323828],
            [-7.387413741374132, 35.832383238323828],
            [-7.387413741374132, 35.968896889688963],
            [-7.387413741374132, 36.027402740274027],
            [-7.387413741374132, 36.664466446644667],
            [-7.387413741374132, 36.768476847684767],
            [-7.387413741374132, 36.833483348334838],
            [-7.387413741374132, 36.917991799179916],
            [-7.387413741374132, 37.03500350035003],
            [-7.387413741374132, 37.074007400740072],
            [-7.387413741374132, 37.126012601260129],
            [-7.430793079307925, 37.243024302430243],
            [-7.422117211721172, 37.347034703470342],
            [-7.430793079307925, 37.41854185418542],
            [-7.500200020001998, 37.581058105810584],
            [-7.318006800680067, 37.828082808280826],
            [-7.248599859985994, 37.893089308930897],
            [-7.127137713771376, 38.003600360036003],
            [-7.101110111011096, 38.023102310231025],
            [-6.996999699969997, 38.081608160816081],
            [-7.02302730273027, 38.127112711271124],
            [-6.996999699969997, 38.153115311531153],
            [-7.170517051705168, 38.348134813481352],
            [-7.300655065506547, 38.497649764976501],
            [-7.283303330333027, 38.549654965496543],
            [-7.248599859985994, 38.634163416341636],
            [-7.222572257225721, 38.731673167316728],
            [-7.153165316531648, 38.803180318031806],
            [-7.066406640664063, 38.900690069006899],
            [-6.970972097209717, 38.998199819981998],
            [-6.953620362036197, 39.02420242024202],
            [-6.962296229622957, 39.037203720372034],
            [-6.962296229622957, 39.056705670567055],
            [-6.970972097209717, 39.076207620762077],
            [-7.00567556755675, 39.102210221022105],
            [-7.01435143514351, 39.115211521152119],
            [-7.03170317031703, 39.108710871087112],
            [-7.057730773077303, 39.128212821282126],
            [-7.153165316531648, 39.160716071607155],
            [-7.222572257225721, 39.219221922192219],
            [-7.300655065506547, 39.329732973297325],
            [-7.370062006200619, 39.531253125312531],
            [-7.387413741374132, 39.537753775377539],
            [-7.500200020001998, 39.661266126612659],
            [-6.996999699969997, 39.667766776677666],
            [-6.996999699969997, 39.687268726872688],
            [-6.875537553755372, 39.87578757875788],
            [-6.875537553755372, 39.895289528952894],
            [-6.875537553755372, 39.992799279927993],
            [-6.988323832383237, 40.122812281228121],
            [-6.780103010301026, 40.363336333633363],
            [-6.814806480648059, 40.402340234023399],
            [-6.797454745474546, 40.519351935193519],
            [-6.719371937193714, 40.623362336233626],
            [-6.762751275127506, 40.668866886688669],
            [-6.814806480648059, 40.766376637663768],
            [-6.832158215821579, 40.831383138313825],
            [-6.780103010301026, 41.130413041304131],
            [-6.389688968896884, 41.377437743774379],
            [-6.276902690269026, 41.481448144814479],
            [-6.250875087508746, 41.585458545854586],
            [-6.372337233723371, 41.676467646764678],
            [-6.50247524752475, 41.767476747674763],
            [-6.563206320632062, 41.936493649364934],
            [-6.623937393739368, 41.942994299429941],
            [-7.118461846184616, 41.936493649364934],
            [-7.265951595159514, 41.851985198519856],
            [-7.474172417241718, 41.832483248324834],
            [-7.604310431043103, 41.858485848584856],
            [-7.777827782778274, 41.910491049104905],
            [-8.081483148314828, 41.812981298129813],
            [-8.228972897289726, 42.13801380138014],
            [-8.567331733173315, 42.066506650665062],
            [-8.844959495949592, 41.864986498649863],
            [-8.923042304230421, 41.87148714871487],
            [-9.365511551155112, 41.92349234923492],
            [-9.400215021502149, 41.936493649364934],
            [-9.998849884988495, 42.001500150015005],
            [-10.068256825682564, 42.021002100210019],
            [-10.814381438143812, 42.281028102810282],
            [-12.002975297529751, 42.677567756775673],
            [-12.497499749974995, 42.833583358335829],
            [-13.000700070006999, 43.002600260026],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LRBBFIR", name: "BUCAREST FIR", fab: "3" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.665791579157919, 44.198719871987194],
            [22.55300530053006, 44.309230923092308],
            [22.639763976397646, 44.595259525952599],
            [22.457570757075715, 44.621262126212621],
            [21.529252925292532, 44.77077707770777],
            [21.433818381838186, 44.848784878487848],
            [21.407790779077914, 44.985298529852983],
            [21.312356235623568, 45.238823882388239],
            [21.138838883888397, 45.381838183818381],
            [20.626962696269629, 45.85638563856385],
            [20.262576257625767, 46.129412941294127],
            [20.401390139013905, 46.155415541554156],
            [20.705045504550462, 46.161916191619156],
            [20.85253525352536, 46.285428542854284],
            [21.086783678367844, 46.252925292529255],
            [21.104135413541357, 46.26592659265927],
            [21.130163016301637, 46.304930493049305],
            [21.199569956995703, 46.369936993699369],
            [21.251625162516255, 46.421942194219426],
            [21.268976897689775, 46.428442844284426],
            [21.268976897689775, 46.49344934493449],
            [21.268976897689775, 46.519451945194518],
            [21.303680368036808, 46.584458445844582],
            [21.321032103210328, 46.616961696169611],
            [21.416466646664674, 46.636463646364632],
            [21.494549454945499, 46.688468846884689],
            [21.511901190119019, 46.714471447144717],
            [21.581308130813085, 46.857485748574859],
            [21.616011601160125, 46.863986398639867],
            [21.67674267426743, 47.013501350135016],
            [21.69409440944095, 47.059005900590059],
            [21.763501350135016, 47.098009800980094],
            [21.772177217721776, 47.117511751175115],
            [21.806880688068816, 47.156515651565158],
            [21.841584158415849, 47.182518251825186],
            [21.858935893589361, 47.208520852085208],
            [21.858935893589361, 47.247524752475243],
            [22.02377737773778, 47.397039703970393],
            [22.110536053605365, 47.57255725572557],
            [22.197294729472951, 47.637563756375641],
            [22.440219021902195, 47.793579357935791],
            [22.86533653365337, 47.949594959495947],
            [23.099584958495853, 48.014601460146011],
            [23.342509250925097, 48.027602760276025],
            [24.21009600960096, 47.910591059105911],
            [24.583158315831582, 47.956095609560954],
            [24.886813681368139, 47.735073507350734],
            [25.051655165516564, 47.735073507350734],
            [25.164441444144416, 47.865086508650862],
            [25.650290029002903, 47.917091709170919],
            [25.719696969696969, 47.930093009300933],
            [25.780428042804289, 47.943094309430947],
            [25.96262126212622, 47.949594959495947],
            [26.465821582158227, 48.222622262226224],
            [26.621987198719879, 48.248624862486245],
            [26.83020802080209, 48.235623562356238],
            [26.942994299429955, 48.196619661966196],
            [26.969021902190221, 48.144614461446139],
            [27.029752975297541, 48.138113811381132],
            [27.029752975297541, 48.105610561056103],
            [27.099159915991606, 48.021102110211018],
            [27.159890989098912, 47.988598859885983],
            [27.151215121512152, 47.969096909690968],
            [27.177242724272432, 47.949594959495947],
            [27.159890989098912, 47.923592359235926],
            [27.203270327032712, 47.910591059105911],
            [27.211946194619472, 47.89108910891089],
            [27.237973797379738, 47.839083908390833],
            [27.229297929792992, 47.819581958195819],
            [27.246649664966498, 47.774077407740776],
            [27.290029002900297, 47.761076107610762],
            [27.264001400140017, 47.72207220722072],
            [27.411491149114923, 47.585558555855584],
            [27.454870487048709, 47.527052705270521],
            [27.472222222222229, 47.494549454945492],
            [27.550305030503054, 47.429542954295428],
            [27.585008500850094, 47.4035403540354],
            [27.585008500850094, 47.325532553255329],
            [27.6457395739574, 47.312531253125314],
            [27.758525852585265, 47.260526052605258],
            [27.853960396039611, 47.169516951695172],
            [27.862636263626371, 47.104510451045101],
            [28.105560556055607, 46.928992899289923],
            [28.114236423642367, 46.915991599159916],
            [28.166291629162927, 46.779477947794774],
            [28.174967496749687, 46.753475347534753],
            [28.244374437443753, 46.623462346234618],
            [28.227022702270233, 46.512951295129511],
            [28.235698569856993, 46.49344934493449],
            [28.235698569856993, 46.480448044804476],
            [28.253050305030513, 46.447944794479447],
            [28.235698569856993, 46.402440244024405],
            [28.200995099509953, 46.376437643764376],
            [28.200995099509953, 46.317931793179312],
            [28.148939893989407, 46.278927892789277],
            [28.114236423642367, 46.233423342334234],
            [28.122912291229127, 46.194419441944191],
            [28.096884688468847, 46.051405140514049],
            [28.096884688468847, 46.038403840384035],
            [28.096884688468847, 45.940894089408943],
            [28.157615761576167, 45.635363536353637],
            [28.088208820882087, 45.609360936093609],
            [28.157615761576167, 45.544354435443545],
            [28.200995099509953, 45.472847284728473],
            [28.322457245724578, 45.349334933493353],
            [28.331133113311338, 45.336333633363338],
            [28.721547154715481, 45.225822582258225],
            [29.111961196119623, 45.375337533753374],
            [29.502375237523765, 45.41434143414341],
            [29.667216721672176, 45.219321932193218],
            [29.806030603060307, 45.186818681868189],
            [29.970872087208733, 45.147814781478147],
            [30.048954895489558, 44.978797879787976],
            [30.083658365836584, 44.913791379137912],
            [30.153065306530664, 44.764276427642763],
            [30.170417041704184, 44.712271227122713],
            [30.231148114811489, 44.608260826082606],
            [30.265851585158529, 44.497749774977493],
            [30.3959895989599, 44.250725072507251],
            [30.42201720172018, 44.166216621662166],
            [30.4393689368937, 44.088208820882087],
            [30.508775877587766, 43.802180218021803],
            [30.534803480348046, 43.685168516851682],
            [29.632513251325136, 43.717671767176718],
            [29.441644164416445, 43.724172417241718],
            [29.146664666466648, 43.730673067306725],
            [29.033878387838797, 43.730673067306725],
            [28.999174917491757, 43.665666566656668],
            [28.808305830583066, 43.698169816981697],
            [28.626112611261135, 43.743674367436739],
            [28.50465046504651, 43.737173717371732],
            [28.452595259525964, 43.730673067306725],
            [28.235698569856993, 43.763176317631761],
            [28.001450145014502, 43.834683468346839],
            [27.932043204320436, 43.997199719971995],
            [27.793229322932305, 43.95819581958196],
            [27.749849984998505, 43.951695169516952],
            [27.65441544154416, 44.049204920492045],
            [27.550305030503054, 44.016701670167016],
            [27.420167016701683, 44.016701670167016],
            [27.281353135313537, 44.114211421142116],
            [27.021077107710781, 44.140214021402137],
            [26.995049504950501, 44.133713371337137],
            [26.83020802080209, 44.08170817081708],
            [26.578607860786079, 44.055705570557052],
            [26.500525052505253, 44.042704270427038],
            [26.214221422142217, 43.984198419841988],
            [25.99732473247326, 43.880188018801874],
            [25.97997299729974, 43.867186718671867],
            [25.95394539453946, 43.86068606860686],
            [25.815131513151314, 43.750175017501746],
            [25.650290029002903, 43.698169816981697],
            [25.641614161416143, 43.685168516851682],
            [25.572207220722078, 43.646164616461647],
            [25.546179617961798, 43.63966396639664],
            [25.416041604160426, 43.626662666266625],
            [25.363986398639867, 43.633163316331633],
            [25.320607060706081, 43.652665266526654],
            [25.12973797379739, 43.698169816981697],
            [24.852110211021113, 43.698169816981697],
            [24.713296329632968, 43.717671767176718],
            [24.531103110311037, 43.769676967696768],
            [24.357585758575865, 43.724172417241718],
            [24.23612361236124, 43.691669166916689],
            [24.088633863386349, 43.717671767176718],
            [24.001875187518763, 43.730673067306725],
            [23.889088908890898, 43.763176317631761],
            [23.828357835783585, 43.776177617761775],
            [23.802330233023305, 43.782678267826782],
            [23.689543954395447, 43.795679567956796],
            [23.498674867486756, 43.80868086808681],
            [23.247074707470752, 43.834683468346839],
            [22.986798679867995, 43.802180218021803],
            [22.847984798479857, 43.867186718671867],
            [23.021502150215028, 44.101210121012102],
            [22.665791579157919, 44.198719871987194],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UKDVFIR", name: "DNIPROPETROVSK FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.371387138713885, 46.571457145714575],
            [36.703345334533466, 46.584458445844582],
            [36.564531453145321, 46.655965596559653],
            [36.096034603460353, 46.915991599159916],
            [36.009275927592768, 46.967996799679966],
            [35.54945494549456, 47.104510451045101],
            [35.254475447544763, 47.195519551955194],
            [34.742599259925996, 47.35153515351535],
            [34.213371337133722, 47.514051405140513],
            [33.927067706770686, 47.592059205920592],
            [33.753550355035514, 47.61806180618062],
            [33.658115811581169, 47.637563756375641],
            [33.259025902590267, 47.696069606960691],
            [33.250350035003507, 47.702570257025698],
            [33.224322432243227, 47.702570257025698],
            [33.206970697069707, 47.709070907090705],
            [33.050805080508056, 47.735073507350734],
            [32.833908390839085, 47.767576757675769],
            [32.877287728772885, 48.027602760276025],
            [32.885963596359645, 48.118611861186118],
            [32.669066906690674, 48.359135913591359],
            [32.651715171517154, 48.385138513851388],
            [32.599659965996608, 48.521652165216523],
            [32.669066906690674, 48.606160616061601],
            [32.738473847384739, 48.690669066906693],
            [33.363136313631372, 48.950695069506949],
            [33.614736473647369, 49.314731473147312],
            [33.770902090209034, 49.821782178217823],
            [33.883688368836886, 50.211821182118207],
            [33.953095309530966, 50.348334833483349],
            [34.039853985398551, 50.504350435043506],
            [34.117936793679377, 50.640864086408641],
            [34.213371337133722, 50.829382938293826],
            [34.447619761976199, 51.232423242324231],
            [35.332558255825589, 51.069906990699067],
            [35.436668666866694, 50.848884888488847],
            [35.445344534453454, 50.816381638163811],
            [35.644889488948905, 50.374337433743371],
            [35.696944694469451, 50.354835483548356],
            [36.295579557955804, 50.322332233223321],
            [36.40836583658367, 50.315831583158314],
            [36.486448644864495, 50.315831583158314],
            [36.824807480748078, 50.315831583158314],
            [37.466821682168231, 50.432843284328435],
            [37.640339033903402, 50.3028302830283],
            [37.761801180118013, 50.094809480948093],
            [38.03942894289429, 49.906290629062902],
            [38.143539353935395, 49.93229322932293],
            [38.178242824282435, 49.951795179517951],
            [38.195594559455955, 50.068806880688065],
            [38.230298029802981, 50.075307530753072],
            [39.496974697469753, 49.743774377437745],
            [40.121637163716386, 49.607260726072603],
            [39.861361136113615, 48.515151515151516],
            [39.939443944394441, 48.196619661966196],
            [39.887388738873895, 48.131613161316132],
            [39.809305930593069, 47.949594959495947],
            [39.679167916791684, 47.832583258325826],
            [39.219346934693476, 47.858585858585855],
            [38.91569156915692, 47.819581958195819],
            [38.317056705670581, 47.553055305530549],
            [38.221622162216235, 47.111011101110108],
            [38.334408440844086, 46.98099809980998],
            [37.501525152515256, 46.785978597859781],
            [37.423442344234431, 46.655965596559653],
            [37.371387138713885, 46.571457145714575],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LIBBUIR", name: "BRINDISI UIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.330558055805582, 43.516151615161519],
            [13.660241024102412, 43.509650965096512],
            [14.501800180018009, 43.503150315031505],
            [14.631938193819387, 43.418641864186412],
            [14.796779677967805, 43.314631463146313],
            [15.109110911091115, 43.113111311131114],
            [15.291304130413046, 42.996099609960993],
            [15.464821482148217, 42.885588558855886],
            [15.52555255525553, 42.846584658465844],
            [15.751125112511254, 42.703570357035701],
            [15.820532053205326, 42.658065806580652],
            [15.933318331833192, 42.580058005800581],
            [16.04610461046105, 42.508550855085502],
            [16.124187418741876, 42.469546954695467],
            [16.098159815981603, 42.378537853785375],
            [16.176242624262429, 42.333033303330332],
            [16.349759975997607, 42.346034603460346],
            [17.25205020502051, 41.929992999299927],
            [17.312781278127815, 41.884488448844884],
            [17.616436643664372, 41.754475447544749],
            [18.128312831283132, 41.5009500950095],
            [18.223747374737478, 41.442444244424436],
            [18.553430343034307, 41.292929292929287],
            [18.865761576157624, 41.130413041304131],
            [18.952520252025209, 40.889888988898889],
            [18.995899589959002, 40.746874687468747],
            [18.995899589959002, 40.597359735973598],
            [18.995899589959002, 40.415341534153413],
            [18.995899589959002, 40.350335033503349],
            [18.995899589959002, 40.246324632463242],
            [18.995899589959002, 40.057805780578057],
            [18.995899589959002, 39.934293429342929],
            [18.995899589959002, 39.817281728172816],
            [18.995899589959002, 39.55725572557256],
            [18.995899589959002, 39.394739473947396],
            [18.995899589959002, 39.29072907290729],
            [18.995899589959002, 38.881188118811878],
            [17.512326232623266, 38.75117511751175],
            [17.382188218821888, 38.7056705670567],
            [17.269401940194022, 38.660166016601664],
            [16.419166916691672, 39.076207620762077],
            [16.254325432543261, 39.232223222322233],
            [16.03742874287429, 39.61576157615761],
            [15.941994199419945, 39.778277827782773],
            [15.742449244924501, 40.135813581358136],
            [15.620987098709875, 40.343834383438342],
            [15.473497349734977, 40.603860386038605],
            [15.421442144214424, 40.688368836883683],
            [15.230573057305733, 41.006900690069003],
            [15.169841984198428, 41.110911091109109],
            [15.117786778677875, 41.201920192019202],
            [14.883538353835391, 41.266926692669266],
            [14.805455545554558, 41.305930593059301],
            [14.545179517951802, 41.396939693969401],
            [14.380338033803383, 41.448944894489443],
            [14.068006800680074, 41.812981298129813],
            [13.755675567556757, 42.164016401640161],
            [13.738323832383244, 42.19001900190019],
            [13.599509950995106, 42.339533953395339],
            [13.599509950995106, 42.346034603460346],
            [13.530103010301033, 42.417541754175417],
            [13.443344334433448, 42.51505150515051],
            [13.434668466846688, 42.534553455345531],
            [13.122337233723378, 42.879087908790879],
            [13.087633763376346, 42.918091809180922],
            [13.295854585458549, 43.36663666366637],
            [13.330558055805582, 43.516151615161519],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "GCCCFIR", name: "CANARIAS FIR", fab: "8" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.230823082308227, 27.667566756675669],
            [-10.519401940194015, 27.667566756675669],
            [-9.139938993899388, 27.667566756675669],
            [-8.662766276627661, 27.667566756675669],
            [-8.662766276627661, 27.336033603360335],
            [-8.662766276627661, 25.996899689968998],
            [-11.196119611961194, 25.996899689968998],
            [-12.002975297529751, 25.996899689968998],
            [-12.002975297529751, 23.448644864486447],
            [-12.618961896189617, 23.416141614161415],
            [-13.000700070006999, 23.169116911691169],
            [-13.165541554155414, 22.733573357335732],
            [-13.000700070006999, 21.335933593359336],
            [-13.963721372137211, 21.335933593359336],
            [-14.996149614961492, 21.335933593359336],
            [-16.045929592959293, 21.335933593359336],
            [-16.870137013701367, 21.335933593359336],
            [-16.93086808680868, 21.335933593359336],
            [-17.034978497849782, 20.913391339133913],
            [-17.034978497849782, 20.763876387638764],
            [-17.746399639963993, 20.165816581658166],
            [-17.997999799979997, 19.918791879187918],
            [-18.995724572457245, 19.002200220022001],
            [-19.73317331733173, 19.58075807580758],
            [-20.00212521252125, 19.834283428342836],
            [-20.696194619461945, 20.367336733673369],
            [-21.97154715471547, 21.34893489348935],
            [-23.602610261026101, 22.915591559155914],
            [-24.999424942494247, 24.001200120012001],
            [-24.999424942494247, 25.002300230023003],
            [-24.999424942494247, 25.996899689968998],
            [-24.999424942494247, 26.997999799979997],
            [-24.999424942494247, 27.999099909990999],
            [-24.999424942494247, 29.000200020001998],
            [-24.999424942494247, 30.001300130013],
            [-24.001700170017003, 30.001300130013],
            [-23.003975397539751, 30.001300130013],
            [-21.997574757475746, 30.001300130013],
            [-20.999849984998498, 30.001300130013],
            [-20.392539253925392, 30.001300130013],
            [-20.00212521252125, 30.001300130013],
            [-17.460096009600957, 31.684968496849685],
            [-17.416716671667164, 31.652465246524653],
            [-16.991599159915989, 31.5029502950295],
            [-16.939543954395436, 31.483448344834482],
            [-16.800730073007298, 31.45094509450945],
            [-16.557805780578057, 31.405440544054407],
            [-16.028577857785777, 31.418441844184418],
            [-15.7509500950095, 31.5029502950295],
            [-15.299804980498049, 31.301430143014301],
            [-14.857335733573354, 31.099909990999102],
            [-14.414866486648663, 30.898389838983896],
            [-13.989748974897488, 30.703370337033704],
            [-13.850935093509349, 30.63836383638364],
            [-13.573307330733069, 30.508350835083508],
            [-13.139513951395138, 30.306830683068306],
            [-12.714396439643963, 30.105310531053107],
            [-12.497499749974995, 30.001300130013],
            [-12.575582558255824, 29.72827282728273],
            [-12.714396439643963, 29.247224722472247],
            [-12.818506850685065, 28.909190919091909],
            [-13.000700070006999, 28.252625262526252],
            [-13.165541554155414, 27.667566756675669],
            [-12.592934293429341, 27.667566756675669],
            [-11.230823082308227, 27.667566756675669],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EYVLUIR", name: "VILNIUS UIR", fab: "1" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.630663066306639, 55.67886788678868],
            [26.552580258025813, 55.457845784578453],
            [26.474497449744987, 55.13931393139314],
            [25.780428042804289, 54.859785978597856],
            [25.737048704870489, 54.690769076907692],
            [25.763076307630769, 54.573757375737571],
            [25.615586558655878, 54.469746974697465],
            [25.589558955895598, 54.385238523852387],
            [25.554855485548558, 54.326732673267323],
            [25.806455645564569, 54.235723572357237],
            [25.780428042804289, 54.164216421642159],
            [25.546179617961798, 54.157715771577159],
            [25.520152015201532, 54.294229422942294],
            [25.268551855185521, 54.274727472747273],
            [25.225172517251735, 54.261726172617259],
            [25.199144914491455, 54.216221622162216],
            [25.06900690069007, 54.190219021902188],
            [24.774027402740273, 54.118711871187116],
            [24.808730873087313, 54.053705370537052],
            [24.687268726872688, 53.982198219821981],
            [24.140689068906894, 53.956195619561953],
            [23.897764776477651, 53.949694969496946],
            [23.741599159915999, 53.930193019301932],
            [23.40324032403241, 54.196719671967195],
            [23.056205620562061, 54.307230723072308],
            [22.752550255025511, 54.65176517651765],
            [22.735198519851991, 54.944294429442941],
            [22.596384638463853, 55.054805480548055],
            [21.720122012201223, 55.13931393139314],
            [21.433818381838186, 55.236823682368232],
            [20.947969796979706, 55.282328232823282],
            [20.913266326632666, 55.301830183018303],
            [20.2972797279728, 55.470847084708467],
            [20.288603860386047, 55.477347734773474],
            [19.837458745874592, 55.600860086008602],
            [19.377637763776384, 55.73087308730873],
            [19.325582558255832, 55.743874387438744],
            [19.091334133413348, 55.808880888088808],
            [18.015526552655274, 56.094909490949092],
            [18.06758175817582, 56.120912091209121],
            [18.284478447844791, 56.231423142314227],
            [18.510051005100514, 56.348434843484348],
            [18.900465046504657, 56.296429642964291],
            [19.256175617561759, 56.250925092509249],
            [19.282203220322039, 56.250925092509249],
            [19.577182718271835, 56.211921192119213],
            [20.080383038303836, 56.146914691469142],
            [20.184493449344941, 56.133913391339135],
            [20.227872787278734, 56.127412741274128],
            [20.496824682468251, 56.088408840884085],
            [20.670342034203429, 56.068906890689064],
            [20.748424842484255, 56.068906890689064],
            [21.060756075607564, 56.068906890689064],
            [21.095459545954604, 56.075407540754071],
            [21.225597559755983, 56.153415341534149],
            [21.277652765276535, 56.179417941794178],
            [21.312356235623568, 56.205420542054206],
            [21.433818381838186, 56.244424442444242],
            [21.555280528052812, 56.309430943094306],
            [21.607335733573365, 56.32243224322432],
            [21.850260026002609, 56.36793679367937],
            [21.945694569456954, 56.37443744374437],
            [21.997749774977507, 56.413441344134412],
            [22.162591259125918, 56.419941994199419],
            [22.353460346034609, 56.393939393939391],
            [22.613736373637373, 56.380938093809377],
            [23.264426442644272, 56.37443744374437],
            [23.429267926792683, 56.361436143614362],
            [23.758950895089512, 56.37443744374437],
            [23.845709570957105, 56.341934193419341],
            [24.027902790279029, 56.296429642964291],
            [24.062606260626069, 56.276927692769277],
            [24.505075507550757, 56.276927692769277],
            [24.513751375137517, 56.283428342834284],
            [24.626537653765382, 56.36793679367937],
            [24.895489548954899, 56.445944594459441],
            [24.956220622062219, 56.36793679367937],
            [25.06900690069007, 56.244424442444242],
            [25.155765576557656, 56.172917291729171],
            [25.363986398639867, 56.153415341534149],
            [25.572207220722078, 56.140414041404142],
            [25.867186718671874, 55.997399739973993],
            [26.309655965596562, 55.802380238023801],
            [26.630663066306639, 55.67886788678868],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EVRRFIR", name: "RIGA FIR", fab: "7" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.510051005100514, 56.348434843484348],
            [18.700920092009206, 56.439443944394441],
            [18.874437443744377, 56.530453045304526],
            [19.073982398239828, 56.627962796279625],
            [19.568506850685075, 56.868486848684867],
            [19.837458745874592, 56.998499849984995],
            [20.063031303130316, 57.414541454145414],
            [20.271252125212527, 57.785078507850784],
            [20.418741874187425, 58.025602560256026],
            [20.496824682468251, 58.168616861686168],
            [20.574907490749084, 58.305130513051303],
            [20.644314431443149, 58.415641564156417],
            [21.485873587358739, 58.116611661166118],
            [21.546604660466052, 58.019101910191019],
            [21.616011601160125, 57.895589558955891],
            [21.650715071507157, 57.856585658565855],
            [21.659390939093917, 57.843584358435841],
            [21.720122012201223, 57.785078507850784],
            [21.841584158415849, 57.765576557655763],
            [21.919666966696674, 57.752575257525748],
            [22.101860186018605, 57.77207720772077],
            [22.275377537753783, 57.82408240824082],
            [22.518301830183027, 57.908590859085905],
            [22.587708770877093, 57.928092809280926],
            [22.709170917091718, 57.94109410941094],
            [22.787253725372544, 57.876087608760876],
            [22.908715871587162, 57.778577857785777],
            [22.995474547454748, 57.700570057005699],
            [23.177667766776686, 57.583558355835585],
            [23.359860986098617, 57.590059005900585],
            [23.40324032403241, 57.596559655965592],
            [23.585433543354341, 57.668066806680663],
            [23.646164616461654, 57.785078507850784],
            [23.602785278527861, 57.902090209020898],
            [23.915116511651171, 57.902090209020898],
            [24.21009600960096, 57.902090209020898],
            [24.29685468546856, 57.902090209020898],
            [24.461696169616971, 57.928092809280926],
            [24.982248224822484, 58.012601260126011],
            [25.91924192419242, 57.856585658565855],
            [26.387738773877388, 57.577057705770578],
            [26.604635463546359, 57.525052505250521],
            [26.81285628562857, 57.583558355835585],
            [27.220622062206232, 57.596559655965592],
            [27.385463546354643, 57.603060306030599],
            [27.69779477947796, 57.551055105510549],
            [27.6891189118912, 57.382038203820379],
            [27.906015601560156, 56.751475147514746],
            [28.131588158815887, 56.504450445044505],
            [28.200995099509953, 56.361436143614362],
            [28.148939893989407, 56.166416641664163],
            [27.819256925692571, 56.036403640364036],
            [27.567656765676574, 55.78287828782878],
            [26.977697769776981, 55.815381538153815],
            [26.630663066306639, 55.67886788678868],
            [26.309655965596562, 55.802380238023801],
            [25.867186718671874, 55.997399739973993],
            [25.572207220722078, 56.140414041404142],
            [25.363986398639867, 56.153415341534149],
            [25.155765576557656, 56.172917291729171],
            [25.06900690069007, 56.244424442444242],
            [24.956220622062219, 56.36793679367937],
            [24.895489548954899, 56.445944594459441],
            [24.626537653765382, 56.36793679367937],
            [24.513751375137517, 56.283428342834284],
            [24.505075507550757, 56.276927692769277],
            [24.062606260626069, 56.276927692769277],
            [24.027902790279029, 56.296429642964291],
            [23.845709570957105, 56.341934193419341],
            [23.758950895089512, 56.37443744374437],
            [23.429267926792683, 56.361436143614362],
            [23.264426442644272, 56.37443744374437],
            [22.613736373637373, 56.380938093809377],
            [22.353460346034609, 56.393939393939391],
            [22.162591259125918, 56.419941994199419],
            [21.997749774977507, 56.413441344134412],
            [21.945694569456954, 56.37443744374437],
            [21.850260026002609, 56.36793679367937],
            [21.607335733573365, 56.32243224322432],
            [21.555280528052812, 56.309430943094306],
            [21.433818381838186, 56.244424442444242],
            [21.312356235623568, 56.205420542054206],
            [21.277652765276535, 56.179417941794178],
            [21.225597559755983, 56.153415341534149],
            [21.095459545954604, 56.075407540754071],
            [21.060756075607564, 56.068906890689064],
            [20.748424842484255, 56.068906890689064],
            [20.670342034203429, 56.068906890689064],
            [20.496824682468251, 56.088408840884085],
            [20.227872787278734, 56.127412741274128],
            [20.184493449344941, 56.133913391339135],
            [20.080383038303836, 56.146914691469142],
            [19.577182718271835, 56.211921192119213],
            [19.282203220322039, 56.250925092509249],
            [19.256175617561759, 56.250925092509249],
            [18.900465046504657, 56.296429642964291],
            [18.510051005100514, 56.348434843484348],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LFMMFIR", name: "MARSEILLE FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [2.910841084108412, 46.376437643764376],
            [3.266551655165522, 46.499949994999497],
            [3.613586358635871, 46.499949994999497],
            [4.403090309030908, 46.499949994999497],
            [4.541904190419046, 46.499949994999497],
            [4.672042204220425, 46.499949994999497],
            [4.836883688368843, 46.499949994999497],
            [4.949669966996701, 46.499949994999497],
            [5.019076907690774, 46.499949994999497],
            [5.166566656665672, 46.499949994999497],
            [5.348759875987604, 46.499949994999497],
            [6.008125812581262, 46.545454545454547],
            [6.094884488448848, 46.545454545454547],
            [6.094884488448848, 46.525952595259525],
            [6.068856885688575, 46.415441544154419],
            [6.120912091209128, 46.330933093309326],
            [5.97342234223423, 46.194419441944191],
            [5.97342234223423, 46.148914891489149],
            [6.008125812581262, 46.142414241424142],
            [6.077532753275335, 46.187918791879184],
            [6.216346634663473, 46.207420742074206],
            [6.294429442944299, 46.317931793179312],
            [6.47662266226623, 46.382938293829383],
            [6.528677867786783, 46.356935693569355],
            [6.658815881588161, 46.363436343634362],
            [6.745574557455747, 46.343934393439341],
            [6.780278027802787, 46.343934393439341],
            [6.81498149814982, 46.239923992399241],
            [6.80630563056306, 46.142414241424142],
            [6.988498849884991, 46.005900590058999],
            [7.049229922992303, 45.9018901890189],
            [6.867036703670372, 45.752375237523751],
            [7.040554055405543, 45.479347934793481],
            [7.031878187818783, 45.466346634663466],
            [7.023202320232031, 45.453345334533452],
            [7.083933393339336, 45.401340134013395],
            [6.962471247124718, 45.193319331933196],
            [6.641464146414648, 45.115311531153111],
            [6.780278027802787, 45.011301130113011],
            [6.901740174017405, 44.842284228422841],
            [7.014526452645271, 44.803280328032798],
            [7.040554055405543, 44.660266026602656],
            [7.005850585058511, 44.582258225822585],
            [6.919091909190925, 44.45224522452245],
            [7.005850585058511, 44.237723772377237],
            [7.083933393339336, 44.211721172117208],
            [7.283478347834787, 44.140214021402137],
            [7.621837183718377, 44.146714671467144],
            [7.708595859585962, 44.062206220622059],
            [7.665216521652169, 43.997199719971995],
            [7.587133713371344, 43.899689968996896],
            [7.535078507850791, 43.776177617761775],
            [7.682568256825689, 43.769676967696768],
            [7.717271727172722, 43.737173717371732],
            [7.838733873387341, 43.704670467046704],
            [8.324582458245828, 43.568156815681569],
            [8.541479147914799, 43.503150315031505],
            [8.602210221022105, 43.49014901490149],
            [8.71499649964997, 43.464146414641462],
            [8.801755175517556, 43.438143814381434],
            [8.819106910691076, 43.431643164316426],
            [8.957920792079214, 43.386138613861384],
            [9.174817481748178, 43.334133413341334],
            [9.747424742474252, 43.165116511651163],
            [9.747424742474252, 42.957095709570957],
            [9.747424742474252, 42.820582058205815],
            [9.747424742474252, 42.619061906190616],
            [9.747424742474252, 42.502050205020502],
            [9.747424742474252, 42.41104110411041],
            [9.747424742474252, 42.326532653265325],
            [9.747424742474252, 41.936493649364934],
            [9.747424742474252, 41.793479347934792],
            [9.747424742474252, 41.747974797479742],
            [9.747424742474252, 41.734973497349735],
            [9.747424742474252, 41.663466346634664],
            [9.747424742474252, 41.33193319331933],
            [9.617286728672873, 41.33193319331933],
            [9.495824582458248, 41.33193319331933],
            [9.313631363136317, 41.33193319331933],
            [9.270252025202524, 41.33193319331933],
            [9.105410541054113, 41.33193319331933],
            [9.03600360036004, 41.33193319331933],
            [8.810431043104316, 41.33193319331933],
            [8.688968896889691, 41.33193319331933],
            [8.567506750675072, 41.33193319331933],
            [8.446044604460454, 41.33193319331933],
            [8.333258325832588, 41.33193319331933],
            [8.281203120312036, 41.286428642864287],
            [8.159740974097417, 41.162916291629159],
            [8.003575357535759, 41.000400040003996],
            [8.003575357535759, 40.915891589158917],
            [8.003575357535759, 40.863886388638861],
            [8.003575357535759, 40.746874687468747],
            [8.003575357535759, 40.610361036103612],
            [8.003575357535759, 40.42184218421842],
            [8.003575357535759, 40.350335033503349],
            [8.003575357535759, 39.999299929993001],
            [8.003575357535759, 39.700270027002702],
            [8.003575357535759, 39.212721272127212],
            [8.003575357535759, 39.147714771477148],
            [8.003575357535759, 38.998199819981998],
            [7.630513051305137, 38.998199819981998],
            [7.569781978197824, 38.998199819981998],
            [7.32685768576858, 38.998199819981998],
            [7.031878187818783, 38.998199819981998],
            [6.719546954695474, 38.998199819981998],
            [6.251050105010506, 38.998199819981998],
            [6.086208620862088, 38.998199819981998],
            [5.374787478747876, 38.998199819981998],
            [5.001725172517254, 38.998199819981998],
            [4.680718071807185, 38.998199819981998],
            [4.663366336633665, 39.583258325832581],
            [4.663366336633665, 39.719771977197723],
            [4.663366336633665, 39.953795379537951],
            [4.663366336633665, 40.031803180318036],
            [4.663366336633665, 40.064306430643065],
            [4.663366336633665, 40.181318131813185],
            [4.663366336633665, 40.285328532853285],
            [4.663366336633665, 40.389338933893384],
            [4.663366336633665, 40.499849984998498],
            [4.663366336633665, 40.551855185518548],
            [4.663366336633665, 40.857385738573853],
            [4.663366336633665, 41.05890589058906],
            [4.663366336633665, 41.416441644164415],
            [4.663366336633665, 41.734973497349735],
            [4.663366336633665, 42.001500150015005],
            [4.437793779377941, 42.066506650665062],
            [4.411766176617668, 42.079507950795076],
            [4.333683368336835, 42.099009900990097],
            [4.116786678667872, 42.170517051705168],
            [4.038703870387046, 42.196519651965197],
            [4.004000400040006, 42.209520952095204],
            [3.92591759175918, 42.216021602160211],
            [3.830483048304835, 42.261526152615261],
            [3.500800080008005, 42.333033303330332],
            [3.37933793379338, 42.372037203720367],
            [3.197144714471449, 42.430543054305431],
            [3.179792979297936, 42.430543054305431],
            [3.162441244124416, 42.430543054305431],
            [3.127737773777383, 42.430543054305431],
            [2.980248024802485, 42.482548254825481],
            [2.910841084108412, 42.463046304630467],
            [2.832758275827587, 42.450045004500453],
            [2.745999599960001, 42.586558655865588],
            [2.598509850985103, 42.710071007100709],
            [2.277502750275033, 43.002600260026],
            [2.57248224822483, 43.256125612561256],
            [2.711296129612968, 43.210621062106213],
            [2.719971997199721, 43.717671767176718],
            [2.815406540654067, 44.023202320232024],
            [2.902165216521659, 44.250725072507251],
            [3.032303230323038, 44.614761476147613],
            [3.040979097909798, 44.666766676667663],
            [3.049654965496551, 44.874787478747876],
            [3.032303230323038, 45.147814781478147],
            [3.023627362736278, 45.225822582258225],
            [3.014951495149518, 45.401340134013395],
            [2.954220422042205, 46.064406440644063],
            [2.928192819281932, 46.252925292529255],
            [2.919516951695172, 46.330933093309326],
            [2.910841084108412, 46.376437643764376],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EGTTFIR", name: "LONDON FIR", fab: "9" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.001725172517254, 55.002800280027998],
            [4.533228322832286, 54.5022502250225],
            [4.177517751775184, 54.105710571057102],
            [4.082083208320839, 54.001700170017003],
            [3.969296929692973, 53.865186518651861],
            [3.9432693269327, 53.839183918391839],
            [3.709020902090217, 53.572657265726569],
            [3.648289828982904, 53.501150115011498],
            [3.613586358635871, 53.462146214621463],
            [3.561531153115318, 53.403640364036399],
            [3.431393139313933, 53.247624762476249],
            [3.361986198619867, 53.176117611761171],
            [3.301255125512554, 53.0981098109811],
            [3.257875787578762, 53.046104610461043],
            [3.162441244124416, 52.929092909290929],
            [3.145089508950896, 52.916091609160915],
            [3.067006700670071, 52.825082508250823],
            [2.997599759976005, 52.73407340734073],
            [2.902165216521659, 52.617061706170617],
            [2.832758275827587, 52.532553255325531],
            [2.780703070307034, 52.474047404740475],
            [2.693944394439448, 52.363536353635361],
            [2.650565056505656, 52.318031803180318],
            [2.641889188918896, 52.305030503050304],
            [2.598509850985103, 52.253025302530254],
            [2.581158115811583, 52.233523352335233],
            [2.494399439943997, 52.123012301230119],
            [2.485723572357237, 52.116511651165112],
            [2.477047704770484, 52.103510351035105],
            [2.355585558555859, 51.947494749474949],
            [2.294854485448546, 51.86948694869487],
            [2.225447544754481, 51.784978497849785],
            [2.173392339233928, 51.713471347134714],
            [2.164716471647168, 51.706970697069707],
            [2.156040604060408, 51.693969396939693],
            [2.103985398539855, 51.635463546354636],
            [2.051930193019302, 51.570457045704572],
            [1.999874987498757, 51.498949894989501],
            [1.999874987498757, 51.492449244924494],
            [1.999874987498757, 51.440444044404437],
            [1.999874987498757, 51.401440144014401],
            [1.999874987498757, 51.38193819381938],
            [1.999874987498757, 51.362436243624359],
            [1.999874987498757, 51.245424542454245],
            [1.999874987498757, 51.212921292129209],
            [1.999874987498757, 51.199919991999195],
            [1.999874987498757, 51.128412841284124],
            [1.999874987498757, 51.115411541154117],
            [1.730923092309233, 51.056905690569053],
            [1.670192019201927, 51.043904390439046],
            [1.618136813681375, 51.030903090309032],
            [1.557405740574062, 51.017901790179017],
            [1.470647064706476, 50.998399839983996],
            [1.470647064706476, 50.965896589658968],
            [1.470647064706476, 50.959395939593961],
            [1.470647064706476, 50.920392039203918],
            [1.470647064706476, 50.913891389138911],
            [1.470647064706476, 50.907390739073904],
            [1.470647064706476, 50.855385538553854],
            [1.470647064706476, 50.777377737773776],
            [1.470647064706476, 50.751375137513747],
            [1.470647064706476, 50.666866686668662],
            [1.453295329532956, 50.660366036603662],
            [1.401240124012403, 50.647364736473648],
            [1.366536653665371, 50.634363436343634],
            [1.227722772277232, 50.575857585758577],
            [1.097584758475854, 50.530353035303527],
            [1.062881288128814, 50.517351735173513],
            [1.002150215021508, 50.497849784978499],
            [0.967446744674469, 50.478347834783477],
            [0.889363936393643, 50.445844584458442],
            [0.81995699569957, 50.41984198419842],
            [0.767901790179025, 50.400340034003399],
            [0.637763776377639, 50.354835483548356],
            [0.446894689468948, 50.276827682768278],
            [0.264701470147017, 50.205320532053207],
            [0.099859985998606, 50.133813381338129],
            [-0.0042504250425, 50.101310131013101],
            [-0.108360836083605, 50.055805580558051],
            [-0.247174717471744, 49.997299729972994],
            [-0.368636863686362, 49.997299729972994],
            [-0.53347834783478, 49.997299729972994],
            [-0.837133713371337, 49.997299729972994],
            [-1.001975197519748, 49.997299729972994],
            [-1.054030403040301, 49.997299729972994],
            [-1.192844284428439, 49.997299729972994],
            [-1.253575357535752, 49.997299729972994],
            [-1.38371337133713, 49.997299729972994],
            [-1.496499649964996, 49.997299729972994],
            [-1.513851385138508, 49.997299729972994],
            [-1.687368736873687, 49.997299729972994],
            [-1.71339633963396, 49.997299729972994],
            [-1.782803280328032, 49.997299729972994],
            [-1.843534353435338, 49.997299729972994],
            [-1.860886088608858, 49.997299729972994],
            [-1.886913691369131, 49.997299729972994],
            [-1.973672367236723, 49.997299729972994],
            [-1.999699969996996, 49.997299729972994],
            [-2.008375837583756, 49.997299729972994],
            [-2.103810381038102, 49.98429842984298],
            [-2.164541454145414, 49.971297129712973],
            [-2.173217321732167, 49.971297129712973],
            [-2.24262426242624, 49.958295829582958],
            [-2.372762276227618, 49.938793879387937],
            [-2.511576157615757, 49.912791279127909],
            [-2.997424742474244, 49.834783478347831],
            [-3.032128212821277, 49.821782178217823],
            [-3.06683168316831, 49.821782178217823],
            [-3.162266226622656, 49.802280228022802],
            [-3.170942094209416, 49.802280228022802],
            [-3.257700770077001, 49.782778277827781],
            [-3.526652665266525, 49.737273727372738],
            [-3.821632163216321, 49.685268526852681],
            [-4.003825382538253, 49.652765276527653],
            [-4.159990999099904, 49.620262026202617],
            [-4.333508350835082, 49.587758775877589],
            [-4.44629462946294, 49.568256825682568],
            [-4.914791479147908, 49.477247724772475],
            [-5.001550155015501, 49.464246424642461],
            [-5.036253625362534, 49.457745774577454],
            [-5.270502050205017, 49.412241224122411],
            [-5.860461046104611, 49.28872887288729],
            [-6.14676467646764, 49.236723672367233],
            [-7.213896389638961, 49.009200920092006],
            [-7.578282828282823, 48.931193119311928],
            [-8.003400340034002, 48.833683368336835],
            [-8.003400340034002, 49.002700270026999],
            [-8.003400340034002, 49.165216521652162],
            [-8.003400340034002, 49.249724972497248],
            [-8.003400340034002, 49.308230823082305],
            [-8.003400340034002, 49.503250325032504],
            [-8.003400340034002, 49.581258125812582],
            [-8.003400340034002, 49.997299729972994],
            [-8.003400340034002, 50.075307530753072],
            [-8.003400340034002, 50.25082508250825],
            [-8.003400340034002, 50.367836783678364],
            [-8.003400340034002, 50.660366036603662],
            [-8.003400340034002, 50.751375137513747],
            [-8.003400340034002, 50.998399839983996],
            [-7.682393239323929, 51.180418041804181],
            [-7.569606960696063, 51.238923892389238],
            [-7.508875887588758, 51.271427142714273],
            [-7.491524152415238, 51.277927792779273],
            [-7.413441344134412, 51.323432343234323],
            [-7.231248124812481, 51.427442744274423],
            [-7.092434243424343, 51.498949894989501],
            [-6.866861686168612, 51.615961596159615],
            [-6.710696069606961, 51.706970697069707],
            [-6.563206320632062, 51.784978497849785],
            [-6.407040704070404, 51.86948694869487],
            [-6.250875087508746, 51.947494749474949],
            [-6.242199219921986, 51.953995399539956],
            [-6.233523352335233, 51.960496049604956],
            [-6.120737073707367, 52.012501250125013],
            [-5.851785178517851, 52.155515551555155],
            [-5.843109310931091, 52.155515551555155],
            [-5.825757575757571, 52.168516851685169],
            [-5.686943694369432, 52.24002400240024],
            [-5.678267826782673, 52.24002400240024],
            [-5.66959195919592, 52.246524652465247],
            [-5.496074607460741, 52.331033103310332],
            [-5.496074607460741, 52.415541554155411],
            [-5.496074607460741, 52.552055205520553],
            [-5.496074607460741, 52.55855585558556],
            [-5.496074607460741, 52.669066906690666],
            [-5.496074607460741, 52.740574057405738],
            [-5.496074607460741, 52.753575357535752],
            [-5.496074607460741, 52.948594859485944],
            [-5.496074607460741, 53.05260526052605],
            [-5.496074607460741, 53.065606560656065],
            [-5.496074607460741, 53.085108510851086],
            [-5.496074607460741, 53.176117611761171],
            [-5.496074607460741, 53.182618261826178],
            [-5.496074607460741, 53.195619561956192],
            [-5.496074607460741, 53.247624762476249],
            [-5.496074607460741, 53.273627362736271],
            [-5.496074607460741, 53.280128012801278],
            [-5.496074607460741, 53.286628662866285],
            [-5.496074607460741, 53.384138413841384],
            [-5.496074607460741, 53.462146214621463],
            [-5.496074607460741, 53.481648164816477],
            [-5.496074607460741, 53.494649464946491],
            [-5.496074607460741, 53.663666366636662],
            [-5.496074607460741, 53.683168316831683],
            [-5.496074607460741, 53.68966896689669],
            [-5.496074607460741, 53.761176117611761],
            [-5.496074607460741, 53.767676767676768],
            [-5.496074607460741, 53.806680668066804],
            [-5.496074607460741, 53.917191719171917],
            [-5.496074607460741, 54.898789878987898],
            [-5.496074607460741, 55.002800280027998],
            [-4.897439743974395, 55.002800280027998],
            [-4.003825382538253, 55.002800280027998],
            [-3.517976797679765, 55.002800280027998],
            [-3.439893989398939, 55.002800280027998],
            [-2.711121112111208, 55.002800280027998],
            [-2.468196819681964, 55.002800280027998],
            [-2.121162116211615, 55.002800280027998],
            [-1.912941294129411, 55.002800280027998],
            [-1.505175517551748, 55.002800280027998],
            [-1.270927092709265, 55.002800280027998],
            [-0.984623462346228, 55.002800280027998],
            [-0.86316131613161, 55.002800280027998],
            [-0.785078507850784, 55.002800280027998],
            [2.980248024802485, 55.002800280027998],
            [3.049654965496551, 55.002800280027998],
            [3.119061906190623, 55.002800280027998],
            [3.500800080008005, 55.002800280027998],
            [4.038703870387046, 55.002800280027998],
            [5.001725172517254, 55.002800280027998],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UKBVFIR", name: "KIEV FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.276802680268034, 47.806580658065805],
            [29.233423342334234, 47.884588458845883],
            [29.137988798879888, 47.99509950995099],
            [28.730223022302241, 48.125112511251125],
            [27.836608660866091, 48.417641764176416],
            [27.836608660866091, 48.593159315931587],
            [27.845284528452851, 48.97019701970197],
            [27.836608660866091, 49.048204820482049],
            [27.845284528452851, 49.152215221522148],
            [27.845284528452851, 49.39273927392739],
            [27.853960396039611, 49.763276327632759],
            [27.489573957395748, 49.951795179517951],
            [27.047104710471046, 50.179317931793179],
            [27.211946194619472, 50.465346534653463],
            [27.255325532553258, 50.543354335433541],
            [27.350760076007603, 50.705870587058705],
            [27.437518751875189, 50.848884888488847],
            [27.749849984998505, 51.375437543754373],
            [27.749849984998505, 51.38193819381938],
            [27.758525852585265, 51.472947294729472],
            [28.46994699469947, 51.550955095509551],
            [28.678167816781681, 51.563956395639565],
            [28.678167816781681, 51.446944694469444],
            [28.747574757475761, 51.459945994599458],
            [28.782278227822786, 51.420942094209423],
            [28.790954095409546, 51.498949894989501],
            [28.834333433343346, 51.557455745574558],
            [28.912416241624172, 51.589958995899586],
            [29.033878387838797, 51.589958995899586],
            [29.085933593359343, 51.667966796679664],
            [29.33753375337534, 51.563956395639565],
            [29.389588958895899, 51.401440144014401],
            [29.58913391339135, 51.466446644664465],
            [29.927492749274933, 51.459945994599458],
            [30.265851585158529, 51.479447944794479],
            [30.378637863786381, 51.323432343234323],
            [30.560831083108312, 51.264926492649266],
            [30.604210421042112, 51.316931693169316],
            [30.569506950695072, 51.511951195119508],
            [30.560831083108312, 51.64846484648465],
            [30.630238023802391, 51.713471347134714],
            [31.011976197619774, 52.084008400840084],
            [31.411066106610662, 52.142514251425141],
            [31.680018001800192, 52.136013601360133],
            [31.784128412841284, 52.136013601360133],
            [31.792804280428044, 52.116511651165112],
            [31.948969896989709, 52.084008400840084],
            [32.252625262526266, 52.097009700970098],
            [32.330708070807091, 52.298529852985297],
            [32.669066906690674, 52.272527252725268],
            [33.311081108110812, 52.350535053505347],
            [33.901040104010406, 52.324532453245325],
            [34.117936793679377, 51.999499949994998],
            [34.169991999199922, 51.966996699669963],
            [34.499674967496759, 51.7004700470047],
            [34.083233323332337, 51.64846484648465],
            [34.447619761976199, 51.232423242324231],
            [34.213371337133722, 50.829382938293826],
            [34.117936793679377, 50.640864086408641],
            [34.039853985398551, 50.504350435043506],
            [33.953095309530966, 50.348334833483349],
            [33.883688368836886, 50.211821182118207],
            [33.770902090209034, 49.821782178217823],
            [33.614736473647369, 49.314731473147312],
            [33.363136313631372, 48.950695069506949],
            [32.738473847384739, 48.690669066906693],
            [32.669066906690674, 48.606160616061601],
            [32.599659965996608, 48.521652165216523],
            [32.651715171517154, 48.385138513851388],
            [32.669066906690674, 48.359135913591359],
            [32.885963596359645, 48.118611861186118],
            [32.877287728772885, 48.027602760276025],
            [32.833908390839085, 47.767576757675769],
            [31.601935193519353, 47.956095609560954],
            [31.27225222522253, 48.001600160015997],
            [30.977272727272734, 48.047104710471046],
            [30.664941494149417, 48.086108610861089],
            [30.092334233423344, 48.16411641164116],
            [29.884113411341147, 48.190119011901189],
            [29.684568456845696, 48.066606660666068],
            [29.415616561656165, 47.884588458845883],
            [29.285478547854794, 47.806580658065805],
            [29.276802680268034, 47.806580658065805],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LFFFFIR", name: "PARIS FIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.247174717471744, 47.169516951695172],
            [-0.247174717471744, 47.416541654165414],
            [-0.247174717471744, 47.852085208520847],
            [-0.247174717471744, 47.93659365936594],
            [-0.247174717471744, 48.170617061706167],
            [-0.247174717471744, 48.359135913591359],
            [-0.247174717471744, 48.463146314631459],
            [-0.247174717471744, 48.534653465346537],
            [-0.247174717471744, 48.547654765476551],
            [-0.247174717471744, 48.658165816581658],
            [-0.247174717471744, 48.736173617361736],
            [-0.247174717471744, 48.840184018401843],
            [-0.247174717471744, 48.931193119311928],
            [-0.247174717471744, 48.950695069506949],
            [-0.247174717471744, 49.165216521652162],
            [-0.247174717471744, 49.444744474447447],
            [-0.247174717471744, 49.529252925292525],
            [-0.247174717471744, 49.815281528152816],
            [-0.247174717471744, 49.997299729972994],
            [-0.108360836083605, 50.055805580558051],
            [-0.0042504250425, 50.101310131013101],
            [0.099859985998606, 50.133813381338129],
            [0.264701470147017, 50.205320532053207],
            [0.446894689468948, 50.276827682768278],
            [0.637763776377639, 50.354835483548356],
            [0.767901790179025, 50.400340034003399],
            [0.81995699569957, 50.41984198419842],
            [0.889363936393643, 50.445844584458442],
            [0.967446744674469, 50.478347834783477],
            [1.002150215021508, 50.497849784978499],
            [1.062881288128814, 50.517351735173513],
            [1.097584758475854, 50.530353035303527],
            [1.227722772277232, 50.575857585758577],
            [1.366536653665371, 50.634363436343634],
            [1.401240124012403, 50.647364736473648],
            [1.453295329532956, 50.660366036603662],
            [1.470647064706476, 50.666866686668662],
            [1.470647064706476, 50.751375137513747],
            [1.470647064706476, 50.777377737773776],
            [1.470647064706476, 50.855385538553854],
            [1.470647064706476, 50.907390739073904],
            [1.470647064706476, 50.913891389138911],
            [1.470647064706476, 50.920392039203918],
            [1.470647064706476, 50.959395939593961],
            [1.470647064706476, 50.965896589658968],
            [1.470647064706476, 50.998399839983996],
            [1.557405740574062, 51.017901790179017],
            [1.618136813681375, 51.030903090309032],
            [1.670192019201927, 51.043904390439046],
            [1.730923092309233, 51.056905690569053],
            [1.999874987498757, 51.115411541154117],
            [2.242799279927993, 51.102410241024103],
            [2.54645464546455, 51.082908290829081],
            [2.589833983398343, 51.01140114011401],
            [2.650565056505656, 50.816381638163811],
            [2.650565056505656, 50.809880988098811],
            [2.902165216521659, 50.699369936993698],
            [2.910841084108412, 50.692869286928691],
            [3.153765376537656, 50.783878387838783],
            [3.266551655165522, 50.608360836083605],
            [3.283903390339034, 50.595359535953591],
            [3.361986198619867, 50.517351735173513],
            [3.37933793379338, 50.497849784978499],
            [3.483448344834486, 50.517351735173513],
            [3.509475947594765, 50.52385238523852],
            [3.552855285528558, 50.497849784978499],
            [3.604910491049111, 50.484848484848484],
            [3.630938093809384, 50.47184718471847],
            [3.648289828982904, 50.452345234523449],
            [3.665641564156417, 50.41984198419842],
            [3.665641564156417, 50.348334833483349],
            [3.674317431743177, 50.341834183418342],
            [3.787103710371042, 50.341834183418342],
            [3.873862386238628, 50.341834183418342],
            [3.92591759175918, 50.335333533353335],
            [3.969296929692973, 50.328832883288328],
            [4.090759075907592, 50.315831583158314],
            [4.116786678667872, 50.296329632963293],
            [4.177517751775184, 50.224822482248221],
            [4.168841884188424, 50.146814681468143],
            [4.142814281428144, 49.97779777977798],
            [4.151490149014904, 49.97779777977798],
            [4.368386838683875, 49.964796479647966],
            [4.481173117311734, 49.951795179517951],
            [4.533228322832286, 49.964796479647966],
            [4.567931793179319, 49.97779777977798],
            [4.862911291129116, 49.98429842984298],
            [4.854235423542356, 49.860786078607859],
            [4.854235423542356, 49.802280228022802],
            [4.940994099409949, 49.802280228022802],
            [4.984373437343741, 49.802280228022802],
            [5.11451145114512, 49.737273727372738],
            [5.12318731873188, 49.737273727372738],
            [5.166566656665672, 49.717771777177717],
            [5.262001200120018, 49.691769176917688],
            [5.288028802880291, 49.678767876787674],
            [5.314056405640571, 49.646264626462646],
            [5.374787478747876, 49.626762676267624],
            [5.383463346334636, 49.61376137613761],
            [5.418166816681669, 49.600760076007596],
            [5.444194419441949, 49.568256825682568],
            [5.452870287028709, 49.548754875487546],
            [5.435518551855189, 49.516251625162518],
            [5.574332433243328, 49.529252925292525],
            [5.747849784978499, 49.542254225422539],
            [5.756525652565259, 49.542254225422539],
            [5.817256725672571, 49.548754875487546],
            [5.825932593259331, 49.542254225422539],
            [5.834608460846091, 49.542254225422539],
            [5.843284328432844, 49.529252925292525],
            [5.999449944994502, 49.457745774577454],
            [5.808580858085811, 49.360236023602361],
            [5.556980698069815, 49.269226922692269],
            [4.802180218021803, 48.950695069506949],
            [5.730498049804986, 48.248624862486245],
            [5.496249624962502, 48.216121612161217],
            [5.166566656665672, 48.16411641164116],
            [5.12318731873188, 48.125112511251125],
            [5.001725172517254, 48.014601460146011],
            [4.949669966996701, 47.975597559755975],
            [4.932318231823189, 47.956095609560954],
            [4.75880088008801, 47.800080008000798],
            [4.585283528352839, 47.650565056505648],
            [4.333683368336835, 47.416541654165414],
            [4.836883688368843, 46.499949994999497],
            [4.672042204220425, 46.499949994999497],
            [4.541904190419046, 46.499949994999497],
            [4.403090309030908, 46.499949994999497],
            [3.613586358635871, 46.499949994999497],
            [3.266551655165522, 46.499949994999497],
            [2.910841084108412, 46.376437643764376],
            [2.884813481348139, 46.499949994999497],
            [2.876137613761379, 46.53895389538954],
            [2.841434143414347, 46.727472747274724],
            [2.832758275827587, 46.746974697469746],
            [2.164716471647168, 47.08500850085008],
            [1.999874987498757, 47.169516951695172],
            [1.461971197119716, 47.169516951695172],
            [1.054205420542061, 47.169516951695172],
            [0.82863286328633, 47.169516951695172],
            [0.256025602560257, 47.169516951695172],
            [0.177942794279431, 47.169516951695172],
            [0.047804780478053, 47.169516951695172],
            [-0.247174717471744, 47.169516951695172],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LQSBFIR", name: "SARAJEVO FIR", fab: "5" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.030603060306035, 44.848784878487848],
            [19.360286028602864, 44.887788778877891],
            [19.299554955495552, 44.686268626862685],
            [19.108685868586868, 44.595259525952599],
            [19.126037603760381, 44.322232223222322],
            [19.368961896189624, 44.18571857185718],
            [19.46439643964397, 44.133713371337137],
            [19.559830983098315, 44.068706870687066],
            [19.585858585858588, 44.062206220622059],
            [19.620562056205628, 44.042704270427038],
            [19.603210321032108, 44.010201020102009],
            [19.46439643964397, 43.964696469646967],
            [19.299554955495552, 43.997199719971995],
            [19.360286028602864, 43.867186718671867],
            [19.507775777577763, 43.717671767176718],
            [19.533803380338043, 43.691669166916689],
            [19.525127512751283, 43.672167216721675],
            [19.499099909991003, 43.581158115811576],
            [19.1433893389339, 43.529152915291533],
            [18.926492649264929, 43.522652265226526],
            [19.212796279627966, 43.334133413341334],
            [19.073982398239828, 43.308130813081306],
            [19.047954795479555, 43.249624962496249],
            [18.917816781678177, 43.334133413341334],
            [18.761651165116518, 43.295129512951291],
            [18.666216621662173, 43.035103510351036],
            [18.501375137513755, 42.996099609960993],
            [18.553430343034307, 42.586558655865588],
            [18.484023402340242, 42.450045004500453],
            [18.449319931993202, 42.502050205020502],
            [18.440644064406449, 42.541054105410538],
            [18.310506050605063, 42.612561256125616],
            [18.249774977497758, 42.599559955995602],
            [17.850685068506856, 42.853085308530851],
            [17.833333333333336, 42.866086608660865],
            [17.711871187118717, 42.840084008400837],
            [17.547029702970299, 42.905090509050908],
            [17.703195319531957, 42.95059505950595],
            [17.659815981598165, 43.041604160416043],
            [17.451595159515954, 43.210621062106213],
            [17.373512351235128, 43.27562756275627],
            [17.278077807780782, 43.353635363536355],
            [17.25205020502051, 43.451145114511448],
            [17.24337433743375, 43.483648364836483],
            [17.21734673467347, 43.496649664966498],
            [17.199994999499957, 43.496649664966498],
            [17.121912191219124, 43.516151615161519],
            [17.043829382938299, 43.561656165616562],
            [16.948394839483953, 43.633163316331633],
            [16.826932693269335, 43.737173717371732],
            [16.748849884988502, 43.802180218021803],
            [16.722822282228229, 43.821682168216824],
            [16.670767076707676, 43.873687368736874],
            [16.479897989798985, 44.029702970297024],
            [16.315056505650567, 44.12721272127213],
            [16.306380638063814, 44.153215321532151],
            [16.271677167716774, 44.166216621662166],
            [16.141539153915396, 44.445744574457443],
            [15.968021802180225, 44.679767976797677],
            [15.768476847684774, 44.77077707770777],
            [15.742449244924501, 44.939793979397933],
            [15.733773377337741, 45.193319331933196],
            [15.889938993899392, 45.219321932193218],
            [15.907290729072912, 45.225822582258225],
            [15.933318331833192, 45.219321932193218],
            [16.306380638063814, 44.998299829982997],
            [16.37578757875788, 45.050305030503054],
            [16.445194519451952, 45.128312831283125],
            [16.462546254625465, 45.147814781478147],
            [16.488573857385745, 45.199819981998203],
            [16.523277327732778, 45.225822582258225],
            [16.557980798079811, 45.219321932193218],
            [16.679442944294436, 45.199819981998203],
            [16.731498149814989, 45.193319331933196],
            [16.783553355335542, 45.186818681868189],
            [16.835608560856087, 45.219321932193218],
            [16.948394839483953, 45.238823882388239],
            [17.017801780178026, 45.225822582258225],
            [17.069856985698578, 45.199819981998203],
            [17.165291529152924, 45.154315431543154],
            [17.182643264326437, 45.147814781478147],
            [17.23469846984699, 45.141314131413139],
            [17.25205020502051, 45.154315431543154],
            [17.295429542954302, 45.173817381738175],
            [17.356160616061608, 45.141314131413139],
            [17.364836483648368, 45.134813481348132],
            [17.399539953995408, 45.134813481348132],
            [17.408215821582161, 45.134813481348132],
            [17.451595159515954, 45.160816081608161],
            [17.451595159515954, 45.128312831283125],
            [17.486298629862993, 45.134813481348132],
            [17.494974497449753, 45.115311531153111],
            [17.521002100210026, 45.102310231023097],
            [17.547029702970299, 45.115311531153111],
            [17.659815981598165, 45.115311531153111],
            [17.842009200920096, 45.043804380438047],
            [17.859360936093616, 45.043804380438047],
            [17.894064406440648, 45.063306330633061],
            [17.998174817481754, 45.134813481348132],
            [18.379912991299136, 45.102310231023097],
            [18.666216621662173, 45.082808280828083],
            [18.726947694769486, 44.998299829982997],
            [18.831058105810584, 44.848784878487848],
            [19.030603060306035, 44.848784878487848],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LLLLFIR", name: "TEL AVIV FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.979122912291231, 31.834483448344834],
            [33.996474647464751, 32.172517251725168],
            [34.057205720572071, 32.335033503350331],
            [34.169991999199922, 32.497549754975495],
            [34.196019601960202, 32.491049104910488],
            [34.352185218521853, 32.614561456145616],
            [34.543054305430545, 32.783578357835779],
            [34.551730173017305, 32.855085508550857],
            [34.603785378537864, 33.089108910891085],
            [34.864061406140621, 33.102110211021099],
            [34.950820082008207, 33.102110211021099],
            [35.332558255825589, 33.102110211021099],
            [35.332558255825589, 33.069606960696071],
            [35.419316931693174, 32.998099809980999],
            [35.53210321032104, 33.134613461346134],
            [35.584158415841586, 33.284128412841284],
            [35.801055105510557, 33.316631663166319],
            [35.879137913791382, 32.9005900590059],
            [35.748999899990011, 32.718571857185722],
            [35.53210321032104, 32.601560156015601],
            [35.54945494549456, 31.76947694769477],
            [35.384613461346134, 30.930893089308931],
            [35.037578757875792, 29.715271527152716],
            [35.002875287528767, 29.585258525852584],
            [34.968171817181727, 29.539753975397538],
            [34.881413141314141, 29.533253325332531],
            [34.265426542654268, 31.216921692169215],
            [34.499674967496759, 31.600460046004599],
            [33.979122912291231, 31.834483448344834],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "EDUUUIR", name: "RHEIN UIR", fab: "6" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.55655565556556, 47.527052705270521],
            [9.5478797879788, 47.546554655465542],
            [9.452445244524455, 47.585558555855584],
            [9.365686568656869, 47.605060506050606],
            [9.03600360036004, 47.683068306830677],
            [9.001300130013007, 47.676567656765677],
            [8.888513851385142, 47.657065706570656],
            [8.628237823782385, 47.767576757675769],
            [8.498099809980999, 47.767576757675769],
            [8.411341134113414, 47.676567656765677],
            [8.524127412741279, 47.644064406440641],
            [8.524127412741279, 47.631063106310634],
            [8.454720472047207, 47.57255725572557],
            [8.281203120312036, 47.605060506050606],
            [8.20312031203121, 47.61806180618062],
            [8.081658165816584, 47.559555955595556],
            [7.942844284428446, 47.546554655465542],
            [7.908140814081413, 47.579057905790577],
            [7.899464946494653, 47.585558555855584],
            [7.856085608560861, 47.592059205920592],
            [7.847409740974101, 47.579057905790577],
            [7.830058005800588, 47.579057905790577],
            [7.778002800280035, 47.553055305530549],
            [7.699919991999202, 47.540054005400535],
            [7.682568256825689, 47.566056605660563],
            [7.578457845784584, 47.605060506050606],
            [7.500375037503751, 47.631063106310634],
            [7.535078507850791, 47.689568956895684],
            [7.535078507850791, 47.780578057805783],
            [7.561106110611064, 47.832583258325826],
            [7.561106110611064, 47.839083908390833],
            [7.587133713371344, 47.910591059105911],
            [7.613161316131617, 47.988598859885983],
            [7.613161316131617, 48.001600160015997],
            [7.578457845784584, 48.086108610861089],
            [7.64786478647865, 48.196619661966196],
            [7.65654065406541, 48.216121612161217],
            [7.699919991999202, 48.346134613461345],
            [7.734623462346242, 48.437143714371437],
            [7.795354535453548, 48.508650865086508],
            [7.804030403040308, 48.521652165216523],
            [7.968871887188726, 48.716671667166715],
            [8.020927092709279, 48.775177517751771],
            [8.151065106510657, 48.918191819181914],
            [8.220472047204723, 48.976697669766978],
            [8.116361636163617, 48.989698969896992],
            [8.064306430643072, 49.002700270026999],
            [8.003575357535759, 49.02220222022202],
            [7.830058005800588, 49.041704170417042],
            [7.717271727172722, 49.048204820482049],
            [7.587133713371344, 49.080708070807077],
            [7.3442094209421, 49.11971197119712],
            [7.162016201620169, 49.152215221522148],
            [7.040554055405543, 49.171717171717169],
            [6.728222822282234, 49.223722372237219],
            [6.684843484348441, 49.249724972497248],
            [6.563381338133816, 49.431743174317432],
            [6.546029602960303, 49.457745774577454],
            [6.46794679467947, 49.464246424642461],
            [6.398539853985405, 49.464246424642461],
            [6.381188118811885, 49.581258125812582],
            [6.381188118811885, 49.600760076007596],
            [6.424567456745677, 49.639763976397639],
            [6.433243324332437, 49.665766576657667],
            [6.45927092709271, 49.691769176917688],
            [6.520002000200023, 49.730773077307731],
            [6.537353735373543, 49.769776977697767],
            [6.424567456745677, 49.815281528152816],
            [6.381188118811885, 49.834783478347831],
            [6.398539853985405, 49.860786078607859],
            [6.355160516051612, 49.860786078607859],
            [6.242374237423746, 49.893289328932894],
            [6.225022502250226, 49.925792579257923],
            [6.216346634663473, 49.951795179517951],
            [6.198994899489954, 49.951795179517951],
            [6.198994899489954, 49.958295829582958],
            [6.164291429142921, 49.971297129712973],
            [6.164291429142921, 49.97779777977798],
            [6.129587958795881, 50.068806880688065],
            [6.120912091209128, 50.075307530753072],
            [6.138263826382641, 50.127312731273129],
            [6.146939693969401, 50.146814681468143],
            [6.155615561556161, 50.159815981598157],
            [6.190319031903194, 50.179317931793179],
            [6.181643164316434, 50.218321832183214],
            [6.216346634663473, 50.231323132313229],
            [6.225022502250226, 50.237823782378236],
            [6.242374237423746, 50.263826382638264],
            [6.251050105010506, 50.263826382638264],
            [6.277077707770779, 50.270327032703271],
            [6.285753575357539, 50.283328332833278],
            [6.372512251225125, 50.315831583158314],
            [6.398539853985405, 50.335333533353335],
            [6.48529852985299, 50.367836783678364],
            [6.50265026502651, 50.374337433743371],
            [6.632788278827888, 50.426342634263428],
            [6.745574557455747, 50.478347834783477],
            [6.81498149814982, 50.504350435043506],
            [6.901740174017405, 50.543354335433541],
            [6.927767776777685, 50.549854985498548],
            [6.997174717471751, 50.582358235823584],
            [7.057905790579063, 50.595359535953591],
            [7.127312731273129, 50.608360836083605],
            [7.214071407140722, 50.621362136213619],
            [7.222747274727475, 50.621362136213619],
            [7.378912891289133, 50.647364736473648],
            [7.526402640264031, 50.673367336733669],
            [7.595809580958097, 50.686368636863683],
            [7.64786478647865, 50.699369936993698],
            [7.725947594759482, 50.705870587058705],
            [7.812706270627068, 50.718871887188719],
            [7.864761476147621, 50.731873187318733],
            [7.994899489948999, 50.757875787578755],
            [8.029602960296032, 50.796879687968797],
            [7.951520152015206, 50.907390739073904],
            [7.882113211321133, 50.998399839983996],
            [7.968871887188726, 51.069906990699067],
            [8.263851385138516, 51.082908290829081],
            [8.324582458245828, 51.115411541154117],
            [8.489423942394247, 51.193419341934195],
            [8.550155015501552, 51.225922592259224],
            [8.662941294129418, 51.28442844284428],
            [8.767051705170523, 51.336433643364337],
            [8.905865586558662, 51.336433643364337],
            [9.166141614161418, 51.336433643364337],
            [9.521852185218528, 51.336433643364337],
            [9.58258325832584, 51.336433643364337],
            [9.938293829382943, 51.336433643364337],
            [10.059755975597561, 51.336433643364337],
            [10.389438943894397, 51.336433643364337],
            [10.606335633563361, 51.531453145314529],
            [10.701770177017707, 51.563956395639565],
            [10.988073807380744, 51.719971997199721],
            [11.005425542554264, 51.732973297329728],
            [11.204970497049707, 51.843484348434842],
            [11.126887688768882, 51.908490849084906],
            [11.118211821182122, 51.97349734973497],
            [11.118211821182122, 51.999499949994998],
            [11.083508350835089, 52.29202920292029],
            [11.083508350835089, 52.305030503050304],
            [11.074832483248329, 52.318031803180318],
            [11.066156615661569, 52.376537653765375],
            [11.083508350835089, 52.46104610461046],
            [11.083508350835089, 52.480548054805482],
            [11.109535953595362, 52.727572757275723],
            [11.135563556355642, 53.000600060006001],
            [11.170267026702675, 53.351635163516349],
            [11.196294629462948, 53.585658565856583],
            [11.204970497049707, 53.637663766376633],
            [11.230998099809987, 53.930193019301932],
            [11.25702570257026, 54.131713171317131],
            [11.638763876387642, 54.294229422942294],
            [12.003150315031505, 54.450245024502451],
            [12.063881388138817, 54.489248924892486],
            [12.289453945394541, 54.606260626062607],
            [12.515026502650272, 54.736273627362735],
            [12.601785178517858, 54.781778177817777],
            [12.783978397839789, 54.879287928792877],
            [12.853385338533862, 54.91829182918292],
            [13.000875087508753, 54.91829182918292],
            [13.382613261326135, 54.91829182918292],
            [13.564806480648073, 54.91829182918292],
            [13.660241024102412, 54.91829182918292],
            [13.746999699970004, 54.91829182918292],
            [13.764351435143517, 54.91829182918292],
            [14.163441344134419, 54.91829182918292],
            [14.34563456345635, 54.91829182918292],
            [14.35431043104311, 54.91829182918292],
            [14.33695869586959, 54.762276227622763],
            [14.328282828282831, 54.664766476647664],
            [14.284903490349038, 54.33323332333233],
            [14.258875887588765, 54.164216421642159],
            [14.224172417241732, 54.053705370537052],
            [14.224172417241732, 53.930193019301932],
            [14.224172417241732, 53.852185218521853],
            [14.276227622762278, 53.761176117611761],
            [14.293579357935798, 53.735173517351733],
            [14.276227622762278, 53.683168316831683],
            [14.310931093109318, 53.64416441644164],
            [14.328282828282831, 53.507650765076505],
            [14.389013901390143, 53.377637763776377],
            [14.406365636563663, 53.358135813581356],
            [14.415041504150423, 53.332133213321328],
            [14.406365636563663, 53.273627362736271],
            [14.380338033803383, 53.176117611761171],
            [14.33695869586959, 53.169616961696171],
            [14.154765476547659, 52.903090309030901],
            [14.241524152415245, 52.812081208120809],
            [14.371662166216623, 52.727572757275723],
            [14.527827782778282, 52.630063006300631],
            [14.605910591059114, 52.604060406040603],
            [14.640614061406147, 52.539053905390539],
            [14.536503650365042, 52.402540254025403],
            [14.579882988298834, 52.29202920292029],
            [14.65796579657966, 52.207520752075204],
            [14.67531753175318, 52.136013601360133],
            [14.753400340034005, 52.058005800580055],
            [14.67531753175318, 51.901990199019899],
            [14.631938193819387, 51.830483048304828],
            [14.614586458645867, 51.797979797979799],
            [14.66664166416642, 51.75247524752475],
            [14.718696869686973, 51.7004700470047],
            [14.736048604860493, 51.583458345834579],
            [14.996324632463249, 51.498949894989501],
            [14.961621162116217, 51.407940794079408],
            [15.013676367636769, 51.199919991999195],
            [14.987648764876489, 51.115411541154117],
            [14.926917691769184, 50.991899189918989],
            [14.857510751075111, 50.907390739073904],
            [14.822807280728078, 50.88788878887889],
            [14.822807280728078, 50.868386838683868],
            [14.796779677967805, 50.835883588358833],
            [14.631938193819387, 50.913891389138911],
            [14.579882988298834, 50.913891389138911],
            [14.571207120712074, 50.959395939593961],
            [14.562531253125314, 51.004900490049003],
            [14.397689768976903, 51.017901790179017],
            [14.267551755175525, 51.017901790179017],
            [14.302255225522558, 50.952895289528954],
            [14.397689768976903, 50.939893989398939],
            [14.397689768976903, 50.920392039203918],
            [14.389013901390143, 50.900890089008897],
            [14.33695869586959, 50.881388138813882],
            [13.764351435143517, 50.731873187318733],
            [13.625537553755379, 50.712371237123712],
            [13.009550955095513, 50.517351735173513],
            [12.914116411641167, 50.478347834783477],
            [12.749274927492756, 50.432843284328435],
            [12.636488648864891, 50.393839383938392],
            [12.593109310931098, 50.374337433743371],
            [12.488998899889992, 50.341834183418342],
            [12.43694369436944, 50.328832883288328],
            [12.384888488848887, 50.289828982898285],
            [12.350185018501854, 50.237823782378236],
            [12.341509150915094, 50.192319231923193],
            [12.315481548154821, 50.172817281728172],
            [12.211371137113716, 50.159815981598157],
            [12.211371137113716, 50.101310131013101],
            [12.263426342634268, 50.068806880688065],
            [12.306805680568061, 50.055805580558051],
            [12.324157415741581, 50.042804280428044],
            [12.324157415741581, 50.036303630363037],
            [12.384888488848887, 50.016801680168015],
            [12.45429542954296, 49.990799079907987],
            [12.497674767476752, 49.971297129712973],
            [12.488998899889992, 49.93229322932293],
            [12.46297129712972, 49.808780878087809],
            [12.4456195619562, 49.795779577957795],
            [12.402240224022407, 49.756775677567752],
            [12.471647164716479, 49.704770477047703],
            [12.541054105410545, 49.665766576657667],
            [12.584433443344338, 49.633263326332631],
            [12.575757575757578, 49.574757475747575],
            [12.584433443344338, 49.568256825682568],
            [12.610461046104618, 49.529252925292525],
            [13.096309630963098, 49.204220422042205],
            [13.113661366136618, 49.191219121912191],
            [13.261151115111517, 49.100210021002098],
            [13.686268626862692, 48.879187918791878],
            [13.790379037903797, 48.827182718271828],
            [13.80773077307731, 48.606160616061601],
            [13.738323832383244, 48.52815281528153],
            [13.720972097209724, 48.508650865086508],
            [13.504075407540761, 48.586658665866587],
            [13.452020202020208, 48.476147614761473],
            [13.425992599259928, 48.398139813981402],
            [13.321882188218829, 48.352635263526352],
            [13.183068306830691, 48.294129412941288],
            [13.165716571657171, 48.294129412941288],
            [13.096309630963098, 48.26162616261626],
            [13.018226822682273, 48.242124212421245],
            [12.96617161716172, 48.203120312031203],
            [12.862061206120615, 48.183618361836182],
            [12.836033603360342, 48.16411641164116],
            [12.766626662666269, 48.125112511251125],
            [12.757950795079516, 48.079607960796082],
            [12.801330133013309, 48.047104710471046],
            [12.853385338533862, 47.735073507350734],
            [12.740599059905996, 47.709070907090705],
            [12.593109310931098, 47.689568956895684],
            [12.46297129712972, 47.67006700670067],
            [12.202695269526956, 47.637563756375641],
            [12.167991799179923, 47.631063106310634],
            [12.063881388138817, 47.61806180618062],
            [12.046529652965305, 47.611561156115613],
            [11.786253625362541, 47.585558555855584],
            [11.60406040604061, 47.579057905790577],
            [11.499949994999504, 47.488048804880485],
            [11.491274127412744, 47.488048804880485],
            [11.343784378437846, 47.35153515351535],
            [11.109535953595362, 47.35153515351535],
            [10.996749674967504, 47.416541654165414],
            [10.979397939793984, 47.423042304230421],
            [10.849259925992605, 47.501050105010499],
            [10.72779777977798, 47.520552055205521],
            [10.710446044604467, 47.540054005400535],
            [10.667066706670674, 47.553055305530549],
            [10.484873487348743, 47.566056605660563],
            [10.450170017001703, 47.585558555855584],
            [10.441494149414943, 47.527052705270521],
            [10.441494149414943, 47.475047504750478],
            [10.43281828182819, 47.397039703970393],
            [10.43281828182819, 47.384038403840378],
            [10.346059605960598, 47.312531253125314],
            [10.311356135613565, 47.306030603060307],
            [10.233273327332739, 47.280028002800279],
            [10.172542254225426, 47.273527352735272],
            [10.198569856985706, 47.332033203320336],
            [10.215921592159219, 47.384038403840378],
            [10.085783578357841, 47.449044904490449],
            [10.085783578357841, 47.507550755075506],
            [9.946969696969703, 47.520552055205521],
            [9.816831683168324, 47.546554655465542],
            [9.799479947994804, 47.57255725572557],
            [9.782128212821284, 47.592059205920592],
            [9.764776477647771, 47.579057905790577],
            [9.730073007300732, 47.533553355335528],
            [9.55655565556556, 47.527052705270521],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LIMMFIR", name: "MILANO FIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.467521752175223, 46.863986398639867],
            [10.502225222522256, 46.844484448444845],
            [10.545604560456049, 46.850985098509852],
            [10.545604560456049, 46.837983798379838],
            [10.667066706670674, 46.876987698769881],
            [10.693094309430947, 46.850985098509852],
            [10.75382538253826, 46.831483148314831],
            [10.72779777977798, 46.798979897989796],
            [10.73647364736474, 46.792479247924788],
            [10.7451495149515, 46.792479247924788],
            [10.788528852885293, 46.792479247924788],
            [10.805880588058812, 46.779477947794774],
            [10.875287528752878, 46.76647664766476],
            [10.970722072207224, 46.772977297729767],
            [11.014101410141016, 46.772977297729767],
            [11.213646364636467, 46.941994199419938],
            [11.25702570257026, 46.974497449744973],
            [11.352460246024606, 46.98099809980998],
            [11.378487848784886, 46.98099809980998],
            [11.447894789478951, 46.987498749874987],
            [11.525977597759784, 47.020002000200023],
            [11.690819081908195, 47.020002000200023],
            [11.751550155015508, 47.020002000200023],
            [11.855660566056613, 47.000500050005002],
            [11.855660566056613, 47.013501350135016],
            [11.985798579857992, 47.046004600460044],
            [12.185343534353443, 47.08500850085008],
            [12.237398739873996, 47.065506550655066],
            [12.194019401940196, 47.02650265026503],
            [12.12461246124613, 47.000500050005002],
            [12.159315931593163, 46.909490949094909],
            [12.220047004700476, 46.876987698769881],
            [12.289453945394541, 46.870487048704874],
            [12.306805680568061, 46.824982498249824],
            [12.280778077807788, 46.805480548054803],
            [12.480323032303232, 46.675467546754675],
            [12.914116411641167, 46.610461046104611],
            [13.165716571657171, 46.590959095909589],
            [13.235123512351237, 46.558455845584561],
            [13.313206320632069, 46.558455845584561],
            [13.330558055805582, 46.558455845584561],
            [13.382613261326135, 46.564956495649568],
            [13.399964996499655, 46.571457145714575],
            [13.573482348234826, 46.53895389538954],
            [13.712296229622964, 46.525952595259525],
            [13.703620362036212, 46.49344934493449],
            [13.694944494449452, 46.467446744674461],
            [13.625537553755379, 46.44144414441444],
            [13.530103010301033, 46.415441544154419],
            [13.408640864086415, 46.311431143114305],
            [13.469371937193728, 46.22042204220422],
            [13.608185818581866, 46.194419441944191],
            [13.694944494449452, 46.187918791879184],
            [13.608185818581866, 46.11641164116412],
            [13.504075407540761, 45.992899289928992],
            [13.599509950995106, 45.986398639863985],
            [13.608185818581866, 46.012401240124007],
            [13.668916891689172, 45.992899289928992],
            [13.634213421342139, 45.940894089408943],
            [13.590834083408346, 45.84988498849885],
            [13.703620362036212, 45.784878487848786],
            [13.799054905490557, 45.752375237523751],
            [13.920517051705176, 45.635363536353637],
            [13.83375837583759, 45.576857685768573],
            [13.729647964796484, 45.615861586158616],
            [13.530103010301033, 45.62886288628863],
            [13.504075407540761, 45.635363536353637],
            [13.391289128912895, 45.557355735573552],
            [13.330558055805582, 45.524852485248523],
            [13.278502850285037, 45.485848584858488],
            [13.183068306830691, 45.420842084208417],
            [13.131013101310138, 45.388338833883388],
            [13.113661366136618, 45.375337533753374],
            [13.000875087508753, 45.297329732973296],
            [12.992199219922, 45.26482648264826],
            [12.992199219922, 45.225822582258225],
            [13.000875087508753, 45.180318031803182],
            [13.000875087508753, 45.167316731673168],
            [13.000875087508753, 45.160816081608161],
            [13.087633763376346, 44.985298529852983],
            [13.131013101310138, 44.900790079007905],
            [13.200420042004204, 44.783778377837784],
            [13.269826982698277, 44.634263426342635],
            [13.330558055805582, 44.530253025302528],
            [13.460696069606968, 44.413241324132414],
            [13.686268626862692, 44.224722472247223],
            [13.755675567556757, 44.159715971597159],
            [13.920517051705176, 44.016701670167016],
            [13.946544654465448, 43.977697769776981],
            [14.024627462746281, 43.925692569256924],
            [14.276227622762278, 43.698169816981697],
            [14.328282828282831, 43.652665266526654],
            [14.423717371737176, 43.561656165616562],
            [14.501800180018009, 43.503150315031505],
            [13.660241024102412, 43.509650965096512],
            [13.330558055805582, 43.516151615161519],
            [13.044254425442546, 43.54215421542154],
            [11.925067506750679, 43.652665266526654],
            [11.638763876387642, 43.678667866786682],
            [11.560681068106817, 43.685168516851682],
            [11.508625862586264, 43.691669166916689],
            [11.170267026702675, 43.717671767176718],
            [10.771177117711773, 43.659165916591661],
            [10.710446044604467, 43.646164616461647],
            [10.675742574257427, 43.63966396639664],
            [10.519576957695776, 43.620162016201618],
            [10.302680268026805, 43.587658765876583],
            [10.059755975597561, 43.405640564056405],
            [9.747424742474252, 43.165116511651163],
            [9.174817481748178, 43.334133413341334],
            [8.957920792079214, 43.386138613861384],
            [8.819106910691076, 43.431643164316426],
            [8.801755175517556, 43.438143814381434],
            [8.71499649964997, 43.464146414641462],
            [8.602210221022105, 43.49014901490149],
            [8.541479147914799, 43.503150315031505],
            [8.324582458245828, 43.568156815681569],
            [7.838733873387341, 43.704670467046704],
            [7.717271727172722, 43.737173717371732],
            [7.682568256825689, 43.769676967696768],
            [7.535078507850791, 43.776177617761775],
            [7.587133713371344, 43.899689968996896],
            [7.665216521652169, 43.997199719971995],
            [7.708595859585962, 44.062206220622059],
            [7.621837183718377, 44.146714671467144],
            [7.283478347834787, 44.140214021402137],
            [7.083933393339336, 44.211721172117208],
            [7.005850585058511, 44.237723772377237],
            [6.919091909190925, 44.45224522452245],
            [7.005850585058511, 44.582258225822585],
            [7.040554055405543, 44.660266026602656],
            [7.014526452645271, 44.803280328032798],
            [6.901740174017405, 44.842284228422841],
            [6.780278027802787, 45.011301130113011],
            [6.641464146414648, 45.115311531153111],
            [6.962471247124718, 45.193319331933196],
            [7.083933393339336, 45.401340134013395],
            [7.023202320232031, 45.453345334533452],
            [7.031878187818783, 45.466346634663466],
            [7.040554055405543, 45.479347934793481],
            [6.867036703670372, 45.752375237523751],
            [7.049229922992303, 45.9018901890189],
            [7.101285128512856, 45.875887588758872],
            [7.448319831983206, 45.927892789278928],
            [7.543754375437551, 45.966896689668964],
            [7.725947594759482, 45.934393439343935],
            [7.847409740974101, 45.927892789278928],
            [7.864761476147621, 45.940894089408943],
            [7.873437343734381, 45.953895389538957],
            [7.908140814081413, 45.992899289928992],
            [7.968871887188726, 45.999399939993999],
            [8.029602960296032, 46.077407740774078],
            [8.133713371337137, 46.181418141814177],
            [8.081658165816584, 46.259425942594262],
            [8.090334033403344, 46.26592659265927],
            [8.229147914791483, 46.330933093309326],
            [8.315906590659068, 46.38943894389439],
            [8.289878987898796, 46.415441544154419],
            [8.437368736873694, 46.467446744674461],
            [8.446044604460454, 46.278927892789277],
            [8.446044604460454, 46.27242724272427],
            [8.463396339633967, 46.252925292529255],
            [8.480748074807487, 46.246424642464248],
            [8.506775677567759, 46.226922692269227],
            [8.567506750675072, 46.168416841684163],
            [8.662941294129418, 46.103410341034106],
            [8.853810381038109, 45.992899289928992],
            [8.905865586558662, 45.908390839083907],
            [8.966596659665974, 45.84988498849885],
            [9.03600360036004, 45.862886288628857],
            [9.070707070707073, 45.869386938693864],
            [9.03600360036004, 46.025402540254021],
            [9.209520952095211, 46.142414241424142],
            [9.226872687268731, 46.155415541554156],
            [9.296279627962804, 46.207420742074206],
            [9.278927892789284, 46.311431143114305],
            [9.278927892789284, 46.415441544154419],
            [9.278927892789284, 46.480448044804476],
            [9.365686568656869, 46.512951295129511],
            [9.504500450045008, 46.350435043504348],
            [9.521852185218528, 46.337433743374334],
            [9.5478797879788, 46.304930493049305],
            [9.90359035903591, 46.415441544154419],
            [10.042404240424048, 46.480448044804476],
            [10.059755975597561, 46.506450645064504],
            [10.146514651465154, 46.616961696169611],
            [10.198569856985706, 46.623462346234618],
            [10.215921592159219, 46.636463646364632],
            [10.337383738373845, 46.545454545454547],
            [10.476197619761983, 46.564956495649568],
            [10.467521752175223, 46.701470147014703],
            [10.467521752175223, 46.863986398639867],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "LGGGUIR", name: "ATHINAI UIR", fab: "2" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.995899589959002, 38.881188118811878],
            [18.995899589959002, 39.29072907290729],
            [18.995899589959002, 39.394739473947396],
            [18.995899589959002, 39.55725572557256],
            [18.995899589959002, 39.817281728172816],
            [18.995899589959002, 39.934293429342929],
            [18.995899589959002, 40.057805780578057],
            [18.995899589959002, 40.246324632463242],
            [18.995899589959002, 40.350335033503349],
            [18.995899589959002, 40.415341534153413],
            [19.44704470447045, 39.999299929993001],
            [19.585858585858588, 39.979797979797979],
            [19.585858585858588, 39.973297329732972],
            [19.750700070007007, 39.947294729472944],
            [19.846134613461352, 39.901790179017901],
            [19.97627262726273, 39.83028302830283],
            [20.00230023002301, 39.680768076807681],
            [20.123762376237629, 39.667766776677666],
            [20.210521052105214, 39.641764176417638],
            [20.245224522452254, 39.706770677067709],
            [20.2972797279728, 39.817281728172816],
            [20.366686668666873, 39.817281728172816],
            [20.279927992799287, 39.986298629862986],
            [20.392714271427145, 39.979797979797979],
            [20.401390139013905, 40.057805780578057],
            [20.470797079707978, 40.064306430643065],
            [20.592259225922596, 40.070807080708065],
            [20.670342034203429, 40.096809680968093],
            [20.705045504550462, 40.272327232723271],
            [20.791804180418048, 40.441344134413441],
            [20.956645664566466, 40.480348034803484],
            [21.034728472847291, 40.584358435843583],
            [21.052080208020804, 40.616861686168619],
            [20.956645664566466, 40.831383138313825],
            [21.000025002500259, 40.863886388638861],
            [21.121487148714877, 40.857385738573853],
            [21.355735573557361, 40.876887688768875],
            [21.425142514251434, 40.922392239223925],
            [21.69409440944095, 40.935393539353939],
            [21.780853085308536, 40.935393539353939],
            [21.919666966696674, 41.078407840784081],
            [21.997749774977507, 41.130413041304131],
            [22.03245324532454, 41.130413041304131],
            [22.179942994299438, 41.169416941694166],
            [22.205970597059711, 41.175917591759173],
            [22.431543154315435, 41.117411741174116],
            [22.483598359835987, 41.117411741174116],
            [22.518301830183027, 41.130413041304131],
            [22.613736373637373, 41.175917591759173],
            [22.761226122612264, 41.247424742474252],
            [22.787253725372544, 41.33193319331933],
            [22.986798679867995, 41.351435143514351],
            [23.281778177817785, 41.390439043904394],
            [23.455295529552963, 41.403440344034401],
            [23.507350735073516, 41.383938393839387],
            [23.637488748874894, 41.364436443644365],
            [24.036578657865789, 41.455445544554451],
            [24.123337333733375, 41.54645464546455],
            [24.166716671667174, 41.54645464546455],
            [24.27082708270828, 41.578957895789578],
            [24.314206420642066, 41.520452045204522],
            [24.400965096509651, 41.54645464546455],
            [24.461696169616971, 41.533453345334536],
            [24.600510051005102, 41.448944894489443],
            [24.704620462046208, 41.422942294229422],
            [24.800055005500553, 41.416441644164415],
            [24.878137813781379, 41.396939693969401],
            [25.051655165516564, 41.364436443644365],
            [25.09503450345035, 41.344934493449344],
            [25.12106210621063, 41.351435143514351],
            [25.242524252425255, 41.253925392539252],
            [25.329282928292841, 41.240924092409244],
            [25.485448544854492, 41.292929292929287],
            [25.537503750375038, 41.286428642864287],
            [25.572207220722078, 41.325432543254323],
            [25.624262426242623, 41.305930593059301],
            [25.658965896589663, 41.318931893189315],
            [25.719696969696969, 41.299429942994294],
            [25.737048704870489, 41.325432543254323],
            [25.832483248324834, 41.351435143514351],
            [25.893214321432154, 41.312431243124308],
            [25.99732473247326, 41.33193319331933],
            [26.136138613861391, 41.364436443644365],
            [26.170842084208431, 41.416441644164415],
            [26.066731673167325, 41.734973497349735],
            [26.300980098009802, 41.734973497349735],
            [26.552580258025813, 41.630963096309628],
            [26.595959595959599, 41.33193319331933],
            [26.318331833183322, 41.247424742474252],
            [26.292304230423042, 41.065406540654067],
            [26.318331833183322, 40.948394839483946],
            [26.248924892489256, 40.883388338833882],
            [25.901890189018914, 40.668866886688669],
            [25.901890189018914, 40.564856485648562],
            [25.884538453845394, 40.499849984998498],
            [25.91924192419242, 40.382838283828377],
            [25.554855485548558, 40.096809680968093],
            [25.615586558655878, 39.901790179017901],
            [25.884538453845394, 39.453245324532453],
            [26.153490349034911, 39.368736873687368],
            [26.370387038703882, 39.433743374337432],
            [26.431118111811188, 39.414241424142418],
            [26.500525052505253, 39.329732973297325],
            [26.535228522852293, 39.284228422842283],
            [26.526552655265533, 39.264726472647268],
            [26.752125212521264, 38.998199819981998],
            [26.630663066306639, 38.881188118811878],
            [26.431118111811188, 38.835683568356835],
            [26.318331833183322, 38.6016601660166],
            [26.300980098009802, 38.484648464846487],
            [26.196869686968697, 38.348134813481352],
            [26.170842084208431, 38.244124412441245],
            [26.248924892489256, 38.166116611661167],
            [26.300980098009802, 38.107610761076103],
            [26.526552655265533, 37.984098409840982],
            [26.873587358735875, 37.958095809580954],
            [27.003725372537261, 37.951595159515946],
            [27.029752975297541, 37.88658865886589],
            [27.047104710471046, 37.84108410841084],
            [27.081808180818086, 37.698069806980698],
            [26.977697769776981, 37.652565256525648],
            [26.986373637363741, 37.626562656265627],
            [27.116511651165126, 37.431543154315435],
            [27.099159915991606, 37.132513251325136],
            [27.177242724272432, 37.048004800480044],
            [27.402815281528163, 36.865986598659866],
            [27.229297929792992, 36.716471647164717],
            [27.359435943594363, 36.61246124612461],
            [27.532953295329534, 36.566956695669568],
            [27.723822382238225, 36.638463846384639],
            [27.862636263626371, 36.696969696969695],
            [27.914691469146916, 36.664466446644667],
            [27.992774277427756, 36.495449544954496],
            [28.053505350535062, 36.482448244824482],
            [28.209670967096713, 36.534453445344532],
            [28.48729872987299, 36.547454745474546],
            [28.548029802980309, 36.384938493849383],
            [28.816981698169826, 36.215921592159219],
            [29.250775077507754, 36.085908590859084],
            [29.32018201820182, 36.183418341834184],
            [29.502375237523765, 36.170417041704169],
            [29.58045804580459, 36.144414441444141],
            [29.710596059605962, 36.085908590859084],
            [29.806030603060307, 36.072907290729077],
            [29.996899689968998, 36.079407940794077],
            [29.996899689968998, 35.832383238323828],
            [29.996899689968998, 35.663366336633658],
            [29.996899689968998, 35.585358535853587],
            [29.996899689968998, 35.201820182018196],
            [29.996899689968998, 34.967796779677968],
            [29.996899689968998, 34.915791579157911],
            [29.996899689968998, 34.870287028702869],
            [29.996899689968998, 34.649264926492648],
            [29.996899689968998, 34.584258425842584],
            [29.996899689968998, 34.499749974997499],
            [29.996899689968998, 34.324232423242321],
            [29.996899689968998, 33.732673267326732],
            [29.996899689968998, 33.498649864986497],
            [28.747574757475761, 33.71317131713171],
            [28.270402040204033, 33.797679767976796],
            [27.61971197119712, 33.921192119211923],
            [27.333408340834083, 33.953695369536952],
            [27.168566856685672, 33.999199919991995],
            [26.81285628562857, 33.999199919991995],
            [25.502800280028012, 33.999199919991995],
            [25.14708970897091, 33.999199919991995],
            [24.453020302030211, 33.999199919991995],
            [24.166716671667174, 33.999199919991995],
            [23.585433543354341, 34.330733073307329],
            [23.004150415041508, 34.62326232623262],
            [21.963046304630467, 35.130313031303132],
            [21.15619061906191, 35.520352035203516],
            [18.995899589959002, 36.501950195019504],
            [18.995899589959002, 36.768476847684767],
            [18.995899589959002, 36.963496349634966],
            [18.995899589959002, 37.399039903990399],
            [18.995899589959002, 37.750075007500747],
            [18.995899589959002, 38.341634163416344],
            [18.995899589959002, 38.517151715171515],
            [18.995899589959002, 38.725172517251721],
            [18.995899589959002, 38.881188118811878],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: "UKOVFIR", name: "ODESSA FIR" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.276802680268034, 47.806580658065805],
            [29.285478547854794, 47.806580658065805],
            [29.415616561656165, 47.884588458845883],
            [29.684568456845696, 48.066606660666068],
            [29.884113411341147, 48.190119011901189],
            [30.092334233423344, 48.16411641164116],
            [30.664941494149417, 48.086108610861089],
            [30.977272727272734, 48.047104710471046],
            [31.27225222522253, 48.001600160015997],
            [31.601935193519353, 47.956095609560954],
            [32.833908390839085, 47.767576757675769],
            [33.050805080508056, 47.735073507350734],
            [32.93801880188019, 47.098009800980094],
            [32.93801880188019, 47.08500850085008],
            [32.885963596359645, 46.733973397339732],
            [32.903315331533165, 46.213921392139213],
            [32.911991199119925, 46.12291229122912],
            [32.452170217021703, 45.914891489148914],
            [32.391439143914397, 45.882388238823879],
            [32.235273527352746, 45.810881088108815],
            [32.0964596459646, 45.745874587458744],
            [31.888238823882389, 45.641864186418644],
            [31.506500650065007, 45.453345334533452],
            [31.419741974197422, 45.407840784078402],
            [31.107410741074119, 45.258325832583253],
            [30.560831083108312, 44.978797879787976],
            [30.361286128612875, 44.874787478747876],
            [30.083658365836584, 44.913791379137912],
            [30.048954895489558, 44.978797879787976],
            [29.970872087208733, 45.147814781478147],
            [29.806030603060307, 45.186818681868189],
            [29.667216721672176, 45.219321932193218],
            [29.502375237523765, 45.41434143414341],
            [29.111961196119623, 45.375337533753374],
            [28.721547154715481, 45.225822582258225],
            [28.331133113311338, 45.336333633363338],
            [28.322457245724578, 45.349334933493353],
            [28.200995099509953, 45.472847284728473],
            [28.313781378137818, 45.544354435443545],
            [28.435243524352444, 45.485848584858488],
            [28.51332633263327, 45.498849884988502],
            [28.50465046504651, 45.570357035703566],
            [28.548029802980309, 45.596359635963594],
            [28.50465046504651, 45.674367436743672],
            [28.730223022302241, 45.823882388238822],
            [28.869036903690372, 45.973397339733971],
            [28.955795579557957, 46.096909690969099],
            [29.051230123012303, 46.181418141814177],
            [29.085933593359343, 46.519451945194518],
            [29.32885788578858, 46.467446744674461],
            [29.693244324432456, 46.363436343634362],
            [29.675892589258936, 46.402440244024405],
            [29.753975397539762, 46.454445444544454],
            [29.797354735473547, 46.434943494349433],
            [29.832058205820587, 46.382938293829383],
            [29.884113411341147, 46.363436343634362],
            [29.944844484448453, 46.402440244024405],
            [29.996899689968998, 46.382938293829383],
            [30.066306630663078, 46.402440244024405],
            [30.135713571357144, 46.415441544154419],
            [30.127037703770384, 46.421942194219426],
            [30.118361836183624, 46.434943494349433],
            [30.074982498249838, 46.434943494349433],
            [30.074982498249838, 46.454445444544454],
            [29.979547954795493, 46.519451945194518],
            [29.901465146514653, 46.545454545454547],
            [29.944844484448453, 46.649464946494646],
            [29.996899689968998, 46.831483148314831],
            [29.884113411341147, 46.876987698769881],
            [29.875437543754387, 46.876987698769881],
            [29.762651265126522, 46.863986398639867],
            [29.727947794779482, 46.922492249224916],
            [29.58045804580459, 46.948494849484945],
            [29.632513251325136, 47.000500050005002],
            [29.615161516151616, 47.013501350135016],
            [29.623837383738376, 47.078507850785073],
            [29.528402840284031, 47.111011101110108],
            [29.59780978097811, 47.286528652865286],
            [29.58913391339135, 47.371037103710371],
            [29.511051105110511, 47.371037103710371],
            [29.485023502350245, 47.312531253125314],
            [29.441644164416445, 47.2995299529953],
            [29.389588958895899, 47.325532553255329],
            [29.32885788578858, 47.442544254425442],
            [29.190044004400448, 47.468546854685471],
            [29.207395739573968, 47.57255725572557],
            [29.224747474747488, 47.728572857285727],
            [29.276802680268034, 47.806580658065805],
          ],
        ],
      },
    },
  ],
};
