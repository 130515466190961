import React from "react";
import Title from "antd/lib/typography/Title";

export default function Restrictions() {
  return (
    <div className="landing-section termsAndPolicy">
      <Title level={1}>NEW restrictions on flight details</Title>

      <div>
        <Title level={2}>WHY this change?</Title>
        <p>
          CTOT app relies partially on Eurocontrol's data to provide you with
          your flight details. Most ATFCM data come from them and they
          strenghtened their rules recently.
        </p>
        <p>
          While CTOT app got the approval to display flight data as presented in
          previous version, the rules have changed and we are obliged to follow
          them.
        </p>
      </div>
      <div>
        <Title level={2}>What will change?</Title>
        <p>
          The main goal of those new rules is to restrict the access to flights
          not related to your activity. For example:
        </p>
        <ul>
          <li>
            If you are an airline, you can only see flights from your own
            airline.
          </li>
          <li>
            If you are a handling company, you can only see flights to/from your
            airport.
          </li>
          <li>
            If you are a private owner, you can only see your own aircraft
            flights.
          </li>
        </ul>
      </div>
      <div>
        <Title level={2}>What is the transition phase?</Title>
        <p>
          In order to make it smoother, there is a transition phase where you
          can disable the new rule. To disable the restrictions, go to the iOS
          or Android app, then Settings and disable from there. You will recover
          normal app use for a limited time.
        </p>
      </div>
      <div>
        <Title level={2}>I am not happy with the change</Title>
        <p>
          If the change impacts you too much, you can cancel your subscription.
        </p>
        <ul>
          <li>
            iOS in App Purchase: visit{" "}
            <a href="https://support.apple.com/en-us/HT202039" target="_blank">
              https://support.apple.com/en-us/HT202039
            </a>{" "}
            to cancel your subscription. If you need a refund, please also
            visit:{" "}
            <a href="https://support.apple.com/en-kw/HT204084" target="_blank">
              https://support.apple.com/en-kw/HT204084
            </a>
          </li>
          <li>
            Stripe purchase (bought on Android and web dashboard): Go to your
            account settings and click "Cancel" on your subscription. You will
            be automatically refunded on a pro rata basis.
          </li>
        </ul>
      </div>
      <div>
        <Title level={2}>
          I cannot access flights for my activity, what to do?
        </Title>
        <p>
          We have implemented a filter that should garantee access to the
          flights related to your activity. However, if you encounter a
          situation where you cannot access a flight, please contact us at
          contact@ctot.app
        </p>
      </div>
    </div>
  );
}
