import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Search from "./Search";
import DataDisplay from "./DataDisplay";
import ProfileDropdown from "./ProfileDropdown";
import _ from "lodash";

export default function Header({ user }) {
  const Menu = (
    <div className="ctot-navbar-dashboard">
      <Link to="/#top" className="ctot-navbar-dashboard-logo">
        <img
          src="/images/logo/vector/default-monochrome-white-nosub.svg"
          alt="CTOT APP"
          height={40}
          style={{ marginRight: "40px" }}
        />
      </Link>

      <div className="ctot-navbar-dashboard-items">
        <Search className="navbar-dashboard-item navbar-dashboard-item-search" />
        <Routes>
          <Route
            path="/"
            element={
              <DataDisplay className="ctot-navbar-dashboard-item ctot-navbar-dashboard-item-display" />
            }
          />
        </Routes>

        <ProfileDropdown
          user={user}
          className="ctot-navbar-dashboard-item-profile"
        />
      </div>
    </div>
  );

  return Menu;
}
