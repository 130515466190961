import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Title from "antd/lib/typography/Title";

export default function FeatureWithIcon({
  reverse,
  title,
  subtitle,
  icon,
  vertical,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: vertical
          ? "column-reverse"
          : reverse
          ? "row-reverse"
          : "row",
        justifyContent: "flex-end", // horizontal
        alignItems: "center", // vertical
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          textAlign: reverse ? "left" : "right",
        }}
      >
        <h3 style={{ margin: 0, textAlign: vertical ? "center" : "left" }}>
          {title}
        </h3>
        <p style={{ margin: 0, fontSize: 14, textAlign: vertical ? "center" : "left" }}>{subtitle}</p>
      </div>

      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#6292f0",
          border: "15px solid #6292f0",
          borderRadius: 99,
          marginBottom: vertical ? 10 : 0,
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            fontSize: 30,
            width: 30,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
    </div>
  );
}
