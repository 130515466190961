import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function SubscriptionCardTemplate({
  title,
  subtitle,
  subtitleSmall,
  headerAddon,
  descriptionTop = "",
  description = "",
  points = [],
}) {
  return (
    <div className="ctot-card">
      <div className="ctot-card-header">
        <div className="ctot-card-title">{title}</div>
        <div className="ctot-card-subtitle">{subtitle}</div>
        <div className="ctot-card-title-subtitleSmall">{subtitleSmall}</div>
        <div className="ctot-card-title-headerAddon">{headerAddon}</div>
      </div>

      <div className="ctot-card-content">
        {descriptionTop}
        {descriptionTop && <br />}
        {points.map((point, i) => (
          <div key={i} className="ctot-card-list-item">
            {typeof point === "string" && (
              <FontAwesomeIcon
                icon={faCheck}
                style={{ marginRight: 10, color: "#A6D244" }}
              />
            )}
            {point}
          </div>
        ))}

        {description && <br />}
        {description}
      </div>
    </div>
  );
}
