import Dashboard from "./dashboard/Dashboard";
import Landing from "./landing/Landing";

function App() {
  const subdomain = window.location.host.split(".")[0];

  if (
    subdomain === "dashboard" ||
    process.env.REACT_APP_SUBDOMAIN === "dashboard"
  )
    return (
      <div>
        <Dashboard />
      </div>
    );

  return (
    <div>
      <Landing />
    </div>
  );
}

export default App;
