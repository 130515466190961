import React from "react";

import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-items">
        <div className="footer-item">
          <h4>About CTOT</h4>
          <p>
            We're passionate about creating the best mobile apps for airline
            staff.
          </p>
        </div>
        <div className="footer-item">
          <h4>Legal stuff</h4>
          <ul>
            <li>
              <div>
                Read our <Link to="/terms-conditions">Terms & Conditions</Link>
              </div>
            </li>
            <li>
              <i></i>
              <div>
                Read our <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-copyright">
        CTOT.app - Registered in France under SIRET number 98497297600018 - All rights
        reserved
      </div>
    </div>
  );
}
