import React from "react";
import Title from "antd/lib/typography/Title";


export default function PrivacyPolicy() {
  return (
    <div className="landing-section termsAndPolicy">
      <Title level={1}>HOW WE HANDLE YOUR DATA</Title>

      <div>
        <Title level={2}>Private Data We Receive And Collect</Title>
        <p>
          CTOT app automatically collects and receives certain information from
          your computer or mobile device, including the activities you perform
          on our Website, the Platforms, and the Applications, the type of
          hardware and software you are using (for example, your operating
          system or browser), and information obtained from cookies. For
          example, each time you visit the Website or otherwise use the
          Services, we automatically collect your IP address, browser and device
          type, access times, the web page from which you came, the regions from
          which you navigate the web page, and the web page(s) you access (as
          applicable).
        </p>
        <p>
          When you first register for a CTOT account, and when you use the
          Services, we collect some{" "}
          <span className="blue">Personal Information</span> about you such as:
        </p>
        <ul>
          <li>
            The geographic area where you use your computer and mobile devices
          </li>
          <li>Your email address</li>
          <li>
            A unique CTOT user ID (an alphanumeric string) which is assigned to
            you upon registration
          </li>
          <li>Other optional information as part of your account profile</li>
        </ul>
      </div>
      <div>
        <Title level={2}>How We Use CTOT Data</Title>
        <p>CTOT uses visitors' data for the following general purposes:</p>
        <ol>
          <li>
            To analyze the Website or the other Services and information about
            our visitors and users, including research into our user
            demographics and user behaviour in order to improve our content and
            Services
          </li>
          <li>
            To keep you informed about the Services, features, newsletters,
            offers, and events we think you may find useful or which you have
            requested from us
          </li>
          <li>To sell or market CTOT products and services to you</li>
          <li>
            To better understand your needs and the needs of users in the
            aggregate, diagnose problems, analyze trends, improve the features
            and usability of the Services, and better understand and market to
            our customers and users
          </li>
          <li>To keep the Services safe and secure</li>
          <li>
            We also use non-identifiable information gathered for statistical
            purposes to keep track of the number of visits to the Services with
            a view to introducing improvements and improving usability of the
            Services. We may share this type of statistical data so that our
            partners also understand how often people use the Services, so that
            they, too, may provide you with an optimal experience.
          </li>
        </ol>
      </div>

      <div>
        <Title level={2}>Privacy Policy</Title>
        <p>
          Your privacy is important to us. It is CTOT's policy to respect your
          privacy regarding any information we may collect from you through our
          app, CTOT app.
        </p>
        <p>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </p>
        <p>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorised access, disclosure, copying, use or
          modification.
        </p>
        <p>
          We don’t share any personally identifying information publicly or with
          third-parties, except when required to by law. In this particular
          matter, personally identifying information can be requested by
          our data provider for audit and security purposes.
        </p>
        <p>
          Our app may link to external sites that are not operated by us. Please
          be aware that we have no control over the content and practices of
          these sites, and cannot accept responsibility or liability for their
          respective privacy policies. However we don't share personally
          identifying information with those third parties.
        </p>
        <p>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </p>
        <p>
          Your continued use of our app will be regarded as acceptance of our
          practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </p>
        <p>This policy is effective as of 26 May 2020.</p>
      </div>
      <div>
        <Title level={2}>Consent Of Using CTOT</Title>
        <p>
          By using any of the Services, or submitting or collecting any Personal
          Information via the Services, you consent to the collection, transfer,
          storage disclosure, and use of your Personal Information in the manner
          set out in this Privacy Policy. If you do not consent to the use of
          your Personal Information in these ways, please stop using the
          Services.
        </p>
      </div>
    </div>
  );
}
