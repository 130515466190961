import React from "react";

import { Checkbox, Dropdown, Menu } from "antd";
import dataDisplayColumns from "./dataDisplayColumns";

import { DownOutlined } from "@ant-design/icons";
import { AppContext } from "../AppContext";

import _ from "lodash";

export default function DataDisplay({ className }) {
  const { currentColumns, setCurrentColumns } = React.useContext(AppContext);

  const onChange = (checkedValues) => {
    setCurrentColumns(_.orderBy(checkedValues, "order"));
  };

  const items = dataDisplayColumns.map((ele, i) => ({
    key: `${i}`,
    label: <Checkbox value={ele}>{ele.titleText || ele.title}</Checkbox>,
  }));

  return (
    <Checkbox.Group
      onChange={onChange}
      defaultValue={currentColumns}
      className={className}
    >
      <Dropdown menu={{ items }}>
        <div className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Data display <DownOutlined />
        </div>
      </Dropdown>
    </Checkbox.Group>
  );
}
