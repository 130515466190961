import React from "react";
import _ from "lodash";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula as prismStyle } from "react-syntax-highlighter/dist/esm/styles/prism";

const languageList = [
  {
    id: "node_fetch",
    title: "Node + Request",
    name: "nodejs",
    language: "javascript",
  },
  {
    id: "shell_curl",
    title: "Shell + Curl",
    name: "curl",
    language: "http",
  },
  {
    id: "php_curl",
    title: "Php + Http1",
    name: "php",
    language: "php",
  },
  {
    id: "python_python3",
    title: "Python + Python3",
    name: "python",
    language: "python",
  },
];

const CodeBlock = ({ children, language = "http" }) => {
  return (
    <SyntaxHighlighter
      language={language}
      style={prismStyle}
      className="ctot-code-block"
    >
      {children}
    </SyntaxHighlighter>
  );
};

export default function Snippets({ codeSamples }) {
  codeSamples = _.filter(codeSamples, (ele) =>
    _.find(languageList, { id: ele.id })
  );

  const [currentId, setCurrentId] = React.useState(codeSamples[0]?.id);
  const currentCode = _.find(codeSamples, { id: currentId });
  const currentLabels = _.find(languageList, { id: currentId });

  return (
    <div className="ctot-code-snippets-container">
      <div className="ctot-code-lang-buttons-container">
        <div className="ctot-code-lang-buttons">
          {codeSamples.map((ele, i) => {
            const labels = _.find(languageList, { id: ele?.id });
            const isActive = ele.id === currentId;
            return (
              <button
                onClick={() => setCurrentId(ele?.id)}
                className={`ctot-code-lang-button ${
                  isActive ? "active" : "inactive"
                }`}
              >
                <img
                  alt={labels?.name}
                  src={`/images/lang/${labels?.name}.png`}
                  height={20}
                />
                <div>{labels?.name}</div>
              </button>
            );
          })}
        </div>
      </div>

      <div className="ctot-code-block-container">
        <CodeBlock language={currentLabels?.language}>
          {currentCode?.code}
        </CodeBlock>
      </div>
    </div>
  );
}
