import _ from "lodash";

export function getComponentFromRef(openapi, $ref) {
  try {
    let path = $ref?.substring(2, $ref.lengths).replaceAll("/", ".");
    const schema = _.get(openapi, path, {});
    let name = path?.split(".").at(-1);

    return { name, schema };
  } catch (e) {
    console.log("getComponentFromRef", e);
  }
}
