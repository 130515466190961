import * as React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Docs from "./Docs.js";
import HomeContent from "./HomeContent";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import Restrictions from "./Restrictions.js";
import EndOfService from "./EndOfService.js";

import _ from "lodash";
import HeaderPublic from "../components/HeaderPublic.js";
import Footer from "../components/Footer.js";

export default function MainLanding({ urlPrefix = "" }) {
  const { pathname } = useLocation();

  const small = _.some(["/docs", "/end-of-service-individual"], (path) =>
    _.startsWith(pathname, path)
  );

  const Content = () => {
    return (
      <div className="landing-container">
        <HeaderPublic small={small} urlPrefix={urlPrefix} />

        <Routes>
          <Route path="terms-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="restrictions" element={<Restrictions />} />
          <Route path="end-of-service-individual" element={<EndOfService />} />
          <Route path="docs" element={<Docs />} />
          <Route path="docs/:docId" element={<Docs />} />
          <Route exact path="/*" element={<HomeContent />} />
        </Routes>
        <Footer />
      </div>
    );
  };

  return <Content />;
}
