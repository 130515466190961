import { Tag } from "antd";
import _ from "lodash";
import * as React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula as prismStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import { getComponentFromRef } from "../api/openapi.js";
import Snippets from "../components/Snippets.js";
import constants from "../../constants.js";


export default function OpenApiViewer() {
  const [openapi, setOpenapi] = React.useState({});

  const getOpenapi = async () => {
    const response = await fetch(constants.API_URL + "/rest/docs/v1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();

    setOpenapi(res);
  };

  React.useEffect(() => {
    getOpenapi();
  }, []);

  React.useEffect(() => {}, []);

  const ServerViewer = () => {
    return _.map(openapi.servers, (server, i) => {
      return (
        <div className="ctot-code-server-viewer-item">
          <CodeBlock className={"ctot-code-block-medium"}>
            {server.url}
          </CodeBlock>
          {server.description}
        </div>
      );
    });
  };

  const AuthViewer = () => {
    return (
      <div>
        <h2>Auth</h2>
        {_.map(openapi.components?.securitySchemes, (scheme, key) => {
          return (
            <div key={key}>
              <h3 className="ctot-code-section-title">
                {_.capitalize(scheme.in)}
              </h3>
              <div className="ctot-code-section">
                <div>
                  <code className="ctot-code-param">{scheme.name}</code>{" "}
                  <span className="ctot-code-param-addon"> required</span>
                </div>
              </div>
            </div>
          );
        })}
        <br />
        <br />
      </div>
    );
  };

  const PathViewer = ({ path, endpoint }) => {
    return (
      <div className="ctot-code-endpoint-container">
        {_.map(path, (ele, key) => (
          <MethodViewer
            key={key}
            methodName={key}
            method={ele}
            endpoint={endpoint}
          />
        ))}
      </div>
    );
  };

  const MethodViewer = ({ method, methodName, endpoint }) => {
    const codeSamples = method && method["x-codeSamples"];

    console.log(method);

    return (
      <div className="ctot-code-layout-container">
        <div className="ctot-code-layout-content-left">
          <h2>{method?.summary}</h2>
          <p>{method?.description}</p>
          <h3>
            <Tag color="green" style={{ fontSize: "20px" }}>
              {methodName?.toUpperCase()}
            </Tag>
            {endpoint}
          </h3>
          <RequestBodyViewer requestBody={method?.requestBody} />
          <ParametersViewer parameters={method?.parameters} />
          <ResponsesViewer responses={method?.responses} />
        </div>
        <div className="ctot-code-layout-content-right">
          <h3>Code examples</h3>
          <Snippets codeSamples={codeSamples} />
        </div>
      </div>
    );
  };

  const ParametersViewer = ({ parameters }) => {
    const paramsByType = _.groupBy(parameters, (ele) => ele.in);

    return _.map(paramsByType, (type, i) => {
      return (
        <div>
          <h3 className="ctot-code-section-title">
            {_.capitalize(i)} parameters
          </h3>

          {_.map(parameters, (param, i) => {
            return (
              <div key={i} className="ctot-code-section">
                <div>
                  <code className="ctot-code-param">{param.name}</code>{" "}
                  <span className="ctot-code-param-addon">
                    {param.required ? "required" : "optional"}&nbsp;
                    {param.schema?.type}
                  </span>
                </div>

                <div>
                  {param.schema.description}{" "}
                  {param.schema?.example && (
                    <span>eg: {param.schema?.example}</span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const RequestBodyViewer = ({ requestBody }) => {
    const content = requestBody?.content;

    if (!content) return null;

    return _.map(content, (type, i) => {
      console.log(type, i);
      const param = _.get(type, "schema", {});

      return (
        <div>
          <h3 className="ctot-code-section-title">Body request</h3>

          <div key={i} className="ctot-code-section">
            <div>
              <code className="ctot-code-param">
                {_.capitalize(i).toLocaleLowerCase()}
              </code>{" "}
              <span className="ctot-code-param-addon">
                required &nbsp;
                {param?.schema?.type}
              </span>
            </div>

            <div>
              <RefViewer $ref={type.$ref} schema={type.schema} />
            </div>
          </div>
        </div>
      );
    });
  };

  const ResponsesViewer = ({ responses }) => {
    return (
      <div>
        <h3 className="ctot-code-section-title">Responses</h3>

        {_.map(responses, (response, code) => (
          <OneResponseViewer key={code} code={code} response={response} />
        ))}
      </div>
    );
  };

  const OneResponseViewer = ({ response, code }) => {
    return (
      <div className="ctot-code-section">
        <div>
          <code className="ctot-code-param">{code}</code>{" "}
          <span className="ctot-code-param-addon">Status</span>
        </div>
        <div className="ctot-code-response-container-content">
          {response.description}
          <br />
          <br />

          {_.map(response.content, (content, key) => (
            <div key={key}>
              <ParameterViewer parameter={content} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ParameterViewer = ({ parameter }) => {
    const schema = parameter?.schema;
    let ref = schema?.$ref;

    if (ref) return <RefViewer $ref={ref} />;
    else return <code>NOT FOUND</code>;
  };

  const RefViewer = ({
    $ref,
    withDescription,
    onlyType,
    onlyName,
    onlyDescription,
    schema,
    name,
  }) => {
    // If a ref is provided, go to the ref data
    if ($ref) {
      const fromRef = getComponentFromRef(openapi, $ref);
      schema = fromRef.schema;
      name = fromRef.name;
    }

    if (onlyType) return schema?.type;

    if (onlyName) return name;

    if (onlyDescription) return schema.description;

    if (schema?.oneOf) {
      return (
        <span>
          {_.map(schema?.oneOf, (ele, i) => (
            <span key={i}>
              {(ele.$ref && <RefViewer $ref={ele.$ref} onlyName />) ||
                ele + "\n||"}
              &nbsp;
            </span>
          ))}
        </span>
      );
    }

    if (schema?.type === "array") {
      const items = schema.items;

      return (
        <span>
          <strong>{"["}</strong>
          <div className="ctot-code-indent">
            <RefViewer $ref={items?.$ref} withDescription={withDescription} />
          </div>
          <strong>{"]"}</strong>
        </span>
      );
    }

    if (schema?.type === "object") {
      const properties = schema.properties;

      return (
        <span>
          <strong>{"{"}</strong>
          <div className="ctot-code-indent">
            {_.map(properties, (property, key) => (
              <div key={key}>
                <code>
                  <strong>{key}</strong>
                </code>
                :{" "}
                <RefViewer
                  $ref={property?.$ref}
                  schema={property}
                  withDescription={withDescription}
                />
              </div>
            ))}
          </div>
          <strong>{"}"}</strong>
        </span>
      );
    }

    if (schema?.type === "string") {
      return (
        <code>
          {!withDescription && `"${schema.example}"`} <br />
          <span>{withDescription && schema.description}</span>
          {schema.enum && (
            <div>
              <code>
                Enum: <Tag color="blue">{JSON.stringify(schema.enum)}</Tag>
              </code>
            </div>
          )}
        </code>
      );
    }

    if (schema?.type === "boolean") {
      console.log(schema);
      return (
        <span>
          <code>
            {schema.example ? "true" : "false"} <br />
            <span>{withDescription && schema.description}</span>
          </code>
        </span>
      );
    }

    return null;
  };

  return (
    <div className="ctot-code-main-container">
      <h2>Servers</h2>
      <ServerViewer />
      <AuthViewer />

      <div>
        {_.map(openapi.paths, (path, endpoint, i) => (
          <PathViewer path={path} key={i} endpoint={endpoint} />
        ))}
      </div>
    </div>
  );
}

const CodeBlock = ({ className, children, lang = "http" }) => {
  return (
    <SyntaxHighlighter className={className} language={lang} style={prismStyle}>
      {children}
    </SyntaxHighlighter>
  );
};
