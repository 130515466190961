import _ from "lodash";
import * as React from "react";
import Map, { Source, Layer } from "react-map-gl/dist/es5";
import { fir } from "../api/fir";
import { Airports } from "../api/airports";

import "mapbox-gl/dist/mapbox-gl.css";

export default function RestrictionMap(props) {
  const regulationLocations = _.get(props, "regulationLocations");
  const regulationLocationsIds = _.map(regulationLocations, (ele) =>
    ele.referenceLocation.id.substring(0, 4)
  );

  const featuresFiltered = _.filter(fir.features, (firElement) => {
    return _.includes(
      regulationLocationsIds,
      firElement.properties.id.substring(0, 4)
    );
  });

  const departureAirport = _.get(props, "departureAirport");
  const arrivalAirport = _.get(props, "arrivalAirport");

  const departureAirportData = _.find(Airports, {
    icao: departureAirport,
  });
  const arrivalAirportData = _.find(Airports, {
    icao: arrivalAirport,
  });

  const flightLine = [
    {
      latitude: departureAirportData?.latitude,
      longitude: departureAirportData?.longitude,
    },
    {
      latitude: arrivalAirportData?.latitude,
      longitude: arrivalAirportData?.longitude,
    },
  ];

  const geoJsonPoints = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: flightLine.map((ele) => [ele.longitude, ele.latitude]),
    },
  };

  const geoJson = {
    type: "FeatureCollection",
    name: "firs",
    features: featuresFiltered,
  };

  console.log(geoJson);

  const regulationsAirportsIds = _.filter(Airports, (ele) =>
    _.includes(regulationLocationsIds, ele.icao)
  );

  let initialRegion = {
    latitude: 50,
    longitude: 8,
    latitudeDelta: 40,
    longitudeDelta: 40,
  };

  if (flightLine.length > 1) {
    let avgLatitude = (flightLine[0].latitude + flightLine[1].latitude) / 2;
    let avgLongitude = (flightLine[0].longitude + flightLine[1].longitude) / 2;
    const latitudeDelta =
      Math.abs(flightLine[0].latitude - flightLine[1].latitude) + 10;
    const longitudeDelta =
      Math.abs(flightLine[0].longitude - flightLine[1].longitude) + 10;

    initialRegion = {
      latitude: avgLatitude,
      longitude: avgLongitude,
      latitudeDelta: latitudeDelta,
      longitudeDelta: longitudeDelta,
    };
  }

  const geoJsonCircles = {
    type: "FeatureCollection",
    features: regulationsAirportsIds.map((airport) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [airport.longitude, airport.latitude],
        },
      };
    }),
  };

  return (
    <Map
      initialViewState={{
        longitude: initialRegion.longitude,
        latitude: initialRegion.latitude,
        zoom: 3,
      }}
      style={{ width: "100%", height: 400 }}
      mapStyle="mapbox://styles/mapbox/light-v9"
      mapboxAccessToken="pk.eyJ1Ijoic2VyZ2VqYWNvZCIsImEiOiJjbDMzYmQ4NXkwNDlqM2pxOXI3b3U5ZzZwIn0.iGjJZbAgwx79alPDBgCP-A"
    >
      <Source type="geojson" data={geoJson}>
        <Layer
          id={"data"}
          type="fill"
          paint={{
            "fill-color": "rgba(255,120, 120, .7)",
          }}
        />
      </Source>
      <Source type="geojson" data={geoJsonPoints}>
        <Layer
          id={"data2"}
          type="line"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "rgba(3, 170, 238, 0.5)",
            "line-width": 2,
            "line-dasharray": [2, 2],
          }}
        />
      </Source>

      <Source id="my-data" type="geojson" data={geoJsonCircles}>
        <Layer
          id="point"
          type="circle"
          paint={{
            "circle-radius": 10,
            "circle-color": "rgba(255,120, 120, .7)",
          }}
        />
      </Source>
    </Map>
  );
}
