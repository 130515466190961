import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { HashLink } from "react-router-hash-link";
import HeaderPublicItems from "./HeaderPublicItems";

export default function HeaderPublic({ small, urlPrefix = "" }) {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  let transparency = scrollTop / 100;
  let height = 120 - Math.min(60, scrollTop / 2);

  if (small) {
    transparency = 1;
    height = 60;
  }

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };

    if (!small) {
      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    } else return () => true;
  }, [scrollTop]);

  const [opened, setOpened] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setOpened(false); // Close the navigation panel
  }, [pathname]);

  return (
    <div
      style={{
        backgroundColor: `rgba(65, 57, 168, ${transparency})`,
        //backgroundColor: `linear-gradient(120deg, rgba(42,36,126, ${transparency}), rgba(69,71,181,${transparency}))`,
        height,
      }}
      className={`ctot-navbar ctot-navbar-${
        opened ? "opened" : "closed"
      } ctot-navbar-stretched`}
    >
      <HashLink smooth to={urlPrefix + "/#top"}>
        <img
          src="/images/logo/vector/default-monochrome-white-nosub.svg"
          alt="CTOT APP"
          className="logo"
        />
      </HashLink>
      <div
        className="ctot-navbar-open-button ctot-navbar-small-controls"
        onClick={() => setOpened(!opened)}
      >
        <FontAwesomeIcon icon={faBars} style={{ fontSize: 30 }} />
      </div>
      <div className="ctot-navbar-content" onClick={() => setOpened(!opened)}>
        <img
          src="/images/logo/vector/default-monochrome-white-nosub.svg"
          alt="CTOT APP"
          className="logo ctot-navbar-small-controls"
        />
        <HeaderPublicItems />
        <div className="ctot-navbar-close-button ctot-navbar-small-controls ctot-navbar-item">
          CLOSE
        </div>
      </div>
    </div>
  );
}
