import { Dropdown, Menu, Space } from "antd";
import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function HeaderPublicItems({ urlPrefix = "" }) {
  return (
    <Space style={{ marginLeft: "auto" }} size={20}>
      <HashLink smooth to={urlPrefix + "/#features"} className="ctot-navbar-item">
        FEATURES
      </HashLink>
      <HashLink smooth to={urlPrefix + "/#pricing"} className="ctot-navbar-item">
        PRICING
      </HashLink>
      
      <HashLink
        smooth
        to={urlPrefix + "/#integrations"}
        className="ctot-navbar-item"
      >
        API INTEGRATIONS
      </HashLink>

      <HashLink
        smooth
        to={urlPrefix + "/docs/introduction#top"}
        className="ctot-navbar-item"
      >
        DOCS
      </HashLink>

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <HashLink smooth to={urlPrefix + "/privacy-policy#top"}>
                PRIVACY POLICY
              </HashLink>
            </Menu.Item>
            <Menu.Item>
              <HashLink smooth to={urlPrefix + "/terms-conditions#top"}>
                TERMS AND CONDITIONS
              </HashLink>
            </Menu.Item>
          </Menu>
        }
        className="ctot-navbar-item"
      >
        <Link
          to="#"
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          LEGAL <DownOutlined />
        </Link>
      </Dropdown>
      <HashLink smooth to={urlPrefix + "/#contact"} className="ctot-navbar-item">
        CONTACT
      </HashLink>
      <a href="http://dashboard.ctot.app" className="ctot-navbar-item bordered">
        LOGIN or SIGNUP
      </a>
    </Space>
  );
}
