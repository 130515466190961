import * as React from "react";

import { useMutation } from "@apollo/client";

import { logoutGQL } from "../graphql/mutations";
import apolloClient from "../graphql/client";
import Loading from "./Loading";

import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

export default function Logout({ ...rest }) {
  let navigate = useNavigate();

  const [logout, { loading }] = useMutation(logoutGQL, {
    onCompleted: async () => {
      try {
        localStorage.removeItem("token");

        console.log("logging out");
        const cookies = new Cookies();
        cookies.remove("ctot.session");

        console.warn("Logged out");
      } catch (e) {
        console.log(e);
      } finally {
        try {
          await apolloClient.refetchQueries({
            include: ["user"],
          });
        } catch (e) {
          console.log("logout err", e);
        } finally {
          navigate("./");
        }
      }
    },
    onError: (err) => {
      console.log("logout errors", err);
      return;
    },
  });

  const handleClick = async () => {
    await logout();
  };

  return loading ? (
    <Loading />
  ) : (
    <div onClick={handleClick} type="link" size="small" {...rest}>
      Logout
    </div>
  );
}
