const delayCodes = [
  {
    code: "06",
    letters: "OA",
    details: "No gate/stand availability due to own airline activity",
    category: "Own airline",
  },
  {
    code: "09",
    letters: "SG",
    details: "Scheduled ground time less than declared minimum ground time",
    category: "Own airline",
  },
  {
    code: "11",
    letters: "PD",
    details: "Late check-in, acceptance of passengers after deadline",
    category: "Passengers/Baggage",
  },
  {
    code: "12",
    letters: "PL",
    details: "Late Check-in, congestion in check-in area",
    category: "Passengers/Baggage",
  },
  {
    code: "13",
    letters: "PE",
    details: "Check-in error",
    category: "Passengers/Baggage",
  },
  {
    code: "14",
    letters: "PO",
    details: "Oversales, booking errors",
    category: "Passengers/Baggage",
  },
  {
    code: "15",
    letters: "PH",
    details:
      "Boarding, discrepancies and paging, missing checked-in passenger at gate",
    category: "Passengers/Baggage",
  },
  {
    code: "16",
    letters: "PS",
    details:
      "Commercial Publicity, Passenger Convenience, VIP, Press, Ground meals and missing personal items",
    category: "Passengers/Baggage",
  },
  {
    code: "17",
    letters: "PC",
    details: "Catering order, late or incorrect order given to supplier",
    category: "Passengers/Baggage",
  },
  {
    code: "18",
    letters: "PB",
    details: "Baggage processing, sorting, etc.",
    category: "Passengers/Baggage",
  },
  {
    code: "19",
    letters: "PW",
    details:
      "Reduced Mobility, Boarding/Deboarding of passengers with reduced mobility",
    category: "Passengers/Baggage",
  },
  {
    code: "21",
    letters: "CD",
    details: "Documentation, errors, etc.",
    category: "Cargo/Mail",
  },
  {
    code: "22",
    letters: "CP",
    details: "Late positioning",
    category: "Cargo/Mail",
  },
  {
    code: "23",
    letters: "CC",
    details: "Late acceptance",
    category: "Cargo/Mail",
  },
  {
    code: "24",
    letters: "CI",
    details: "Inadequate packing",
    category: "Cargo/Mail",
  },
  {
    code: "25",
    letters: "CO",
    details: "Oversales, booking errors",
    category: "Cargo/Mail",
  },
  {
    code: "26",
    letters: "CU",
    details: "Late preparation in warehouse",
    category: "Cargo/Mail",
  },
  {
    code: "27",
    letters: "CE",
    details: "Mail Oversales, packing, etc.",
    category: "Cargo/Mail",
  },
  {
    code: "28",
    letters: "CL",
    details: "Mail Late positioning",
    category: "Cargo/Mail",
  },
  {
    code: "29",
    letters: "CA",
    details: "Mail Late acceptance",
    category: "Cargo/Mail",
  },
  {
    code: "31",
    letters: "GD",
    details:
      "Aircraft documentation late or inaccurate, weight and balance (Loadsheet), general declaration, passenger manifest, etc.",
    category: "Handling",
  },
  {
    code: "32",
    letters: "GL",
    details:
      "Loading, Unloading, bulky/special load, cabin load, lack of loading staff",
    category: "Handling",
  },
  {
    code: "33",
    letters: "GE",
    details:
      "Loading Equipment, lack of or breakdown, e.g. container pallet loader, lack of staff",
    category: "Handling",
  },
  {
    code: "34",
    letters: "GS",
    details:
      "Servicing Equipment, lack of or breakdown, lack of staff, e.g. steps",
    category: "Handling",
  },
  {
    code: "35",
    letters: "GC",
    details: "Aircraft Cleaning",
    category: "Handling",
  },
  {
    code: "36",
    letters: "GF",
    details: "Fuelling, Defuelling, fuel supplier",
    category: "Handling",
  },
  {
    code: "37",
    letters: "GB",
    details: "Catering, late delivery or loading",
    category: "Handling",
  },
  {
    code: "38",
    letters: "GU",
    details: "ULD, Containers, pallets, lack of or breakdown",
    category: "Handling",
  },
  {
    code: "39",
    letters: "GT",
    details:
      "Technical equipment, lack of or breakdown, lack of staff, e.g. pushback",
    category: "Handling",
  },
  {
    code: "41",
    letters: "TD",
    details: "Aircraft defects",
    category: "Technical",
  },
  {
    code: "42",
    letters: "TM",
    details: "Scheduled maintenance, late release",
    category: "Technical",
  },
  {
    code: "43",
    letters: "TN",
    details:
      "Non-scheduled maintenance, special checks and / or additional works beyond normal maintenance",
    category: "Technical",
  },
  {
    code: "44",
    letters: "TS",
    details: "Spares and maintenance equipment, lack of or breakdown",
    category: "Technical",
  },
  {
    code: "45",
    letters: "TA",
    details:
      "Aircraft on ground for technical reasons) Spares, to be carried to another station",
    category: "Technical",
  },
  {
    code: "46",
    letters: "TC",
    details: "Aircraft change for technical reasons",
    category: "Technical",
  },
  {
    code: "47",
    letters: "TL",
    details:
      "Standby aircraft, lack of planned standby aircraft for technical reasons",
    category: "Technical",
  },
  {
    code: "48",
    letters: "TV",
    details: "Scheduled cabin configuration and version adjustment",
    category: "Technical",
  },
  {
    code: "51",
    letters: "DF",
    details:
      "Damage during flight operations, bird or lightning strike, turbulence, heavy or overweight landing",
    category: "Damage/Failure",
  },
  {
    code: "52",
    letters: "DG",
    details:
      "Damage during ground operations, collisions (other than during taxiing), loading/offloading damage, contamination, towing, extreme weather conditions.",
    category: "Damage/Failure",
  },
  {
    code: "55",
    letters: "ED",
    details:
      "Departure Control System, Check-in, weight and balance (loadcontrol), computer system error, baggage sorting, gate-reader error or problems",
    category: "Damage/Failure",
  },
  {
    code: "56",
    letters: "EC",
    details: "Cargo preparation/documentation system",
    category: "Damage/Failure",
  },
  {
    code: "57",
    letters: "EF",
    details: "Flight plans",
    category: "Damage/Failure",
  },
  {
    code: "58",
    letters: "EO",
    details: "Other computer systems",
    category: "Damage/Failure",
  },
  {
    code: "61",
    letters: "FP",
    details: "Flight plan, late completion or change of flight documentation",
    category: "Operations",
  },
  {
    code: "62",
    letters: "FF",
    details: "Operational requirements, fuel, load alteration",
    category: "Operations",
  },
  {
    code: "63",
    letters: "FT",
    details: "Late crew boarding or departure procedures",
    category: "Operations",
  },
  {
    code: "64",
    letters: "FS",
    details: "Flight deck crew shortage, Crew rest",
    category: "Operations",
  },
  {
    code: "65",
    letters: "FR",
    details:
      "Flight deck crew special request or error not within operational requirements",
    category: "Operations",
  },
  {
    code: "66",
    letters: "FL",
    details: "Late cabin crew boarding or departure procedures",
    category: "Operations",
  },
  {
    code: "67",
    letters: "FC",
    details: "Cabin crew shortage",
    category: "Operations",
  },
  {
    code: "68",
    letters: "FA",
    details: "Cabin crew error or special request",
    category: "Operations",
  },
  {
    code: "69",
    letters: "FB",
    details: "Captain request for security check, extraordinary",
    category: "Operations",
  },
  {
    code: "71",
    letters: "WO",
    details: "Departure station",
    category: "Weather",
  },
  {
    code: "72",
    letters: "WT",
    details: "Destination station",
    category: "Weather",
  },
  {
    code: "73",
    letters: "WR",
    details: "En route or Alternate",
    category: "Weather",
  },
  {
    code: "75",
    letters: "WI",
    details: "De-Icing of aircraft, removal of ice/snow, frost prevention",
    category: "Weather",
  },
  {
    code: "76",
    letters: "WS",
    details: "Removal of snow/ice/water/sand from airport/runway",
    category: "Weather",
  },
  {
    code: "77",
    letters: "WG",
    details: "Ground handling impaired by adverse weather conditions",
    category: "Weather",
  },
  {
    code: "81",
    letters: "AT",
    details: "ATC restriction en-route or capacity",
    category: "Air Traffic Control",
  },
  {
    code: "82",
    letters: "AX",
    details:
      "ATC restriction due to staff shortage or equipment failure en-route",
    category: "Air Traffic Control",
  },
  {
    code: "83",
    letters: "AE",
    details: "ATC restriction at destination",
    category: "Air Traffic Control",
  },
  {
    code: "84",
    letters: "AW",
    details: "ATC restriction due to weather at destination",
    category: "Air Traffic Control",
  },
  {
    code: "85",
    letters: "AS",
    details: "Mandatory security",
    category: "Air Traffic Control",
  },
  {
    code: "86",
    letters: "AG",
    details: "Immigration, Customs, Health",
    category: "Air Traffic Control",
  },
  {
    code: "87",
    letters: "AF",
    details:
      "Airport Facilities, parking stands, ramp congestion, buildings, gate limitations, ...",
    category: "Air Traffic Control",
  },
  {
    code: "88",
    letters: "AD",
    details:
      "Restrictions at airport of destination, airport/runway closed due obstruction, industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights, ...",
    category: "Air Traffic Control",
  },
  {
    code: "89",
    letters: "AM",
    details:
      "Restrictions at airport of departure, airport/runway closed due obstruction, industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights, start-up and pushback, ...",
    category: "Air Traffic Control",
  },
  {
    code: "91",
    letters: "RL",
    details:
      "Passenger or Load Connection, awaiting load or passengers from another flight. Protection of stranded passengers onto a new flight.",
    category: "Miscellaneous",
  },
  {
    code: "92",
    letters: "RT",
    details: "Through Check-in error, passenger and baggage",
    category: "Miscellaneous",
  },
  {
    code: "93",
    letters: "RA",
    details:
      "Aircraft rotation, late arrival of aircraft from another flight or previous sector",
    category: "Miscellaneous",
  },
  {
    code: "94",
    letters: "RS",
    details: "Cabin crew rotation",
    category: "Miscellaneous",
  },
  {
    code: "95",
    letters: "RC",
    details:
      "Crew rotation, awaiting crew from another flight (flight deck or entire crew)",
    category: "Miscellaneous",
  },
  {
    code: "96",
    letters: "RO",
    details:
      "Operations control, rerouting, diversion, consolidation, aircraft change for reasons other than technical",
    category: "Miscellaneous",
  },
  {
    code: "97",
    letters: "MI",
    details: "Industrial action within own airline",
    category: "Miscellaneous",
  },
  {
    code: "98",
    letters: "MO",
    details: "Industrial action outside own airline, excluding ATS",
    category: "Miscellaneous",
  },
  {
    code: "99",
    letters: "MX",
    details: "Miscellaneous, not elsewhere specified",
    category: "Miscellaneous",
  },
];

export default delayCodes;
