import * as React from "react";
import { Routes, Route } from "react-router-dom";

import _ from "lodash";
import Header from "../components/Header";
import FlightsTable from "../components/FlightsTable";

export default function DashboardContent({ user }) {
  console.log("Dashboard content")
  return (
    <>
      <Header user={user} />

      <Routes>
        <Route exact path="/*" element={<FlightsTable />} />
      </Routes>
    </>
  );
}
