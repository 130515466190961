import React, { useEffect } from "react";

import _ from "lodash";

const ErrorMessage = (error) => {
  const messageFirstError = _.get(error, "error.graphQLErrors[0].message");

  useEffect(() => {
    if (messageFirstError == 4032) {
      alert("Subscription expired");
    }
  }, [messageFirstError]);

  if (messageFirstError == 4032) {
    return null;
  }

  if (messageFirstError == 4031) {
    var message = "User error - Please try to log out";
  } else {
    var message = "Connection error";
  }

  if (error.error?._errorCode === "404")
    message =
      "Flight not found. Displaying old result only.\nThis can be due to a cancelled or new flight plan.\nPlease go back and redo the search.";

  if (!error.error) return null;
  
  return (
    <div style={{ width: "100%", backgroundColor: "red", padding: 5 }}>
      <div style={{ color: "white", textAlign: "center" }}>{message}</div>
    </div>
  );
};

export default ErrorMessage;
