import * as React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { ConfigProvider, theme } from "antd";

import "./styles/css/index.css";
import MainLanding from "./ui/MainLanding";

export default function Landing() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        hashed: false,
        token: {
          // Seed Token
          //colorPrimary: "#2A247E",
          colorPrimary: "#4547B5",
          borderRadius: 2,

          colorText: "white",
          colorTextHeading: "white",

          // Alias Token
          colorBgContainer: "#262430",
          fontFamily:
            "'IBM Plex Sans', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
        components: {
          Table: {
            colorBgContainer: "#211E29",
          },
          Input: {
            colorBgContainer: "#262430",
            borderRadius: 4,
            controlOutlineWidth: 0,
            lineWidth: 0,
          },
          Button: {
            lineWidth: 0,
            defaultShadow: "none",
            primaryShadow: "none",
            fontWeight: 500,
            
          },
          Breadcrumb: {
            fontSize: 18,
          },
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainLanding />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}
