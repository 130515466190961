import * as React from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />;

const Loading = () => <Spin indicator={antIcon} />;

export default Loading;
