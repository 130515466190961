import { Button, Form, Input, theme } from "antd";
import React from "react";
import { requestMagicLinkGQL } from "../graphql/mutations";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";

export default function LoginForm() {
  const [step, setStep] = React.useState(0);

  const [form] = Form.useForm();

  let [mutateLogin, { loading }] = useMutation(requestMagicLinkGQL, {
    refetchQueries: ["user"],
    onError: (err) =>
      console.error("Something went wrong \n" + JSON.stringify(err)),
  });

  const handleLogin = () => {
    const email = form.getFieldValue("email");

    mutateLogin({
      variables: {
        email: email.trim(),
        callbackUri:
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname,
        platform: "web",
      },
      onCompleted: () => setStep(1),
    });

    //login();
  };

  const { token } = theme.useToken();

  return step ? (
    <div style={{ maxWidth: "60ch" }}>
      Check your emails and click on the magic login link 🪄 we just sent to{" "}
      <strong>{form.getFieldValue("email")}</strong>.
      <br />
      <br />
      <Button
        type="ghost"
        onClick={() => setStep(0)}
        style={{ color: token.colorLink }}
      >
        Go back
      </Button>
    </div>
  ) : (
    <Form form={form} autoComplete="off" style={{ maxWidth: "60ch" }}>
      <Form.Item
        name="email"
        //label="Email"
        validateTrigger="onBlur"
        rules={[
          {
            type: "email",
            required: true,
            message: "Insert a valid email",
          },
        ]}
      >
        <Input
          type="text"
          placeholder="Enter your email"
          style={{ textAlign: "center" }}
        />
      </Form.Item>

      <Form.Item>
        By clicking the next button you accept our{" "}
        <Link to={"https://www.ctot.app/terms-conditions#top"} target="_blank">
          Terms and Conditions
        </Link>{" "}
        as well as our{" "}
        <Link to={"https://www.ctot.app/privacy-policy#top"} target="_blank">
          Privacy policy
        </Link>
      </Form.Item>

      <Form.Item>
        <SubmitButton
          form={form}
          type="primary"
          block
          loading={loading}
          onClick={handleLogin}
        >
          CONTINUE
        </SubmitButton>
      </Form.Item>
    </Form>
  );
}

const SubmitButton = ({ form, children, loading, ...rest }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable || loading}
      {...rest}
    >
      {children}
    </Button>
  );
};
