import { gql } from "@apollo/client";

export const logoutGQL = gql`
  mutation logout {
    logout
  }
`;

export const requestMagicLinkGQL = gql`
  mutation loginRequest(
    $email: String!
    $callbackUri: String
    $platform: String
  ) {
    loginRequest(
      email: $email
      callbackUri: $callbackUri
      platform: $platform
    ) {
      type
    }
  }
`;

export const stripeCancelSubscriptionGQL = gql`
  mutation stripeCancelSubscription($subscriptionId: String!) {
    stripeCancelSubscription(subscriptionId: $subscriptionId)
  }
`;
