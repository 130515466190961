import { Button, Row, Space } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import Typist from "react-typist-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";
import { faMap, faBell } from "@fortawesome/free-regular-svg-icons";
import {
  faGlobe,
  faCheck,
  faSync,
  faListUl,
  faSearch,
  faZap,
  faServer,
  faExternalLink,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import FeatureWithIcon from "../components/FeatureWithIcon";
import SubscriptionBusinessAppsCard from "../components/SubscriptionBusinessAppsCard";
import SubscriptionBusinessApiCard from "../components/SubscriptionBusinessApiCard";

export default function HomeContent() {
  const [count, setCount] = React.useState(1);

  React.useEffect(() => {
    // document.title = `You clicked ${count} times`;
    console.log("Count: " + count);
    setCount(1);
  }, [count]);

  return (
    <>
      <div
        className="landing-section landing-section-background landing-section-first"
        id="top"
      >
        <div className="landing-header">
          <div className="landing-header-content">
            <h1>
              MOBILE APPS <br />
              FOR <br />
              <div
                style={{
                  color: "#72DDEB",

                  minHeight: "2em",
                }}
              >
                <Typist key={count} onTypingDone={() => setCount(count + 1)}>
                  PILOTS
                  <Typist.Delay ms={800} />
                  <Typist.Backspace count={6} />
                  ATC
                  <Typist.Delay ms={800} />
                  <Typist.Backspace count={3} />
                  GROUND CREW
                  <Typist.Delay ms={800} />
                  <Typist.Backspace count={11} />
                  OPERATORS
                  <Typist.Delay ms={800} />
                  <Typist.Backspace count={9} />
                </Typist>
              </div>
            </h1>

            <p>
              CTOT app offers you the possibilty to check live CTOT information
              about the flights in European airspace.
            </p>
            <Space wrap className="landing-app-links">
              <a
                href="https://apps.apple.com/app/ctot/id1513174766#?platform=iphone"
                target="blank"
              >
                <Button type="primary" size="large">
                  <FontAwesomeIcon icon={faApple} style={{ width: 15 }} />
                  &nbsp;GET iOS APP
                </Button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=app.ctot.ctotapp"
                target="blank"
              >
                <Button type="primary" size="large">
                  <FontAwesomeIcon icon={faAndroid} style={{ width: 15 }} />
                  &nbsp;GET ANDROID APP
                </Button>
              </a>
              <a href="https://dashboard.ctot.app">
                <Button type="primary" size="large">
                  <FontAwesomeIcon icon={faGlobe} style={{ width: 15 }} />
                  &nbsp;WEB BROWSER VERSION
                </Button>
              </a>
            </Space>
          </div>

          <div className="landing-header-content">
            <div className="container-header-image">
              <img
                src="/images/screenshots/alldevices.png"
                alt="alternative"
                className="landing-header-image"
                style={{ maxHeight: "50vh" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="landing-section" id="features">
        <h1>APP FEATURES</h1>
        <p>
          CTOT was designed to save time. It is used on the ramp and in the
          cockpit by crews to deal with{" "}
          <span className="blue">flight plan issues</span>. Unleash your crew's
          abilities !
        </p>
        <br />
        <Row style={{ justifyContent: "center" }}>
          <div className="landing-features">
            <div direction="vertical" className="landing-features-list">
              <FeatureWithIcon
                title="Search flight"
                subtitle="Search by aircraft registration, flight number, or airports."
                icon={faSearch}
                textAlign="right"
              />
              <FeatureWithIcon
                title="Flight plan status"
                subtitle="Check if your flight plan is still active or not."
                icon={faCheck}
                textAlign="right"
              />
              <FeatureWithIcon
                title="Live information"
                subtitle="View the latest data, automatic refresh."
                icon={faSync}
                textAlign="right"
              />
            </div>

            <div className="landing-features-image">
              <img
                src="/images/screenshots/details.png"
                alt="alternative"
                width="100%"
              />
            </div>

            <div direction="vertical" className="landing-features-list">
              <FeatureWithIcon
                title="Notifications"
                subtitle="Get a notification when your CTOT changes or appears"
                icon={faBell}
                reverse
              />

              <FeatureWithIcon
                title="Restriction details map"
                subtitle="Find where is the CTOT restriction and get the details."
                icon={faMap}
                reverse
              />

              {/* <FeatureWithIcon
                title="Ready Message"
                subtitle="Send a ready message from the app. For AIRLINE & HANDLER only."
                icon={faRocket}
                reverse
              /> */}
              <FeatureWithIcon
                title="Favorite list"
                subtitle="Save your favorite flights, update all of them with one swipe."
                icon={faListUl}
                reverse
              />
            </div>
          </div>
        </Row>
      </div>

      <div className="landing-section center">
        <h1>SAVE TIME WITH OUR APPS</h1>
        <p>
          In an office, in a flight deck or on the apron, there is a solution to
          improve your access to flight plan data.
        </p>
        <Row style={{ justifyContent: "center" }}>
          <Space wrap>
            <div style={{ width: "30vw" }}>
              <img
                className="img-fluid"
                src="/images/screenshots/macbook.png"
                alt="alternative"
                width="100%"
              />
            </div>
            <div style={{ width: "8vw" }}>
              <img
                className="img-fluid"
                src="/images/screenshots/map.png"
                alt="alternative"
                width="100%"
              />
            </div>

            <Space direction="vertical" style={{ width: 300 }}>
              <FeatureWithIcon
                title="Browser based version"
                subtitle="View all your flights and identify the flight plan issues"
                icon={faGlobe}
                reverse
              />
              <FeatureWithIcon
                title="iOS App"
                subtitle="Find it in the App Store"
                icon={faApple}
                reverse
              />
              <FeatureWithIcon
                title="Android App"
                subtitle="Find it in the Play Store"
                icon={faAndroid}
                reverse
              />
            </Space>
          </Space>
        </Row>
      </div>

      <div
        className="landing-section landing-section-contact"
        id="integrations"
      >
        <h1 style={{ textAlign: "center", marginBottom: 0 }}>
          API INTEGRATIONS
        </h1>
        <h3
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: 400,
            fontSize: 24,
            marginTop: 0,
          }}
        >
          Integrate your business ecosystem with CTOT data
        </h3>

        <FeatureWithIcon
          title="API and Webhook notifications"
          subtitle={
            <>
              Access your flight data and receive webhook notification directly
              with the API, without the need for an app. <br />
              Build your custom integrations.
              <br />
              Manage your users access to the CTOT apps. <br />
              <br />
              <Link to={"./docs"}>
                <Button type="primary" size="large">
                  <FontAwesomeIcon icon={faBook} />
                  &nbsp;&nbsp;Explore our docs
                </Button>
              </Link>
            </>
          }
          icon={faServer}
          reverse
          vertical
        />
        <br />
        <br />

        <FeatureWithIcon
          title="Automations with Zapier"
          subtitle={
            <>
              Create automations with Zapier. No code. Ready in 5 minutes.
              <br />
              For example: send SMS with CTOT change or consolidate flight data
              in spreadsheets.
              <br />
              <br />
              <a href="https://zapier.com" target="_blank">
                <Button type="primary" size="large">
                  <FontAwesomeIcon icon={faExternalLink} />
                  &nbsp;&nbsp;Explore what{" "}
                  <img
                    src="/images/zapier-logo.png"
                    height={17}
                    style={{
                      paddingBottom: 1,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  />{" "}
                  can do
                </Button>
              </a>
            </>
          }
          icon={faZap}
          reverse
          vertical
        />
      </div>

      <div className="landing-section" id="pricing">
        <h1 style={{ textAlign: "center" }}>PRICING</h1>
        <Space align="block" size={40} wrap>
          <SubscriptionBusinessAppsCard />
          <SubscriptionBusinessApiCard />
        </Space>

        {/*  <Subscriptions
          noTitle={true}
          onClick={() => (window.location.href = "https://dashboard.ctot.app")}
          style={{display: "flex", justifyContent: "center"}}
        /> */}

        {/*  <div className="landing-section-price-calculator">
          <PriceCalculator />
        </div> */}
      </div>

      <div
        className="landing-section landing-section-contact"
        id="contact"
        style={{ justifyContent: "center" }}
      >
        {/* <div className="center">
          <Title level={1}>
            ARE YOU AN <span className="blue">AIRCRAFT OPERATOR</span> OR{" "}
            <span className="blue">HANDLER</span> ?
          </Title>
          You could benefit from custom features ! Contact us to know more.
          <br />
          We have a Business subscription that allows you to add CTOT app users
          to your team. You can add as many users as you want. It is very easy
          through our administration console. No need for complicated
          authentification system. Simply copy/paste the emails of your users
          and we will send them a link to login the app.
        </div> */}
        <div className="center" style={{ marginTop: 0, paddingTop: 0 }}>
          <Title level={1}>CONTACT</Title>
          Don't hesitate to contact us :)
          <br />
          <a className="blue" href="mailto:contact@ctot.app">
            <h3 className="blue">contact@ctot.app</h3>
          </a>
        </div>
      </div>
    </>
  );
}
