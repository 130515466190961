import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Logout from "./Logout";
import { Link } from "react-router-dom";
import _ from "lodash";

export default function ProfileDropdown({ user, ...rest }) {
  const contracts = _.get(user, "contracts", []);

  const hasContract = contracts?.length > 0;

  let items = [
    {
      key: 1,
      label: <Link to="/profile">{user?.workEmail || user?.email}</Link>,
    },
    {
      key: 2,
      type: "divider",
    },
  ];

  if (hasContract) {
    items.push({
      key: 5,
      label: (
        <Link to="http://account.ctot.app" className="ctot-navbar-dashboard-item" target="_blank">
          Business account
        </Link>
      ),
    });
  }

  items.push({
    key: 6,
    label: <Logout className="ctot-navbar-dashboard-item" />,
  });

  return (
    <Dropdown menu={{ items }} {...rest} className="ctot-navbar-dashboard-item">
      <div href="" onClick={(e) => e.preventDefault()}>
        ACCOUNT <DownOutlined />
      </div>
    </Dropdown>
  );
}
