import React from "react";
import Title from "antd/lib/typography/Title";
import { List } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function EndOfService() {
  return (
    <div
      className="ctot-docs ctot-docs-container"
      style={{
        fontSize: 15,
        fontWeight: 400,
        color: "#EEE",
        flexDirection: "column",
        padding: "10%",
      }}
    >
      <Title level={1} style={{ marginLeft: -5 }}>
        End of service for individual users
      </Title>
      <div>
        <p>Dear CTOT user,</p>
        <p>
          We have the deep regret to inform you that CTOT will no longer be able
          to supply you with flight data services from January 1st, 2024
          following a unilateral decision from Eurocontrol.
          <br />
          <br />
          Despite our efforts to keep your access open, Eurocontrol decided to
          stop supporting any app providing data to individual users, i.e. not
          enrolled directly by an organization having special arrangements with
          us and Eurocontrol. We are really sorry about this unexpected outcome
          and the associated consequences for your operations.
          <br />
          <br />
          You can find more details on what happened and what to expect in your
          email box where a detailed FAQ is provided.
          <br />
          <br />
          We would like to thank you for being part of this adventure and hope
          to see you again as quickly as possible once necessary agreements are
          in place.
          <br />
          <br />
          Kind regards
        </p>
      </div>

      <div>
        <Title level={4}>IMPORTANT: SUBSCRIPTIONS AND REFUND</Title>
        <p>
          All users have now be refunded automatically. This has been done
          either via the Apple App Store or via the credit card used for your
          subscription is you payed by card. Please allow a few days for the
          refund to appear on your account.
        </p>
      </div>
    </div>
  );
}
