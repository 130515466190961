import * as React from "react";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DashboardContent from "./ui/DashboardContent";
import LoginPage from "./ui/LoginPage";
import { userWithContractsGQL } from "./graphql/queries";
import useLoginLink from "./components/useLoginLink";

export default function AppAuth() {
  useLoginLink();

  let { loading, data } = useQuery(userWithContractsGQL, {
    errorPolicy: "ignore",
  });

  const user = _.get(data, "user", {});
  const isLogged = !loading && user.email;

  console.log("AppAuth", loading, isLogged);

  if (loading)
    return (
      <div
        style={{
          height: "80vh",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </div>
    );

  // Redirect to the first contract
  if (!loading && isLogged) {
    return <DashboardContent user={user} />;
  } else {
    return <LoginPage />;
  }
}
